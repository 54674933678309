import { formatedSubtitleConfig } from "../../../helpers/formatTempelates";
import { Tabs, TabsProps } from "antd";
import { CustomThumpnail } from "../../../remotion/Composition";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { colors } from "../../../Themes/colors";
import { applyTemplate } from "../../../redux/Slices/clipEditingSlice";
import { useMemo } from "react";
import { ISubtitleStyleEnum } from "../../../types/interfaces";
import { getPersonsDimensions } from "../../../remotion/subtitles/utils";
import {
  PLAYER_HEIGHT,
  PLAYER_HEIGHT_SCALE,
  PLAYER_WIDTH,
  PLAYER_WIDTH_SCALE,
} from "../../../constants/constants";

const SubtitleTempelates = () => {
  const dispatch = useAppDispatch();

  const { video, selectedClip } = useAppSelector((state) => state.videoDetails);
  const { selectedTemplate, selectedFaceIndex, editorClipScenes } =
    useAppSelector((state) => state.clipEditing);
  const temps = useMemo(
    () => formatedSubtitleConfig({ selectedClip }),
    [selectedClip]
  );

  const updateClipConfig = (id: string) => {
    return dispatch(applyTemplate({ id, temps }));
  };

  const words = useMemo(() => {
    if (!selectedClip) return [];

    return selectedClip.words.map((word) => ({
      ...word,
      start: word.start - selectedClip.start,
      end: word.end - selectedClip.start,
    }));
  }, [selectedClip]);

  const transform = useMemo(() => {
    if (!video || !selectedClip) {
      return "";
    }
    const videoHeight = video && video?.videoHeight;
    const videoWidth = video && video?.videoWidth;
    const objectTop =
      selectedClip?.facesInfo &&
      selectedClip?.facesInfo[selectedFaceIndex]?.BoundingBox.Top;
    const objectLeft =
      selectedClip?.facesInfo &&
      selectedClip?.facesInfo[selectedFaceIndex]?.BoundingBox.Left;
    const objectWidth =
      selectedClip?.facesInfo &&
      selectedClip?.facesInfo[selectedFaceIndex]?.BoundingBox.Width;
    const objectHeight =
      selectedClip?.facesInfo &&
      selectedClip?.facesInfo[selectedFaceIndex]?.BoundingBox.Height;
    if (
      typeof videoHeight === "number" &&
      typeof videoWidth === "number" &&
      typeof objectTop === "number" &&
      typeof objectLeft === "number" &&
      typeof objectWidth === "number" &&
      typeof objectHeight === "number"
    ) {
      const { translateX, translateY, scale } = getPersonsDimensions({
        videoOriginDimesnsions: {
          videoHeight,
          videoWidth,
        },
        objectTop,
        objectLeft,
        objectWidth,
        objectHeight,
      });
      const transform = `scale(${scale}, ${scale}) translate(${Math.ceil(
        translateX
      )}px, ${Math.ceil(translateY)}px)`;

      return transform;
    }
    return "";
  }, [selectedFaceIndex, selectedClip, video]);

  const tabsItems: TabsProps["items"] = [
    {
      label: "Templates",
      key: "1",
      children: (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            overflow: "scroll",
            height: "92vh",
            width: "100%",
            rowGap: "10px",
            gap: "5px",
          }}
        >
          {video?.videoUrl &&
            selectedClip &&
            temps.map((temp) => {
              return (
                <div
                  key={temp.id}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    updateClipConfig(temp.id);
                  }}
                >
                  <div
                    style={{
                      width: "fit-content",
                      cursor: "pointer",
                      border:
                        selectedTemplate === temp.id
                          ? `5px solid ${colors.mainColor}`
                          : "5px solid transparent",
                      borderRadius: "15px",
                    }}
                  >
                    <CustomThumpnail
                      source={selectedClip.thumbnailUrl}
                      selectedFaceIndex={selectedFaceIndex}
                      onSelectedFaceIndexChange={() => {}}
                      textOverlays={temp.textOverlays}
                      mediaOverlays={temp.mediaOverlays}
                      startFrom={words[0]?.start}
                      endTime={selectedClip.end}
                      words={words}
                      framesPerSecond={30}
                      clipTargetsPersonsDimensions={selectedClip?.facesInfo}
                      videoOriginDimesnsions={{
                        videoHeight: video.videoHeight,
                        videoWidth: video.videoWidth,
                      }}
                      subtitlesConfig={temp?.subTitleConfig}
                      selectedId=""
                      onSelectedChange={() => {}}
                      onMediaOverlayTransformChange={() => {}}
                      onSubtitlesTransformChange={() => {}}
                      onTextOverlayTransformChange={() => {}}
                      subtitleStyle={
                        temp?.subtitleStyles
                          ?.subtitleStyle as ISubtitleStyleEnum
                      }
                      subtitleEffectColor={
                        temp?.subtitleStyles?.subtitleEffectColor
                      }
                      subtitleEffectBGColor={
                        temp.subtitleStyles.subtitleEffectBGColor
                      }
                      width={PLAYER_WIDTH}
                      height={PLAYER_HEIGHT}
                      widthScale={PLAYER_WIDTH_SCALE}
                      heightScale={PLAYER_HEIGHT_SCALE}
                      transform={transform}
                      onSceneScreenTransformChange={() => {}}
                      hasWatermark={false}
                      scenes={editorClipScenes}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      ),
    },
    // {
    //   label: "My Templates",
    //   key: "2",
    //   children: "",
    // },
  ];
  return (
    <div>
      <Tabs
        defaultActiveKey="3"
        // tabPosition={"right"}
        // className={styles.tabs}
        style={{ backgroundColor: "white", marginTop: 10, padding: 10 }}
        items={tabsItems}
      />
    </div>
  );
};

export default SubtitleTempelates;

import { PLAYER_HEIGHT, PLAYER_WIDTH } from "../../constants/constants";
import { IEditorClipScene, ISubtitlesConfig, getPersonsDimensionsProps } from "../../types/interfaces";
import { ClipProps, ClipScene } from "../../types/models";
import { FontFamilyEnum, getFontFamily } from "./fonts";


export function configToBlockStyles(config: ISubtitlesConfig, { widthScale, heightScale } = { widthScale: 1, heightScale: 1 }) {
    const style: any = {
        fontSize: applyDiagonalScaling(config.fontSize!, { widthScale, heightScale }) + 'px',
        textAlign: config.textAlign,
        textTransform: config.textTransform,
        borderRadius: applyDiagonalScaling(5, { widthScale, heightScale }),
        color: config.color,
        lineHeight: config.lineHeight!,
        fontFamily: getFontFamily(config.fontFamily),
        transform: applyTransformScaling(config.transform, { widthScale, heightScale })
    };
    if (config.bold) {
        style.fontWeight = 'bold';
    }
    if (config.italic) {
        style.fontStyle = 'italic';
    }
    if (config.underline) {
        style.textDecoration = 'underline';
    }
    if (config.blockBG.enabled) {
        style.padding = applyDiagonalScaling(config.blockBG.padding!, { widthScale, heightScale }) + 'px';
        style.borderRadius = applyDiagonalScaling(config.blockBG.borderRadius!, { widthScale, heightScale }) + 'px';
        style.backgroundColor = config.blockBG.color;
    }

    return style;
}

export function configToInlineStyles(config: ISubtitlesConfig, { widthScale, heightScale } = { widthScale: 1, heightScale: 1 }) {
    const style: any = {
        display: 'inline-block',
        fontFamily: getFontFamily(config.fontFamily),
        fontSize: applyDiagonalScaling(config.fontSize!, { widthScale, heightScale }) + 'px',
    };
    if (config.textBG.enabled) {
        style.backgroundColor = config.textBG.color;
        style.padding = applyDiagonalScaling(config.textBG.padding!, { widthScale, heightScale }) + 'px';
        style.borderRadius = applyDiagonalScaling(config.textBG.borderRadius!, { widthScale, heightScale }) + 'px';
        style.margin = applyDiagonalScaling(config.textBG.margin!, { widthScale, heightScale }) + 'px';
    }
    if (config.textOutline.enabled) {
        const outlineWidth = config.textOutline.width * widthScale;

        style.textShadow = `0px 0px ${outlineWidth}px ${config.textOutline.color},
     0px 0px ${outlineWidth}px ${config.textOutline.color},0px 0px ${outlineWidth}px ${config.textOutline.color}
     ,0px 0px ${outlineWidth}px ${config.textOutline.color},0px 0px ${outlineWidth}px ${config.textOutline.color}
     ,0px 0px ${outlineWidth}px ${config.textOutline.color},0px 0px ${outlineWidth}px ${config.textOutline.color}
     ,0px 0px ${outlineWidth}px ${config.textOutline.color},0px 0px ${outlineWidth}px ${config.textOutline.color}
     ,0px 0px ${outlineWidth}px ${config.textOutline.color},0px 0px ${outlineWidth}px ${config.textOutline.color}
     ,0px 0px ${outlineWidth}px ${config.textOutline.color},0px 0px ${outlineWidth}px ${config.textOutline.color}
     ,0px 0px ${outlineWidth}px ${config.textOutline.color},0px 0px ${outlineWidth}px ${config.textOutline.color}
     ,0px 0px ${outlineWidth}px ${config.textOutline.color},0px 0px ${outlineWidth}px ${config.textOutline.color}
     ,0px 0px ${outlineWidth}px ${config.textOutline.color},0px 0px ${outlineWidth}px ${config.textOutline.color}`
    }
    if ('textShadow' in style && config.textShadow.enabled) {
        style.textShadow += `, ${config.textShadow.offsetX}px ${config.textShadow.offsetY}px ${config.textShadow.blur}px ${config.textShadow.color}`;
    } else if (config.textShadow.enabled) {
        style.textShadow = `${config.textShadow.offsetX}px ${config.textShadow.offsetY}px ${config.textShadow.blur}px ${config.textShadow.color}`;
    }
    if (config.underline) {
        style.textDecoration = 'underline';
    }

    return style;
}

export function getDefaultTextConfig() {
    const textConfig: ISubtitlesConfig = {
        fontSize: 20,
        color: "rgba(255,255,255,1)",
        bold: false,
        italic: true,
        underline: false,
        textAlign: "center",
        textTransform: "uppercase",
        textBG: { color: "rgba(255,255,255,1)", enabled: false, padding: 0, borderRadius: 5, margin: 5 },
        blockBG: { color: "rgba(255,255,255,1)", enabled: true, padding: 5, borderRadius: 5 },
        textOutline: { color: "rgba(255,255,255,1)", enabled: true, width: 0 },
        textShadow: { color: "rgba(255,255,255,1)", enabled: true, offsetX: 0, offsetY: 0, blur: 0 },
        fontFamily: FontFamilyEnum.OpenSans,
        lineHeight: 1.2,
        transform: 'translate(0px, 100px)'
    };

    return textConfig;
}

export function applyDiagonalScaling(value: number, { widthScale, heightScale }: { widthScale: number, heightScale: number }) {
    return value * Math.sqrt(widthScale * widthScale + heightScale * heightScale);
}

export function applyTransformScaling(transform: string, { widthScale, heightScale } = { widthScale: 1, heightScale: 1 }) {

    const numberRe = /([+-]?\d*\.?\d+)/
    const parts = transform.trim().match(/(scale\(([+-]?\d*\.?\d+), ([+-]?\d*\.?\d+)\))?\s?(translate\(([+-]?\d*\.?\d+)px,\s([+-]?\d*\.?\d+)px\))\s?(rotate\(([+-]?\d*\.?\d+)deg\))?/);
    const scaleParts = transform.trim().match(/(scale\(([+-]?\d*\.?\d+), ([+-]?\d*\.?\d+)\))/);
    const translateParts = transform.trim().match(/(translate\(([+-]?\d*\.?\d+)px,\s([+-]?\d*\.?\d+)px\))/);
    const rotateParts = transform.trim().match(/(rotate\(([+-]?\d*\.?\d+)deg\))/);


    let translateX = 0;
    let translateY = 0;
    let rotate = 0;
    let scaleX = 1;
    let scaleY = 1;

    if (!parts) {
        return;
    }

    if (translateParts?.length) {
        if (translateParts[2]) {
            translateX = parseFloat(translateParts[2]) * widthScale;
        }
        if (translateParts[3]) {
            translateY = parseFloat(translateParts[3]) * heightScale;
        }
    }

    if (rotateParts?.length) {
        rotate = parseFloat(rotateParts[2]);
    }

    if (scaleParts && scaleParts[2]) {
        scaleX = parseFloat(scaleParts[2]);
    }

    if (scaleParts && scaleParts[3]) {
        scaleY = parseFloat(scaleParts[3]);
    }

    const result = `scale(${scaleX}, ${scaleY}) translate(${translateX}px, ${translateY}px) rotate(${rotate}deg)`;
    return result
}

export const getPersonsDimensions = ({
    videoOriginDimesnsions,
    objectTop,
    objectLeft,
    objectWidth,
    objectHeight,
    position = "top"
}: getPersonsDimensionsProps) => {
    const playerWidth = PLAYER_WIDTH;
    const playerHeight = PLAYER_HEIGHT;
    const { videoHeight, videoWidth } = videoOriginDimesnsions;
    const personWidth = objectWidth * videoWidth;
    const personHeight = objectHeight * videoHeight;

    const personLeft = objectLeft * videoWidth;
    const personTop = objectTop * videoHeight;
    let widthDiff = personWidth < playerWidth ? playerWidth - personWidth : 0;

    const heightDiff =
        personHeight < playerHeight ? playerHeight - personHeight : 0;

    let translateX = playerWidth / 2 - (personLeft + personWidth / 2);
    let translateY = playerHeight / 2 - (personTop + personHeight / 2);

    let scale = 1
    // translateX = - personLeft;
    // translateY = -personTop;
    if (position === 'center') {
        if (translateX > 0 && translateX < playerWidth) {
            translateX = 0;
        } else if (personLeft + personWidth / 2 + playerWidth / 2 > videoWidth) {
            translateX = -videoWidth + playerWidth;
        }

        if (translateY > 0 && translateY < playerHeight) {
            translateY = 0;
        } else if (personTop + personHeight / 2 + playerHeight / 2 > videoHeight) {
            translateY = -videoHeight + playerHeight;
        } else if (translateY > -playerHeight / 2) {
            console.log("playher")
        }

        translateX = translateX - (1 - scale) * videoWidth / 2;
        translateY = translateY - (1 - scale) * videoHeight / 2;

    } else {
        /**
         * We need the person height to be the same as playerHeight/2
         * personHeight * scale = playerHieght/2
         * So we get the scale by the following
         */
        scale = playerHeight / 2 / personHeight;
        // const scaledVideoWidth = videoWidth * scale;
        // const scaledVideoHeight = videoHeight * scale;
        /**
         * Next, we reset the translateX & translateY to accomodate for the scale
         * by placing them to the new top=0,left=0 coordinates
         */
        // translateX = - (objectLeft * scaledVideoWidth + objectWidth * scaledVideoWidth / 2) - playerWidth / 2;
        translateX = - personLeft
        translateY = position === 'top' ? -personTop : playerHeight / 2 + (1 - scale) * playerHeight;
        /**
         * Next, we move things around to center the person in the screen
         */


    }

    return {
        personWidth,
        personHeight,
        personTop,
        personLeft,
        widthDiff,
        heightDiff,
        translateX,
        translateY,
        scale,
    };
};

export function formatClipScenes({ selectedClip, videoWidth, videoHeight }: { selectedClip: ClipProps, videoWidth: number, videoHeight: number }) {

    const scenes: IEditorClipScene[] = selectedClip.scenes
        ?.slice()
        ?.map((item) => ({ ...item, screens: [], splitScreen: item.noOfSpeakers > 1 }));

    if (scenes?.length) {
        scenes.forEach((scene, sceneIndex) => {
            // scene.start = scene.start - selectedClip.start;
            // scene.end = scene.end - selectedClip.start;

            const speakersIds = scene.speakersIds
                ?.split("_")
                .map((item) => parseInt(item));
            if (!speakersIds?.length) {
                return;
            }

            type speakerPositionType = 'center' | 'top' | 'bottom';

            const slicedSpeakerIds = speakersIds.slice(0, 2);
            const speakersPositions: speakerPositionType[] = [];
            if (slicedSpeakerIds.length === 1) {
                speakersPositions.push('center')
            } else if (slicedSpeakerIds.length === 2) {
                const firstSpeaker = scene.speakers.find((item) => item.id === slicedSpeakerIds[0])!;
                const secondSpeaker = scene.speakers.find((item) => item.id === slicedSpeakerIds[1])!;
                // place the nearest to the bottom at top
                // place nearest to top at bottom
                if (firstSpeaker.y < secondSpeaker.y) {
                    speakersPositions.push('top')
                    speakersPositions.push('bottom')
                } else {
                    speakersPositions.push('bottom')
                    speakersPositions.push('top')
                }
                // check the bottom space for the top element, the top space of the bottom element
            }

            slicedSpeakerIds.forEach((speakerId, speakerIndex) => {
                const speaker = scene.speakers.find((item) => item.id === speakerId);
                if (!speaker) {
                    return;
                }

                if (!scene.screens?.length) {
                    scene.screens = [];
                }


                let position: speakerPositionType = speakersPositions[speakerIndex];
                // speakersIds.length === 1 ? 'center' : speakerIndex === 0 ? 'top' : 'bottom'

                const { translateX, translateY, scale } = getPersonsDimensions({
                    videoOriginDimesnsions: {
                        videoHeight,
                        videoWidth,
                    },
                    objectTop: speaker.y / videoHeight,
                    objectLeft: speaker.x / videoWidth,
                    objectWidth: speaker.width / videoWidth,
                    objectHeight: speaker.height / videoHeight,
                    position
                });
                const transform = `scale(${scale}, ${scale}) translate(${Math.ceil(
                    translateX
                )}px, ${Math.ceil(translateY)}px) rotate(0deg)`;

                scene.screens.push({
                    speakerId: speaker.id,
                    transform,
                });
            });
        });
    } else {
        // Fallback if there's no scenes to a signle scene
        const scene: IEditorClipScene = {
            // start: 0,
            // end: selectedClip.end - selectedClip.start,
            start: selectedClip.start,
            end: selectedClip.end,
            noOfSpeakers: selectedClip.facesInfo.length,
            speakers: selectedClip.facesInfo.map((face, index) => ({
                width: face.BoundingBox.Width * videoWidth,
                height: face.BoundingBox.Height * videoHeight,
                frame: -1,
                time: selectedClip.start,
                id: index + 1,
                x: face.BoundingBox.Left * videoWidth,
                y: face.BoundingBox.Top * videoHeight,
            })),
            speakersIds: selectedClip.facesInfo
                .map((_, index) => index + 1)
                .join("_"),
            splitScreen: selectedClip.facesInfo.length > 1,
            screens: selectedClip.facesInfo.map((face, index) => {
                const objectTop = selectedClip?.facesInfo && face.BoundingBox.Top;
                const objectLeft = selectedClip?.facesInfo && face.BoundingBox.Left;

                const objectWidth = selectedClip?.facesInfo && face.BoundingBox.Width;
                const objectHeight =
                    selectedClip?.facesInfo && face.BoundingBox.Height;

                let position: 'center' | 'top' | 'bottom' = 'center';
                // : selectedClip.facesInfo.length === 1 ? 'center' : index === 0 ? 'top' : 'bottom'
                const { translateX, translateY, scale } = getPersonsDimensions({
                    videoOriginDimesnsions: {
                        videoHeight,
                        videoWidth,
                    },
                    objectTop,
                    objectLeft,
                    objectWidth,
                    objectHeight,
                    position,
                });

                const transform = `scale(${scale}, ${scale}) translate(${Math.ceil(
                    translateX
                )}px, ${Math.ceil(translateY)}px) rotate(0deg)`;
                return { speakerId: index + 1, transform };
            }),
        };

        scenes.push(scene);
    }

    return scenes;
}
import React from "react";

const YoutubeIcon = ({
  width = "100%",
  height = "auto",
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxWidth: "100%" }}
    >
      <path
        d="M24.866 49.6C38.4643 49.6 49.488 38.5638 49.488 24.95C49.488 11.3362 38.4643 0.300003 24.866 0.300003C11.2676 0.300003 0.243988 11.3362 0.243988 24.95C0.243988 38.5638 11.2676 49.6 24.866 49.6Z"
        fill="#FF0000"
      />
      <path
        d="M37.0121 18.6855C36.7189 17.5871 35.8531 16.7203 34.756 16.4268C32.7656 15.8926 24.7858 15.8926 24.7858 15.8926C24.7858 15.8926 16.806 15.8926 14.8156 16.4268C13.7185 16.7203 12.8527 17.5871 12.5596 18.6855C12.0259 20.6781 12.0259 24.8353 12.0259 24.8353C12.0259 24.8353 12.0259 28.9926 12.5596 30.9852C12.8527 32.0836 13.7185 32.9503 14.8156 33.2439C16.806 33.7781 24.7858 33.7781 24.7858 33.7781C24.7858 33.7781 32.7656 33.7781 34.756 33.2439C35.8531 32.9503 36.7189 32.0836 37.0121 30.9852C37.5457 28.9926 37.5457 24.8353 37.5457 24.8353C37.5457 24.8353 37.5457 20.6781 37.0121 18.6855ZM22.2343 28.667V21.0014L28.865 24.833L22.2343 28.667Z"
        fill="white"
      />
    </svg>
  );
};

export default YoutubeIcon;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { BrowserRouter as Router, RouterProvider } from "react-router-dom";
import { ConfigProvider, notification } from "antd";
import { colors } from "./Themes/colors";
import "./global.scss";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { router } from "./routes/routes";
import { initSessionTracker } from "./helpers/tracker";
import { BreakpointProvider } from "react-socks";

notification.config({ placement: "topRight" });

initSessionTracker();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BreakpointProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#6165DB",
            },
            components: {
              Input: {
                hoverBorderColor: "none",
                activeBorderColor: colors.mainColor,
              },
              Button: {
                hoverBorderColor: "none",
                groupBorderColor: colors.mainColor,
              },
            },
          }}
        >
          <RouterProvider router={router} />
        </ConfigProvider>
      </BreakpointProvider>
    </Provider>
  </React.StrictMode>
);

import React, { useEffect } from "react";
import AppHeader from "../../Components/Header/Header";
import { Layout, Typography } from "antd";
import { stylesComponent } from "./styles";
import LoginForm from "../../Components/LoginForm/LoginForm";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { checkLocalStorage } from "../../redux/Slices/AuthSlice";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;
const { Title } = Typography;
const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(checkLocalStorage());
    if (user && user.id) {
      navigate("/dashboard/videos");
    }
  }, []);

  return (
    <Layout
      style={{
        backgroundColor: "#fff",
      }}
    >
      <AppHeader />
      <Content style={stylesComponent.contentStyle}>
        <Title style={stylesComponent.titleStyle}>Welcome Back 👋</Title>
        <Content style={stylesComponent.formContentStyle}>
          <LoginForm />
        </Content>
      </Content>
    </Layout>
  );
};

export default Login;

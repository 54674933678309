import React, { useState } from "react";
import { IVideo } from "../../types/models";
import { Card, Dropdown, Menu, Modal, Typography, notification } from "antd";
import { Link } from "react-router-dom";
import { secondsToHMS } from "../../helpers/formatedSeconed";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  HourglassOutlined,
  MoreOutlined,
  SyncOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import styles from "./videoCard.module.scss";
import { useAppDispatch } from "../../redux/store";
import { deleteVideo } from "../../redux/Slices/VideosSlice";

const StatusComp = ({ status }: { status: string }) => {
  switch (status) {
    case "COMPLETED":
      return (
        <div className={styles.statusWrapper}>
          <CheckCircleOutlined className={styles.iconDone} />
          <Typography.Text type="success">Completed</Typography.Text>
        </div>
      );
    case "FAILED":
      return (
        <div className={styles.statusWrapper}>
          <CloseCircleOutlined className={styles.iconFail} />
          <Typography.Text type="danger">Failed</Typography.Text>
        </div>
      );
    case "PROCESSING":
      return (
        <div className={styles.statusWrapper}>
          <SyncOutlined className={styles.iconPending} spin />
          <Typography.Text type="warning">Processing</Typography.Text>
        </div>
      );
    default:
      return (
        <div className={styles.statusWrapper}>
          <HourglassOutlined className={styles.iconQueue} spin />
          <Typography.Text className={styles.iconQueue}>Queued</Typography.Text>
        </div>
      );
  }
};

const VideoCard = ({ video }: { video: IVideo }) => {
  const [isDeleteVisisble, setIsDeleteVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const toggleVisible = () => {
    setIsDeleteVisible(!isDeleteVisisble);
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      await dispatch(deleteVideo(video.id));
      notification.success({
        message: "Deleted successfully",
      });
    } catch (err) {
      notification.error({ message: "Something went wrong" });
    }
    setLoading(false);
  };

  return (
    <Card
      cover={
        video.thumbnailUrl ? (
          <Link
            to={`/dashboard/videos/${video?.id}`}
            style={{ display: "block", width: "100%" }}
          >
            <img src={video.thumbnailUrl} width="100%" />
          </Link>
        ) : (
          <Link
            to={`/dashboard/videos/${video?.id}`}
            style={{ display: "block", width: "100%" }}
          >
            <video src={video.videoUrl} preload="false" width="100%" />
          </Link>
        )
      }
    >
      <Link to={`/dashboard/videos/${video?.id}`}>
        <div className={styles.row}>
          <Typography.Text ellipsis className={styles.title}>
            {video.title || "Sample Video"}
          </Typography.Text>
          <Typography.Text>
            {secondsToHMS(video.durationSeconds)}
          </Typography.Text>
        </div>
      </Link>
      <div className={styles.row}>
        <StatusComp status={video.status} />
        <Dropdown
          menu={{
            items: [
              {
                label: "Delete",
                key: "delete",
                icon: <DeleteFilled style={{ color: "red" }} />,
                onClick: toggleVisible,
              },
            ],
          }}
          trigger={["click"]}
        >
          <MoreOutlined />
        </Dropdown>
      </div>
      <Modal
        title={`Delete ${video.title}`}
        open={isDeleteVisisble}
        okText="Yes"
        onCancel={toggleVisible}
        okButtonProps={{ loading }}
        onOk={onDelete}
      >
        <Typography.Paragraph>
          Are you sure you want to delete the video {video.title}?
        </Typography.Paragraph>
        <Typography.Paragraph>
          <small>(All associated clips will be deleted)</small>
        </Typography.Paragraph>
      </Modal>
    </Card>
  );
};

export default VideoCard;

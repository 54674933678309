import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Grid,
  Image,
  Menu,
  Layout,
  Popover,
  Dropdown,
  MenuProps,
  Typography,
  Tooltip,
} from "antd";
import logo from "../../assets/images/logo.png";
import "./dashboardLayout.scss";
import {
  BarsOutlined,
  BranchesOutlined,
  ExperimentOutlined,
  GatewayOutlined,
  LinkOutlined,
  MoneyCollectOutlined,
  VideoCameraOutlined,
  DollarOutlined,
  StarOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Link, Outlet, useLocation } from "react-router-dom";
import format from "date-fns/format";
import { ROUTES_MAP } from "../../routes/routesMap";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { currentUser, forceLogout } from "../../redux/Slices/AuthSlice";
import { identifySessionUser } from "../../helpers/tracker";
import { fetchPlans } from "../../redux/Slices/BillingSlice";

const { Header, Sider, Content } = Layout;

const MENU = [
  {
    title: "Videos",
    key: ROUTES_MAP.dashboard.videos,
    icon: <VideoCameraOutlined />,
  },
  // {
  //   title: "Connections",
  //   key: ROUTES_MAP.dashboard.connections,
  //   icon: <LinkOutlined />,
  // },
  {
    title: "Billing",
    key: ROUTES_MAP.dashboard.settings.billing,
    icon: <DollarOutlined />,
  },
];

const HeaderDropdown = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { user } = useAppSelector((state) => state.auth);
  const { plans } = useAppSelector((state) => state.billing);
  const dispatch = useAppDispatch();
  const loacation = useLocation();

  const currentPlan = useMemo(() => {
    if (user?.subscription.planId && plans.length) {
      const plan = plans.find((plan) => plan.id === user.subscription.planId);
      return plan;
    }

    return null;
  }, [user?.subscription?.planId, plans]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onLogout = () => {
    dispatch(forceLogout());
    window.location.href = ROUTES_MAP.login;
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Typography.Paragraph>
          <strong>{user?.name}</strong>
          <br />
          {user?.email}
        </Typography.Paragraph>
      ),
    },
    {
      key: "2",
      label: <Link to={ROUTES_MAP.dashboard.settings.profile}>Profile</Link>,
    },
    {
      key: "3",
      label: <Link to={ROUTES_MAP.dashboard.settings.billing}>Billing</Link>,
    },
    {
      key: "4",
      label: (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onLogout}>Logout</Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (user?.email) {
      identifySessionUser(user?.email);
    }
  }, [user?.email]);
  useEffect(() => {
    dispatch(fetchPlans());
  }, []);

  return (
    <Header
      className={
        loacation.pathname.includes("/dashboard/videos/")
          ? "custom-header"
          : "header"
      }
    >
      <div>
        <Button
          className="icon"
          icon={<BarsOutlined />}
          size="large"
          onClick={toggleCollapsed}
          style={{ marginRight: 10 }}
        />
        <Link to={ROUTES_MAP.dashboard.videos}>
          <Image width={50} src={"/logo.png"} preview={false} />
        </Link>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {user?.subscription ? (
          <Tooltip
            title={
              <div>
                <Typography.Paragraph style={{ marginTop: 0, marginBottom: 0, color: "white" }}>
                  Process {Math.floor(user.subscription.tokens / 60)} minutes of
                  video content
                </Typography.Paragraph>
                {user.subscription.status === "ENABLED" &&
                user.subscription.nextBillingDate ? (
                  <Typography.Paragraph
                    style={{ marginTop: 0, color: "white", marginBottom: 0 }}
                  >
                    Refills on:{" "}
                    {format(
                      new Date(user.subscription.nextBillingDate),
                      "MM/dd"
                    )}
                  </Typography.Paragraph>
                ) : null}
              </div>
            }
          >
            <Typography.Paragraph style={{ marginRight: 10, marginBottom: 0 }}>
              <ExperimentOutlined style={{ marginRight: 2 }} />
              <Typography.Text style={{ marginRight: 5 }}>
                {Math.floor(user?.subscription.tokens / 60)}
              </Typography.Text>
              <Typography.Text style={{ marginRight: 5 }}>
                {"/"}
              </Typography.Text>
              <Typography.Text style={{ marginRight: 5 }}>
                {Math.floor((currentPlan?.tokens || 0) / 60)}
              </Typography.Text>
              mins left
            </Typography.Paragraph>
          </Tooltip>
        ) : null}
        {currentPlan && currentPlan.id === "free_trial" ? (
          <div style={{ marginRight: 10 }}>
            <Link target="_blank" to={ROUTES_MAP.dashboard.settings.billing}>
              <Button type="primary" icon={<StarFilled />}>
                Upgrade
              </Button>
            </Link>
          </div>
        ) : null}
        <Dropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
          <Avatar
            size="large"
            style={{ backgroundColor: "#f56a00", cursor: "pointer" }}
          >
            {user?.name?.charAt(0).toUpperCase()}
          </Avatar>
        </Dropdown>
      </div>
    </Header>
  );
};

const SideMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  const breakpoint = Grid.useBreakpoint();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const selectedKeys = useMemo(() => {
    const currentItem = MENU.find((item) =>
      location.pathname.startsWith(item.key)
    );
    return currentItem ? [currentItem.key] : [];
  }, [location.pathname]);

  const onMenuItemClick = () => {
    if (!breakpoint.sm) {
      setCollapsed(true);
    }
  };

  useEffect(() => {
    if (!breakpoint.sm) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [breakpoint.sm]);

  useEffect(() => {
    dispatch(currentUser());
    const intervalId = setInterval(() => {
      dispatch(currentUser());
    }, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <Sider
      className="sidebar"
      collapsed={
        location.pathname.includes("/dashboard/videos/") ? true : collapsed
      }
    >
      <Menu mode="inline" selectedKeys={selectedKeys}>
        {MENU.map((item) => (
          <Menu.Item icon={item.icon} key={item.key} onClick={onMenuItemClick}>
            <Link to={item.key}>{item.title}</Link>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

const DashboardLayout: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(currentUser());
  }, []);

  return (
    <Layout className={"dashboardLayout"}>
      <HeaderDropdown />
      <Layout hasSider>
        <SideMenu />
        <Content className="content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;

import React from "react";

const LandingPlayButton = ({
  width = "100%",
  height = "auto",
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxWidth: "100%" }}
    >
      <path
        d="M37.2422 46.4233C47.448 41.3546 51.6124 28.9722 46.5437 18.7664C41.475 8.56064 29.0926 4.39622 18.8869 9.46492C8.6811 14.5336 4.51668 26.916 9.58538 37.1218C14.6541 47.3276 27.0365 51.492 37.2422 46.4233Z"
        fill="#333333"
      />
      <path
        d="M20.4845 27.948V18.9733C20.4845 17.2867 22.3127 16.2279 23.7715 17.0712L31.5398 21.5586L39.308 26.046C40.7731 26.8893 40.7731 29.0006 39.308 29.8439L31.5398 34.3313L23.7715 38.8187C22.3065 39.662 20.4845 38.6094 20.4845 36.9166V27.948Z"
        fill="#F1F2F2"
      />
    </svg>
  );
};

export default LandingPlayButton;

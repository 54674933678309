import React from "react";
import { Tabs } from "antd";
import "./TabsComponent.scss";

interface TabsComponentProps {
  setActiveTab: (tab: string) => void;
  activeTab: string;
  clipsLength?: number;
}

const TabsComponent: React.FC<TabsComponentProps> = ({
  setActiveTab,
  activeTab,
  clipsLength,
}) => {
  const onChange = (key: string) => {
    setActiveTab(key);
  };
  return (
    <Tabs
      defaultActiveKey="1"
      onChange={onChange}
      className="tabs-component"
      type="card"
      size={"large"}
      items={[
        {
          key: "1",
          label: `${clipsLength || 0} Clips`,
        },
        // {
        //   key: "2",
        //   label: "Stories",
        // },
        // {
        //   key: "3",
        //   label: "Video",
        // },
      ]}
    />
  );
};

export default TabsComponent;

import React from "react";
import { Space, Spin } from "antd";
import styles from "./Loading.module.scss";

const Loading: React.FC = () => {
  return (
    <Space className={styles.loadingLayout}>
      <Spin size="large" />
    </Space>
  );
};

export default Loading;

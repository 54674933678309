import { IEditorMediaOverlay, ISubtitleStyleEnum, ISubtitlesConfig } from '../types/interfaces';
import { FontFamilyEnum } from '../remotion/subtitles/fonts';
import { tempelates } from './../constants/tempelates';
import { hexToRgb } from './hexToRgb';
import { MediaOverlayTypeEnum } from '../types/models';
interface BRoll {
    assetTag: string;
    assetType: string;
    height: number;
    id: string;
    top: number;
    metaData: {
        duration: number;
        isDraft: boolean;
        start: number;
        isTemplate: boolean;
        end: number;
        isFullVideoLength: boolean;
    };
    url: string;
    duration: number | null;
    left: number;
    width: number;
}

interface Subtitle {
    lineHeight: number;
    underline: boolean;
    text: string;
    effect_type: string;
    fontFamily: string;
    shadow: {
        config: {
            offsetX: string;
            blur: string;
            offsetY: string;
        };
        enabled: boolean;
        color: string;
    };
    fill: string;
    fontSize: number;
    textBgColor: {
        color: string;
        radius: number;
        enabled: boolean;
    };
    noEffect: boolean;
    bold: boolean;
    blockBackground: {
        radius: number;
        color: string;
        padding: number;
        enabled: boolean;
    };
    top: number;
    effect_color: string;
    style: {
        type: string;
        color: string;
    };
    textAlign: string;
    padding: {
        top: number;
        left: number;
        right: number;
        bottom: number;
    };
    italic: boolean;
    textBackgroundColor: null;
    stroke: {
        color: string;
        config: {
            width: string;
        };
        enabled: boolean;
    };
    fontColor: string;
    margin: {
        left: number;
        right: number;
        bottom: number;
        top: number;
    };
    width: number;
    maxHeight: null;
    textTransform: string;
    left: number;
}

interface Text {
    blockBackground: {
        enabled: boolean;
        radius: number;
        color: string;
        padding: number;
    };
    end: number;
    start: number;
    effect_color: string | null;
    fontColor: string;
    text: string;
    shadow: {
        color: string;
        enabled: boolean;
        config: {
            offsetY: number;
            blur: number;
            offsetX: number;
        };
    };
    stroke: {
        color: string;
        enabled: boolean;
        config: {
            width: number;
        };
    };
    fontSize: number;
    isFullVideoLength: boolean;
    lineHeight: number;
    width: number;
    effect_type: string;
    underline: boolean;
    left: number;
    noEffect: boolean;
    bold: boolean;
    textTransform: string;
    fontFamily: string;
    id: string;
    textBgColor: {
        color: string;
        radius: number;
        enabled: boolean;
    };
    margin: {
        left: number;
        bottom: number;
        right: number;
        top: number;
    };
    padding: {
        right: number;
        bottom: number;
        top: number;
        left: number;
    };
    maxHeight: number;
    fill: string;
    top: number;
    italic: boolean;
    textAlign: string;
    textBackgroundColor: null;
    duration: number;
}

export interface ITemplate {
    updatedAt: string;
    bRolls?: BRoll[];
    texts?: Text[];
    name: string;
    createdAt: string;
    id: string;
    height: number;
    width: number;
    subtitle?: Subtitle;
    subTitleConfig?: ISubtitlesConfig;
}

const getSubtitleConfig = (tempGetter: any) => {
    const fontFamily = (tempGetter?.fontFamily as string) || '';
    const validFontFamily =
        fontFamily && FontFamilyEnum[fontFamily as keyof typeof FontFamilyEnum]
            ? FontFamilyEnum[fontFamily as keyof typeof FontFamilyEnum]
            : FontFamilyEnum.OpenSans;
    const finalConfig = {
        fontSize: Number(tempGetter?.fontSize),
        color: hexToRgb(tempGetter?.fontColor) || 'white',
        bold: tempGetter?.bold || false,
        italic: tempGetter?.italic || false,
        underline: tempGetter?.underline || false,
        textTransform: tempGetter?.textTransform?.toLowerCase() as "none" | "uppercase" | "lowercase" | "capitalize" || 'none',
        textAlign: tempGetter?.textAlign as 'left' | 'center' | 'right' || 'center',
        lineHeight: 1.2,
        textBG: {
            color: hexToRgb(tempGetter?.textBgColor?.color) || 'transparent',
            enabled: tempGetter?.textBgColor?.enabled || false,
            borderRadius: tempGetter?.textBgColor?.radius || 0,
            padding: 0,
            margin: 0,
        },
        blockBG: {
            borderRadius: tempGetter?.blockBackground?.radius || 0,
            color: hexToRgb(tempGetter?.blockBackground?.color) || 'transparent',
            padding: tempGetter?.blockBackground?.padding || 0,
            enabled: tempGetter?.blockBackground?.enabled || false,
        },
        textOutline: {
            color: hexToRgb(tempGetter?.stroke?.color) || 'black',
            enabled: tempGetter?.stroke?.enabled || false,
            width: Number(tempGetter?.stroke?.config?.width) || 0
        },
        textShadow: {
            color: hexToRgb(tempGetter?.shadow?.color) || 'black',
            enabled: tempGetter?.shadow?.enabled || false,
            offsetX: Number(tempGetter?.shadow?.config?.offsetX) || 0,
            offsetY: Number(tempGetter?.shadow?.config?.offsetY) || 0,
            blur: Number(tempGetter?.shadow?.config?.blur) || 0
        },
        transform: 'translate(0px, 100px)',
        fontFamily: validFontFamily
    }

    return finalConfig
}

export const formatedSubtitleConfig = ({ selectedClip }: { selectedClip?: any }) => {
    const formatedTemp = tempelates.map((temp) => {
        const subTitleConfig = getSubtitleConfig(temp.subtitle)
        const textOverlays = [] || temp?.texts?.map((text, index) => {
            return {
                id: index.toString(),
                start: selectedClip?.start || 0,
                end: selectedClip?.end || 0,
                text: text?.type === 'clip_title' ? selectedClip?.topic : text.text,
                fullClip: false,
                config: getSubtitleConfig(text)
            };

        })

        const mediaOverlays = [] || temp?.bRolls?.map((roll) => {
            const overlay: IEditorMediaOverlay = {
                sourceId: '',
                start: selectedClip?.start || 0,
                end: selectedClip?.end || 0,
                fullClip: false,
                transform: ``,
                // transform: `translate(${Math.floor(roll.left + 50)}px, ${Math.floor(roll.top - 60)}px)`,
                id: roll.id || '',
                title: '',
                type: MediaOverlayTypeEnum.image,
                userId: '',
                durationSeconds: roll.metaData.duration,
                url: roll.url,
                width: roll.width * 1.2,
                height: roll.height * 1.2,
                thumbnailUrl: '',
                createdAt: 0,
                volume: 0
            }
            return overlay
        }) || []
        const tempsStyles = temp.subtitle.style
        const subtitleStyles = {
            subtitleStyle: tempsStyles?.type === 'default' ? ISubtitleStyleEnum.BASIC
                : tempsStyles?.type === 'word_color_change' ? ISubtitleStyleEnum.HIGHLIGHT_CURRENT_WORD
                    : tempsStyles?.type === 'word_background_change' ? ISubtitleStyleEnum.HIGHLIGHT_CURRENT_BG
                        : tempsStyles?.type === 'two_word' ? ISubtitleStyleEnum.TWO_WORDS
                            : tempsStyles?.type === "word_appended" ? ISubtitleStyleEnum.ONE_WORD
                                : ISubtitleStyleEnum.STORY,
            subtitleEffectColor: tempsStyles?.type === 'word_color_change' ? hexToRgb(tempsStyles?.color) || '' : '',
            subtitleEffectBGColor: tempsStyles?.type === 'word_background_change' ? hexToRgb(tempsStyles?.color) || '' : ''
        }

        return { subtitleStyles, mediaOverlays, textOverlays, subTitleConfig, ...temp }
    })

    return formatedTemp
}




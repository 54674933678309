import { IEditorTextOverlay } from "../../types/interfaces";
import { useRef, useState } from "react";
import { configToBlockStyles, configToInlineStyles } from "../subtitles/utils";
import Moveable, { OnDrag, OnDragEnd, OnRotate, OnScale } from "react-moveable";
import MoveableElement, {
  ITransformable,
} from "../MoveableElement/MoveableElement";

interface IProps {
  overlay: IEditorTextOverlay;
  widthScale: number;
  heightScale: number;
  isSelected: boolean;
  onSelectedChange: (id: string) => void;
}
const TextOverlay = ({
  overlay,
  isSelected,
  onSelectedChange,
  widthScale,
  heightScale,
}: IProps) => {
  const styles = configToBlockStyles(overlay.config, {
    widthScale,
    heightScale,
  });
  const textStyle = configToInlineStyles(overlay.config, {
    widthScale,
    heightScale,
  });

  // console.log(styles, textStyle);
  return (
    <>
      <div
        id={overlay.id}
        style={{
          ...styles,
          cursor: "move",
          position: "absolute",
          left: "10%",
          right: "10%",
          top: "20%",
        }}
        onClick={(e) => {
          onSelectedChange(overlay.id);
        }}
      >
        <span style={{ ...textStyle }}>{overlay.text}</span>
      </div>
    </>
  );
};

export default TextOverlay;

import { IClipsExport, IVideo } from "../types/models";
import { axiosInstance } from "../helpers/request";

class ClipsExportApi {
  async createClipExport(body: any) {
    const data = await axiosInstance.post<IClipsExport>("/clips-exports", body);
    return data.data;
  }

  async getClipExport(id: string) {
    const data = await axiosInstance.get<IClipsExport>(`/clips-exports/${id}`);
    return data.data;
  }

}

const clipsExportApi = new ClipsExportApi();

export default clipsExportApi;

import { Player, PlayerRef, Thumbnail } from "@remotion/player";
import { SetStateAction, useEffect, useRef, useState } from "react";
import {
  AbsoluteFill,
  Sequence,
  useCurrentFrame,
  useVideoConfig,
  Video,
  Audio,
} from "remotion";
import {
  ClipScene,
  IBoundingBox,
  ITranscriptionWord,
  MediaOverlayTypeEnum,
} from "../types/models";
import { Button, Spin, Typography } from "antd";
import {
  ISubtitlesConfig,
  IEditorTextOverlay,
  IEditorMediaOverlay,
  ISubtitleStyleEnum,
  IEditorClipScene,
} from "../types/interfaces";
import TextOverlay from "./Overlays/TextOverlay";
import ImageOverlay from "./Overlays/ImageOverlay";
import VideoOverlay from "./Overlays/VideoOverlay";
import { z } from "zod";
import { CompositionProps } from "./constants";
import { applyTransformScaling } from "./subtitles/utils";
import MoveableElement from "./MoveableElement/MoveableElement";
import Subtitles from "./subtitles";
import {
  PLAYER_HEIGHT,
  PLAYER_HEIGHT_SCALE,
  PLAYER_WIDTH,
  PLAYER_WIDTH_SCALE,
} from "../constants/constants";
import { WATERMARK_TEXT_OVERLAY } from "./watermark";
import AudioOverlay from "./Overlays/AudioOverlay";
import SceneOverlay from "./Overlays/SceneOverlay";

export const VideoOnCanvas = ({
  source,
  scenes,
  startFrom,
  endTime,
  words,
  videoOriginDimesnsions,
  textOverlays,
  mediaOverlays,
  subtitlesConfig,
  selectedId,
  widthScale,
  heightScale,
  subtitleStyle,
  onSelectedChange = () => {},
  onTextOverlayTransformChange = () => {},
  onMediaOverlayTransformChange = () => {},
  onSubtitlesTransformChange = () => {},
  subtitleEffectColor,
  subtitleEffectBGColor,
  transform,
  onSceneScreenTransformChange,
  onLoadedData = () => {},
  sourceType = "video",
  hasWatermark,
}: z.infer<typeof CompositionProps>) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { fps } = useVideoConfig();
  const currentFrame = useCurrentFrame();
  const currentTime = currentFrame / fps;

  return (
    <AbsoluteFill onClick={(e) => e.stopPropagation()}>
      <AbsoluteFill style={{ position: "relative" }} ref={containerRef}>
        {sourceType === "image"
          ? scenes[0].screens.map((screen, index) => (
              <img
                key={`image-scene-overlay-${index}`}
                src={source}
                onLoadedData={() => onLoadedData()}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: videoOriginDimesnsions.videoWidth * widthScale!,
                  height: videoOriginDimesnsions.videoHeight * heightScale!,
                  cursor: "move",
                  transform: applyTransformScaling(screen.transform, {
                    widthScale,
                    heightScale,
                  }),
                  transformOrigin: "left top",
                }}
              />
            ))
          : scenes.map((scene, index) => (
              <SceneOverlay
                key={`scene-overlay-${scene.speakersIds}-${index}`}
                heightScale={heightScale}
                widthScale={widthScale}
                videoHeight={videoOriginDimesnsions.videoHeight}
                videoWidth={videoOriginDimesnsions.videoWidth}
                onLoadedData={onLoadedData}
                scene={scene}
                sceneIndex={index}
                source={source}
                startFrom={startFrom}
                selectedId={selectedId}
                onSelectedChange={onSelectedChange}
              />
            ))}
      </AbsoluteFill>

      <Subtitles
        subtitleStyle={subtitleStyle}
        words={words}
        currentTime={currentTime}
        subtitlesConfig={subtitlesConfig}
        selectedId={selectedId}
        onSelectedChange={() => onSelectedChange("subtitles")}
        widthScale={widthScale!}
        heightScale={heightScale!}
        onSubtitlesTransformChange={(transform) =>
          onSubtitlesTransformChange(transform)
        }
        subtitleEffectBGColor={subtitleEffectBGColor}
        subtitleEffectColor={subtitleEffectColor}
      />
      {hasWatermark && (
        <TextOverlay
          overlay={
            {
              ...WATERMARK_TEXT_OVERLAY,
              start: startFrom,
              end: endTime,
            } as IEditorTextOverlay
          }
          widthScale={widthScale!}
          heightScale={heightScale!}
          isSelected={false}
          onSelectedChange={() => {}}
        />
      )}
      {textOverlays.map((overlay, index) => (
        <TextOverlay
          key={overlay.id}
          overlay={overlay}
          widthScale={widthScale!}
          heightScale={heightScale!}
          isSelected={selectedId === overlay.id}
          onSelectedChange={onSelectedChange}
        />
      ))}
      {mediaOverlays.map((overlay, index) =>
        overlay.type === MediaOverlayTypeEnum.image ? (
          <Sequence
            from={overlay.start * fps}
            durationInFrames={(overlay.end - overlay.start) * fps}
            key={overlay.id}
            layout="none"
          >
            <ImageOverlay
              key={overlay.id}
              overlay={overlay}
              widthScale={widthScale!}
              heightScale={heightScale!}
              isSelected={selectedId === overlay.id}
              onSelectedChange={onSelectedChange}
            />
          </Sequence>
        ) : overlay.type === MediaOverlayTypeEnum.audio ? (
          <Sequence
            from={overlay.start * fps}
            durationInFrames={(overlay.end - overlay.start) * fps}
            key={overlay.id}
            layout="none"
          >
            <AudioOverlay overlay={overlay} currentTime={currentTime} />
          </Sequence>
        ) : (
          <Sequence
            from={overlay.start * fps}
            durationInFrames={(overlay.end - overlay.start) * fps}
            key={overlay.id}
            layout="none"
          >
            <VideoOverlay
              overlay={overlay}
              widthScale={widthScale!}
              heightScale={heightScale!}
              isSelected={selectedId === overlay.id}
              onSelectedChange={onSelectedChange}
              currentTime={currentTime}
            />
          </Sequence>
        )
      )}
    </AbsoluteFill>
  );
};

interface IProps {
  source: string;
  startFrom: number;
  endTime: number;
  framesPerSecond: number;
  words: ITranscriptionWord[];
  videoOriginDimesnsions: {
    videoWidth: number;
    videoHeight: number;
  };
  clipTargetsPersonsDimensions: IBoundingBox[];
  selectedFaceIndex: number;
  noSpeaker?: boolean;
  playerRef?: React.Ref<PlayerRef>;
  subtitlesConfig: ISubtitlesConfig;
  textOverlays: IEditorTextOverlay[];
  mediaOverlays: IEditorMediaOverlay[];
  selectedId: string;
  onSelectedChange: (id: string) => void;
  widthScale: number;
  heightScale: number;
  onTextOverlayTransformChange: (index: number, transform: string) => void;
  onMediaOverlayTransformChange: (index: number, transform: string) => void;
  onSubtitlesTransformChange: (transform: string) => void;
  onSelectedFaceIndexChange: (index: number) => void;
  subtitleStyle: ISubtitleStyleEnum;
  subtitleEffectColor?: string;
  subtitleEffectBGColor?: string;
  width: number;
  height: number;
  transform: string;
  onSceneScreenTransformChange: (
    sceneIndex: number,
    screenIndex: number,
    transform: string
  ) => void;
  hasWatermark: boolean;
  scenes: IEditorClipScene[];
}

export const RemotionVideos = ({
  playerRef,
  source,
  startFrom,
  endTime,
  framesPerSecond,
  words,
  videoOriginDimesnsions,
  clipTargetsPersonsDimensions,
  selectedFaceIndex = 0,
  noSpeaker,
  textOverlays,
  mediaOverlays,
  subtitlesConfig,
  selectedId,
  onSelectedChange,
  subtitleStyle = ISubtitleStyleEnum.BASIC,
  onTextOverlayTransformChange = () => {},
  onMediaOverlayTransformChange = () => {},
  onSubtitlesTransformChange = () => {},
  onSelectedFaceIndexChange = () => {},
  subtitleEffectColor = "",
  subtitleEffectBGColor = "",
  widthScale,
  heightScale,
  width,
  height,
  transform,
  scenes,
  hasWatermark,
  onSceneScreenTransformChange = (transform) => {},
}: IProps) => {
  const [loaded, setLoaded] = useState(false);
  // const width = 720;
  // const height = 1280;
  // const width  = 1080;
  // const height = 1920;

  const durationInFrames = Math.ceil((endTime - startFrom) * 30);

  useEffect(() => {
    onSelectedFaceIndexChange(0);
  }, [startFrom]);

  return (
    <div style={{ position: "relative" }}>
      <Player
        ref={playerRef}
        component={VideoOnCanvas}
        inputProps={{
          source,
          startFrom,
          endTime,
          framesPerSecond,
          words,
          videoOriginDimesnsions,
          clipTargetsPersonsDimensions,
          selectedFaceIndex,
          textOverlays,
          mediaOverlays,
          subtitlesConfig,
          selectedId,
          onSelectedChange,
          widthScale,
          heightScale,
          onTextOverlayTransformChange,
          onMediaOverlayTransformChange,
          onSubtitlesTransformChange,
          width,
          height,
          subtitleStyle,
          subtitleEffectColor,
          subtitleEffectBGColor,
          transform,
          onSceneScreenTransformChange,
          onLoadedData: () => setLoaded(true),
          hasWatermark,
          scenes,
        }}
        style={{ backgroundColor: "black" }}
        durationInFrames={durationInFrames}
        compositionWidth={width}
        compositionHeight={height}
        fps={30}
        // clickToPlay
        controls
      />
      {!loaded && (
        <AbsoluteFill
          style={{
            zIndex: 10,
            width,
            height,
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
          <Typography.Paragraph>Initializing...</Typography.Paragraph>
        </AbsoluteFill>
      )}
      {/* {!noSpeaker && (
        <div style={{ position: "absolute", left: -200, top: "30%" }}>
          <Typography.Title level={4}>Detected People</Typography.Title>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {clipTargetsPersonsDimensions.map((face, index) => (
              <Button
                key={`${face.BoundingBox.Left}-${face.BoundingBox.Width}`}
                style={{ marginBottom: 10 }}
                onClick={() => onSelectedFaceIndexChange(index)}
                type={selectedFaceIndex === index ? "primary" : "dashed"}
              >
                Person {index + 1}
              </Button>
            ))}
          </div>
        </div>
      )} */}
    </div>
  );
};

export default RemotionVideos;

export const CustomThumpnail = ({
  source,
  startFrom,
  endTime,
  framesPerSecond,
  words,
  videoOriginDimesnsions,
  clipTargetsPersonsDimensions,
  selectedFaceIndex = 0,
  textOverlays,
  mediaOverlays,
  subtitlesConfig,
  selectedId,
  onSelectedChange,
  subtitleStyle = ISubtitleStyleEnum.BASIC,
  onTextOverlayTransformChange = () => {},
  onMediaOverlayTransformChange = () => {},
  onSubtitlesTransformChange = () => {},
  subtitleEffectColor = "",
  subtitleEffectBGColor = "",
  transform,
  onSceneScreenTransformChange,
  hasWatermark = false,
  scenes,
}: IProps) => {
  const factor = 1.5;
  const width = PLAYER_WIDTH / factor;
  const height = PLAYER_HEIGHT / factor;
  const widthScale = PLAYER_WIDTH_SCALE / factor;
  const heightScale = PLAYER_HEIGHT_SCALE / factor;

  const durationInFrames = Math.floor(endTime - startFrom) * 30;

  return (
    <div style={{ position: "relative" }}>
      <Thumbnail
        style={{
          borderRadius: "10px",
          cursor: "pointer",
          zIndex: 1,
        }}
        component={VideoOnCanvas}
        compositionWidth={width}
        compositionHeight={height}
        frameToDisplay={0}
        durationInFrames={durationInFrames}
        fps={30}
        inputProps={{
          source,
          sourceType: "image",
          startFrom,
          endTime,
          framesPerSecond,
          words,
          videoOriginDimesnsions,
          clipTargetsPersonsDimensions,
          selectedFaceIndex,
          textOverlays,
          mediaOverlays,
          subtitlesConfig,
          selectedId,
          onSelectedChange,
          widthScale,
          heightScale,
          onTextOverlayTransformChange,
          onMediaOverlayTransformChange,
          onSubtitlesTransformChange,
          width,
          height,
          subtitleStyle,
          subtitleEffectColor,
          subtitleEffectBGColor,
          transform,
          onSceneScreenTransformChange,
          hasWatermark,
          // onLoadedData: () => setLoaded(true),
          scenes,
        }}
      />
      <AbsoluteFill style={{ zIndex: 2 }}></AbsoluteFill>
      {/* {!loaded && (
        <AbsoluteFill
          style={{
            zIndex: 10,
            width,
            height,
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
          <Typography.Paragraph>Initializing...</Typography.Paragraph>
        </AbsoluteFill>
      )} */}
    </div>
  );
};

import React from "react";
import { IEditorSubtitles } from "../../types/interfaces";
import { ITranscriptionWord } from "../../types/models";
import { useMemo, useRef } from "react";
import { configToBlockStyles, configToInlineStyles } from "./utils";
import MoveableElement from "../MoveableElement/MoveableElement";
interface IHighlightedWord extends ITranscriptionWord {
  highlighted?: boolean;
}

const HighlightCurrentWordSubtitles = ({
  words,
  currentTime,
  config,
  isSelected,
  onSelectedChange,
  widthScale,
  heightScale,
  bg,
  subtitleEffectBGColor,
  subtitleEffectColor,
}: IEditorSubtitles) => {
  const styles = configToBlockStyles(config, { widthScale, heightScale });
  const textStyle = configToInlineStyles(config, { widthScale, heightScale });

  const subtitlesSlices = useMemo(() => {
    let nextCount = 5;

    const slices: {
      start: number;
      end: number;
      words: IHighlightedWord[];
    }[] = [];

    let accumilator: IHighlightedWord[] = [];
    words.forEach((word) => {
      accumilator.push(word);
      if (
        accumilator.length === nextCount ||
        (accumilator.length &&
          (word.word.includes(".") || word.word.includes(",")))
      ) {
        slices.push({
          start: accumilator[0].start,
          end: accumilator[accumilator.length - 1].end,
          words: accumilator,
        });
        if (accumilator.length <= 3) {
          nextCount = 5;
        } else if (nextCount === 5) {
          nextCount = 4;
        } else {
          nextCount = 5;
        }
        accumilator = [];
      }
    });

    if (accumilator.length) {
      slices.push({
        start: accumilator[0].start,
        end: accumilator[accumilator.length - 1].end,
        words: accumilator,
      });
    }

    return slices;
  }, [words]);

  const currentSlice = useMemo(() => {
    const slice = subtitlesSlices.find(
      (slice) => currentTime >= slice.start && currentTime <= slice.end
    );
    if (!slice) return slice;
    return {
      ...slice,
      words: slice.words.map((word) => ({
        ...word,
        highlighted: currentTime >= word.start && currentTime <= word.end,
      })),
    };
  }, [subtitlesSlices, currentTime]);

  const prevSlice = useMemo(() => {
    return subtitlesSlices
      .slice()
      .reverse()
      .find((slice) => slice.start <= currentTime);
  }, [subtitlesSlices, currentTime]);

  const { margin, backgroundColor, ...rest } = textStyle;

  return (
    <>
      <div
        id="subtitles"
        style={{
          ...styles,
          cursor: "move",
          position: "absolute",
          left: "10%",
          right: "10%",
          top: "50%",
        }}
        onClick={() => onSelectedChange()}
      >
        <span style={{ ...textStyle, backgroundColor }}>
          {(currentSlice?.words || prevSlice?.words)?.map((word) => (
            <React.Fragment key={`${word.start}-${word.end}`}>
              {word.highlighted ? (
                <span
                  style={{
                    ...rest,
                    display: "inline-block",
                    padding: 0,
                    paddingLeft: 5,
                    paddingRight: 5,
                    borderRadius: 5,
                    color: bg ? "" : subtitleEffectColor,
                    backgroundColor: bg ? subtitleEffectBGColor : "",
                  }}
                >
                  {word.word}
                </span>
              ) : (
                <span
                  style={{
                    ...rest,
                    display: "inline-block",
                    padding: 0,
                    borderRadius: 5,
                    paddingLeft: 5,
                    paddingRight: 5,
                    fontFamily: config.fontFamily,
                  }}
                >
                  {word.word}
                </span>
              )}
            </React.Fragment>
          ))}
        </span>
      </div>
    </>
  );
};

export default HighlightCurrentWordSubtitles;

import { Col, Row, Tooltip, Typography, theme } from "antd";
import { IMediaOverlay } from "../../../types/models";
import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { useRef, useState } from "react";

interface IProps {
  overlay: IMediaOverlay;
  onAddOverlay: () => void;
}
const AudioUploadedOverlay = ({ overlay, onAddOverlay }: IProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const token = theme.useToken();

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Row
      gutter={24}
      align="middle"
      style={{ border: "2px solid #eee", padding: 5 }}
    >
      <Col span={4}>
        <audio
          ref={audioRef}
          preload="false"
          src={overlay.url}
        />
        {isPlaying ? (
          <PauseCircleOutlined
            style={{ cursor: "pointer" }}
            onClick={togglePlay}
          />
        ) : (
          <PlayCircleOutlined
            style={{ cursor: "pointer" }}
            onClick={togglePlay}
          />
        )}
      </Col>
      <Col span={16}>
        <Typography.Text>
          <Tooltip title={overlay.title}>
            {overlay.title.length > 12
              ? overlay.title.substring(0, 9) + "..."
              : overlay.title}
          </Tooltip>
        </Typography.Text>
      </Col>
      <Col span={3}>
        <Tooltip title="Add to overlays">
          <PlusCircleFilled
            style={{ cursor: "pointer", color: token.token.colorPrimary }}
            onClick={onAddOverlay}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

export default AudioUploadedOverlay;

import React from "react";
import { RouteObject, createBrowserRouter } from "react-router-dom";
import Login from "../Pages/Login/Login";
import SignUp from "../Pages/SignUp/SignUp";
import DashboardLayout from "../Layouts/DashboardLayout/DashboardLayout";
import Videos from "../Pages/Dashboard/Videos/Videos";
import Connections from "../Pages/Dashboard/Connections/Connections";
import Pipelines from "../Pages/Dashboard/Pipelines/Pipelines";
import { ROUTES_MAP } from "./routesMap";
import Landing from "../Pages/Landing/Landing";
import Billing from "../Pages/Dashboard/Billing/Billing";
import VideoDetails from "../Pages/VideoDetails/VideoDetails";
import TextEditing from "../Pages/VideoEditing/TextEditing";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";

const routes: RouteObject[] = [
  {
    path: ROUTES_MAP.landing,
    element: <Landing />,
  },
  {
    path: ROUTES_MAP.login,
    element: <Login />,
  },
  {
    path: ROUTES_MAP.resetPassword,
    element: <ResetPassword />,
  },
  {
    path: ROUTES_MAP.signUp,
    element: <SignUp />,
  },
  {
    path: ROUTES_MAP.dashboard.index,
    element: <DashboardLayout />,
    children: [
      {
        path: ROUTES_MAP.dashboard.videos,
        element: <Videos />,
      },
      {
        path: ROUTES_MAP.dashboard.pipelines,
        element: <Pipelines />,
      },
      {
        path: ROUTES_MAP.dashboard.connections,
        element: <Connections />,
      },
      {
        path: ROUTES_MAP.dashboard.settings.billing,
        element: <Billing />,
      },
      {
        path: ROUTES_MAP.dashboard.videoDetails(":id"),
        element: <VideoDetails />,
      },
      {
        path: ROUTES_MAP.dashboard.videoEdit(":id",":clipId"),
        element: <TextEditing />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);

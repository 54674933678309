import { Typography } from "antd";
import styles from "./ClipEditorTabContent.module.scss";
import "./ClipEditorTabContent.scss";
import React from "react";

interface IProps {
  children: React.ReactNode | React.ReactNode[];
  title: string;
  btn?: React.ReactNode | React.ReactNode[];
}

const ClipEditorTabContent = ({ children, title, btn }: IProps) => {
  return (
    <div className={"clip-editor-tab-content"}>
      {/* <div className={"header-row"}>
        <Typography.Text className={"title-text"}>{title}</Typography.Text>
        {btn || null}
      </div> */}
      <div className={styles.wrapper}>
        <div className={styles.headerRow}>
          <Typography.Text className={styles.titleText}>
            {title}
          </Typography.Text>
          <div className={styles.buttonWrapper}>{btn || null}</div>
        </div>
      </div>
      <div className="clip-editor-content-container">{children}</div>
    </div>
  );
};

export default ClipEditorTabContent;

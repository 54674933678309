// import { colors } from '../../Themes/colors';
import { colors } from '../../Themes/colors';
import { CustomStyles } from '../../types/stylesTypes';

export const styles: CustomStyles = {
    formStyle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    inputStyle: {
        minWidth: "350px",
        width: "30%",
    },
    forgotStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: "30%",
        minWidth: "350px",
    },
    forgotStyleLink: {
        textDecoration: 'none',
        fontSize: 'calc(16px + 0.1vw)',
        fontWeight: '500',
        color: colors.mainColor
    },
    submitStyle: {
        fontSize: '18px',
        fontWeight: '500',
        backgroundColor: colors.mainColor,
        height: '40px',
        width: '100%',
        marginTop: '20px',
    }
}
import { axiosInstance as api } from '../helpers/request'
import { IMediaOverlay, MediaOverlayTypeEnum } from '../types/models';


class MediaOverlaysApi {

    async createMediaOverlay({ title, url, type }: { title: string, url: string, type: MediaOverlayTypeEnum }) {
        const data = await api.post<IMediaOverlay>(`/media-overlays`, { title, url, type });
        return data.data;
    }

    async getMediaOverlays() {
        const data = await api.get<{mediaOverlays: IMediaOverlay[]}>(`/media-overlays`);
        return data.data
    }

}

const mediaOverlaysApi = new MediaOverlaysApi();

export default mediaOverlaysApi;
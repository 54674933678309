import {
  Timeline,
  TimelineAction,
  TimelineEffect,
  TimelineRow,
  TimelineState,
} from "@xzdarcy/react-timeline-editor";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./ClipEditorTimeline.scss";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Image, Tooltip } from "antd";
import { IEditorTextOverlay } from "../../types/interfaces";
import {
  setEditorClipScenes,
  setMediaOverlays,
  setSelectedId,
  setTextOverlays,
} from "../../redux/Slices/clipEditingSlice";
import { AudioOutlined } from "@ant-design/icons";

const CustomScale = (props: { scale: number }) => {
  const { scale } = props;
  const min = parseInt(scale / 60 + "");
  const second = ((scale % 60) + "").padStart(2, "0");
  return <>{`${min}:${second}`}</>;
};

export interface CustomTimelineAction extends TimelineAction {
  data: {
    src: string;
    name: string;
  };
  type: string;
}

export interface CusTomTimelineRow extends TimelineRow {
  actions: CustomTimelineAction[];
}

const CustomRender0: FC<{
  action: CustomTimelineAction;
  row: CusTomTimelineRow;
  textOverlays: IEditorTextOverlay[];
}> = ({
  action,
  row,
}: {
  action: any;
  row: any;
  textOverlays: IEditorTextOverlay[];
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        cursor: "move",
        border: row.selected ? "1px solid #6165db" : "none",
      }}
    >
      <div
        style={{
          color: "white",
        }}
      >
        {["text", "scene"].includes(action.type) ? (
          <p>{action.text}</p>
        ) : action.subType === "audio" ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <AudioOutlined style={{ marginRight: 5 }} />
              </div>
              <div>
                <Tooltip title={action.title}>
                  {action.title.length > 13
                    ? action.title.substring(0, 10) + "..."
                    : action.title}
                </Tooltip>
                <br />
                Volume: {action.volume}%
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                src={action.media}
                style={{
                  height: "30px",
                  width: "auto",
                  marginRight: 5,
                }}
              />
              <div>
                <Tooltip title={action.title}>
                  {action.title.length > 13
                    ? action.title.substring(0, 10) + "..."
                    : action.title}
                </Tooltip>
                {action.subType === "video" ? (
                  <>
                    <br />
                    Volume: {action.volume}%
                  </>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// const [testedItemsUpdate, setTestedITemsUpdate] = useState<TimelineRow[]>();

const mockEffect: Record<string, TimelineEffect> = {
  effect0: {
    id: "effect0",
    name: "Effect 0",
  },
  effect1: {
    id: "effect1",
    name: "Effect 1",
  },
};

const OVERLAYS_ORDER = ["video", "image", "audio"];

const SCALE_WIDTH = 50;
interface IProps {
  onCursorTimeChange: (time: number) => void;
  onClipStartEndChange: (start: number, end: number) => void;
}

const ClipEditorTimeline = ({
  onCursorTimeChange,
  onClipStartEndChange,
}: IProps) => {
  const { selectedClip, video } = useAppSelector((state) => state.videoDetails);
  const {
    textOverlays,
    mediaOverlays,
    editorClipScenes,
    currentTime,
    selectedId,
  } = useAppSelector((state) => state.clipEditing);
  const [allow, setAllow] = useState(true);
  const ref = useRef<TimelineState>(null);

  const dispatch = useAppDispatch();

  const data = useMemo(() => {
    if (!selectedClip || !editorClipScenes.length) return [];

    const minStart = editorClipScenes[0].start;
    const maxEnd = editorClipScenes[editorClipScenes.length - 1].end;
    const formatTextOverlays: TimelineRow[] = textOverlays?.map((item) => ({
      id: item.id,
      selected: selectedId === item.id,
      actions: [
        {
          id: item.id,
          start: item.start,
          end: item.end,
          effectId: "effect0",
          text: item.text,
          type: "text",
          minStart,
          maxEnd,
        },
      ],
    }));
    const formattedMediaOverlays: TimelineRow[] = mediaOverlays
      ?.slice()
      .sort(
        (a, b) =>
          OVERLAYS_ORDER.indexOf(a.type) - OVERLAYS_ORDER.indexOf(b.type)
      )
      .map((item) => ({
        id: item.id,
        selected: selectedId === item.id,
        actions: [
          {
            id: item.id,
            start: item.start,
            end: item.end,
            effectId: "effect0",
            media: item.thumbnailUrl || item.url,
            type: "media",
            volume: item.volume,
            subType: item.type,
            title: item.title,
            minStart,
            maxEnd,
          },
        ],
      }));

    const sceneRow: TimelineRow = {
      id: `original_video`,
      selected: selectedId.startsWith("original_video_"),
      actions: editorClipScenes.map((scene, index) => ({
        id: `original_video_${index}`,
        start: scene.start,
        end: scene.end,
        effectId: "effect0",
        type: "scene",
        text: `Scene ${index + 1}`,
      })),
    };
    const mergedData = [
      sceneRow,
      ...formatTextOverlays,
      ...formattedMediaOverlays,
    ];

    return mergedData;
  }, [textOverlays, mediaOverlays, editorClipScenes, selectedClip, selectedId]);

  const onClickAction = (action: TimelineAction) => {
    if (action.id.startsWith("original_video")) {
      dispatch(setSelectedId(`original_video_0_0`));
    } else {
      const id = action.id;
      dispatch(setSelectedId(id));
    }
  };

  const onDataChange = (data: CusTomTimelineRow[]) => {
    const newTextOverlays = textOverlays.slice();
    const newMediaOverlays = mediaOverlays.slice();
    const newScenes = editorClipScenes.slice();
    data.forEach((row) => {
      const fullClip =
        selectedClip?.start === row.actions[0].start &&
        selectedClip.end === row.actions[0].end;
      if (row.actions[0].type === "text") {
        const index = newTextOverlays.findIndex((item) => item.id === row.id);
        if (newTextOverlays[index]) {
          newTextOverlays[index] = {
            ...newTextOverlays[index],
            start: row.actions[0].start,
            end: row.actions[0].end,
            fullClip,
          };
        }
      }
      if (row.actions[0].type === "media") {
        const index = newMediaOverlays.findIndex((item) => item.id === row.id);
        if (newMediaOverlays[index]) {
          newMediaOverlays[index] = {
            ...newMediaOverlays[index],
            start: row.actions[0].start,
            end: row.actions[0].end,
            fullClip,
          };
        }
      }
    });

    // Sync scenes row
    data
      .find((row) => row.id === "original_video")
      ?.actions.forEach((action) => {
        const index = parseInt(action.id.replace("original_video_", ""));
        newScenes[index] = {
          ...newScenes[index],
          start: action.start,
          end: action.end,
        };
      });

    // Resync overlays to respect scenes start/end
    // const minStart = newScenes
    //   .slice()
    //   .sort((a, b) => a.start - b.start)[0].start;

    // const maxEnd = newScenes
    //   .slice()
    //   .sort((a, b) => a.start - b.start)
    //   .pop()!.end;

    // if (
    //   newMediaOverlays.some(
    //     (overlay) => overlay.start < minStart || overlay.end > maxEnd
    //   )
    // ) {
    //   newMediaOverlays.forEach((overlay, index) => {
    //     if (overlay.start < minStart) {
    //       newMediaOverlays[index] = {
    //         ...newMediaOverlays[index],
    //         start: minStart,
    //       };
    //     } else if (overlay.end > maxEnd) {
    //       newMediaOverlays[index] = {
    //         ...newMediaOverlays[index],
    //         end: maxEnd,
    //       };
    //     }
    //   });
    // }

    // if (
    //   newTextOverlays.some(
    //     (overlay) => overlay.start < minStart || overlay.end > maxEnd
    //   )
    // ) {
    //   newTextOverlays.forEach((overlay, index) => {
    //     if (overlay.start < minStart) {
    //       newTextOverlays[index] = {
    //         ...newTextOverlays[index],
    //         start: minStart,
    //       };
    //     } else if (overlay.end > maxEnd) {
    //       newTextOverlays[index] = {
    //         ...newTextOverlays[index],
    //         end: maxEnd,
    //       };
    //     }
    //   });
    // }

    dispatch(setEditorClipScenes(newScenes));
    dispatch(setTextOverlays(newTextOverlays));
    dispatch(setMediaOverlays(newMediaOverlays));

    const firstScene = newScenes[0];
    const lastScene = newScenes[newScenes.length - 1];
    if (
      firstScene.start !== selectedClip?.start ||
      lastScene.end !== selectedClip.end
    ) {
      onClipStartEndChange(firstScene.start, lastScene.end);
    }
  };

  const startLeft = -10;

  useEffect(() => {
    if (ref.current && ref.current.getTime() !== currentTime) {
      const second = currentTime;
      ref.current.setTime(second);
      ref.current.setScrollLeft((second + startLeft) * SCALE_WIDTH);
    }
  }, [ref.current, currentTime]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Timeline
        editorData={data}
        effects={mockEffect}
        ref={ref}
        scaleWidth={SCALE_WIDTH}
        style={{ height: 250 }}
        rowHeight={30}
        autoScroll
        autoReRender
        startLeft={startLeft}
        minScaleCount={video?.durationSeconds}
        maxScaleCount={video?.durationSeconds}
        onChange={(data) => {
          onDataChange(data as CusTomTimelineRow[]);
        }}
        onClickAction={(_, { action }) => {
          onClickAction(action);
        }}
        gridSnap
        onClickTimeArea={(time: number) => {
          onCursorTimeChange(time);
          return true;
        }}
        onCursorDragEnd={(time) => onCursorTimeChange(time)}
        getScaleRender={(scale) => <CustomScale scale={scale} />}
        getActionRender={(action, row) => {
          if (action.effectId === "effect0") {
            return (
              <CustomRender0
                textOverlays={textOverlays}
                action={action as CustomTimelineAction}
                row={row as CusTomTimelineRow}
              />
            );
          }
          return <div>Action not supported</div>;
        }}
      />
    </div>
  );
};

export default ClipEditorTimeline;

import Moveable, { OnDrag, OnDragEnd, OnRotate, OnScale } from "react-moveable";
import {
  HORIZONTAL_GUIDELINES,
  VERTICAL_GUIDELINES,
} from "../../constants/constants";
import "./style.scss";

export interface ITransformable {
  onTransformChange: (transform: string) => void;
}

interface IProps extends ITransformable {
  elRef: React.RefObject<any> | null;
}

const MoveableElement = ({ elRef, onTransformChange }: IProps) => {
  return (
    <Moveable
      target={elRef}
      // snappable={true}
      // rootContainer={document.getElementById("video-player-wrapper")}
      // container={document.getElementById("video-player-wrapper")}
      // viewContainer={document.getElementById("video-player-wrapper")}
      // dragContainer={document.getElementById("video-player-wrapper")}
      // snapContainer={document.getElementById("video-player-wrapper")}
      snapThreshold={5}
      verticalGuidelines={VERTICAL_GUIDELINES}
      horizontalGuidelines={HORIZONTAL_GUIDELINES}
      // container={parentRef?.current}
      origin={false}
      /* Resize event edges */
      edge={false}
      /* draggable */
      draggable={true}
      throttleDrag={0}
      // onDragStart={({ target, clientX, clientY }) => {
      //   console.log("onDragStart", target);
      // }}
      onDrag={({ target, transform }: OnDrag) => {
        target!.style.transform = transform;
      }}
      onDragEnd={({ target }: OnDragEnd) => {
        const transform = target.style.transform;
        onTransformChange(transform);
      }}
      /* When resize or scale, keeps a ratio of the width, height. */
      keepRatio={true}
      scalable={true}
      throttleScale={0}
      onScale={({ target, transform }: OnScale) => {
        target!.style.transform = transform;
      }}
      onScaleEnd={({ target }) => {
        const transform = target.style.transform;
        onTransformChange(transform);
      }}
      /* rotatable */
      rotatable={true}
      throttleRotate={10}
      onRotate={({ target, transform }: OnRotate) => {
        target!.style.transform = transform;
      }}
      onRotateEnd={({ target }) => {
        const transform = target.style.transform;
        onTransformChange(transform);
      }}
    />
  );
};

export default MoveableElement;

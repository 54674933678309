export const hexToRgb = (hexCode: string): string | null => {
    const newHexCode = hexCode?.replace(/^#/, "");

    const hexRegex = /^[0-9A-Fa-f]{6}$/g;
    if (!hexRegex.test(newHexCode)) {
        return hexCode;
    }

    const bigint = parseInt(newHexCode, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const color = `rgba(${r},${g},${b},1)`;
    return color;
};
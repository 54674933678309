export const WATERMARK_TEXT_OVERLAY = {
    "id": "1700540141229",
    "text": "InstaClipify.ai",
    "fullClip": true,
    "config": {
        "fontSize": 20,
        "color": "#ffffff",
        "bold": false,
        "italic": true,
        "underline": false,
        "textAlign": "center",
        "textTransform": "none",
        "textBG": {
            "color": "#5271ff",
            "enabled": false,
            "padding": 0,
            "borderRadius": 5,
            "margin": 5
        },
        "blockBG": {
            "color": "#00000045",
            "enabled": true,
            "padding": 5,
            "borderRadius": 5
        },
        "textOutline": {
            "color": "#0000ff",
            "enabled": true,
            "width": 2
        },
        "textShadow": {
            "color": "#0000ff",
            "enabled": true,
            "offsetX": 0,
            "offsetY": 0,
            "blur": 0
        },
        "fontFamily": "Open Sans",
        "lineHeight": 1.2,
        "transform": "scale(0.258523, 0.258523) translate(-407.134px, -288.514px) rotate(0deg)"
    }
}
import { loadFont as Inter } from '@remotion/google-fonts/Inter'
import { loadFont as Kanit } from '@remotion/google-fonts/Kanit';
import { loadFont as Lato } from '@remotion/google-fonts/Lato';
import { loadFont as Lora } from '@remotion/google-fonts/Lora';
import { loadFont as Merriweather } from '@remotion/google-fonts/Merriweather';
import { loadFont as Montserrat } from '@remotion/google-fonts/Montserrat';
import { loadFont as Mukta } from '@remotion/google-fonts/Mukta';
import { loadFont as NotoSans } from '@remotion/google-fonts/NotoSans';
import { loadFont as NotoSansJP } from '@remotion/google-fonts/NotoSansJP';
import { loadFont as NotoSansKR } from '@remotion/google-fonts/NotoSansKR';
import { loadFont as Nunito } from '@remotion/google-fonts/Nunito';
import { loadFont as NunitoSans } from '@remotion/google-fonts/NunitoSans';
import { loadFont as OpenSans } from '@remotion/google-fonts/OpenSans';
import { loadFont as Oswald } from '@remotion/google-fonts/Oswald';
import { loadFont as PTSans } from '@remotion/google-fonts/PTSans';
import { loadFont as PlayfairDisplay } from '@remotion/google-fonts/PlayfairDisplay';
import { loadFont as Poppins } from '@remotion/google-fonts/Poppins';
import { loadFont as Raleway } from '@remotion/google-fonts/Raleway';
import { loadFont as Roboto } from '@remotion/google-fonts/Roboto';
import { loadFont as RobotoCondensed } from '@remotion/google-fonts/RobotoCondensed';
import { loadFont as RobotoMono } from '@remotion/google-fonts/RobotoMono';
import { loadFont as RobotoSlab } from '@remotion/google-fonts/RobotoSlab';
import { loadFont as Rubik } from '@remotion/google-fonts/Rubik';
import { loadFont as Ubuntu } from '@remotion/google-fonts/Ubuntu';
import { loadFont as WorkSans } from '@remotion/google-fonts/WorkSans';


export enum FontFamilyEnum {
    Inter = "Inter",
    Kanit = "Kanit",
    Lato = "Lato",
    Lora = "Lora",
    Merriweather = "Merriweather",
    Montserrat = "Montserrat",
    Mukta = "Mukta",
    NotoSans = "Noto Sans",
    NotoSansJP = "Noto Sans JP",
    NotoSansKR = "Noto Sans KR",
    Nunito = "Nunito",
    NunitoSans = "Nunito Sans",
    OpenSans = "Open Sans",
    Oswald = "Oswald",
    PTSans = "PT Sans",
    PlayfairDisplay = "Playfair Display",
    Poppins = "Poppins",
    Raleway = "Raleway",
    Roboto = "Roboto",
    RobotoCondensed = "Roboto Condensed",
    RobotoMono = "Roboto Mono",
    RobotoSlab = "Roboto Slab",
    Rubik = "Rubik",
    Ubuntu = "Ubuntu",
    WorkSans = "Work Sans"
}

export const fontsArray = [
    {
        value: FontFamilyEnum.Inter,
        label: "Inter",
        key: 'Inter'
    },
    {
        value: FontFamilyEnum.Kanit,
        label: "Kanit",
        key: "Kanit",
    },
    {
        value: FontFamilyEnum.Lato,
        label: "Lato",
        key: "Lato",
    },
    {
        value: FontFamilyEnum.Lora,
        label: "Lora",
        key: "Lora",
    },
    {
        value: FontFamilyEnum.Montserrat,
        label: "Montserrat",
        key: "Montserrat",
    },
    {
        value: FontFamilyEnum.Mukta,
        label: "Mukta",
        key: "Mukta",
    },
    {
        value: FontFamilyEnum.NotoSans,
        label: "Noto Sans",
        key: "Noto Sans",
    },
    {
        value: FontFamilyEnum.NotoSansJP,
        label: "Noto Sans JP",
        key: "Noto Sans JP",
    },
    {
        value: FontFamilyEnum.NotoSansKR,
        label: "Noto Sans KR",
        key: "Noto Sans KR",
    },
    {
        value: FontFamilyEnum.Nunito,
        label: "Nunito",
        key: "Nunito",
    },
    {
        value: FontFamilyEnum.NunitoSans,
        label: "Nunito Sans",
        key: "Nunito Sans",
    },
    {
        value: FontFamilyEnum.OpenSans,
        label: "Open Sans",
        key: "Open Sans",
    },
    {
        value: FontFamilyEnum.Oswald,
        label: "Oswald",
        key: "Oswald",
    },
    {
        value: FontFamilyEnum.PTSans,
        label: "PT Sans",
        key: "PT Sans",
    },
    {
        value: FontFamilyEnum.PlayfairDisplay,
        label: "Playfair Display",
        key: "Playfair Display",
    },
    {
        value: FontFamilyEnum.Poppins,
        label: "Poppins",
        key: "Poppins",
    },
    {
        value: FontFamilyEnum.Raleway,
        label: "Raleway",
        key: "Raleway",
    },
    {
        value: FontFamilyEnum.Roboto,
        label: "Roboto",
        key: "Roboto",
    },
    {
        value: FontFamilyEnum.RobotoCondensed,
        label: "Roboto Condensed",
        key: "Roboto Condensed",
    },
    {
        value: FontFamilyEnum.RobotoMono,
        label: "Roboto Mono",
        key: "Roboto Mono",
    },
    {
        value: FontFamilyEnum.RobotoSlab,
        label: "Roboto Slab",
        key: "Roboto Slab",
    },
    {
        value: FontFamilyEnum.Rubik,
        label: "Rubik",
        key: "Rubik",
    },
    {
        value: FontFamilyEnum.Ubuntu,
        label: "Ubuntu",
        key: "Ubuntu",
    },
    {
        value: FontFamilyEnum.WorkSans,
        label: "Work Sans",
        key: "Work Sans",
    },
    {
        value: FontFamilyEnum.Merriweather,
        label: "Merriweather",
        key: "Merriweather",
    },
];


export const getFontFamily = (fontFamily: FontFamilyEnum) => {
    switch (fontFamily) {
        case FontFamilyEnum.Inter:
            return Inter().fontFamily
        case FontFamilyEnum.Kanit:
            return Kanit().fontFamily
        case FontFamilyEnum.Lato:
            return Lato().fontFamily
        case FontFamilyEnum.Lora:
            return Lora().fontFamily
        case FontFamilyEnum.Merriweather:
            return Merriweather().fontFamily
        case FontFamilyEnum.Montserrat:
            return Montserrat().fontFamily
        case FontFamilyEnum.Mukta:
            return Mukta().fontFamily
        case FontFamilyEnum.NotoSans:
            return NotoSans().fontFamily
        case FontFamilyEnum.NotoSansJP:
            return NotoSansJP().fontFamily
        case FontFamilyEnum.NotoSansKR:
            return NotoSansKR().fontFamily
        case FontFamilyEnum.Nunito:
            return Nunito().fontFamily
        case FontFamilyEnum.NunitoSans:
            return NunitoSans().fontFamily
        case FontFamilyEnum.OpenSans:
            return OpenSans().fontFamily
        case FontFamilyEnum.Oswald:
            return Oswald().fontFamily
        case FontFamilyEnum.PTSans:
            return PTSans().fontFamily
        case FontFamilyEnum.PlayfairDisplay:
            return PlayfairDisplay().fontFamily
        case FontFamilyEnum.Poppins:
            return Poppins().fontFamily
        case FontFamilyEnum.Raleway:
            return Raleway().fontFamily
        case FontFamilyEnum.Roboto:
            return Roboto().fontFamily
        case FontFamilyEnum.RobotoCondensed:
            return RobotoCondensed().fontFamily
        case FontFamilyEnum.RobotoMono:
            return RobotoMono().fontFamily
        case FontFamilyEnum.RobotoSlab:
            return RobotoSlab().fontFamily
        case FontFamilyEnum.Rubik:
            return Rubik().fontFamily
        case FontFamilyEnum.Ubuntu:
            return Ubuntu().fontFamily
        case FontFamilyEnum.WorkSans:
            return WorkSans().fontFamily
        default:
            return;
    }
}
import React, { CSSProperties, useEffect, useState } from "react";
import styles from "./SubtitleEditingTextFontSettings.module.scss";
import { Button, ColorPicker, InputNumber, Select } from "antd";
import type { Color } from "antd/es/color-picker";
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  ItalicOutlined,
  LineHeightOutlined,
  UnderlineOutlined,
} from "@ant-design/icons";
import { colors } from "../../../Themes/colors";
import { ISubtitlesConfig } from "../../../types/interfaces";
import {
  FontFamilyEnum,
  getFontFamily,
} from "../../../remotion/subtitles/fonts";
import CustomColorPicker from "../../CustomColorPicker/CustomColorPicker";

interface IconButtonProps {
  icon: React.ReactElement;
  onClick: () => void;
  style?: CSSProperties;
}

interface ISubtitleEditingTextFontSettings {
  config: ISubtitlesConfig;
  onChangeFontSize: (value: number | null) => void;
  onChangeFontLineHeight: (value: number | null) => void;
  handleEditMainOptions: (index: number) => void;
  handleAlignment: (index: number) => void;
  handleFontChange: (value: FontFamilyEnum) => void;
  changeFontColor: (value: string) => void;
  capitalizeText: () => void;
  unCapitalizeText: () => void;
  fontsArray: {
    value: FontFamilyEnum;
    key: string;
    label: string;
  }[];
}

const IconButton: React.FC<IconButtonProps> = ({ icon, onClick, style }) => {
  return (
    <Button type="default" onClick={onClick} style={style}>
      {icon}
    </Button>
  );
};

const SubtitleEditingTextFontSettings: React.FC<
  ISubtitleEditingTextFontSettings
> = ({
  config,
  onChangeFontSize,
  onChangeFontLineHeight,
  handleEditMainOptions,
  handleAlignment,
  fontsArray,
  handleFontChange,
  changeFontColor,
  capitalizeText,
  unCapitalizeText,
}) => {
  const [activeMainOptions, setActiveMainOptions] = useState<number[]>([]);
  const handleClickMainOptions = (index: number) => {
    if (activeMainOptions?.includes(index)) {
      setActiveMainOptions(activeMainOptions?.filter((item) => item !== index));
    } else {
      setActiveMainOptions([...activeMainOptions, index]);
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (config.bold) {
      setActiveMainOptions([...activeMainOptions, 0]);
    } else if (config.underline) {
      setActiveMainOptions([...activeMainOptions, 1]);
    } else {
      setActiveMainOptions([...activeMainOptions, 2]);
    }
  }, [config]);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.selectContainer}>
          <Select
            showSearch
            className={styles.select}
            placeholder="Select Your Font Family"
            optionFilterProp="children"
            optionLabelProp="label"
            onChange={handleFontChange}
            filterOption={filterOption}
            size="large"
            defaultValue={FontFamilyEnum.OpenSans}
          >
            {fontsArray.map((font) => (
              <Select.Option value={font.value}>
                <span
                  style={{
                    fontFamily: getFontFamily(font.value),
                    fontSize: 18,
                  }}
                >
                  {font.label}
                </span>
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.colorSizeContainer}>
          <InputNumber
            className={styles.sizeInput}
            defaultValue={config.fontSize}
            onChange={onChangeFontSize}
            max={86}
          />
          <CustomColorPicker
            colorValue={config.color}
            onChange={(value: string) => {
              console.log(value);
              if (changeFontColor) {
                changeFontColor(value);
              }
            }}
            trigger={
              <Button
                style={{
                  border: "1px solid gray",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: config.color,
                  }}
                />
              </Button>
            }
          />
        </div>
      </div>
      <div className={styles.fontOptionsContainer}>
        <div className={styles.mainOptionsContainer}>
          <div className={styles.mainOptions}>
            {[BoldOutlined, UnderlineOutlined, ItalicOutlined].map(
              (Icon, i) => {
                return (
                  <IconButton
                    icon={<Icon className={styles.icon} />}
                    onClick={() => {
                      handleEditMainOptions(i);
                      handleClickMainOptions(i);
                    }}
                    style={{
                      marginRight: 10,
                      color:
                        config.bold && activeMainOptions.includes(0) && i === 0
                          ? colors.white
                          : config.underline &&
                            activeMainOptions.includes(1) &&
                            i === 1
                          ? colors.white
                          : config.italic &&
                            activeMainOptions.includes(2) &&
                            i === 2
                          ? colors.white
                          : "",
                      backgroundColor:
                        config.bold && activeMainOptions.includes(0) && i === 0
                          ? colors.mainColor
                          : config.underline &&
                            activeMainOptions.includes(1) &&
                            i === 1
                          ? colors.mainColor
                          : config.italic &&
                            activeMainOptions.includes(2) &&
                            i === 2
                          ? colors.mainColor
                          : "",
                    }}
                  />
                );
              }
            )}
          </div>
          <IconButton
            icon={
              <>
                <span
                  style={{
                    color: config.textTransform === "none" ? colors.white : "",
                  }}
                >
                  a{" "}
                </span>
                <strong
                  style={{
                    color:
                      config.textTransform === "uppercase" ? colors.white : "",
                  }}
                >
                  A
                </strong>
              </>
            }
            onClick={() => {
              if (config.textTransform === "uppercase") {
                unCapitalizeText();
              } else {
                capitalizeText();
              }
            }}
            style={{
              marginRight: 10,
              backgroundColor: colors.mainColor,
            }}
          />
          <div className={styles.mainOptions}>
            {[AlignLeftOutlined, AlignCenterOutlined, AlignRightOutlined].map(
              (Icon, i, arr) => {
                return (
                  <div>
                    <IconButton
                      icon={<Icon />}
                      onClick={() => {
                        handleAlignment(i);
                      }}
                      style={{
                        marginRight: i === arr.length - 1 ? 0 : 10,
                        color:
                          config.textAlign === "left" && i === 0
                            ? colors.white
                            : config.textAlign === "center" && i === 1
                            ? colors.white
                            : config.textAlign === "right" && i === 2
                            ? colors.white
                            : "",
                        backgroundColor:
                          config.textAlign === "left" && i === 0
                            ? colors.mainColor
                            : config.textAlign === "center" && i === 1
                            ? colors.mainColor
                            : config.textAlign === "right" && i === 2
                            ? colors.mainColor
                            : "",
                      }}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
        <InputNumber
          className={styles.lineHeightInput}
          defaultValue={config.lineHeight}
          step={0.1}
          onChange={onChangeFontLineHeight}
          addonBefore={<LineHeightOutlined />}
        />
      </div>
    </div>
  );
};

export default SubtitleEditingTextFontSettings;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse, RegisterResponse, User, userVerificationResponse } from "../../types/models";
import { userLogin, userRegister, userVerification } from "../../types/authTypes";
import authServiceInstance from "../../api/AuthApi";
import { notification } from "antd";
import { ROUTES_MAP } from "../../routes/routesMap";

interface InitialState {
    user: User | null;
    token: string | null;
    isLoading: boolean;
    isLoggedIn: boolean;
    error: string | null;
    userVerified: boolean;
}

const storedUser = localStorage.getItem('user');
const initialUser = storedUser ? JSON.parse(storedUser) : null;
const initialState: InitialState = {
    user: initialUser,
    token: "",
    isLoading: false,
    isLoggedIn: false,
    error: null,
    userVerified: initialUser?.user?.verified,
}

export const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        forceLogout: (state) => {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            state.isLoggedIn = false;
            state.user = null;
            state.token = null;
            window.location.href = ROUTES_MAP.login
        },
        clearError(state, action: PayloadAction<boolean>) {
            state.error = null;
        },
        registerUserSuccess: (state, action: PayloadAction<RegisterResponse>) => {
            state.error = null;
            state.isLoading = false;
            state.user = action.payload.user
            state.token = action.payload.authToken
            state.userVerified = action.payload.verified
        },
        registerUserError: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },
        verifyUserSuccess: (state, action: PayloadAction<userVerificationResponse>) => {
            state.userVerified = action.payload.verified
        },
        verifyUserError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
        updateUserSuccess: (state, action: PayloadAction<LoginResponse>) => {
            state.user = action.payload.user || null
        },
        updateUserFailure: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
        loginSuccess: (state, action: PayloadAction<LoginResponse>) => {
            state.user = action.payload.user || null;
            state.token = action.payload.authToken;
        },
        loginFailure: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        setUserVerified: (state) => {
            state.userVerified = true
        },
        checkLocalStorage: (state) => {
            const storedUser = localStorage.getItem('user');
            const storedToken = localStorage.getItem('token');

            if (storedUser) {
                state.user = JSON.parse(storedUser);
            }

            if (storedToken) {
                state.token = storedToken;
            }
        },
    },
});


export const {
    registerUserError,
    registerUserSuccess,
    clearError,
    forceLogout,
    setIsLoggedIn,
    setLoading,
    verifyUserSuccess,
    verifyUserError,
    updateUserSuccess,
    updateUserFailure,
    loginSuccess,
    loginFailure,
    setUserVerified,
    checkLocalStorage
} = AuthSlice.actions

export const login = createAsyncThunk(
    "auth/login",
    async (userData: userLogin, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const response = await authServiceInstance.login(userData);
            dispatch(loginSuccess(response.data));
            if (response.data.authToken) {
                localStorage.setItem('token', response.data.authToken);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                dispatch(setIsLoggedIn(true));
            }
            dispatch(setLoading(false))
            return Promise.resolve(response);
        } catch (err: any) {
            const message = err?.message || 'Something went wrong'
            notification.error({
                message
            })
            dispatch(loginFailure(err.message))
            return null;
        }
    }
);

export const register = createAsyncThunk(
    "auth/register",
    async (userData: userRegister, { dispatch }) => {
        try {
            dispatch(setLoading(true))
            const response = await authServiceInstance.register(userData);
            dispatch(registerUserSuccess(response.data));
            if (response.data.authToken) {
                localStorage.setItem('token', response.data.authToken);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                dispatch(setIsLoggedIn(true));
            }
            dispatch(setLoading(false))
            return Promise.resolve(response);
        } catch (err: any) {
            const message = err?.message || 'Something went wrong'
            notification.error({
                message
            })
            dispatch(registerUserError(err.message))
            return null;
        }
    }
);

export const currentUser = createAsyncThunk(
    'auth/currentUser',
    async (_, { dispatch }) => {

        try {
            const response = await authServiceInstance.getMe();
            const currentUserApi = response.data;
            const currentUser = currentUserApi.user ? JSON.stringify(currentUserApi.user) : null;

            if (currentUser) {
                localStorage.setItem('user', currentUser);
                localStorage.setItem('token', currentUserApi.authToken);
            }

            dispatch(updateUserSuccess(response.data));
        } catch (err: any) {
            const message = err?.message || 'Something went wrong'
            notification.error({
                message
            })
            dispatch(updateUserFailure(err.message))
        }
    }
)

export const verifyUser = createAsyncThunk(
    "auth/verify",
    async (verificationCode: userVerification, { dispatch }) => {
        try {
            dispatch(setLoading(true))
            const response = await authServiceInstance.verifyUser(verificationCode);
            dispatch(currentUser())
            dispatch(setLoading(false))
            return Promise.resolve(response)
        } catch (err: any) {
            const message = err?.message || 'Something went wrong'
            notification.error({
                message
            })
            dispatch(verifyUserError(err.message))
            dispatch(setLoading(false))
            return null;
        }
    }
);

export const resendVerify = createAsyncThunk(
    "auth/resendVerify",
    async (_, { dispatch }) => {
        try {
            dispatch(setLoading(true))
            const response = await authServiceInstance.resendVerify();
            dispatch(currentUser())
            dispatch(setLoading(false))
            return Promise.resolve(response)
        } catch (err: any) {
            const message = err?.message || 'Something went wrong'
            notification.error({
                message
            })
            dispatch(setLoading(false))
            return null;
        }
    }
);


export const requestResetPassword = createAsyncThunk(
    'auth/requestResetPassword',
    async (email: string) => {
        try {
            await authServiceInstance.requestResetPassword(email)
            console.log("DONE")
        } catch (err: any) {
            const message = err?.message || 'Something went wrong'
            notification.error({
                message
            })
            throw err;
        }
    }
)

export const submitResetPassword = createAsyncThunk(
    'auth/submitResetPassword',
    async ({ email, code, password }: { email: string, code: string, password: string }, { dispatch }) => {
        try {
            await authServiceInstance.submitResetPassword({ email, code, password })
            notification.success({ message: "Password updated successfully" })
            await dispatch(login({ email, password }))
            window.location.href = ROUTES_MAP.dashboard.videos
        } catch (err: any) {
            const message = err?.message || 'Something went wrong'
            notification.error({
                message
            })
        }
    }
)
import {
  Button,
  Card,
  Checkbox,
  Input,
  Popconfirm,
  Slider,
  Tooltip,
} from "antd";
import { IEditorMediaOverlay } from "../../../types/interfaces";
import { secondsToHHMMSS } from "../../../helpers/helpers";
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { MediaOverlayTypeEnum } from "../../../types/models";
import { useRef, useState } from "react";
import useDebounce from "../../../helpers/useDebounce";

interface IProps {
  overlay: IEditorMediaOverlay;
  index: number;
  onMarkNow: (index: number, position: "start" | "end") => void;
  onApplyToFullClip: (index: number) => void;
  onDisApplyToFullClip: (index: number) => void;
  onDeleteOverlay: (index: number) => void;
  onVolumeChange: (index: number, value: number) => void;
}

const VOLUME_MARKS = {
  0: "0%",
  //   40: "40%",
  //   60: "60%",
  //   80: "80%",
  50: "50%",
  100: "100%",
};

const MediaOverlayCard = ({
  overlay,
  index,
  onDeleteOverlay,
  onVolumeChange,
}: IProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [volume, setVolume] = useState(overlay.volume);

  useDebounce(() => onVolumeChange(index, volume), 300, [volume]);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const onDelete = () => {
    if (isPlaying) {
      togglePlay();
    }

    onDeleteOverlay(index);
  };

  return (
    <Card
      style={{
        width: "100%",
        borderWidth: 3,
      }}
      bodyStyle={{ padding: 10 }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems:
                overlay.type === MediaOverlayTypeEnum.image
                  ? "center"
                  : "flex-start",
              marginBottom: 10,
            }}
          >
            <div style={{ paddingRight: 10, width: "100%", height: "100%" }}>
              {overlay.type === MediaOverlayTypeEnum.audio ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50px",
                  }}
                >
                  <audio ref={audioRef} preload="false" src={overlay.url} />

                  {isPlaying ? (
                    <PauseCircleOutlined
                      style={{ cursor: "pointer", fontSize: 20 }}
                      onClick={togglePlay}
                    />
                  ) : (
                    <PlayCircleOutlined
                      style={{ cursor: "pointer", fontSize: 20 }}
                      onClick={togglePlay}
                    />
                  )}
                </div>
              ) : (
                <img
                  width={50}
                  height={50}
                  src={overlay.thumbnailUrl || overlay.url}
                />
              )}
            </div>
            <div>
              <div>
                <Tooltip title={overlay.title}>
                  {overlay.title.length > 20
                    ? overlay.title.substring(0, 17) + "..."
                    : overlay.title}
                </Tooltip>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {secondsToHHMMSS(overlay.start)}
                </div>
                <span style={{ margin: 5 }}>-</span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {secondsToHHMMSS(overlay.end)}
                </div>
              </div>

              {/* 
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Input
                    value={secondsToHHMMSS(overlay.start)}
                    readOnly
                    style={{ width: 100 }}
                  />
                  {!overlay.fullClip && (
                    <div>
                      <Button
                        type="link"
                        onClick={() => onMarkNow(index, "start")}
                      >
                        Mark now
                      </Button>
                      {index === 0 && (
                        <Tooltip title="Mark the current time in the video as the text start time">
                          <ExclamationCircleOutlined />
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>
                <span style={{ margin: 5 }}>-</span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Input
                    value={secondsToHHMMSS(overlay.end)}
                    readOnly
                    style={{ width: 100 }}
                  />
                  {!overlay.fullClip && (
                    <div>
                      <Button
                        type="link"
                        onClick={() => onMarkNow(index, "end")}
                      >
                        Mark now
                      </Button>
                      {index === 0 && (
                        <Tooltip title="Mark the current time in the video as the text end time">
                          <ExclamationCircleOutlined />
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>
              </div> */}

              {/* <div style={{ marginBottom: 10 }}>
                <Checkbox
                  checked={overlay.fullClip}
                  onChange={(e) => {
                    if (overlay.fullClip) {
                      onDisApplyToFullClip(index);
                    } else {
                      onApplyToFullClip(index);
                    }
                  }}
                >
                  Apply to full clip
                </Checkbox>
              </div> */}
              {["video", "audio"].includes(overlay.type) ? (
                <div style={{ marginBottom: 10 }}>
                  <Checkbox
                    checked={volume === 0}
                    onChange={(e) => {
                      if (volume === 0) {
                        setVolume(50);
                      } else {
                        setVolume(0);
                      }
                    }}
                  >
                    Mute Audio
                  </Checkbox>
                  {volume > 0 && (
                    <Slider
                      min={5}
                      max={100}
                      value={volume}
                      marks={VOLUME_MARKS}
                      onChange={(value) => {
                        setVolume(value);
                      }}
                      step={5}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          {/* <MediaOverlaySettings
        trigger={
          <SettingFilled
            style={{
              cursor: "pointer",
              fontSize: 16,
              marginRight: 10,
            }}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
            }}
          />
        }
        onChange={() => {}}
        config=""
      /> */}
          <Popconfirm
            title="Delete Overlay"
            description="Are you sure to delete this overlay?"
            cancelText="No"
            okText="Yes"
            placement="left"
            onConfirm={onDelete}
          >
            <DeleteFilled style={{ color: "red", fontSize: 16 }} />
          </Popconfirm>
        </div>
      </div>
    </Card>
  );
};

export default MediaOverlayCard;

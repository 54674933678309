import React from "react";
import { AbsoluteFill, interpolate, useCurrentFrame } from "remotion";

const sentences = [
  "Tell a story with your",
  "video by using these",
  "style of subtitles",
  "Don't You Love these ❤️️",
];

export const SubtitleStylesCardStory: React.FC = () => {
  const frame = useCurrentFrame();

  let totalWords = 0;
  let currentSentenceIndex = 0;
  for (let i = 0; i < sentences.length; i++) {
    totalWords += sentences[i].split(" ").length;
    if (frame < totalWords * 10) {
      currentSentenceIndex = i;
      break;
    }
  }

  const currentSentence = sentences[currentSentenceIndex];

  const wordsInCurrentSentence = currentSentence.split(" ");
  const wordIndex = Math.min(
    Math.floor(
      (frame - (totalWords - wordsInCurrentSentence.length) * 10) / 10
    ),
    wordsInCurrentSentence.length - 1
  );
  const currentWords = wordsInCurrentSentence.slice(0, wordIndex + 1).join(" ");

  return (
    <AbsoluteFill
      style={{
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          fontSize: 24,
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          textAlign: "center",
        }}
      >
        {currentWords}
      </div>
    </AbsoluteFill>
  );
};

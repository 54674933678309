import React from "react";
import { AbsoluteFill, Sequence } from "remotion";

const words = [
  "Go",
  "really",
  "Fast",
  "with",
  "These",
  "Fast",
  "Faster",
  "Fastest",
  "Subtitles",
  "which",
  "Make",
  "Your",
  "video",
  "More",
  "Gripping",
  "And",
  "Engaging",
  "🏃‍♂️",
];

export const SubtitleStylesCardOneWord: React.FC = () => {
  return (
    <AbsoluteFill
      style={{
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {words.map((word, index) => {

        return (
          <Sequence key={index} from={index * 10} durationInFrames={10}>
            <div
              style={{
                fontSize: 30,
                color: "white",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {word}
            </div>
          </Sequence>
        );
      })}
    </AbsoluteFill>
  );
};

import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../redux/store";
import { secondsToHHMMSS } from "../../helpers/helpers";
import {
  Button,
  Form,
  Input,
  Modal,
  TimePicker,
  Tooltip,
  notification,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { createClip } from "../../redux/Slices/videoDetailsSlice";

interface IProps {
  open: boolean;
  onCancel: () => void;
  videoId: string;
  videoUrl: string;
  videoDuration: number;
}

const CreateClipModal = ({
  open,
  onCancel,
  videoId,
  videoUrl,
  videoDuration,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(`Untitled Clip ${Date.now()}`);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(videoDuration);

  const videoRef = useRef<HTMLVideoElement>(null);

  const dispatch = useAppDispatch();

  const onMarkStart = () => {
    if (videoRef.current) {
      setStart(videoRef.current.currentTime);
    }
  };

  const onMarkEnd = () => {
    if (videoRef.current) {
      setEnd(videoRef.current.currentTime);
    }
  };

  const onCreateCustomClip = async () => {
    setLoading(true);
    try {
      await dispatch(
        createClip({
          videoId,
          title,
          start,
          end,
        })
      );
      notification.success({
        message: "Created Successfully",
      });
      onCancel();
    } catch (err: any) {
      const message = err?.message || "Something went wrong";
      notification.error({
        message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!open && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setStart(0);
      setEnd(0);
      setTitle(`Untitle Clip ${Date.now()}`)
    }
  }, [open, videoRef]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onCreateCustomClip}
      title="Create Custom Clip"
      okText="Create"
      okButtonProps={{
        disabled: end < start,
        loading,
      }}
    >
      <video ref={videoRef} src={videoUrl} controls width="100%" />
      <Form>
        <FormItem label="Clip Title" required>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
        </FormItem>
        <FormItem label="Start Time" required>
          <Input
            readOnly
            style={{ width: 100 }}
            value={secondsToHHMMSS(start)}
          />
          <Tooltip title="Mark the current time in the video as the clip start time">
            <Button type="link" onClick={onMarkStart}>
              Mark Now
            </Button>
          </Tooltip>
        </FormItem>
        <FormItem label="End Time" required>
          <Input readOnly style={{ width: 100 }} value={secondsToHHMMSS(end)} />
          <Tooltip title="Mark the current time in the video as the clip end time">
            <Button type="link" onClick={onMarkEnd}>
              Mark Now
            </Button>
          </Tooltip>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default CreateClipModal;

import React from "react";

const FacebookIcon = ({
  width = "100%",
  height = "auto",
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxWidth: "100%" }}
    >
      <path
        d="M24.866 49.3C38.4643 49.3 49.488 38.2638 49.488 24.65C49.488 11.0362 38.4643 0 24.866 0C11.2676 0 0.243988 11.0362 0.243988 24.65C0.243988 38.2638 11.2676 49.3 24.866 49.3Z"
        fill="#1877F2"
      />
      <path
        d="M30.9654 26.8673L31.7487 21.7608H26.8563V18.4497C26.8563 17.0532 27.5389 15.6912 29.7308 15.6912H31.9548V11.3436C31.9548 11.3436 29.9347 10.9996 28.0061 10.9996C23.975 10.9996 21.341 13.444 21.341 17.8718V21.7608H16.861V26.8673H21.341V39.2107C22.2709 39.2107 25.9242 39.2107 26.8541 39.2107V26.8673H30.9654Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookIcon;

import React, { useEffect, useState } from "react";
import { PlusOutlined, VideoCameraAddOutlined } from "@ant-design/icons";
import { Button, Modal, notification } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useNavigate } from "react-router-dom";
import {
  MAX_FILE_DURATION_MINUTES,
  MAX_FILE_SIZE_GB,
} from "../../constants/constants";
import { Uploader } from "../../helpers/Uploader";
import { createVideo, setCreatedVideo } from "../../redux/Slices/VideosSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { ROUTES_MAP } from "../../routes/routesMap";

const UplaodVideoModal = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [filesList, setFilesList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { createdVideo } = useAppSelector((state) => state.videos);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onCreateVideo = async ({ url, Key }: { url: string; Key: string }) => {
    const file = filesList[0];
    await dispatch(createVideo({ url, title: file.name }));

    setLoading(false);
    setFilesList([
      {
        name: file.name,
        fileName: file.name,
        originFileObj: file,
        uid: file.uid,
        status: "done",
        percent: 100,
      },
    ]);
  };

  const onSubmit = () => {
    setLoading(true);
    const file = filesList[0];
    const uploader = new Uploader({
      contentType: file.type || "video/mp4",
      fileName: file.name,
      file: file,
    });
    uploader
      .onComplete(async (data: any) => {
        setTimeout(async () => {
          await onCreateVideo(data);
        }, 5 * 1000);
      })
      .onProgress(({ percentage }: any) => {
        setFilesList([
          {
            name: file.name,
            fileName: file.name,
            originFileObj: file,
            uid: file.uid,
            status: "uploading",
            percent: percentage <= 99 ? percentage : 99,
          },
        ]);
      })
      .onError((err: any) => {
        console.log(err);
        setLoading(false);
        notification.error({
          message: err.message || "Something went wrong",
        });
      });
    uploader.start();
  };

  useEffect(() => {
    if (createdVideo) {
      dispatch(setCreatedVideo(null));
      navigate(`/dashboard/videos/${createdVideo.id}`);
    }
  }, [createdVideo]);

  return (
    <>
      <Modal
        title="New Video"
        open={isOpen}
        className="new-video-modal"
        footer={null}
        closeIcon={null}
      >
        <div className="dropzone-container">
          {/* <DropZone onDrop={onDrop} /> */}
          <Dragger
            disabled={loading}
            name="video"
            accept="video/*"
            // multiple={false}
            onChange={(info) => {
              if (!info.file) {
                return setFilesList([]);
              }

              if (
                (info.file.size || 0) >=
                MAX_FILE_SIZE_GB * 1024 * 1024 * 1024
              ) {
                return notification.info({
                  type: "info",
                  message: `Max file size is ${MAX_FILE_SIZE_GB}GB `,
                  placement: "topRight",
                });
              }
              setFilesList([info.file]);
            }}
            beforeUpload={() => {
              return false;
            }}
            fileList={filesList}
          >
            <p className="ant-upload-drag-icon">
              <VideoCameraAddOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Supported extensions: mp4, mov, webm, mkv <br />
              Max Video Duration: {MAX_FILE_DURATION_MINUTES / 60}h
              <br />
              Max File Size: {MAX_FILE_SIZE_GB}GB
            </p>
          </Dragger>
        </div>
        <div className="actions">
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="primary"
            disabled={!filesList.length}
            loading={loading}
            onClick={onSubmit}
          >
            Upload & Start Processing
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UplaodVideoModal;

import React, { useEffect } from "react";
import {
  Typography,
  Layout,
  Col,
  Row,
  Carousel,
  Image,
  Button,
  Grid,
} from "antd";
import { dividerStyle, styles } from "./styles";
import logo from "../../assets/images/logo.png";
import SignUpCarouselItem from "../../Components/SignUpCarouselItem/SignUpCarouselItem";
import { GoogleIcon } from "../../assets/Icons/google";
import Divider from "../../Components/Divider/Divider";
import SignUpForm from "../../Components/SignUpForm/SignUpForm";
import { Link, useNavigate } from "react-router-dom";
import cssStyles from "./signUp.module.css";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { checkLocalStorage } from "../../redux/Slices/AuthSlice";
import AppHeader from "../../Components/Header/Header";

// const { Content } = Layout;
const { Text } = Typography;
const { useBreakpoint } = Grid;

const SignUp: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const screens = useBreakpoint();
  console.log(screens.lg);
  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  useEffect(() => {
    dispatch(checkLocalStorage());
    if (user && user.id) {
      navigate("/dashboard/videos");
    }
  }, []);

  return (
    <Layout style={styles.layout}>
      <AppHeader signup />
      {screens.lg ? (
        <Row>
          <Col span={24} style={styles.firstColumn}>
            {/* <Text style={styles.titleStyle}>
              Start growing your audience with Shorts!
            </Text> */}
            {/* <Button className={cssStyles.googleButton}>
              <GoogleIcon
                style={{
                  width: "1.2rem",
                }}
              />
              continue with Google
            </Button> */}
            {/* <Divider style={dividerStyle} /> */}
            <SignUpForm />
            <Text>
              Already have an account? &nbsp;
              <Link to="/login">Login</Link>
            </Text>
            <Text style={styles.infoLabel}>No Credit Card required</Text>
          </Col>
          {/* <Col span={7} style={styles.secondColumn}>
            <Carousel
              style={styles.carousel}
              afterChange={onChange}
              waitForAnimate
            >
              <SignUpCarouselItem />
              <SignUpCarouselItem />
            </Carousel>
          </Col> */}
        </Row>
      ) : (
        <>
          <Col span={24} style={styles.firstColumn}>
            {/* <Image width={300} src={logo} preview={false} />
            <Text style={styles.titleStyle}>
              Start growing your audience with Shorts!
            </Text> */}
            {/* <Button className={cssStyles.googleButton}>
              <GoogleIcon
                style={{
                  width: "1.2rem",
                }}
              />
              continue with Google
            </Button> */}
            {/* <Divider style={dividerStyle} /> */}
            <SignUpForm />
            <Text>
              Already have an account? &nbsp;
              <Link to="/login">Login</Link>
            </Text>
            <Text style={styles.infoLabel}>No Credit Card required</Text>
          </Col>
          {/* <Col span={24} style={styles.secondColumn}>
            <Carousel
              style={styles.carousel}
              afterChange={onChange}
              waitForAnimate
            >
              <SignUpCarouselItem />
              <SignUpCarouselItem />
            </Carousel>
          </Col> */}
        </>
      )}
    </Layout>
  );
};

export default SignUp;

import { Button, Tabs } from "antd";
import ClipEditorTabContent from "../../ClipEditorTabContent/ClipEditorTabContent";
import { useState } from "react";
import MediaOverlays from "../../Overlays/MediaOverlays/MediaOverlays";
import TextOverlays from "../../Overlays/TextOverlays/TextOverlays";
import { PlusCircleOutlined } from "@ant-design/icons";
import { MediaOverlayTypeEnum } from "../../../types/models";

const ClipEditorOverlaysTabs = () => {
  const [activeTab, setActiveTab] = useState("1");
  return (
    <ClipEditorTabContent title="Overlays">
      {" "}
      <div
        style={{
          width: "100%",
          padding: 10,
          overflowY: "auto",
          maxHeight: "92vh",
          overflowX: "hidden",
        }}
      >
        <Tabs
          defaultActiveKey="1"
          // className={styles.tabs}
          onChange={(key: string) => setActiveTab(key)}
          items={[
            {
              key: "1",
              label: "Text",
              children: <TextOverlays />,
            },
            {
              key: "2",
              label: "Media",
              children: (
                <MediaOverlays
                  acceptedTypes={[
                    MediaOverlayTypeEnum.video,
                    MediaOverlayTypeEnum.image,
                  ]}
                  accepts="image/*,video/*"
                />
              ),
            },
            {
              key: "3",
              label: "Audio",
              children: (
                <MediaOverlays
                  acceptedTypes={[MediaOverlayTypeEnum.audio]}
                  accepts="audio/mp3,audio/wav,audio/webm"
                />
              ),
            },
          ]}
        />
      </div>
    </ClipEditorTabContent>
  );
};

export default ClipEditorOverlaysTabs;

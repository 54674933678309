import { Divider, Popover, Slider, Typography } from "antd";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { pickersColors } from "../../constants/pickersColors";
import "./Input.scss";

const ColorContent = ({
  onChange,
  color,
}: {
  onChange: (e: string) => void;
  color: string;
}) => {
  const [inputState, setInputState] = useState("");
  const [selectedColorState, setSelectedColorState] = useState("");
  const [sliderState, setSliderState] = useState(0);

  useEffect(() => {
    const opacity =
      color && color.includes("rgba") && color.split(",")[3].split(")")[0];
    setSliderState(Number(opacity) * 100);
    setInputState(color);
  }, [color]);

  const handleSliderChange = (value: number) => {
    setSliderState(value);
    updateColorWithNewAlpha(value);
  };

  const updateColorWithNewAlpha = useCallback(
    (sliderValue: number) => {
      const color = inputState.substring(0, inputState.lastIndexOf(","));
      const updatedColor = `${color},${sliderValue / 100})`;
      setInputState(updatedColor);
    },
    [sliderState, inputState]
  );

  const handleSelectedItem = (value: string) => {
    setSelectedColorState(value);
    updateInputStateBasedOnSelection(value);
  };

  const updateInputStateBasedOnSelection = useCallback(
    (value: string) => {
      const color = value.substring(0, value.lastIndexOf(","));
      const updatedColor = `${color},${Number(sliderState) / 100})`;
      setInputState(updatedColor);
    },
    [sliderState]
  );

  useEffect(() => {
    if (inputState) {
      onChange(inputState);
    }
  }, [inputState]);

  return (
    <div
      style={{
        width: "360px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: "18px",
            }}
          >
            Transparency
          </span>
          <span
            style={{
              fontSize: "18px",
            }}
          ></span>
        </div>
        <Slider
          min={0}
          max={100}
          style={{
            width: "95%",
          }}
          value={sliderState}
          onChange={(value: number) => {
            handleSliderChange(value);
          }}
        />
      </div>
      <Divider />
      <Typography.Text
        style={{
          fontSize: 22,
          marginBottom: 5,
        }}
      >
        Popular Colors
      </Typography.Text>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(6,1fr)",
          gap: "5px",
        }}
      >
        {pickersColors.map((item, index) => (
          <div
            key={index}
            style={{
              margin: 2,
              borderRadius: "10px",
              borderWidth: 2,
              borderStyle: "solid",
              borderColor:
                inputState === item.color
                  ? "black"
                  : item.color === "rgba(255, 255, 255, 1)"
                  ? "lightgray"
                  : "transparent",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              overflow: "hidden",
            }}
            onClick={() => {
              handleSelectedItem(item.color);
            }}
          >
            <div
              style={{
                backgroundColor: item.color,
                width: "100%",
                height: "40px",
              }}
            />
          </div>
        ))}
      </div>
      <div className="input-with-addon">
        <span className="addon">#</span>
        <input
          type="text"
          className="color-input"
          value={inputState}
          onChange={(e) => {
            setInputState(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

interface IPopover {
  trigger: ReactNode;
  onChange: (color: string) => void;
  colorValue: string;
}
const CustomColorPicker = ({ trigger, onChange, colorValue }: IPopover) => {
  return (
    <Popover
      placement="leftBottom"
      content={<ColorContent onChange={onChange} color={colorValue} />}
      trigger="click"
    >
      {trigger}
    </Popover>
  );
};

export default CustomColorPicker;

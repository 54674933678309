import React, { useState } from "react";
import { Button, Form, Input, Space, Typography } from "antd";
import { ResetPasswordFormTypes } from "../../types/stylesTypes";
import { styles } from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  login,
  requestResetPassword,
  setUserVerified,
  submitResetPassword,
} from "../../redux/Slices/AuthSlice";
import OTPInput from "react-otp-input";

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const ResetPasswordForm: React.FC = () => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [otp, setOtp] = useState("");
  const [resent, setResent] = useState(false);
  const password = Form.useWatch("password", form);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      if (!submitted) {
        await dispatch(requestResetPassword(values.email));
        setSubmitted(true);
      } else {
        await dispatch(submitResetPassword({ ...values, code: otp }));
      }
    } catch (err) {}
    setLoading(false);
  };
  return (
    <Form
      name="basic"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      style={styles.formStyle}
      layout="vertical"
      labelAlign="left"
      requiredMark={false}
    >
      <Form.Item<ResetPasswordFormTypes>
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your email!",
            type: "email",
          },
        ]}
        style={styles.inputStyle}
      >
        <Input disabled={submitted} />
      </Form.Item>
      {submitted && (
        <>
          <Form.Item<ResetPasswordFormTypes>
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password!",
                type: "string",
              },
            ]}
            style={styles.inputStyle}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item<ResetPasswordFormTypes>
            label="Confirm Password"
            name="passwordConfirm"
            rules={[
              {
                required: true,
                message: "Please enter your password confirmation!",
                type: "string",
              },
              {
                validator(rule, value, callback) {
                  callback(
                    value === password ? undefined : "Passwords doesn't match"
                  );
                },
              },
            ]}
            style={styles.inputStyle}
          >
            <Input.Password />
          </Form.Item>

          <Typography.Paragraph>
            Enter your verification code
          </Typography.Paragraph>
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              width: "50px",
              height: "50px",
            }}
          />
        </>
      )}

      <Form.Item style={styles.inputStyle}>
        <Button
          type="primary"
          htmlType="submit"
          style={styles.submitStyle}
          loading={loading}
        >
          {submitted ? <span>Update Password</span> : <span>Get Code</span>}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ResetPasswordForm;

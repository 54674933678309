import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClipProps, IMediaOverlay, MediaOverlayTypeEnum, VideoDetailsTypes } from "../../types/models";
import { notification } from "antd";
import videoDetailsApi from "../../api/videoDetailsApi";
import mediaOverlaysApi from "../../api/mediaOverlaysApi";

interface InitialState {
    loading: boolean;
    error: string;
    video?: VideoDetailsTypes | null;
    videoStatus?: boolean;
    selectedClip?: ClipProps | null
    mediaOverlays: IMediaOverlay[];
}

const initialState: InitialState = {
    loading: false,
    error: "",
    video: undefined,
    videoStatus: false,
    selectedClip: undefined,
    mediaOverlays: []
}

export const videoDetailsSlice = createSlice({
    name: 'videoDetails',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        setVideoSuccess(state, action: PayloadAction<VideoDetailsTypes | null>) {
            state.error = '';
            state.video = action.payload;
        },
        setVideoError(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
            state.video = undefined;
        },
        setVideoStatus(state, action: PayloadAction<boolean>) {
            state.videoStatus = action.payload;
        },
        setSelectedClip(state, action: PayloadAction<ClipProps | null>) {
            state.selectedClip = action.payload;
        },
        onDeleteClipSuccess(state, action: PayloadAction<string>) {
            if (state.video) {
                state.video.clips = state.video?.clips.filter(clip => clip.id !== action.payload);
                state.selectedClip = state.video.clips[0];
            }
        },
        onCreateClipSuccess(state, action: PayloadAction<ClipProps>) {
            if (state.video) {
                state.video.clips = state.video.clips.concat([action.payload])
                state.video.clips = state.video.clips.sort((a, b) => a.start - b.start)
                state.selectedClip = action.payload;
            }
        },
        setMediaOverlays(state, action: PayloadAction<IMediaOverlay[]>) {
            state.mediaOverlays = action.payload;
        },
        onCreateMediaOverlaySuccess(state, action: PayloadAction<IMediaOverlay>) {
            state.mediaOverlays = [action.payload].concat(state.mediaOverlays);
        },
        setSelectedClipStartEnd(state, action: PayloadAction<{ start: number, end: number }>) {
            state.selectedClip!.start = action.payload.start
            state.selectedClip!.end = action.payload.end
        }
    }
});

export const {
    onDeleteClipSuccess,
    setLoading,
    setVideoSuccess,
    setVideoError,
    setVideoStatus,
    setSelectedClip,
    onCreateClipSuccess,
    setMediaOverlays,
    onCreateMediaOverlaySuccess,
    setSelectedClipStartEnd,

} = videoDetailsSlice.actions;

export const getVideoById = createAsyncThunk(
    'video/getVideoById',
    async (id: string, { dispatch }) => {
        dispatch(setLoading(true))
        try {
            const response = await videoDetailsApi.getVideoDetails(id)
            dispatch(setVideoSuccess(response.data))
            if (response.data.clips && response.data.clips.length) {
                dispatch(setSelectedClip(response.data.clips[0]))
            }
        } catch (err: any) {
            const message = err?.message || 'Something went wrong'
            notification.error({
                message
            })
            dispatch(setVideoError(message));
        }
        dispatch(setLoading(false))
    }
)


export const updateVideoStatus = createAsyncThunk(
    'video/updateVideoStatus',
    async (id: string, { dispatch }) => {
        try {
            const response = await videoDetailsApi.getVideoDetails(id)
            dispatch(setVideoSuccess(response.data))
            dispatch(setVideoStatus((response.data.status === "QUEUED" || response.data.status === "PROCESSING") ? true : false))
        } catch (err: any) {
            const message = err?.message || 'Something went wrong'
            notification.error({
                message
            })
            dispatch(setVideoError(message));
        }
    }
)

export const createClip = createAsyncThunk(
    'video/createClip',
    async ({ videoId, start, end, title }: { videoId: string, start: number, end: number, title: string }, { dispatch }) => {
        const newClip = await videoDetailsApi.createClip({ videoId, start, end, title })
        dispatch(onCreateClipSuccess(newClip))
    }
)

export const deleteClip = createAsyncThunk(
    'video/deleteClip',
    async ({ videoId, id }: { videoId: string, id: string }, { dispatch }) => {
        try {
            await videoDetailsApi.deleteClip({ id, videoId })
            dispatch(onDeleteClipSuccess(id))
            notification.success({
                message: 'Delete successfully',
            })
        } catch (err: any) {
            const message = err?.message || 'Something went wrong'
            notification.error({
                message
            })
        }
    }
)

export const getMediaOverlays = createAsyncThunk(
    'video/getMediaOverlays',
    async (_, { dispatch }) => {
        try {
            const response = await mediaOverlaysApi.getMediaOverlays();
            dispatch(setMediaOverlays(response.mediaOverlays))
        } catch (err) {
        }
    }
)

export const createMediaOverlay = createAsyncThunk(
    'video/createMediaOverlay',
    async ({ title, url, type }: { title: string, url: string, type: MediaOverlayTypeEnum }, { dispatch }) => {
        const mediaOverlay = await mediaOverlaysApi.createMediaOverlay({
            title,
            url,
            type
        })
        dispatch(onCreateMediaOverlaySuccess(mediaOverlay));
        return mediaOverlay;
    }
)

const ApiUrlConstans = {
    register: '/auth/register',
    login: '/auth/login',
    verifyUser: '/auth/verify',
    resendVerifyUser: '/auth/verify-resend',
    requestResetPassword: '/auth/reset-password/request',
    submitResetPassword: '/auth/reset-password/submit',
    me: '/auth/me',
    videoDetails: '/videos',
    mediaOverlays: '/media-overlays'
};

export default ApiUrlConstans;

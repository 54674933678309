import { FontFamilyEnum } from "../remotion/subtitles/fonts";
import { ClipScene, IMediaOverlay, ITranscriptionWord } from "./models";

export interface ISubtitlesConfig {
    fontSize: number;
    color: string;
    bold: boolean;
    italic: boolean;
    underline: boolean;
    textTransform: 'uppercase' | 'lowercase' | 'capitalize' | 'none',
    textAlign: 'left' | 'center' | 'right';
    lineHeight: number;
    textBG: { color: string, enabled: boolean, margin: number, padding: number, borderRadius: number };
    blockBG: { color: string, enabled: boolean, padding: number, borderRadius: number };
    textOutline: { color: string, enabled: boolean, width: number };
    textShadow: { color: string, enabled: boolean, offsetX: number, offsetY: number, blur: number };
    fontFamily: FontFamilyEnum;
    transform: string;
}

interface ILayer {
    zIndex: number;
}
export interface IImageOverlay extends ILayer {
    url: string;
    start: number;
    end: number;
}

export interface IEditorTextOverlay {
    id: string;
    start: number;
    end: number;
    text: string;
    fullClip: boolean;
    config: ISubtitlesConfig
}

export interface IEditorMediaOverlay extends IMediaOverlay {
    sourceId: string;
    start: number;
    end: number;
    fullClip: boolean;
    transform: string;
    volume: number
}

export interface IEditorSubtitles {
    words: ITranscriptionWord[];
    currentTime: number;
    config: ISubtitlesConfig;
    isSelected: boolean;
    onSelectedChange: () => void;
    widthScale: number;
    heightScale: number;
    bg?: boolean;
    subtitleEffectBGColor?: string;
    subtitleEffectColor?: string;
}

export interface IEditorClipScene extends ClipScene {
    splitScreen: boolean;
    screens: IEditorClipScreen[]
}

export interface IEditorClipScreen {
    transform: string;
    speakerId: number;
}

export interface getPersonsDimensionsProps {
    videoOriginDimesnsions: {
        videoHeight: number;
        videoWidth: number;
    };
    objectTop: number;
    objectLeft: number;
    objectWidth: number;
    objectHeight: number;
    position?: 'top' | 'bottom' | 'center'
}

export enum ISubtitleStyleEnum {
    BASIC = 'SubtitleStylesBasic',
    ONE_WORD = 'SubtitleStylesCardOneWord',
    TWO_WORDS = 'SubtitleStylesCardTwoWords',
    HIGHLIGHT_CURRENT_BG = 'SubtitleStylesCardHighlightCurrentBG',
    HIGHLIGHT_CURRENT_WORD = 'SubtitleStylesCardHighlightCurrentColor',
    STORY = 'SubtitleStylesCardStory',
}
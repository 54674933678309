import React from "react";
import { AbsoluteFill, Sequence, useCurrentFrame } from "remotion";

const sentences = [
  "Highlight the words",
  "being spoken easily with",
  "this styles of subtitles",
  "looks yummy! 😎",
];

export const SubtitleStylesCardHighlightCurrentBG: React.FC = () => {
  const frame = useCurrentFrame();

  return (
    <AbsoluteFill
      style={{
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {sentences.map((sentence, sentenceIndex) => {
        const words = sentence.split(" ");
        const totalWordCount = words.length;
        const currentWordIndex = Math.min(
          Math.floor((frame - sentenceIndex * 60) / 10),
          totalWordCount - 1
        );

        return (
          <Sequence
            key={sentenceIndex}
            from={sentenceIndex * 60}
            durationInFrames={sentence.length / 0.6}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: 20,
                color: "white",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              {words.map((word, wordIndex) => {
                const wordFrame = frame - sentenceIndex * 60 - wordIndex * 10;
                const wordColor =
                  wordIndex === currentWordIndex
                    ? "blue"
                    : wordFrame >= frame - 5 && wordFrame < frame
                    ? "blue"
                    : "";

                return (
                  <React.Fragment key={wordIndex}>
                    <span style={{ backgroundColor: wordColor }}>{word}</span>
                    &nbsp;
                  </React.Fragment>
                );
              })}
            </div>
          </Sequence>
        );
      })}
    </AbsoluteFill>
  );
};

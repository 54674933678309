import React from "react";

const TiktokIcon = ({
  width = "100%",
  height = "auto",
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxWidth: "100%" }}
    >
      <path
        d="M24.622 49.5999C38.2203 49.5999 49.244 38.5638 49.244 24.95C49.244 11.3361 38.2203 0.299973 24.622 0.299973C11.0236 0.299973 0 11.3361 0 24.95C0 38.5638 11.0236 49.5999 24.622 49.5999Z"
        fill="black"
      />
      <path
        d="M21.5986 22.5904V21.5517C21.2391 21.4944 20.8749 21.4646 20.5107 21.4577C16.9835 21.4508 13.8593 23.7392 12.8012 27.1077C11.7407 30.4761 12.9913 34.1427 15.8863 36.1606C12.8493 32.9068 13.021 27.8025 16.2734 24.7619C17.721 23.4068 19.6174 22.634 21.5986 22.5904Z"
        fill="#25F4EE"
      />
      <path
        d="M21.7933 34.3491C23.7654 34.3468 25.387 32.7921 25.474 30.8178V13.2143H28.6852C28.6188 12.8474 28.589 12.4736 28.5913 12.0999H24.1983V29.685C24.125 31.6685 22.4988 33.2392 20.5176 33.2415C19.9266 33.2369 19.3426 33.0902 18.8181 32.8173C19.5098 33.7735 20.616 34.3445 21.7933 34.3491ZM34.6838 19.1876V18.2085C33.5019 18.2085 32.3453 17.8599 31.3604 17.2041C32.2239 18.2085 33.3897 18.9056 34.6838 19.1876Z"
        fill="#25F4EE"
      />
      <path
        d="M31.3627 17.2064C30.3916 16.1012 29.8579 14.6795 29.8579 13.2074H28.6852C28.9898 14.8515 29.9587 16.2984 31.3627 17.2064ZM20.5107 25.858C18.4745 25.8695 16.83 27.5296 16.8414 29.5704C16.8483 30.9347 17.6064 32.1844 18.8112 32.8196C17.6202 31.1732 17.9867 28.8687 19.6335 27.6741C20.261 27.2178 21.0192 26.9724 21.7956 26.9747C22.1644 26.9793 22.5308 27.0366 22.8836 27.1467V22.6707C22.524 22.6157 22.1598 22.5881 21.7956 22.5858H21.6009V25.9887C21.2436 25.8901 20.8772 25.8465 20.5107 25.858Z"
        fill="#FE2C55"
      />
      <path
        d="M34.6838 19.1876V22.5904C32.4964 22.5858 30.3641 21.8934 28.5913 20.607V29.5475C28.5821 34.0097 24.9678 37.6212 20.5107 37.6212C18.8547 37.6235 17.24 37.1121 15.8886 36.1582C18.9211 39.4235 24.0242 39.6092 27.2857 36.5733C28.9257 35.0461 29.8579 32.9045 29.8579 30.6619V21.7443C31.6375 23.0192 33.7699 23.7048 35.9596 23.7003V19.3206C35.5312 19.3206 35.1029 19.277 34.6838 19.1876Z"
        fill="#FE2C55"
      />
      <path
        d="M28.5913 29.5498V20.6093C30.3686 21.8865 32.5033 22.5698 34.693 22.5652V19.1624C33.4012 18.8895 32.2308 18.2016 31.3627 17.2064C29.961 16.2984 28.9921 14.8515 28.6852 13.2074H25.474V30.8178C25.3893 32.8563 23.6738 34.4385 21.6376 34.3559C20.5084 34.3101 19.464 33.7483 18.802 32.8334C16.9995 31.8841 16.3078 29.6507 17.256 27.846C17.8905 26.6399 19.1387 25.8809 20.5015 25.874C20.8703 25.8786 21.2368 25.9337 21.5895 26.0437V22.5904C17.1415 22.6661 13.5959 26.39 13.6715 30.843C13.7059 32.8609 14.4938 34.7916 15.8795 36.2546C17.2446 37.1786 18.8639 37.6556 20.5107 37.6235C24.9678 37.6235 28.5821 34.012 28.5913 29.5498Z"
        fill="white"
      />
    </svg>
  );
};

export default TiktokIcon;

import { IEditorSubtitles, ISubtitlesConfig } from "../../types/interfaces";
import { ITranscriptionWord } from "../../types/models";
import { useMemo, useRef } from "react";
import { configToBlockStyles, configToInlineStyles } from "./utils";
import MoveableElement from "../MoveableElement/MoveableElement";
import { Sequence, useVideoConfig } from "remotion";

const TwoWordsSubtitles = ({
  words,
  currentTime,
  config,
  isSelected,
  onSelectedChange,
  widthScale,
  heightScale,
}: IEditorSubtitles) => {
  const styles = configToBlockStyles(config, { widthScale, heightScale });
  const textStyle = configToInlineStyles(config, { widthScale, heightScale });

  const { fps } = useVideoConfig();

  const subtitlesSlices = useMemo(() => {
    let nextCount = 2;

    const slices: {
      start: number;
      end: number;
      words: ITranscriptionWord[];
    }[] = [];

    let accumilator: ITranscriptionWord[] = [];
    words.forEach((word) => {
      accumilator.push(word);
      if (accumilator.length === nextCount) {
        slices.push({
          start: accumilator[0].start,
          end: accumilator[accumilator.length - 1].end,
          words: accumilator,
        });
        accumilator = [];
      }
    });

    if (accumilator.length) {
      slices.push({
        start: accumilator[0].start,
        end: accumilator[accumilator.length - 1].end,
        words: accumilator,
      });
    }

    return slices.sort((a, b) => a.start - b.start);
  }, [words]);

  return (
    <>
      {subtitlesSlices.map((slice, index) => (
        <Sequence
          key={`${slice.start}-${slice.end}`}
          from={Math.ceil(slice.start * fps)}
          durationInFrames={Math.ceil(
            (index === subtitlesSlices.length - 1
              ? slice.end - slice.start
              : subtitlesSlices[index + 1].start - slice.start) * fps
          )}
        >
          <div
            id="subtitles"
            style={{
              ...styles,
              cursor: "move",
              position: "absolute",
              left: "10%",
              right: "10%",
              top: "50%",
            }}
            onClick={(e) => {
              onSelectedChange();
            }}
          >
            <span style={{ ...textStyle }}>
              {slice.words?.map((word) => word.word).join(" ")}
            </span>
          </div>
        </Sequence>
      ))}
    </>
  );
};

export default TwoWordsSubtitles;

import React, { useRef, useState } from "react";
import { Composition } from "remotion";
import { SubtitleStylesBasic } from "../SubtitleStylesCard/SubtitleStylesCardBasic";
import { Player, PlayerRef } from "@remotion/player";
import { SubtitleStylesCardOneWord } from "../SubtitleStylesCard/SubtitleStylesCardOneWord";
import { SubtitleStylesCardTwoWords } from "../SubtitleStylesCard/SubtitleStylesCardTwoWords";
import { SubtitleStylesCardHighlightCurrentColor } from "../SubtitleStylesCard/SubtitleStylesCardHighlightCurrentColor";
import { SubtitleStylesCardHighlightCurrentBG } from "../SubtitleStylesCard/SubtitleStylesCardHighlightCurrentBG";
import { SubtitleStylesCardStory } from "../SubtitleStylesCard/SubtitleStylesCardStory";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { chooseStyles } from "../../../redux/Slices/clipEditingSlice";
import { colors } from "../../../Themes/colors";
import { ISubtitleStyleEnum } from "../../../types/interfaces";

const styles = [
  {
    id: ISubtitleStyleEnum.BASIC,
    component: SubtitleStylesBasic,
    durationFrame: 150,
  },
  {
    id: ISubtitleStyleEnum.ONE_WORD,
    component: SubtitleStylesCardOneWord,
    durationFrame: 180,
  },
  {
    id: ISubtitleStyleEnum.TWO_WORDS,
    component: SubtitleStylesCardTwoWords,
    durationFrame: 330,
  },
  {
    id: ISubtitleStyleEnum.HIGHLIGHT_CURRENT_WORD,
    component: SubtitleStylesCardHighlightCurrentColor,
    durationFrame: 220,
  },
  {
    id: ISubtitleStyleEnum.HIGHLIGHT_CURRENT_BG,
    component: SubtitleStylesCardHighlightCurrentBG,
    durationFrame: 220,
  },
  {
    id: ISubtitleStyleEnum.STORY,
    component: SubtitleStylesCardStory,
    durationFrame: 170,
  },
];

const SubtitleStyles = () => {
  const videoRefs = styles.map(() => useRef<PlayerRef>(null));
  const [activeVideo, setActiveVideo] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const { subtitleStyle } = useAppSelector((state) => state.clipEditing);

  const handleMouseEnter = (index: number) => {
    if (activeVideo !== null) {
      videoRefs[activeVideo].current?.pause();
    }
    setActiveVideo(index);
    videoRefs[index].current?.play();
  };

  const handleMouseLeave = (index: number) => {
    setActiveVideo(null);
    videoRefs[index].current?.seekTo(10);
    videoRefs[index].current?.pause();
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        rowGap: "10px",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
      }}
    >
      {styles.map((item, index) => (
        <div
          style={{
            width: "fit-content",
            padding: "2px",
            border:
              subtitleStyle === item.id ? `5px solid ${colors.royalBlue}` : "5px solid transparent",
            borderRadius: "15px",
            cursor: "pointer",
          }}
        >
          <div
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
            key={item.id}
            style={{
              width: "fit-content",
              height: "fit-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              dispatch(chooseStyles(item.id));
            }}
          >
            <Player
              ref={videoRefs[index]}
              component={item.component}
              durationInFrames={item.durationFrame}
              fps={30}
              compositionWidth={300}
              compositionHeight={120}
              loop
              style={{
                borderRadius: "10px",
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SubtitleStyles;

import React, { useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { SignUpFormTypes } from "../../types/stylesTypes";
import styles from "./signUpFormStyles.module.scss";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { register } from "../../redux/Slices/AuthSlice";
import { useNavigate } from "react-router-dom";

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

const SignUpForm: React.FC = () => {
  const { isLoading } = useAppSelector((state) => state.auth);

  const [formStep, setFormStep] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onFinish = (values: FormValues) => {
    const registerValues = {
      name: values.firstName + " " + values.lastName,
      email: values.email,
      password: values.password,
    };
    dispatch(register(registerValues)).then((res: any) => {
      if (res?.payload?.data?.authToken) {
        navigate("/dashboard/videos");
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleNext = (values: FormValues) => {
    if (formStep === 0) {
      setFormStep(1);
    } else {
      onFinish(values);
    }
  };
  return (
    <Form
      name="basic"
      onFinish={handleNext}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className={styles.formStyle}
      layout="vertical"
      labelAlign="left"
      requiredMark={false}
    >
      <Form.Item<SignUpFormTypes>
        label="Email"
        name="email"
        validateTrigger="onSubmit"
        rules={[
          {
            required: true,
            message: "Please input your email!",
          },
          {
            type: "email",
            message: "Please enter a valid email!",
          },
        ]}
        className={styles.inputStyle}
      >
        <Input className={styles.mainInput} placeholder="Enter your Email" />
      </Form.Item>
      {formStep === 1 && (
        <>
          <Row className={styles.row}>
            <Col className={styles.col}>
              <Form.Item<SignUpFormTypes>
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "Please input your First Name!" },
                ]}
                className={styles.inputStyleGrid}
              >
                <Input
                  autoFocus
                  className={styles.mainInput}
                  placeholder="First Name"
                />
              </Form.Item>
            </Col>
            <Col className={styles.col}>
              <Form.Item<SignUpFormTypes>
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Please input your Last Name!" },
                ]}
                className={styles.inputStyleGrid}
              >
                <Input className={styles.mainInput} placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item<SignUpFormTypes>
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            className={styles.inputStyle}
          >
            <Input.Password
              className={styles.mainInput}
              placeholder="Enter your password"
            />
          </Form.Item>
        </>
      )}
      <Form.Item className={styles.inputStyle}>
        <Button
          type="primary"
          htmlType="submit"
          className={styles.submitStyle}
          loading={isLoading}
        >
          Sign Up
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SignUpForm;

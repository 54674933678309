import React, { useEffect, useState } from "react";
import { IPlan } from "../../types/models";
import { Badge, Button, Card, Typography } from "antd";
import "./planCard.scss";
import {
  createCheckoutSession,
  getBillingPortalSession,
} from "../../redux/Slices/BillingSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { currentUser } from "../../redux/Slices/AuthSlice";
import { Link, useLocation } from "react-router-dom";
import { CheckOutlined, ExperimentOutlined, WarningFilled } from "@ant-design/icons";
import { format } from "date-fns";
import { ROUTES_MAP } from "../../routes/routesMap";

const PLANS_FEATURES = {
  free: ["720p Quality", "30 renders", 'Community support'],
  starter: ["1080p Quality", "Unlimited renders", 'Priority support'],
  premium: ["1080p Quality", "Unlimited renders", 'Priority support'],
  pro: ["1080p Quality", "Unlimited renders", 'Priority support'],
  agency: ["1080p Quality", "Unlimited renders", 'Priority support'],
};

function getPlanFeatures(id: string) {
  const prefix = id.split("_")[0] as
    | "free"
    | "starter"
    | "premium"
    | "pro"
    | "agency";
  return PLANS_FEATURES[prefix];
}

const PlanCard = ({
  plan,
  landing,
}: {
  landing?: boolean;
  plan: IPlan;
  onChoosePlan?: () => any;
}) => {
  const [openedPortal, setOpenedPortal] = useState(false);

  const { user } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();

  const planId = query.get("plan_id");

  const onChoosePlan = async () => {
    setLoading(true);
    await dispatch(createCheckoutSession({ planId: plan.id }));
    setLoading(false);
  };

  const onManagePlan = async () => {
    setLoading(true);
    await dispatch(getBillingPortalSession());
    setOpenedPortal(true);
    setLoading(false);
  };

  useEffect(() => {
    if (planId && planId === plan.id) {
      if (
        user?.subscription?.planId === planId &&
        user.subscription.status === "ENABLED"
      ) {
        setLoading(false);
      } else {
        setLoading(true);

        const interval = setInterval(() => {
          dispatch(currentUser());
        }, 5 * 1000);

        return () => {
          clearInterval(interval);
        };
      }
    }
    return () => {};
  }, [planId, plan, user]);

  const isActivePlan = user?.subscription?.planId === plan.id;

  const planColor =
    plan.title === "Starter"
      ? "#6165DB"
      : plan.title === "Premium"
      ? "#7926CB"
      : plan.title === "Pro"
      ? "#CD2D94"
      : plan.title === "Agency"
      ? "#263238"
      : "";
  return (
    <Badge.Ribbon
      placement="start"
      {...(isActivePlan
        ? {
            text: "Current Plan",
          }
        : plan.highlighted && user?.subscription.status === "DISABLED"
        ? {
            color: "white",
            text: <span>Most Popular</span>,
            style: { backgroundColor: planColor },
          }
        : { style: { display: "none" } })}
      className="ribbon-plan"
    >
      {/*
        ${plan.highlighted ? "plan-highlighted" : ""} ${
          isActivePlan ? "current-plan" : ""
        } */}
      <Card className={`plan-card `}>
        <div className="card-content">
          <Typography.Title
            level={3}
            style={{
              color: planColor,
            }}
            className="title"
          >
            {plan.title}
          </Typography.Title>
          <Typography.Paragraph style={{ fontWeight: "bold" }}>
            {plan.description}
          </Typography.Paragraph>
          {/* <hr /> */}
          <Typography.Title level={4} style={{ marginTop: 0 }}>
            ${plan.price}
            <small>
              /
              {plan.id.includes("yearly") ? (
                <span>year</span>
              ) : (
                <span>month</span>
              )}
            </small>
          </Typography.Title>
          {/* <hr /> */}
          <Typography.Paragraph>
            <ExperimentOutlined style={{ marginRight: 2 }} /> {plan.tokens / 60}{" "}
            minutes
            {plan.id !== "free_trial" && (
              <span>
                /
                {plan.id.includes("yearly") ? (
                  <span>year</span>
                ) : (
                  <span>month</span>
                )}
              </span>
            )}
          </Typography.Paragraph>
          {getPlanFeatures(plan.id).map((feature) => (
            <Typography.Paragraph key={feature}><CheckOutlined style={{color: 'green'}} /> {feature}</Typography.Paragraph>
          ))}
        </div>
        {(plan.id !== "free_trial" && !landing) || landing && (
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            {landing ? (
              <Link
                to={ROUTES_MAP.signUp}
                className="plans-link"
                style={{
                  color: "white",
                  backgroundColor:
                    plan.title === "Starter"
                      ? "#6165DB"
                      : plan.title === "Premium"
                      ? "#7926CB"
                      : plan.title === "Pro"
                      ? "#CD2D94"
                      : plan.title === "Agency"
                      ? "#263238"
                      : "",
                }}
              >
                CHOOSE PLAN
              </Link>
            ) : user?.subscription.planId === plan.id &&
              user.subscription.status === "ENABLED" ? (
              <Button
                type="default"
                size="large"
                onClick={onManagePlan}
                loading={loading}
              >
                Manage Plan
              </Button>
            ) : user?.subscription?.status === "ENABLED" ? (
              <Button
                type="primary"
                size="large"
                onClick={onManagePlan}
                loading={loading}
              >
                Switch To Plan
              </Button>
            ) : (
              <Button
                type="primary"
                size="large"
                onClick={onChoosePlan}
                loading={loading}
              >
                CHOOSE PLAN
              </Button>
            )}
            {isActivePlan ? (
              <Typography.Title level={5} style={{ textAlign: "left" }}>
                {user.subscription.cancelAt ? (
                  <span>
                    Subscription canceled, no future charges will be applied.
                    <br />
                    <br />
                    You can still use remaining tokens until:{" "}
                    {format(new Date(user.subscription.cancelAt), "MM/dd/yyyy")}
                  </span>
                ) : (
                  <span>
                    Next Billing Cycle:{" "}
                    {format(
                      new Date(user.subscription.nextBillingDate),
                      "MM/dd/yyyy"
                    )}
                  </span>
                )}
              </Typography.Title>
            ) : null}
            {openedPortal ? (
              <Typography.Title style={{ color: "orange" }} level={5}>
                <WarningFilled /> Make sure to refresh this page after you make
                any changes to your plan on your Stripe portal
              </Typography.Title>
            ) : null}
          </div>
        )}
      </Card>
    </Badge.Ribbon>
  );
};

export default PlanCard;

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Loading from "../../Components/Loading/Loading";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Link, useParams } from "react-router-dom";
import {
  getVideoById,
  setSelectedClip,
  setVideoSuccess,
  updateVideoStatus,
} from "../../redux/Slices/videoDetailsSlice";
import { Button, Layout, List, Typography } from "antd";
import styles from "./videoDetails.module.scss";
import ClipsCard from "../../Components/ClipsCard/ClipsCard";
import { ClipProps } from "../../types/models";
import Lottie from "lottie-react";
import videoStatusAnimation from "../../assets/animation/trans.json";
import RemotionVideos from "../../remotion/Composition";
import TabsComponent from "../../Components/TabsComponent/TabsComponent";
import { PlayerRef } from "@remotion/player";
import { DownloadOutlined, PlusCircleOutlined } from "@ant-design/icons";
import CreateClipModal from "../../Components/CreateClipModal/CreateClipModal";
import {
  IEditorMediaOverlay,
  IEditorTextOverlay,
  ISubtitleStyleEnum,
} from "../../types/interfaces";
import {
  formatClipScenes,
  getDefaultTextConfig,
  getPersonsDimensions,
} from "../../remotion/subtitles/utils";
import {
  PLAYER_HEIGHT,
  PLAYER_HEIGHT_SCALE,
  PLAYER_WIDTH,
  PLAYER_WIDTH_SCALE,
} from "../../constants/constants";
import { formatedSubtitleConfig } from "../../helpers/formatTempelates";
import {
  applyTemplate,
  setEditorClipScenes,
} from "../../redux/Slices/clipEditingSlice";

const { Content } = Layout;

const FPS = 30;

const VideoDetails = () => {
  const playerRef = useRef<PlayerRef | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [textOverlays, setTextOvelays] = useState<IEditorTextOverlay[]>([]);
  const [mediaOverlays, setMediaOverlays] = useState<IEditorMediaOverlay[]>([]);
  const [selectedFaceIndex, setSelectedFaceIndex] = useState(0);

  const [activeTab, setActiveTab] = useState<string>("1");
  const [createCustomOpen, setCreateCustomOpen] = useState(false);
  const {
    subtitlesConfig,
    subtitleEffectBGColor,
    subtitleEffectColor,
    subtitleStyle,
    editorClipScenes,
  } = useAppSelector((state) => state.clipEditing);

  const params = useParams();
  const dispatch = useAppDispatch();
  const { loading, video, videoStatus, selectedClip } = useAppSelector(
    (state) => state.videoDetails
  );

  const temps = useMemo(
    () => formatedSubtitleConfig({ selectedClip }),
    [selectedClip]
  );

  useEffect(() => {
    if (params.id) {
      dispatch(getVideoById(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (video && video?.status !== "COMPLETED") {
        dispatch(getVideoById(video.id));
      }
    }, 10000);
    return () => clearInterval(intervalID);
  }, [video]);

  useEffect(() => {
    dispatch(setVideoSuccess(null));
    dispatch(setSelectedClip(null));
  }, []);

  const toggleCreateCustomOpen = () => setCreateCustomOpen(!createCustomOpen);

  const words = useMemo(() => {
    if (!selectedClip) return [];
    return selectedClip.words.map((word) => ({
      ...word,
      start: word.start - selectedClip.start,
      end: word.end - selectedClip.start,
    }));
  }, [selectedClip]);

  useEffect(() => {
    if (playerRef.current) {
      const onTimeUpdate = (data: { detail: { frame: number } }) => {
        setCurrentTime(data.detail.frame / FPS);
      };
      playerRef.current.addEventListener("timeupdate", onTimeUpdate);
      return () => {
        playerRef.current?.removeEventListener("timeupdate", onTimeUpdate);
      };
    }
    return () => {};
  }, [playerRef.current, setCurrentTime]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.pause();
      playerRef.current.seekTo(0);
    }
  }, [selectedClip?.id, playerRef?.current]);

  useEffect(() => {
    if (!video || !selectedClip) {
      return;
    }
    const videoHeight = video && video?.videoHeight;
    const videoWidth = video && video?.videoWidth;

    const scenes = formatClipScenes({
      selectedClip,
      videoWidth,
      videoHeight,
    });

    dispatch(
      setEditorClipScenes(
        scenes.map((scene) => ({
          ...scene,
          start: scene.start - selectedClip.start,
          end: scene.end - selectedClip.start,
        }))
      )
    );
  }, [selectedClip, video]);

  useEffect(() => {
    if (temps.length && selectedClip) {
      dispatch(applyTemplate({ id: temps[0].id, temps }));
    }
  }, [temps, selectedClip]);

  if (['QUEUED', 'PROCESSING'].includes(video?.status || '')) {
    return (
      <div className={styles.processingAnim}>
        <Lottie animationData={videoStatusAnimation} />
        <Typography.Text>
          We are processing your video, please wait
        </Typography.Text>
      </div>
    );
  }

  if (loading || !video) return <Loading />;

  return (
    <Content className={styles.pageContent}>
      <div className={styles.videoDetailsWrapper}>
        <div className={styles.videoPlayerWrapper}>
          <div className={styles.videoPlayerContent}>
            <div className={styles.videoHeader}>
              <Typography.Paragraph className={styles.videoTitle}>
                {video?.title || "Sample Video"}
              </Typography.Paragraph>
              {/* <Button type="primary" shape="round">
                Add to 💛
              </Button> */}
            </div>
            {video?.videoUrl && selectedClip && (
              <RemotionVideos
                playerRef={playerRef}
                selectedFaceIndex={selectedFaceIndex}
                onSelectedFaceIndexChange={setSelectedFaceIndex}
                textOverlays={textOverlays}
                mediaOverlays={mediaOverlays}
                source={video?.videoUrl && video?.videoUrl}
                startFrom={selectedClip.start}
                endTime={selectedClip.end}
                words={words}
                framesPerSecond={FPS}
                clipTargetsPersonsDimensions={selectedClip?.facesInfo}
                videoOriginDimesnsions={{
                  videoHeight: video.videoHeight,
                  videoWidth: video.videoWidth,
                }}
                selectedId=""
                onSelectedChange={() => {}}
                subtitlesConfig={subtitlesConfig}
                subtitleStyle={subtitleStyle}
                subtitleEffectBGColor={subtitleEffectBGColor}
                subtitleEffectColor={subtitleEffectColor}
                onMediaOverlayTransformChange={() => {}}
                onSubtitlesTransformChange={() => {}}
                onTextOverlayTransformChange={() => {}}
                width={PLAYER_WIDTH}
                height={PLAYER_HEIGHT}
                widthScale={PLAYER_WIDTH_SCALE}
                heightScale={PLAYER_HEIGHT_SCALE}
                transform={""}
                onSceneScreenTransformChange={() => {}}
                hasWatermark={false}
                scenes={editorClipScenes}
              />
            )}
            <div className={styles.videoFooter}>
              <Link
                className={styles.editLink}
                to={`/dashboard/videos/${video?.id}/edit/${selectedClip?.id}`}
              >
                Edit and Download Video
                <DownloadOutlined
                  style={{
                    marginLeft: "10px",
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.clipsWrapper}>
          <List
            className={styles.clipsList}
            header={
              <div className={styles.listHeader}>
                <TabsComponent
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  clipsLength={video?.clips?.length}
                />
              </div>
            }
          >
            <div className={styles.clips}>
              {activeTab === "1" && (
                <>
                  <div className={styles.createCustom}>
                    <Button
                      type="dashed"
                      icon={<PlusCircleOutlined />}
                      onClick={toggleCreateCustomOpen}
                    >
                      Create Custom Clip
                    </Button>
                  </div>
                  {video?.clips?.map((clip: ClipProps) => (
                    <List.Item key={clip.id} className={styles.clipsItem}>
                      <ClipsCard clip={clip} />
                    </List.Item>
                  ))}
                </>
              )}
            </div>
          </List>
          {video && (
            <CreateClipModal
              open={createCustomOpen}
              onCancel={toggleCreateCustomOpen}
              videoId={video.id}
              videoUrl={video.videoUrl}
              videoDuration={video.durationSeconds}
            />
          )}
        </div>
      </div>
    </Content>
  );
};

export default VideoDetails;

import { IEditorMediaOverlay } from "../../types/interfaces";
import { useRef, useState } from "react";
import {
  applyTransformScaling,
  configToBlockStyles,
  configToInlineStyles,
} from "../subtitles/utils";
import Moveable, { OnDrag, OnDragEnd, OnRotate, OnScale } from "react-moveable";
import MoveableElement, {
  ITransformable,
} from "../MoveableElement/MoveableElement";

interface IProps {
  overlay: IEditorMediaOverlay;
  isSelected: boolean;
  onSelectedChange: (id: string) => void;
  widthScale: number;
  heightScale: number;
}
const ImageOverlay = ({
  overlay,
  isSelected,
  onSelectedChange,
  widthScale,
  heightScale,
}: IProps) => {
  return (
    <>
      <div
        id={overlay.id}
        style={{
          transform: applyTransformScaling(overlay.transform, {
            widthScale,
            heightScale,
          }),
          cursor: "move",
          position: "absolute",
          left: "10%",
          right: "10%",
          top: "20%",
          width: overlay.width * widthScale,
          height: overlay.height * heightScale,
        }}
        onClick={(e) => {
          onSelectedChange(overlay.id);
        }}
      >
        <img src={overlay.url} width="100%" height="100%" />
      </div>
    </>
  );
};

export default ImageOverlay;

import React from "react";

const VideoPlaceholder = ({
  width = "100%",
  height = "auto",
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 185 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxWidth: "100%" }}
    >
      <path
        d="M180.283 123.794H3.8472C1.72354 123.794 0 122.07 0 119.946V3.8472C0 1.72354 1.72354 0 3.8472 0H180.283C182.406 0 184.13 1.72354 184.13 3.8472V119.946C184.136 122.07 182.413 123.794 180.283 123.794Z"
        fill="#1E1F21"
      />
    </svg>
  );
};

export default VideoPlaceholder;

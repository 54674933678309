import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPlan, IVideo } from "../../types/models";
import videosApi from "../../api/videosApi";
import { notification } from "antd";
import billingApi from "../../api/billingApi";

interface IInitialState {
    loading: boolean;
    plans: IPlan[]
}

const initialState: IInitialState = {
    loading: false,
    plans: []
}
export const BillingSlice = createSlice({
    name: "billing",
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        onFetchPlansSuccess(state, action: PayloadAction<IPlan[]>) {
            state.plans = action.payload;
            state.loading = false;
        }
    },
});

export const {
    setLoading,
    onFetchPlansSuccess
} = BillingSlice.actions;

export const fetchPlans = createAsyncThunk(
    'billing/fetchPlans',
    async (_, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await billingApi.getPlans();
            dispatch(onFetchPlansSuccess(response.plans))
        } catch (err: any) {
            notification.error({
                message: err.message || 'Something went wrong'
            })
        }
        dispatch(setLoading(false));
    }
)

export const createCheckoutSession = createAsyncThunk(
    'billing/createCheckoutSession',
    async ({ planId }: { planId: string }) => {
        try {
            const data = await billingApi.createCheckoutSession({ planId })
            window.location.href = data.url;
        } catch (err: any) {
            notification.error({
                message: err.message || 'Something went wrong'
            })
        }
    }
)

export const getBillingPortalSession = createAsyncThunk(
    'billing/createCheckoutSession',
    async () => {
        try {
            const data = await billingApi.getBillingPortalSession()
            window.open(data.url, '_blank', 'noreferrer');

            // window.location.href = data.url;
        } catch (err: any) {
            notification.error({
                message: err.message || 'Something went wrong'
            })
        }
    }
)




import styles from "./SubtitleEditingHeader.module.scss";
import "./SubtitleEditingHeader.scss";
import { Button, Typography } from "antd";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";

const { Text } = Typography;

const SubtitleEditingHeader = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerRow}>
        <Text className={styles.titleText}>Subtitles</Text>
        <div className={styles.buttonWrapper}>
          {/* <Button
            type="primary"
            icon={<DeleteOutlined />}
            shape="circle"
            size={"large"}
            className={styles.deleteOutlinedButton}
          />
          <Button
            type="primary"
            icon={
              <DownloadOutlined
                style={{
                  color: "red",
                }}
              />
            }
            shape="circle"
            size={"large"}
            className={styles.downloadOutlinedButton}
          />
          <Button type="default" className={styles.addEmojiButton}>
            Add Emoji 😎
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default SubtitleEditingHeader;

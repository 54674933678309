import { colors } from '../../Themes/colors';
import { CustomStyles } from '../../types/stylesTypes';


export const styles: CustomStyles = {
    layout: {
        backgroundColor: colors.white,
        height: '100vh'
    },
    firstColumn: {
        backgroundColor: colors.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh'
    },
    secondColumn: {
        backgroundColor: colors.sideBg,

    },
    titleStyle: {
        color: colors.black,
        fontSize: 18,
        textAlign: 'center',
        marginTop: 20,
    },

    formStyle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40%',
        marginTop: 20,

    },
    inputStyle: {
        width: '100%',
    },
    mainInput: {
        width: '100%',
        height: '50px',

    },
    submitStyle: {
        fontSize: '18px',
        fontWeight: '500',
        backgroundColor: colors.mainColor,
        height: '40px',
        width: '100%',
        marginTop: '20px',
    },
    infoLabel: {
        color: colors.black,
        fontSize: 16,
        textAlign: 'center',
        marginTop: 20,
        backgroundColor: colors.sideBg,
        padding: '0px 50px',
    },
    carousel: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
};


export const dividerStyle = {
    margin: '10px auto',
    backgroundColor: colors.black,
    height: '1px',
    minWidth: "350px",
    width: "30%",
    opacity: 0.3
}
import React, { useState } from "react";
import styles from "./ClipsCard.module.scss";
import { Button, Image, Popconfirm, Typography } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FieldTimeOutlined,
  MoreOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { secondsToHMS } from "../../helpers/formatedSeconed";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  deleteClip,
  setSelectedClip,
} from "../../redux/Slices/videoDetailsSlice";
import { ClipProps } from "../../types/models";
import { Link } from "react-router-dom";
import { ROUTES_MAP } from "../../routes/routesMap";

const { Paragraph } = Typography;

interface ClipsCardProps {
  clip: ClipProps;
}

const ClipsCard: React.FC<ClipsCardProps> = ({ clip }) => {
  const { selectedClip } = useAppSelector((state) => state.videoDetails);

  const { topic, start, end, thumbnailUrl, id } = clip;

  const dispatch = useAppDispatch();

  const onDeleteClip = async () => {
    await dispatch(deleteClip({ videoId: clip.videoId, id: clip.id }));
  };
  const style = {
    color: selectedClip?.id === id ? "white" : "",
  };

  return (
    <div
      className={styles.cardContainer}
      style={{
        backgroundColor: selectedClip?.id === id ? "#6165DB" : "",
      }}
      onClick={() => dispatch(setSelectedClip(clip))}
    >
      <div className={styles.thumbnailWrapper}>
        <Image src={thumbnailUrl} className={styles.imageThumbnail} />
      </div>
      <div className={styles.cardData}>
        <div className={styles.title}>
          <Paragraph className={styles.titleText} style={style}>
            {topic}
          </Paragraph>
        </div>
        <div className={styles.cardInfo}>
          <div className={styles.timeWrapper}>
            <Paragraph className={styles.text} style={style}>
              {secondsToHMS(start)}
            </Paragraph>
            <Paragraph className={styles.text} style={style}>
              -
            </Paragraph>
            <Paragraph className={styles.text} style={style}>
              {secondsToHMS(end)}
            </Paragraph>
            <FieldTimeOutlined className={styles.timeIcon} style={style} />
            <Paragraph className={styles.text} style={style}>
              {secondsToHMS(end - start)}
            </Paragraph>
          </div>
        </div>
      </div>
      {selectedClip?.id === id ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link
            to={ROUTES_MAP.dashboard.videoEdit(
              selectedClip.videoId,
              selectedClip.id
            )}
          >
            <Button
              icon={<EditOutlined style={{ ...style, fontSize: 20 }} />}
              type="text"
              style={{ marginRight: 5 }}
            />
          </Link>
          <Popconfirm
            title="Delete Clip"
            description="Are you sure to delete this Clip?"
            cancelText="No"
            okText="Yes"
            placement="left"
            onConfirm={onDeleteClip}
          >
            <Button
              icon={<DeleteOutlined style={{ ...style, fontSize: 20 }} />}
              type="text"
            />
          </Popconfirm>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            icon={<RightOutlined style={{ ...style, fontSize: 16 }} />}
            type="text"
          />
        </div>
      )}
    </div>
  );
};

export default ClipsCard;

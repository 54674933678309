import React from "react";
import { Layout, Space, Typography } from "antd";
// import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import styles from "./header.module.scss";
import { ROUTES_MAP } from "../../routes/routesMap";
import Logo from "./Logo";
import { useCurrentWidth } from "react-socks";

const { Header } = Layout;

interface HeaderProps {
  landing?: boolean;
  login?: boolean;
  signup?: boolean;
}

const AppHeader: React.FC<HeaderProps> = ({ landing, login, signup }) => {
  const width = useCurrentWidth();

  return (
    <Header className={styles.header}>
      <Space className={styles.headerStyle}>
        <Link to={ROUTES_MAP.landing}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Logo />
            <Typography.Text
              style={{
                marginLeft: 10,
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              Insta Clipify
            </Typography.Text>
          </div>
        </Link>
        {landing || signup ? (
          <Space className={styles.landingLinks}>
            <Link to={ROUTES_MAP.login} className={styles.loginLink}>
              Login
            </Link>
            <Link to={ROUTES_MAP.signUp} className={styles.startTrial}>
              Start Free Trial
            </Link>
          </Space>
        ) : (
          <div className={styles.headerActionStyles}>
            <span className={styles.text}>Don't have an account?</span>
            <Link to={ROUTES_MAP.signUp}>
              <span className={styles.linkStyle}>Sign Up for free</span>
            </Link>
          </div>
        )}
      </Space>
    </Header>
  );
};

export default AppHeader;

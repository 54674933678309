import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVideo } from "../../types/models";
import videosApi from "../../api/videosApi";
import { notification } from "antd";

interface IInitialState {
    createLoading: boolean;
    createdVideo: IVideo | null
    loading: boolean
    error: string
    videos: IVideo[]
    nextPage?: string
}

const initialState: IInitialState = {
    createLoading: false,
    createdVideo: null,
    loading: false,
    videos: [],
    nextPage: '',
    error: '',
}
export const VideosSlice = createSlice({
    name: "videos",
    initialState,
    reducers: {
        setCreateLoading(state, action: PayloadAction<boolean>) {
            state.createLoading = action.payload;
        },
        createVideoSuccess(state, action: PayloadAction<IVideo>) {
            state.createLoading = false;
            state.createdVideo = action.payload;
        },
        createVideoError(state, action: PayloadAction<string>) {
            state.createLoading = false;
            state.error = action.payload;
        },
        setCreatedVideo(state, action: PayloadAction<IVideo | null>) {
            state.createdVideo = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        onFetchVideosSuccess(state, action: PayloadAction<{ videos: IVideo[], nextPage?: string }>) {
            const existingIds = state.videos.map(vid => vid.id)
            state.videos = state.videos.concat(action.payload.videos.filter(vid => !existingIds.includes(vid.id)));
            state.nextPage = action.payload.nextPage;
            state.loading = false
        },
        onFetchVideosError(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = false;
        },
        onDeleteVideoSuccess(state, action: PayloadAction<string>) {
            state.videos = state.videos.filter(video => video.id !== action.payload);
        }
    },
});

export const {
    setCreateLoading,
    createVideoError,
    createVideoSuccess,
    setCreatedVideo,
    setLoading,
    onFetchVideosSuccess,
    onFetchVideosError,
    onDeleteVideoSuccess,
} = VideosSlice.actions;

export const createVideo = createAsyncThunk(
    'videos/createVideo',
    async ({ url, title }: { url: string, title: string }, { dispatch }) => {
        dispatch(setCreateLoading(true));
        try {
            const video = await videosApi.createVideo({ url, title })
            dispatch(createVideoSuccess(video))
            return Promise.resolve(video);
        } catch (err: any) {
            const message = err?.message || 'Something went wrong'
            notification.error({
                message
            })
            dispatch(createVideoError(message));
            return null;
        }
    })

export const fetchVideos = createAsyncThunk(
    'videos/fetchVideos',
    async (_, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await videosApi.getVideos();
            dispatch(onFetchVideosSuccess(response))
        } catch (err: any) {
            dispatch(onFetchVideosError(err?.message || 'Something went wrong'))
        }
    }
)

export const deleteVideo = createAsyncThunk(
    'videos/deleteVideo',
    async (id: string, { dispatch }) => {
        await videosApi.deleteVideo({ id })
        dispatch(onDeleteVideoSuccess(id));
    }
)
export const MAX_FILE_SIZE_GB = 5;
export const MAX_FILE_DURATION_MINUTES = 2 * 60;

// export const PLAYER_WIDTH = 360;
// export const PLAYER_HEIGHT = 640;

// export const PLAYER_WIDTH_SCALE_DOM = 360;
// export const PLAYER_HEIGHT_SCALE_DOM = 640;

export const PLAYER_WIDTH = 281;
export const PLAYER_HEIGHT = 500;

export const PLAYER_WIDTH_SCALE_DOM = 281;
export const PLAYER_HEIGHT_SCALE_DOM = 500;

export const PLAYER_WIDTH_SCALE = PLAYER_WIDTH / PLAYER_WIDTH_SCALE_DOM;
export const PLAYER_HEIGHT_SCALE = PLAYER_HEIGHT / PLAYER_HEIGHT_SCALE_DOM;

export const VERTICAL_GUIDELINES = Array.from(new Array(5)).map((_, i) => Math.round(PLAYER_HEIGHT / 5 * i))
export const HORIZONTAL_GUIDELINES = [PLAYER_WIDTH * 0.2, PLAYER_WIDTH * 0.5, PLAYER_WIDTH * 0.8].map(item => Math.round(item))
import React from "react";
import { Button, Form, Input, Space } from "antd";
import { LoginFormTypes } from "../../types/stylesTypes";
import { styles } from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { login, setUserVerified } from "../../redux/Slices/AuthSlice";
import { ROUTES_MAP } from "../../routes/routesMap";

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const LoginForm: React.FC = () => {
  const { isLoading } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onFinish = (values: any) => {
    dispatch(login(values)).then((res: any) => {
      if (res?.payload?.data?.user?.verified) {
        dispatch(setUserVerified());
      }
      if (res?.payload?.data?.authToken) {
        navigate("/dashboard/videos");
      }
    });
  };
  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      style={styles.formStyle}
      layout="vertical"
      labelAlign="left"
      requiredMark={false}
    >
      <Form.Item<LoginFormTypes>
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your email!",
            type: "email",
          },
        ]}
        style={styles.inputStyle}
      >
        <Input />
      </Form.Item>

      <Form.Item<LoginFormTypes>
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
        style={styles.inputStyle}
      >
        <Input.Password />
      </Form.Item>
      <Space style={styles.forgotStyle}>
        <Link to={ROUTES_MAP.resetPassword} style={styles.forgotStyleLink}>
          Forgot Your Password?
        </Link>
      </Space>

      <Form.Item style={styles.inputStyle}>
        <Button
          type="primary"
          htmlType="submit"
          style={styles.submitStyle}
          loading={isLoading}
        >
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;


export const secondsToHHMMSS = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes
        }:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const getFileDuration = (file: any) =>
    new Promise((resolve, reject) => {
        try {

            let aborted = false;
            try {
                console.log('Attempting to get duration', file)
                setTimeout(() => {
                    console.log('Timed out', aborted)
                    if (!aborted) {
                        aborted = true;
                        reject(new Error('Timed out getting duration'));
                    }
                }, 60 * 1000);
                let video = document.createElement("video");
                video.preload = "metadata";

                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    if (!aborted) {
                        aborted = true;
                        const duration = (this as HTMLVideoElement).duration
                        console.log('Metadata loaded', duration)
                        resolve(duration);
                    }
                };

                video.onerror = function (err) {
                    console.log('On error', err)
                    if (!aborted) {
                        aborted = true;
                        reject(new Error("Invalid video. Please select a video file."));
                    }
                };

                video.src = URL.createObjectURL(file);
            } catch (e) {
                console.log(e);
                if (!aborted) {
                    aborted = true;
                    reject(e);
                }
            }
        } catch (err) {
            console.log(err)
        }
    });

// export const trackFacebookPurchase = () => {
//     try {
//         if (window.fbq) {
//             window.fbq("track", "Purchase", {
//                 value: 20,
//                 currency: "USD",
//             });
//         }
//     } catch (err) {
//         console.log(err);
//     }
// };

// export const trackRedditPurcahse = () => {
//     try {
//         if (window.rdt) {
//             window.rdt("track", "Purchase", {
//                 currency: "USD",
//                 itemCount: 1,
//                 value: 20,
//             });
//         }
//     } catch (err) {
//         console.log(err);
//     }
// };

// export const trackTiktokPurcahse = () => {
//     try {
//         if (window.ttq) {
//             window.ttq.track("CompletePayment", {
//                 quantity: 1,
//                 price: 20,
//                 value: 20,
//                 currency: "USD",
//             });
//         }
//     } catch (err) { }
// };

// export const trackQuoraPurcahse = () => {
//     try {
//         if (window.qp) {
//             window.qp('track', 'Purchase', { value: 29.99 });
//         }
//     } catch (err) { }
// };

// export const trackPurchase = () => {
//     if (process.env.NODE_ENV === "production") {
//         // trackRedditPurcahse();
//         // trackTiktokPurcahse();
//         // trackFacebookPurchase();
//         // trackQuoraPurcahse();
//     }
// };

// const trackRedditSignup = () => {
//     try {
//         if (window.rdt) {
//             window.rdt("track", "SignUp", {
//                 currency: "USD",
//                 value: 20,
//                 itemCount: 1,
//             });
//         }
//     } catch (err) {
//         console.log(err);
//     }
// };

// const trackFacebookSignup = () => {
//     try {
//         if (window.fbq) {
//             window.fbq("track", "CompleteRegistration");
//         }
//     } catch (err) {
//         console.log(err);
//     }
// };

// const trackTiktokSignup = () => {
//     try {
//         if (window.ttq) {
//             window.ttq.track("CompleteRegistration");
//         }
//     } catch (err) { }
// };

// const trackQuoraSignup = () => {
//     try {
//         if (window.qp) {
//             window.qp('track', 'Signup')
//         }

//     } catch (err) { }
// }

// export const trackSignup = () => {
//     if (process.env.NODE_ENV === "production") {
//         // trackRedditSignup();
//         // trackFacebookSignup();
//         // trackTiktokSignup();
//         // trackQuoraSignup();
//     }
// };

// export const updateUserCrispData = ({ email, firstName, lastName }) => {
//     if (typeof window !== 'undefined' && window.$crisp) {
//         try {
//             if (window.$crisp.get) {
//                 const visitorEmail = window.$crisp.get("user:email");
//                 if (!visitorEmail) {
//                     window.$crisp.push(["set", "user:email", email]);
//                     window.$crisp.push([
//                         "set",
//                         "user:nickname",
//                         `${firstName} ${lastName}`,
//                     ]);
//                 }
//             }
//         } catch (err) {
//         }
//     }
// };

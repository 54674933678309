const order = [
'6PWvnXIIIXiApYigxxsbA',
'YHJNGN4iHWJC13p6cnyGr',
'nBYCfOpRQwUlK7VtCZ2Jt',
'4NZ5un4juXEI358L4U00F',
'RCDnVroChHd5SxcYRSZuw',
'eOsn-7-nAk-uzQUEzqm-Q',
'fL2fCbE1bQnnYUxsIXySx',
'0eGqp-fx7EkDpGvJHuDRK',
'C03Xa9XX4u2iTVwPQz7EJ',
'bXGWrz0n04BZzKmIBiSa-',
'7OQESfVaqGcjAME8gyUoG',
'R1I7NNhUMe9d7LJXLCjGZ',
'QFSrVvV1vjkpKFng4ffyo',
'Fmk6lhk834JCYRARyy31V',
'kXPsn-7n_-73v5MyvbDIx'
].reverse()

export const tempelates = [
    {
        bRolls: [
            {
                assetTag: "b_roll",
                assetType: "image",
                height: 17.667139750856343,
                id: "ct1sVhUJ797fFigvr5qx5",
                top: 13.392996108949418,
                metaData: {
                    duration: 48.042,
                    isDraft: false,
                    start: 0,
                    isTemplate: true,
                    end: 5984,
                    isFullVideoLength: true,
                },
                url: "https://storage.googleapis.com/vidyo-bucket/image/fbd6abe6-c202-4572-8d2f-e90a3efea28f.png",
                duration: null,
                left: 149.83701048412968,
                width: 99.36283967260147,
            },
        ],
        subtitle: {
            lineHeight: 1,
            underline: false,
            text: "SO MANY OF US ARE",
            effect_type: "outline",
            fontFamily: "Poppins",
            shadow: {
                config: {
                    offsetX: "2",
                    blur: "0",
                    offsetY: "1",
                },
                enabled: false,
                color: "rgba(248,214,10,1)",
            },
            fill: "rgba(255,255,255,1)",
            fontSize: 17.35821011673152,
            textBgColor: {
                color: "rgba(15,225,208,0.83)",
                radius: 3.47,
                enabled: false,
            },
            noEffect: false,
            bold: true,
            blockBackground: {
                radius: 0,
                color: "rgba(255,255,255,1)",
                padding: 0,
                enabled: false,
            },
            top: 263.0440672432657,
            effect_color: "rgba(0,0,0,1)",
            style: {
                type: "default",
                color: "rgba(0,105,199,1)",
            },
            textAlign: "center",
            padding: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            },
            italic: false,
            textBackgroundColor: null,
            stroke: {
                color: "rgba(0,0,0,1)",
                config: {
                    width: "3",
                },
                enabled: true,
            },
            fontColor: "rgba(255,255,255,1)",
            margin: {
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
            },
            width: 189.28419524185676,
            maxHeight: null,
            textTransform: "UPPERCASE",
            left: 31.076652379071618,
        },
        name: "template-MN47KJDh",
        createdAt: "2023-09-21T10:37:45.633Z",
        texts: [],
        id: "6PWvnXIIIXiApYigxxsbA",
        updatedAt: "2023-09-21T10:37:45.633Z",
        height: 447,
        width: 251.4375,
    },
    {
        updatedAt: "2023-07-26T14:07:10.490Z",
        name: "template-KMbCVJ79",
        subtitle: {
            fontColor: "rgba(0,0,0,1)",
            width: 212,
            top: 311.2452877043708,
            shadow: {
                enabled: false,
                color: "#000000",
                config: {
                    blur: 10,
                    offsetY: 0,
                    offsetX: 0,
                },
            },
            fill: "rgba(0,0,0,1)",
            stroke: {
                enabled: false,
                config: {
                    width: 3,
                },
                color: "#000000",
            },
            text: "guys'videos on creators",
            textTransform: null,
            textBgColor: {
                radius: 4,
                enabled: true,
                color: "rgba(255,255,255,1)",
            },
            fontFamily: "Poppins",
            maxHeight: null,
            bold: true,
            fontSize: 16.03532008830022,
            noEffect: false,
            left: 20.1875,
            lineHeight: 1,
            underline: false,
            effect_type: "none",
            style: {
                type: "random_word_color_change",
                color: "#9e01f2",
            },
            italic: false,
            textAlign: "center",
            padding: {
                left: 4,
                top: 4,
                bottom: 4,
                right: 4,
            },
            textBackgroundColor: null,
            margin: {
                right: 0,
                top: 0,
                left: 0,
                bottom: 3,
            },
            blockBackground: {
                radius: 4,
                padding: 0,
                enabled: false,
                color: "#ab0000",
            },
            effect_color: null,
        },
        height: 454,
        id: "YHJNGN4iHWJC13p6cnyGr",
        bRolls: [],
        width: 255.375,
        backgroundColor: "#008000",
        createdAt: "2023-07-26T14:07:10.490Z",
        texts: [
            {
                blockBackground: {
                    enabled: false,
                    radius: 4,
                    color: "#ab0000",
                    padding: 0,
                },
                end: 28402,
                start: 2330,
                effect_color: null,
                fontColor: "#fff",
                text: "Interviewing Tech Creators on YouTube",
                type: 'clip_title',
                shadow: {
                    color: "#000000",
                    enabled: false,
                    config: {
                        offsetY: 0,
                        blur: 10,
                        offsetX: 0,
                    },
                },
                stroke: {
                    color: "#000000",
                    enabled: false,
                    config: {
                        width: 3,
                    },
                },
                fontSize: 14,
                isFullVideoLength: true,
                lineHeight: 1,
                width: 207.46468274911055,
                effect_type: "none",
                underline: false,
                left: 21.304839877821053,
                noEffect: true,
                bold: false,
                textTransform: "LOWERCASE",
                fontFamily: "Bebas Neue",
                id: "title-text",
                textBgColor: {
                    color: "#000000",
                    radius: 0,
                    enabled: false,
                },
                margin: {
                    left: 0,
                    bottom: 0,
                    right: 0,
                    top: 0,
                },
                padding: {
                    right: 0,
                    bottom: 0,
                    top: 0,
                    left: 0,
                },
                maxHeight: 16.8,
                fill: "#fff",
                top: 22.95215469733027,
                italic: false,
                textAlign: "center",
                textBackgroundColor: null,
                duration: 26072,
            },
        ],
    },
    {
        updatedAt: "2023-08-24T05:14:03.148Z",
        bRolls: [
            {
                id: "F9MUpHdx-rSlQTB0KaITC",
                height: 78.78448707002917,
                width: 254.07997080084397,
                top: 275,
                duration: null,
                assetType: "image",
                url: "https://storage.googleapis.com/vidyo-bucket/image/6a195714-de47-42c0-bd52-0e3790849f5c.png",
                metaData: {
                    isFullVideoLength: true,
                    end: 22640,
                    isDraft: false,
                    duration: 52.256,
                    isTemplate: true,
                    start: 330,
                },
                assetTag: "b_roll",
                left: 11.073754501710937,
            },
        ],
        texts: [],
        width: 289.125,
        name: "TitktokTemplate_3",
        height: 514,
        id: "BG7KQQe-7qmfIqg1T5O6w",
        subtitle: {
            fontColor: "rgba(42,240,234,1)",
            bold: false,
            text: "are.",
            effect_color: "#000000",
            stroke: {
                config: {
                    width: 3,
                },
                enabled: false,
                color: "#000000",
            },
            fontSize: "15",
            textBackgroundColor: null,
            left: 39.484038577203364,
            maxHeight: null,
            textTransform: "LOWERCASE",
            fill: "rgba(42,240,234,1)",
            padding: {
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,
            },
            shadow: {
                config: {
                    offsetX: "0",
                    offsetY: "1",
                    blur: "10",
                },
                color: "#000000",
                enabled: false,
            },
            blockBackground: {
                enabled: false,
                padding: 0,
                radius: 4,
                color: "#808080",
            },
            effect_type: "none",
            underline: false,
            fontFamily: "KOMIKAX_",
            lineHeight: 1,
            textAlign: "center",
            italic: false,
            noEffect: true,
            style: {
                type: "word_color_change",
                color: "rgba(254,40,88,1)",
            },
            top: 296.3922435350146,
            width: 186.18564814814818,
            margin: {
                bottom: 0,
                left: 0,
                top: 0,
                right: 0,
            },
            textBgColor: {
                enabled: false,
                color: "#000000",
                radius: 0,
            },
        },
        createdAt: "2023-08-24T05:14:03.148Z",
    },
    {
        name: "template-dc3GDiLL",
        updatedAt: "2023-08-22T07:50:25.503Z",
        height: 514,
        subtitle: {
            effect_type: "shadow",
            shadow: {
                color: "#000000",
                enabled: true,
                config: {
                    offsetY: "1",
                    blur: "10",
                    offsetX: "0",
                },
            },
            textBgColor: {
                enabled: false,
                color: "#000000",
                radius: 0,
            },
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            blockBackground: {
                enabled: false,
                padding: 8,
                color: "rgba(255,188,2,1)",
                radius: 4,
            },
            top: 286.2143882555739,
            effect_color: "#000000",
            fontFamily: "Source Serif Pro",
            noEffect: false,
            lineHeight: 1,
            fontColor: "rgba(255,255,255,1)",
            textTransform: "UPPERCASE",
            maxHeight: null,
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            italic: false,
            bold: false,
            textAlign: "center",
            underline: false,
            fontSize: 22,
            stroke: {
                config: {
                    width: 3,
                },
                color: "rgba(166,166,166,0.11)",
                enabled: false,
            },
            width: 191.92257977155353,
            style: {
                color: "rgba(38,9,176,1)",
                type: "word_background_change",
            },
            text: "BELIEFS OF THE PEOPLE",
            left: 44.60121011422325,
            fill: "rgba(255,255,255,1)",
            textBackgroundColor: null,
        },
        bRolls: [],
        id: "QhtWuScKfh9qc4rJ9uTT-",
        width: 289.125,
        texts: [],
        createdAt: "2023-08-22T07:48:43.592Z",
    },
    {
        height: 447,
        bRolls: [
            {
                url: "https://storage.googleapis.com/vidyo-bucket/image/fc77f861-a9c5-4f57-b9cd-ee01c0ca3ca1.png",
                height: 37.68727197758528,
                top: 259.7086563263202,
                id: "wR-SGEg4VoB5ADb8N46cQ",
                assetTag: "b_roll",
                left: -0.41405016524862504,
                assetType: "image",
                duration: null,
                metaData: {
                    duration: 47.004,
                    start: 1130,
                    isDraft: false,
                    isTemplate: true,
                    isFullVideoLength: true,
                    end: 48134,
                },
                width: 251.24847985056834,
            },
        ],
        id: "g9SwcYmrVCC2WeZHwaTtg",
        name: "InstagramTemplate_A4",
        texts: [],
        createdAt: "2023-08-31T16:31:47.963Z",
        updatedAt: "2023-08-31T16:31:47.963Z",
        subtitle: {
            text: "DIGITAL MARKE",
            blockBackground: {
                radius: 0,
                padding: 5,
                color: "rgba(0,0,0,0.47)",
                enabled: false,
            },
            textBgColor: {
                color: "rgba(245,241,243,1)",
                enabled: false,
                radius: 4,
            },
            noEffect: true,
            fill: "rgba(255,255,255,1)",
            textAlign: "center",
            left: 21.47271440355206,
            bold: false,
            stroke: {
                enabled: false,
                color: "rgba(0,0,0,1)",
                config: {
                    width: "1",
                },
            },
            padding: {
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
            },
            fontFamily: "HelveticaNowDisplay-Bold",
            fontSize: "20",
            top: 264.0522923151128,
            italic: false,
            shadow: {
                enabled: false,
                config: {
                    offsetX: "1",
                    offsetY: "1",
                    blur: "0",
                },
                color: "rgba(0,0,0,1)",
            },
            maxHeight: null,
            effect_type: "none",
            width: 202.47495071296692,
            fontColor: "rgba(255,255,255,1)",
            textTransform: "UPPERCASE",
            lineHeight: 1,
            underline: false,
            textBackgroundColor: null,
            margin: {
                left: 0,
                bottom: 0,
                top: 0,
                right: 0,
            },
            effect_color: "rgba(245,241,243,1)",
            style: {
                color: "rgba(255,53,155,1)",
                type: "two_word",
            },
        },
        width: 251.4375,
    },
    {
        name: "template-rkdtWe9e",
        texts: [],
        width: 255.375,
        id: "nBYCfOpRQwUlK7VtCZ2Jt",
        height: 454,
        bRolls: [],
        createdAt: "2023-07-26T14:06:13.926Z",
        updatedAt: "2023-07-26T14:06:13.926Z",
        subtitle: {
            stroke: {
                color: "#000000",
                config: {
                    width: 3,
                },
                enabled: false,
            },
            shadow: {
                config: {
                    blur: 10,
                    offsetX: 0,
                    offsetY: 0,
                },
                enabled: false,
                color: "#000000",
            },
            textBackgroundColor: null,
            fontSize: 15.10536044362292,
            fill: "#ffffff",
            style: {
                type: "word_color_change",
                color: "#ffe600",
            },
            textTransform: null,
            bold: true,
            blockBackground: {
                radius: 4,
                padding: 0,
                enabled: false,
                color: "#ab0000",
            },
            effect_color: null,
            effect_type: "none",
            text: "guys'videos on creators",
            textBgColor: {
                enabled: true,
                color: "#000000",
                radius: 4,
            },
            maxHeight: null,
            width: 187.14008908400083,
            italic: false,
            fontColor: "#ffffff",
            margin: {
                right: 0,
                bottom: 3,
                left: 0,
                top: 0,
            },
            noEffect: false,
            textAlign: "center",
            padding: {
                bottom: 4,
                top: 4,
                left: 4,
                right: 4,
            },
            lineHeight: 1,
            underline: false,
            fontFamily: "League Spartan",
            top: 278.1242265930836,
            left: 34.1174554579996,
        },
    },
    {
        updatedAt: "2023-06-20T13:17:17.899Z",
        createdAt: "2023-06-20T13:17:17.899Z",
        height: 454,
        subtitle: {
            textBackgroundColor: null,
            textTransform: null,
            blockBackground: {
                enabled: false,
                color: "#ab0000",
            },
            maxHeight: 45.199999999999996,
            top: 342.3176003548847,
            italic: false,
            shadow: {
                config: {
                    blur: 10,
                    offsetY: 0,
                    offsetX: 0,
                },
                enabled: false,
                color: "#000000",
            },
            effect_color: "#000000",
            left: 54.653180187637986,
            text: "because when we're dead,",
            fill: "#00ff00",
            width: 147.79118377483445,
            bold: false,
            style: {
                type: "random_word_color_change",
                color: "#ffe600",
            },
            stroke: {
                enabled: true,
                config: {
                    width: 3,
                },
                color: "#000000",
            },
            noEffect: false,
            textAlign: "center",
            fontColor: "#00ff00",
            effect_type: "outline",
            fontSize: "20",
            textBgColor: {
                enabled: false,
                color: "#000000",
            },
            lineHeight: 1,
            underline: false,
            fontFamily: "Bangers",
        },
        texts: [
            {
                type: 'clip_title',
                fontColor: "#00ff00",
                noEffect: false,
                top: 14.916907893568236,
                left: 33.33905905077262,
                textBgColor: {
                    color: "#000000",
                    enabled: false,
                },
                stroke: {
                    enabled: true,
                    config: {
                        width: 3,
                    },
                    color: "#000000",
                },
                bold: false,
                italic: false,
                effect_type: "outline",
                underline: false,
                effect_color: "#000000",
                lineHeight: 1,
                text: "Fear of Death and Afterlife",
                fill: "#00ff00",
                end: 205150,
                shadow: {
                    config: {
                        blur: 10,
                        offsetX: 0,
                        offsetY: 0,
                    },
                    color: "#000000",
                    enabled: false,
                },
                maxHeight: 15.854922737306842,
                textAlign: "center",
                duration: 47940,
                fontSize: "20",
                fontFamily: "Koulen",
                id: "title-text",
                blockBackground: {
                    color: "#ab0000",
                    enabled: false,
                },
                start: 157210,
                isFullVideoLength: true,
                textBackgroundColor: "#000000",
                width: 190.41942604856516,
            },
        ],
        bRolls: [],
        width: 255.375,
        name: "template-RCeNznJ4",
        id: "n2EXfE_3YIEaUszU1yOpI",
    },
    {
        texts: [],
        bRolls: [],
        id: "kk59rg-p1Ya5lBKMxYAIN",
        updatedAt: "2023-08-24T23:20:42.938Z",
        name: "nstagramTemplate_3",
        subtitle: {
            style: {
                type: "word_appended",
                color: "rgba(255,243,0,1)",
            },
            top: 330.2143882555739,
            effect_color: "rgba(234,194,32,0.99)",
            fontColor: "rgba(217,217,217,1)",
            effect_type: "shadow",
            noEffect: false,
            textAlign: "left",
            shadow: {
                enabled: true,
                color: "rgba(234,194,32,0.99)",
                config: {
                    offsetY: "0",
                    offsetX: "0",
                    blur: "20",
                },
            },
            textTransform: "LOWERCASE",
            textBackgroundColor: null,
            textBgColor: {
                radius: 0,
                enabled: false,
                color: "#000000",
            },
            left: 26.51349796955128,
            text: "mastery is betraying",
            fontFamily: "KOMIKAX_",
            fill: "rgba(217,217,217,1)",
            fontSize: 24,
            maxHeight: null,
            width: 210.88884113428145,
            stroke: {
                color: "rgba(0,0,0,1)",
                config: {
                    width: 3,
                },
                enabled: true,
            },
            padding: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            blockBackground: {
                color: "rgba(0,0,0,0.46)",
                padding: 3,
                enabled: false,
                radius: 4,
            },
            margin: {
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
            },
            bold: false,
            underline: false,
            italic: false,
            lineHeight: 1,
        },
        width: 289.125,
        height: 514,
        createdAt: "2023-08-24T23:20:42.938Z",
    },
    {
        width: 255.375,
        createdAt: "2023-07-04T13:42:02.147Z",
        height: 454,
        id: "eOsn-7-nAk-uzQUEzqm-Q",
        updatedAt: "2023-07-04T13:42:02.147Z",
        bRolls: [],
        texts: [],
        subtitle: {
            italic: false,
            style: {
                type: "random_word_color_change",
                color: "rgba(255,230,0,1)",
            },
            noEffect: false,
            effect_type: "outline",
            top: 279.86720285609056,
            textAlign: "center",
            maxHeight: 45.199999999999996,
            stroke: {
                enabled: true,
                config: {
                    width: "6",
                },
                color: "#000000",
            },
            text: "THIS PODCAST AND YOUTUBE",
            left: 24.79586669552924,
            blockBackground: {
                enabled: false,
                color: "#ab0000",
            },
            fontFamily: "Montserrat-Black",
            textBackgroundColor: null,
            textTransform: "UPPERCASE",
            shadow: {
                config: {
                    offsetX: 0,
                    offsetY: 0,
                    blur: 10,
                },
                color: "#000000",
                enabled: false,
            },
            lineHeight: 1,
            fill: "#ffffff",
            effect_color: "#000000",
            fontColor: "#ffffff",
            fontSize: "20",
            width: 199.78326660894152,
            textBgColor: {
                color: "#000000",
                enabled: false,
            },
            bold: false,
            underline: false,
        },
        name: "template-aYQYEFnn",
    },
    {
        subtitle: {
            stroke: {
                enabled: false,
                config: {
                    width: "4",
                },
                color: "rgba(0,0,0,1)",
            },
            left: 32.18540571556832,
            effect_color: "rgba(0,0,0,0.51)",
            textTransform: "LOWERCASE",
            italic: false,
            fontColor: "rgba(255,255,255,1)",
            textAlign: "center",
            textBgColor: {
                radius: 5,
                color: "rgba(0,0,0,0.51)",
                enabled: false,
            },
            maxHeight: null,
            textBackgroundColor: null,
            fontFamily: "League Spartan",
            fill: "rgba(255,255,255,1)",
            bold: true,
            fontSize: 20,
            underline: false,
            width: 181.06668856886338,
            blockBackground: {
                padding: 6,
                radius: 2,
                enabled: true,
                color: "rgba(39,38,39,1)",
            },
            effect_type: "none",
            padding: {
                bottom: 0,
                right: 0,
                left: 0,
                top: 0,
            },
            shadow: {
                enabled: false,
                config: {
                    blur: "0",
                    offsetY: "1",
                    offsetX: "1",
                },
                color: "#000000",
            },
            style: {
                type: "default",
                color: "rgba(255,239,14,1)",
            },
            noEffect: false,
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            text: "a wonderful person,",
            lineHeight: 1,
            top: 263.8195870577473,
        },
        height: 447,
        bRolls: [],
        name: "InstagramTemplate_A8",
        width: 251.4375,
        createdAt: "2023-08-31T16:55:58.030Z",
        updatedAt: "2023-08-31T16:55:58.030Z",
        texts: [],
        id: "4NZ5un4juXEI358L4U00F",
    },
    {
        bRolls: [],
        width: 251.4375,
        id: "5dsfktXJYnDWXz73JZdrM",
        updatedAt: "2023-08-31T16:06:30.269Z",
        createdAt: "2023-08-31T16:06:30.269Z",
        name: "InstagramTemplate_A1",
        texts: [],
        height: 447,
        subtitle: {
            left: 27.210182086360703,
            textTransform: "UPPERCASE",
            fill: "rgba(255,246,191,1)",
            fontFamily: "Russo One",
            stroke: {
                enabled: true,
                color: "rgba(89,75,121,1)",
                config: {
                    width: "6",
                },
            },
            width: 194.0171358272786,
            maxHeight: null,
            lineHeight: 1,
            text: "WAYS TO",
            blockBackground: {
                enabled: false,
                color: "rgba(0,0,0,0.46)",
                radius: 4,
                padding: 3,
            },
            margin: {
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
            },
            textAlign: "center",
            effect_color: "rgba(89,75,121,1)",
            padding: {
                right: 0,
                top: 0,
                left: 0,
                bottom: 0,
            },
            fontSize: 20.8715953307393,
            style: {
                color: "#ffe600",
                type: "two_word",
            },
            top: 260.78775442271643,
            effect_type: "outline",
            textBackgroundColor: null,
            shadow: {
                color: "rgba(204,255,0,1)",
                enabled: false,
                config: {
                    offsetY: "0",
                    offsetX: "0",
                    blur: "20",
                },
            },
            noEffect: false,
            fontColor: "rgba(255,246,191,1)",
            bold: false,
            italic: false,
            underline: false,
            textBgColor: {
                enabled: false,
                color: "#000000",
                radius: 0,
            },
        },
    },
    {
        width: 254.8125,
        id: "AbTY59k_A1Jpc3U4uDwRP",
        height: 453,
        texts: [
            {
                type: 'clip_title',
                fontColor: "#fff",
                noEffect: true,
                left: 28.265625,
                fontSize: "22",
                textAlign: "center",
                width: 196,
                fontFamily: "League Spartan",
                italic: false,
                maxHeight: 49.72,
                blockBackground: {
                    color: "#ab0000",
                    enabled: false,
                },
                underline: false,
                duration: 12124,
                effect_type: "none",
                bold: true,
                effect_color: null,
                text: "Why are they in \nchina.",
                top: 18.245768637532137,
                fill: "#fff",
                textBackgroundColor: null,
                shadow: {
                    config: {
                        offsetX: 0,
                        offsetY: 0,
                        blur: 10,
                    },
                    color: "#000000",
                    enabled: false,
                },
                stroke: {
                    enabled: false,
                    config: {
                        width: 3,
                    },
                    color: "#000000",
                },
                textBgColor: {
                    enabled: false,
                    color: "#000000",
                },
            },
        ],
        backgroundColor: "#FFFFFF",
        subtitle: {
            width: 150.265625,
            blockBackground: {
                color: "#ab0000",
                enabled: false,
            },
            maxHeight: 18.08,
            top: 366.4573778920309,
            shadow: {
                color: "#000000",
                config: {
                    blur: 10,
                    offsetX: 0,
                    offsetY: 0,
                },
                enabled: false,
            },
            textBgColor: {
                color: "#000000",
                enabled: false,
            },
            fill: "#ffffff",
            bold: false,
            fontSize: "16",
            text: "You know what",
            effect_type: "none",
            italic: false,
            textBackgroundColor: null,
            stroke: {
                enabled: false,
                config: {
                    width: 3,
                },
                color: "#000000",
            },
            fontFamily: "Sriracha",
            underline: false,
            effect_color: null,
            noEffect: true,
            left: 50,
            textAlign: "center",
            fontColor: "#ffffff",
        },
        isCutMagicEnabled: false,
    },
    {
        width: 255.375,
        updatedAt: "2023-07-26T14:13:39.188Z",
        subtitle: {
            padding: {
                left: 4,
                top: 4,
                bottom: 4,
                right: 4,
            },
            underline: false,
            lineHeight: 1,
            italic: false,
            fontColor: "rgba(255,255,255,1)",
            textAlign: "center",
            text: "guys'videos on creators",
            style: {
                type: "default",
                color: "#ffe600",
            },
            blockBackground: {
                radius: 4,
                color: "#ab0000",
                enabled: false,
                padding: 0,
            },
            textTransform: null,
            margin: {
                top: 0,
                bottom: 3,
                right: 0,
                left: 0,
            },
            fontSize: 15,
            noEffect: false,
            textBackgroundColor: null,
            fontFamily: "Montserrat",
            shadow: {
                config: {
                    offsetX: 0,
                    offsetY: 0,
                    blur: 10,
                },
                enabled: false,
                color: "#000000",
            },
            fill: "rgba(255,255,255,1)",
            effect_type: "none",
            stroke: {
                color: "#000000",
                enabled: false,
                config: {
                    width: 3,
                },
            },
            textBgColor: {
                enabled: true,
                color: "rgba(226,80,35,1)",
                radius: 4,
            },
            width: 236.72253258845436,
            maxHeight: null,
            effect_color: null,
            top: 313.1986745543548,
            bold: true,
            left: 9.866532821229043,
        },
        texts: [
            {
                type: 'clip_title',
                effect_type: "none",
                margin: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                },
                textAlign: "left",
                fontColor: "rgba(227,80,36,1)",
                noEffect: true,
                underline: false,
                width: 202.87550016957545,
                effect_color: null,
                lineHeight: 1,
                shadow: {
                    color: "#000000",
                    config: {
                        offsetY: 0,
                        offsetX: 0,
                        blur: 10,
                    },
                    enabled: false,
                },
                stroke: {
                    enabled: false,
                    color: "#000000",
                    config: {
                        width: 3,
                    },
                },
                maxHeight: 38.4,
                textTransform: "LOWERCASE",
                left: 27.249749915212305,
                blockBackground: {
                    radius: 4,
                    padding: 0,
                    color: "#ab0000",
                    enabled: false,
                },
                start: 2330,
                id: "title-text",
                fontFamily: "Montserrat",
                textBackgroundColor: null,
                padding: {
                    bottom: 0,
                    right: 0,
                    left: 0,
                    top: 0,
                },
                end: 28402,
                italic: false,
                isFullVideoLength: true,
                text: "Interviewing Tech Creators on YouTube",
                textBgColor: {
                    enabled: false,
                    radius: 0,
                    color: "#000000",
                },
                fontSize: "16",
                fill: "rgba(227,80,36,1)",
                top: 36.20225110049326,
                duration: 26072,
                bold: true,
            },
        ],
        bRolls: [
            {
                duration: null,
                assetTag: "b_roll",
                width: 255.80138566358096,
                assetType: "image",
                left: -1.6728937163342152,
                metaData: {
                    duration: 78.62,
                    isTemplate: true,
                    start: 2330,
                    isFullVideoLength: true,
                    end: 28402,
                    isDraft: false,
                },
                height: 454.75801895747725,
                id: "mB4tZZBriGFsIYF1l7Fwt",
                top: -12.990613718411566,
                url: "https://storage.googleapis.com/vidyo-bucket/image/52ca8bc7-32b1-4cc6-82d7-93890b36935d.png",
            },
        ],
        height: 454,
        name: "template2 Reel",
        createdAt: "2023-05-05T12:45:00.432Z",
        id: "fL2fCbE1bQnnYUxsIXySx",
    },
    {
        bRolls: [],
        updatedAt: "2023-09-01T04:43:10.997Z",
        texts: [],
        id: "bXGWrz0n04BZzKmIBiSa-",
        name: "Youtube Template",
        subtitle: {
            width: 178.27866491194004,
            italic: false,
            margin: {
                right: 0,
                left: 0,
                bottom: 0,
                top: 0,
            },
            maxHeight: null,
            padding: {
                bottom: 0,
                top: 0,
                right: 0,
                left: 0,
            },
            fill: "rgba(57,255,20,1)",
            left: 65.20019219551398,
            textBgColor: {
                color: "rgba(15,225,208,0.83)",
                radius: 4,
                enabled: false,
            },
            bold: false,
            textTransform: "UPPERCASE",
            fontColor: "rgba(57,255,20,1)",
            top: 264,
            shadow: {
                enabled: true,
                color: "rgba(0,0,0,1)",
                config: {
                    offsetY: "2",
                    offsetX: "2",
                    blur: "6",
                },
            },
            blockBackground: {
                radius: 0,
                padding: 0,
                enabled: false,
                color: "rgba(255,255,255,1)",
            },
            textBackgroundColor: null,
            style: {
                color: "rgba(0,105,199,1)",
                type: "two_word",
            },
            effect_type: "shadow",
            underline: false,
            lineHeight: 1,
            text: "PAIN IS",
            noEffect: false,
            stroke: {
                enabled: true,
                color: "rgba(231,84,128,1)",
                config: {
                    width: "8",
                },
            },
            fontFamily: "KOMIKAX_",
            effect_color: "rgba(0,0,0,1)",
            fontSize: 22.997762863534675,
            textAlign: "center",
        },
        createdAt: "2023-09-01T04:43:10.997Z",
        width: 289.125,
        height: 514,
    },
    {
        name: "YoutubeTemplate_1",
        updatedAt: "2023-08-25T09:58:07.825Z",
        id: "4QuQHJV1aoLl2I79H6UqD",
        texts: [],
        createdAt: "2023-08-25T09:58:07.825Z",
        bRolls: [
            {
                left: 124.56249999999943,
                url: "https://storage.googleapis.com/vidyo-bucket/image/6729814c-5b82-41d3-b982-29c50ca44897.png",
                id: "cPEIO8SHn0b-75khYSNQ1",
                assetTag: "b_roll",
                duration: null,
                top: 377.64438825557386,
                metaData: {
                    end: 720754,
                    duration: 28.492,
                    isTemplate: true,
                    isDraft: false,
                    isFullVideoLength: true,
                    start: 692262,
                },
                width: 51.85614945595853,
                assetType: "image",
                height: 61.43696228846818,
            },
        ],
        subtitle: {
            italic: false,
            noEffect: false,
            maxHeight: null,
            textBgColor: {
                color: "#000000",
                radius: 0,
                enabled: false,
            },
            fill: "rgba(102,204,51,1)",
            stroke: {
                color: "rgba(0,0,0,1)",
                config: {
                    width: 3,
                },
                enabled: true,
            },
            fontFamily: "Koulen",
            top: 283.2143882555739,
            textTransform: "LOWERCASE",
            padding: {
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
            },
            textBackgroundColor: null,
            underline: false,
            fontColor: "rgba(102,204,51,1)",
            text: "You're forming these",
            effect_type: "shadow",
            fontSize: "22",
            margin: {
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            },
            shadow: {
                color: "rgba(0,0,0,1)",
                enabled: true,
                config: {
                    offsetY: "2",
                    blur: "0",
                    offsetX: "2",
                },
            },
            textAlign: "left",
            blockBackground: {
                radius: 4,
                enabled: false,
                padding: 3,
                color: "rgba(0,0,0,0.46)",
            },
            width: 210.88884113428145,
            style: {
                type: "random_word_color_change",
                color: "rgba(255,222,89,1)",
            },
            bold: false,
            effect_color: "rgba(0,0,0,1)",
            lineHeight: 1,
            left: 37.618079432859304,
        },
        width: 289.125,
        height: 514,
    },
    {
        width: 255.375,
        name: "template2",
        height: 454,
        bRolls: [],
        subtitle: {
            shadow: {
                enabled: false,
                config: {
                    offsetX: 0,
                    offsetY: 0,
                    blur: 10,
                },
                color: "#3fa1ff",
            },
            textBgColor: {
                radius: 4,
                enabled: true,
                color: "#3fa1ff",
            },
            padding: {
                bottom: 4,
                top: 4,
                right: 4,
                left: 4,
            },
            italic: false,
            style: {
                color: "#0000ff",
                type: "two_word",
            },
            textAlign: "center",
            top: 330.4405274504928,
            fill: "#ffffff",
            effect_color: null,
            text: "also andrew,",
            fontColor: "#ffffff",
            stroke: {
                color: "rgba(0,0,0,1)",
                enabled: false,
                config: {
                    width: "3",
                },
            },
            fontSize: 22,
            lineHeight: 1,
            fontFamily: "Poppins",
            effect_type: "none",
            margin: {
                right: 0,
                top: 0,
                left: 0,
                bottom: 3,
            },
            textTransform: null,
            textBackgroundColor: null,
            bold: true,
            noEffect: false,
            underline: false,
            left: 21.12434173101451,
            width: 212.06315826898546,
            maxHeight: null,
            blockBackground: {
                padding: 0,
                radius: 4,
                enabled: false,
                color: "rgba(63,161,255,1)",
            },
        },
        updatedAt: "2023-07-26T14:23:56.350Z",
        createdAt: "2023-04-29T10:43:21.730Z",
        id: "7OQESfVaqGcjAME8gyUoG",
        texts: [
            {
                type: 'clip_title',
                left: 24.126300916452806,
                textBackgroundColor: null,
                textTransform: "LOWERCASE",
                textAlign: "left",
                fill: "#ffffff",
                italic: false,
                bold: true,
                blockBackground: {
                    enabled: false,
                    color: "#ab0000",
                    radius: 4,
                    padding: 0,
                },
                top: 26.915665497637793,
                noEffect: true,
                margin: {
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: 0,
                },
                stroke: {
                    color: "#000000",
                    enabled: false,
                    config: {
                        width: 3,
                    },
                },
                duration: 26072,
                fontSize: 16,
                fontFamily: "Poppins",
                end: 28402,
                text: "Interviewing tech creators on youtube",
                lineHeight: 1,
                maxHeight: 38.4,
                fontColor: "#ffffff",
                padding: {
                    bottom: 0,
                    left: 0,
                    top: 0,
                    right: 0,
                },
                start: 2330,
                width: 213.12239816709445,
                effect_type: "none",
                underline: false,
                textBgColor: {
                    color: "#000000",
                    enabled: false,
                    radius: 0,
                },
                isFullVideoLength: true,
                effect_color: null,
                id: "title-text",
                shadow: {
                    config: {
                        blur: 10,
                        offsetX: 0,
                        offsetY: 0,
                    },
                    color: "#000000",
                    enabled: false,
                },
            },
        ],
    },
    {
        name: "template-TyLXKLwe",
        width: 289.125,
        id: "6v9UsXtmoJ-Sy5jicA_h4",
        createdAt: "2023-08-25T10:54:12.384Z",
        subtitle: {
            lineHeight: 1,
            fontColor: "rgba(255,255,255,1)",
            textTransform: null,
            fontFamily: "Koulen",
            fill: "rgba(255,255,255,1)",
            blockBackground: {
                padding: 0,
                color: "#ab0000",
                enabled: false,
                radius: 4,
            },
            textBgColor: {
                enabled: false,
                radius: 0,
                color: "#000000",
            },
            noEffect: false,
            maxHeight: null,
            textBackgroundColor: null,
            underline: false,
            width: 236.69661458333337,
            padding: {
                left: 0,
                bottom: 0,
                right: 0,
                top: 0,
            },
            effect_type: "outline",
            text: "Can you tell the viewers",
            effect_color: "#000000",
            fontSize: 28,
            margin: {
                right: 0,
                left: 0,
                bottom: 0,
                top: 0,
            },
            top: 284.1999999999998,
            style: {
                color: "rgba(0,128,55,1)",
                type: "word_background_change",
            },
            bold: false,
            italic: false,
            shadow: {
                config: {
                    offsetY: 0,
                    offsetX: 0,
                    blur: 10,
                },
                enabled: false,
                color: "#000000",
            },
            left: 8.574424212538815,
            stroke: {
                color: "#000000",
                enabled: true,
                config: {
                    width: "2",
                },
            },
            textAlign: "left",
        },
        texts: [],
        updatedAt: "2023-08-25T12:13:36.787Z",
        height: 514,
        bRolls: [
            {
                id: "jcKomC5vDAgV8plRsQzM5",
                left: 10.249719104357496,
                duration: null,
                assetType: "image",
                height: 43.60586163774599,
                url: "https://storage.googleapis.com/vidyo-bucket/image/defe11be-7a96-405b-b79f-922d2c2f60a8.png",
                metaData: {
                    isDraft: false,
                    duration: 11.95,
                    start: 26748,
                    end: 82054,
                    isTemplate: true,
                    isFullVideoLength: true,
                },
                width: 238.96012177484795,
                assetTag: "b_roll",
                top: 369.9999999999998,
            },
        ],
    },
    {
        subtitle: {},
        updatedAt: "2023-08-24T04:40:57.470Z",
        createdAt: "2023-08-24T04:40:57.470Z",
        texts: [
            {
                type: 'clip_title',
                shadow: {
                    enabled: false,
                    config: {
                        blur: "10",
                        offsetX: "0",
                        offsetY: "1",
                    },
                    color: "#000000",
                },
                bold: false,
                textBackgroundColor: "#000000",
                fontColor: "rgba(255,255,255,1)",
                id: "title-text",
                duration: 52256,
                textAlign: "center",
                width: 171.390625,
                isFullVideoLength: true,
                fontFamily: "Montserrat",
                text: "ROBIN SHARMA ON THE 05:00 A.M. CLUB",
                margin: {
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 0,
                },
                lineHeight: 1,
                blockBackground: {
                    radius: 4,
                    padding: 0,
                    color: "#808080",
                    enabled: false,
                },
                stroke: {
                    config: {
                        width: "2",
                    },
                    color: "#000000",
                    enabled: false,
                },
                underline: false,
                italic: false,
                top: 38.159199105981116,
                effect_color: "#000000",
                textBgColor: {
                    color: "#000000",
                    radius: 0,
                    enabled: false,
                },
                end: 110634,
                left: 58.8671875,
                textTransform: "UPPERCASE",
                fill: "rgba(255,255,255,1)",
                start: 58378,
                fontSize: 15,
                maxHeight: 36,
                noEffect: true,
                padding: {
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 0,
                },
                effect_type: "none",
            },
            {
                type: 'text',
                underline: false,
                top: 288.44631442046335,
                lineHeight: 1,
                margin: {
                    left: 0,
                    bottom: 0,
                    right: 0,
                    top: 0,
                },
                left: 14.277872678606741,
                width: 40.609375,
                effect_color: "#000000",
                textTransform: "LOWERCASE",
                blockBackground: {
                    color: "#808080",
                    enabled: false,
                    padding: 0,
                    radius: 4,
                },
                effect_type: "none",
                fontSize: 15,
                isFullVideoLength: true,
                stroke: {
                    config: {
                        width: 3,
                    },
                    enabled: true,
                    color: "#000000",
                },
                text: "Name",
                bold: false,
                fontColor: "#ffffff",
                start: 58378,
                noEffect: false,
                textBackgroundColor: "#000000",
                padding: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                },
                fill: "#ffffff",
                fontFamily: "Cabin",
                shadow: {
                    color: "#000000",
                    enabled: false,
                    config: {
                        blur: "10",
                        offsetY: "1",
                        offsetX: "0",
                    },
                },
                italic: false,
                duration: 52256,
                maxHeight: 19.95,
                textBgColor: {
                    radius: 0,
                    color: "#000000",
                    enabled: false,
                },
                end: 110634,
                textAlign: "center",
                id: "MbLAFpFznleiQdy2Pvtpo",
            },
        ],
        bRolls: [
            {
                left: 46.575676164565266,
                url: "https://storage.googleapis.com/vidyo-bucket/image/a67f0621-cf3d-472d-9892-0a66436badbc.png",
                height: 71.6182765574606,
                assetType: "image",
                assetTag: "b_roll",
                width: 195.97364767086947,
                duration: null,
                id: "gjR5qDmQV2E9kx0GP46Jt",
                metaData: {
                    isFullVideoLength: true,
                    duration: 44.08,
                    start: 58378,
                    end: 110634,
                    isDraft: false,
                    isTemplate: true,
                },
                top: 23.350060827250815,
            },
        ],
        id: "gF99QybMARmwaHXsGwvaX",
        width: 289.125,
        height: 514,
        name: "TiktokTemplate_3",
    },
    {
        backgroundColor: "#ffffff",
        name: "template-mU7gEacE",
        height: 454,
        id: "HjSC45omZXJY2UvqAHY-3",
        width: 255.375,
        createdAt: "2023-04-28T09:26:55.189Z",
        texts: [
            {
                type: 'clip_title',
                fill: "rgba(255,255,255,1)",
                textTransform: "LOWERCASE",
                maxHeight: 49.6,
                blockBackground: {
                    padding: 4,
                    color: "rgba(0,0,0,0.49)",
                    radius: 5,
                    enabled: true,
                },
                textBgColor: {
                    color: "#000000",
                    enabled: false,
                    radius: 4,
                },
                width: 227.0353852477814,
                effect_type: "none",
                bold: true,
                left: 14.152114752218608,
                text: "Interviewing Tech Creators on YouTube",
                id: "title-text",
                margin: {
                    left: 0,
                    bottom: 0,
                    top: 0,
                    right: 0,
                },
                noEffect: false,
                top: 315.16228950994935,
                textAlign: "center",
                end: 28402,
                underline: false,
                shadow: {
                    config: {
                        offsetY: 0,
                        blur: 10,
                        offsetX: 0,
                    },
                    color: "#000000",
                    enabled: false,
                },
                fontColor: "rgba(255,255,255,1)",
                duration: 26072,
                stroke: {
                    config: {
                        width: 3,
                    },
                    enabled: false,
                    color: "#000000",
                },
                italic: false,
                fontFamily: "Montserrat",
                isFullVideoLength: true,
                start: 2330,
                padding: {
                    bottom: 0,
                    right: 0,
                    top: 0,
                    left: 0,
                },
                fontSize: "19",
                textBackgroundColor: null,
                effect_color: null,
                lineHeight: 1,
            },
        ],
        updatedAt: "2023-07-26T14:12:02.393Z",
        bRolls: [],
        subtitle: {
            style: {
                type: "word_color_change",
                color: "#ffe600",
            },
            textTransform: null,
            textBackgroundColor: null,
            noEffect: false,
            lineHeight: 1,
            fontColor: "#ffffff",
            italic: false,
            effect_type: "none",
            margin: {
                top: 0,
                right: 0,
                bottom: 3,
                left: 0,
            },
            blockBackground: {
                color: "#ab0000",
                radius: 4,
                padding: 0,
                enabled: false,
            },
            padding: {
                top: 4,
                right: 4,
                bottom: 4,
                left: 4,
            },
            fontFamily: "Montserrat",
            shadow: {
                enabled: false,
                color: "#000000",
                config: {
                    offsetX: 0,
                    blur: 10,
                    offsetY: 0,
                },
            },
            width: 212,
            left: 18.542712243388564,
            textAlign: "center",
            fill: "#ffffff",
            fontSize: "17",
            maxHeight: null,
            stroke: {
                enabled: false,
                color: "#000000",
                config: {
                    width: 3,
                },
            },
            bold: true,
            underline: false,
            textBgColor: {
                enabled: true,
                radius: 4,
                color: "#000000",
            },
            top: 247.53635424067807,
            effect_color: null,
            text: "I've been watching your",
        },
    },
    {
        bRolls: [],
        updatedAt: "2023-08-24T23:03:59.510Z",
        subtitle: {
            left: 47.04146091778807,
            stroke: {
                config: {
                    width: 3,
                },
                color: "rgba(0,0,0,1)",
                enabled: true,
            },
            text: "HE'S BEEN",
            bold: false,
            margin: {
                right: 0,
                top: 0,
                bottom: 0,
                left: 0,
            },
            textAlign: "left",
            italic: false,
            textBackgroundColor: null,
            padding: {
                right: 0,
                top: 0,
                left: 0,
                bottom: 0,
            },
            shadow: {
                enabled: false,
                config: {
                    blur: "10",
                    offsetX: "0",
                    offsetY: "1",
                },
                color: "#000000",
            },
            fill: "rgba(255,255,255,1)",
            effect_type: "outline",
            fontFamily: "HelveticaNowDisplay-Bold",
            effect_color: "rgba(0,0,0,1)",
            textBgColor: {
                enabled: false,
                color: "#000000",
                radius: 0,
            },
            fontSize: 22.997762863534675,
            noEffect: false,
            textTransform: "UPPERCASE",
            style: {
                type: "word_appended",
                color: "rgba(255,87,87,1)",
            },
            width: 206.0165031021506,
            maxHeight: null,
            underline: false,
            lineHeight: 1,
            fontColor: "rgba(255,255,255,1)",
            top: 293.2143882555739,
            blockBackground: {
                enabled: false,
                radius: 4,
                color: "rgba(255,188,2,1)",
                padding: 5,
            },
        },
        name: "InstagramTemplate_2",
        id: "f0clIQNhoUytebuXdVNyl",
        height: 514,
        createdAt: "2023-08-24T23:03:59.510Z",
        width: 289.125,
        texts: [],
    },
    {
        texts: [],
        createdAt: "2023-08-18T05:18:53.852Z",
        subtitle: {
            fontColor: "rgba(255,255,255,1)",
            top: 276.2995541936639,
            effect_color: "rgba(0,0,0,1)",
            fill: "rgba(255,255,255,1)",
            margin: {
                bottom: 0,
                right: 0,
                left: 0,
                top: 0,
            },
            fontSize: 20.8715953307393,
            italic: false,
            textBgColor: {
                enabled: false,
                color: "#000000",
                radius: 0,
            },
            stroke: {
                config: {
                    width: 3,
                },
                enabled: true,
                color: "#000000",
            },
            fontFamily: "KOMIKAX_",
            blockBackground: {
                color: "#ab0000",
                enabled: false,
                radius: 4,
                padding: 0,
            },
            noEffect: false,
            effect_type: "shadow",
            shadow: {
                config: {
                    offsetX: 0,
                    offsetY: "1",
                    blur: "12",
                },
                color: "rgba(0,0,0,1)",
                enabled: true,
            },
            lineHeight: 1.1,
            maxHeight: null,
            textTransform: null,
            textBackgroundColor: null,
            padding: {
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
            },
            bold: false,
            underline: false,
            style: {
                type: "random_word_color_change",
                color: "rgba(126,217,87,1)",
            },
            width: 180.5339402117797,
            text: "I don't qualify his",
            textAlign: "center",
            left: 37.1073354496657,
        },
        name: "template-GXPpbMfM",
        id: "RXyJ9uAH8xaYqDT1Ai1Hw",
        bRolls: [],
        height: 447,
        updatedAt: "2023-08-22T15:55:51.809Z",
        width: 251.4375,
    },
    {
        height: 447,
        bRolls: [],
        texts: [],
        subtitle: {
            textTransform: "LOWERCASE",
            top: 269.77788239346603,
            fontSize: 20.8715953307393,
            maxHeight: null,
            lineHeight: 1,
            stroke: {
                enabled: false,
                color: "rgba(166,166,166,0.11)",
                config: {
                    width: 3,
                },
            },
            textBackgroundColor: null,
            italic: false,
            style: {
                color: "#ffe600",
                type: "default",
            },
            padding: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            textAlign: "center",
            underline: false,
            fill: "rgba(255,222,89,1)",
            fontColor: "rgba(255,222,89,1)",
            effect_type: "none",
            width: 194.0171358272786,
            margin: {
                bottom: 0,
                top: 0,
                right: 0,
                left: 0,
            },
            noEffect: false,
            text: "I don't qualify his",
            fontFamily: "Montserrat",
            textBgColor: {
                color: "#000000",
                enabled: false,
                radius: 0,
            },
            left: 27.747188192889553,
            effect_color: "#000000",
            bold: true,
            shadow: {
                color: "rgba(204,255,0,1)",
                enabled: false,
                config: {
                    offsetX: "0",
                    blur: "20",
                    offsetY: "0",
                },
            },
            blockBackground: {
                radius: 4,
                padding: 3,
                color: "rgba(0,0,0,0.46)",
                enabled: true,
            },
        },
        createdAt: "2023-08-18T05:05:41.611Z",
        id: "-Ys9JWpJFX4IZ1QTJb1BE",
        updatedAt: "2023-08-22T15:54:56.971Z",
        name: "template-eREHDJkr",
        width: 251.4375,
    },
    {
        texts: [],
        name: "template-cRnqQV79",
        createdAt: "2023-08-18T05:31:54.359Z",
        updatedAt: "2023-08-22T15:53:36.651Z",
        height: 447,
        id: "R1I7NNhUMe9d7LJXLCjGZ",
        subtitle: {
            fontColor: "rgba(255,255,255,1)",
            effect_type: "none",
            maxHeight: null,
            top: 268.77897928560503,
            textBackgroundColor: null,
            textTransform: "UPPERCASE",
            effect_color: "#000000",
            margin: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
            fontFamily: "Montserrat",
            width: 194.0171358272786,
            textAlign: "center",
            lineHeight: 1,
            shadow: {
                color: "rgba(204,255,0,1)",
                config: {
                    blur: "20",
                    offsetY: "0",
                    offsetX: "0",
                },
                enabled: false,
            },
            italic: false,
            left: 24.710182086360703,
            style: {
                color: "rgba(255,22,22,1)",
                type: "word_background_change",
            },
            underline: false,
            textBgColor: {
                enabled: false,
                radius: 0,
                color: "#000000",
            },
            blockBackground: {
                enabled: false,
                padding: 8,
                radius: 4,
                color: "rgba(0,0,0,0.11)",
            },
            bold: true,
            text: "I DON'T QUALIFY HIS",
            padding: {
                bottom: 0,
                right: 0,
                top: 0,
                left: 0,
            },
            noEffect: true,
            stroke: {
                color: "rgba(166,166,166,0.11)",
                config: {
                    width: 3,
                },
                enabled: false,
            },
            fontSize: 20.8715953307393,
            fill: "rgba(255,255,255,1)",
        },
        width: 251.4375,
        bRolls: [],
    },
    {
        createdAt: "2023-08-24T03:42:55.439Z",
        width: 289.125,
        updatedAt: "2023-08-24T03:42:55.439Z",
        height: 514,
        subtitle: {},
        texts: [
            {
                type: 'text',
                fontFamily: "Montserrat",
                end: 44330,
                fill: "#ffffff",
                effect_type: "none",
                isFullVideoLength: true,
                textTransform: "LOWERCASE",
                bold: false,
                italic: false,
                textAlign: "center",
                fontSize: "18",
                underline: false,
                left: 9.616029888838455,
                text: "Name",
                stroke: {
                    config: {
                        width: "2",
                    },
                    color: "#000000",
                    enabled: true,
                },
                duration: 44080,
                textBgColor: {
                    radius: 0,
                    color: "#000000",
                    enabled: false,
                },
                fontColor: "#ffffff",
                blockBackground: {
                    enabled: false,
                    color: "#808080",
                    radius: 4,
                    padding: 0,
                },
                start: 250,
                maxHeight: 21.6,
                padding: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
                noEffect: false,
                id: "5H17oKUNWEirgkHDTKbUB",
                effect_color: "#000000",
                top: 282.5999999999999,
                shadow: {
                    config: {
                        offsetY: "1",
                        blur: "10",
                        offsetX: "0",
                    },
                    enabled: false,
                    color: "#000000",
                },
                textBackgroundColor: "#000000",
                lineHeight: 1,
                margin: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                },
                width: 60.78558966651531,
            },
        ],
        bRolls: [],
        id: "69Xb3diUM_1QY_Ev-002G",
        name: "TiktokTemplate_1",
    },
    {
        subtitle: {
            shadow: {
                config: {
                    blur: 10,
                    offsetY: 0,
                    offsetX: 0,
                },
                enabled: false,
                color: "#000000",
            },
            noEffect: true,
            italic: false,
            fontFamily: "Montserrat",
            effect_type: "none",
            width: 208.79346199611564,
            textBgColor: {
                enabled: false,
                color: "#000000",
                radius: 0,
            },
            textAlign: "center",
            textBackgroundColor: null,
            textTransform: null,
            top: 331.1076927389574,
            left: 18.79166093061673,
            bold: false,
            effect_color: null,
            fontColor: "#ffffff",
            maxHeight: null,
            underline: false,
            fill: "#ffffff",
            stroke: {
                color: "#000000",
                enabled: false,
                config: {
                    width: 3,
                },
            },
            style: {
                type: "default",
                color: "#ffe600",
            },
            lineHeight: 1,
            text: "I remember waking up and",
            fontSize: 17.722466960352424,
            margin: {
                right: 0,
                left: 0,
                bottom: 0,
                top: 0,
            },
            blockBackground: {
                enabled: false,
                color: "#ab0000",
                padding: 0,
                radius: 4,
            },
            padding: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            },
        },
        name: "template 4 reel",
        id: "k2p-D6G01Rj6dP6Aiqjgy",
        bRolls: [
            {
                duration: null,
                top: 8.421783319310308,
                height: 447.3701240581472,
                assetType: "image",
                assetTag: "b_roll",
                url: "https://storage.googleapis.com/vidyo-bucket/image/f4b44864-74d4-45c9-afc5-a04ba7392024.png",
                width: 251.64569478270784,
                left: 0.46634012413454684,
                metaData: {
                    isTemplate: true,
                    end: 13280,
                    duration: 55.796,
                    isFullVideoLength: true,
                    isDraft: false,
                    start: 90,
                },
                id: "VsUHoX8zwANHgY-UTe4Bh",
            },
        ],
        height: 447,
        texts: [
            {
                type: 'clip_title',
                padding: {
                    right: 0,
                    left: 0,
                    bottom: 0,
                    top: 0,
                },
                bold: true,
                id: "title-text",
                textBgColor: {
                    enabled: false,
                    color: "#000000",
                    radius: 0,
                },
                fontSize: 13,
                fontFamily: "Montserrat",
                textTransform: "LOWERCASE",
                italic: false,
                width: 223.684609030837,
                left: 17.72246696035245,
                effect_color: "#000000",
                underline: false,
                lineHeight: 1,
                textAlign: "left",
                maxHeight: 31.2,
                isFullVideoLength: true,
                shadow: {
                    config: {
                        offsetY: 0,
                        offsetX: 0,
                        blur: 10,
                    },
                    enabled: false,
                    color: "#000000",
                },
                blockBackground: {
                    color: "#808080",
                    radius: 4,
                    padding: 0,
                    enabled: false,
                },
                margin: {
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                },
                start: 90,
                fill: "rgba(247,255,41,1)",
                noEffect: true,
                stroke: {
                    enabled: false,
                    color: "#000000",
                    config: {
                        width: 3,
                    },
                },
                text: "What happened to my memory after I was locked up?",
                effect_type: "none",
                end: 13280,
                textBackgroundColor: "#000000",
                duration: 13190,
                fontColor: "rgba(247,255,41,1)",
                top: 290.52282502486224,
            },
        ],
        createdAt: "2023-06-05T10:00:46.543Z",
        updatedAt: "2023-08-09T16:38:21.962Z",
        width: 251.4375,
    },
    {
        bRolls: [],
        height: 514,
        subtitle: {
            blockBackground: {
                radius: 4,
                color: "#ab0000",
                enabled: false,
                padding: 0,
            },
            effect_type: "none",
            margin: {
                right: 0,
                top: 0,
                bottom: 0,
                left: 0,
            },
            noEffect: false,
            fill: "rgba(255,222,89,1)",
            stroke: {
                config: {
                    width: 3,
                },
                enabled: true,
                color: "#000000",
            },
            effect_color: null,
            textTransform: null,
            lineHeight: 1.1,
            top: 275.2143882555739,
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
            underline: false,
            textBackgroundColor: null,
            left: 25.906162709497153,
            fontColor: "rgba(255,222,89,1)",
            italic: false,
            textAlign: "center",
            style: {
                color: "rgba(255,255,255,1)",
                type: "word_appended",
            },
            maxHeight: null,
            bold: false,
            shadow: {
                enabled: false,
                color: "#000000",
                config: {
                    offsetX: 0,
                    offsetY: 0,
                    blur: 10,
                },
            },
            fontFamily: "THEBOLDFONT",
            fontSize: 24,
            textBgColor: {
                enabled: false,
                color: "#000000",
                radius: 0,
            },
            width: 207.59383729050285,
            text: "onto driftwood that",
        },
        updatedAt: "2023-08-18T04:41:04.169Z",
        name: "Template_N_4.0",
        id: "C03Xa9XX4u2iTVwPQz7EJ",
        texts: [],
        width: 289.125,
        createdAt: "2023-08-18T04:41:04.169Z",
    },
    {
        texts: [],
        name: "template-19 reel",
        bRolls: [
            {
                width: 257.12620158699156,
                id: "XdMih3JUQPMw25W926e4-",
                duration: null,
                assetTag: "b_roll",
                url: "https://storage.googleapis.com/vidyo-bucket/image/a477898a-2345-4a1f-9328-dcca5ae258a1.png",
                top: 0.556623632881383,
                metaData: {
                    duration: 38.746,
                    isDraft: false,
                    isFullVideoLength: true,
                    start: 87388,
                    isTemplate: true,
                    end: 126134,
                },
                assetType: "image",
                height: 457.11324726576277,
                left: 0.125,
            },
        ],
        height: 454,
        updatedAt: "2023-06-24T17:32:04.248Z",
        width: 255.375,
        id: "oony2wkTU-T0jg0mVflAD",
        createdAt: "2023-06-24T17:32:04.248Z",
        subtitle: {
            left: 25.884245792308207,
            italic: false,
            text: "I will get suggestions,",
            bold: false,
            stroke: {
                enabled: false,
                color: "#000000",
                config: {
                    width: "7",
                },
            },
            top: 318.99400722021664,
            fill: "#ffffff",
            effect_color: null,
            underline: false,
            style: {
                type: "default",
                color: "rgba(178,101,255,1)",
            },
            fontColor: "#ffffff",
            textAlign: "center",
            textTransform: "LOWERCASE",
            noEffect: true,
            shadow: {
                enabled: false,
                config: {
                    offsetY: 0,
                    blur: 10,
                    offsetX: 0,
                },
                color: "#000000",
            },
            fontFamily: "GOTHICB",
            maxHeight: 49.72,
            textBackgroundColor: null,
            blockBackground: {
                color: "#808080",
                enabled: false,
            },
            width: 197.6065084153836,
            lineHeight: 1,
            fontSize: "22",
            textBgColor: {
                enabled: false,
                color: "#000000",
            },
            effect_type: "none",
        },
    },
    {
        width: 251.4375,
        updatedAt: "2023-08-31T16:14:51.129Z",
        bRolls: [],
        createdAt: "2023-08-31T16:14:51.129Z",
        texts: [],
        id: "qhaUC6Hni8aBePAKfKFqz",
        name: "InstagramTemplate_A3",
        height: 447,
        subtitle: {
            underline: false,
            maxHeight: null,
            width: 173.8166659037272,
            noEffect: false,
            text: "tools.",
            effect_color: "rgba(0,0,0,1)",
            fontSize: 20,
            stroke: {
                config: {
                    width: "1",
                },
                color: "rgba(0,0,0,1)",
                enabled: false,
            },
            lineHeight: 1,
            padding: {
                left: 0,
                bottom: 0,
                top: 0,
                right: 0,
            },
            italic: false,
            shadow: {
                config: {
                    offsetX: "1",
                    offsetY: "1",
                    blur: "0",
                },
                enabled: true,
                color: "rgba(0,0,0,1)",
            },
            textBgColor: {
                radius: 4,
                enabled: false,
                color: "rgba(245,241,243,1)",
            },
            textTransform: "LOWERCASE",
            blockBackground: {
                enabled: false,
                radius: 0,
                padding: 0,
                color: "rgba(255,255,255,1)",
            },
            bold: false,
            fontColor: "rgba(255,255,255,1)",
            fontFamily: "HelveticaNowDisplay-Bold",
            margin: {
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
            },
            style: {
                type: "word_background_change",
                color: "rgba(255,53,155,1)",
            },
            textAlign: "center",
            textBackgroundColor: null,
            top: 240.61060613605167,
            effect_type: "shadow",
            left: 38.8104170481364,
            fill: "rgba(255,255,255,1)",
        },
    },
    {
        width: 374.0625,
        bRolls: [],
        name: "template-blue-black-highlight",
        texts: [],
        updatedAt: "2023-06-25T07:57:31.516Z",
        subtitle: {
            textAlign: "center",
            underline: false,
            maxHeight: 63.279999999999994,
            fontFamily: "Montserrat-Black",
            textBgColor: {
                color: "#000000",
                enabled: false,
            },
            effect_type: "outline",
            textBackgroundColor: null,
            fontColor: "#ffffff",
            top: 419.6223127753304,
            stroke: {
                color: "#000000",
                enabled: true,
                config: {
                    width: "7",
                },
            },
            italic: false,
            style: {
                color: "rgba(178,101,255,1)",
                type: "random_word_color_change",
            },
            blockBackground: {
                enabled: false,
                color: "#808080",
            },
            text: "I'LL BE BREAKING DOWN",
            bold: false,
            lineHeight: 1,
            noEffect: false,
            textTransform: "UPPERCASE",
            shadow: {
                color: "#000000",
                config: {
                    offsetY: 0,
                    offsetX: 0,
                    blur: 10,
                },
                enabled: false,
            },
            effect_color: "#000000",
            width: 289.44565659962575,
            fontSize: "28",
            left: 37.308421700187125,
            fill: "#ffffff",
        },
        createdAt: "2023-06-08T08:11:13.860Z",
        id: "Fmk6lhk834JCYRARyy31V",
        height: 665,
    },
    {
        width: 255.375,
        id: "QFSrVvV1vjkpKFng4ffyo",
        updatedAt: "2023-07-26T13:52:52.956Z",
        name: "template-yKtVaqQF",
        bRolls: [],
        createdAt: "2023-04-29T10:37:17.980Z",
        texts: [
            {
                type: 'clip_title',
                underline: false,
                textBgColor: {
                    color: "#000000",
                    radius: 0,
                    enabled: false,
                },
                effect_type: "none",
                left: 29.68102641888703,
                fill: "rgba(255,255,255,1)",
                effect_color: null,
                textAlign: "left",
                fontColor: "rgba(255,255,255,1)",
                top: 22.874964245681884,
                text: "Unveiling the Truth about Scientology",
                blockBackground: {
                    radius: 4,
                    color: "rgba(0,0,0,0.48)",
                    padding: 0,
                    enabled: false,
                },
                margin: {
                    left: 0,
                    top: 0,
                    bottom: 0,
                    right: 0,
                },
                bold: true,
                textTransform: "LOWERCASE",
                isFullVideoLength: true,
                maxHeight: 38.4,
                end: 164234,
                italic: false,
                noEffect: true,
                lineHeight: 1,
                fontFamily: "Poppins",
                shadow: {
                    config: {
                        offsetY: 0,
                        blur: 10,
                        offsetX: 0,
                    },
                    color: "#000000",
                    enabled: false,
                },
                textBackgroundColor: null,
                start: 112210,
                id: "title-text",
                padding: {
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                },
                stroke: {
                    config: {
                        width: 3,
                    },
                    color: "#000000",
                    enabled: false,
                },
                fontSize: "16",
                duration: 52024,
                width: 204.50647358111297,
            },
        ],
        subtitle: {
            fontColor: "rgba(0,0,0,1)",
            maxHeight: null,
            textBackgroundColor: null,
            fontFamily: "Poppins",
            noEffect: false,
            italic: false,
            underline: false,
            left: 9.6875,
            effect_color: null,
            lineHeight: 1,
            fill: "rgba(0,0,0,1)",
            effect_type: "none",
            top: 316.99437733635403,
            textAlign: "center",
            bold: true,
            style: {
                type: "default",
                color: "#ffe600",
            },
            shadow: {
                enabled: false,
                config: {
                    blur: 10,
                    offsetX: 0,
                    offsetY: 0,
                },
                color: "#000000",
            },
            width: 232,
            text: "So I think they're going",
            margin: {
                bottom: 0,
                top: 0,
                right: 0,
                left: 0,
            },
            fontSize: "16",
            blockBackground: {
                padding: 0,
                radius: 4,
                color: "rgba(250,255,0,1)",
                enabled: false,
            },
            textTransform: null,
            padding: {
                top: 5,
                right: 5,
                left: 5,
                bottom: 5,
            },
            textBgColor: {
                color: "rgba(231,238,0,1)",
                radius: 4,
                enabled: true,
            },
            stroke: {
                config: {
                    width: 3,
                },
                enabled: false,
                color: "#000000",
            },
        },
        height: 454,
    },
    {
        texts: [
            {
                type: 'clip_title',
                end: 1558080,
                stroke: {
                    config: {
                        width: 3,
                    },
                    enabled: false,
                    color: "#000000",
                },
                text: "Investor-Founder Relationship",
                fill: "rgba(255,255,255,1)",
                underline: false,
                id: "title-text",
                textBgColor: {
                    color: "#000000",
                    enabled: false,
                },
                lineHeight: 1,
                blockBackground: {
                    color: "rgba(0,0,0,0.48)",
                    enabled: false,
                },
                bold: true,
                fontColor: "rgba(255,255,255,1)",
                shadow: {
                    color: "#000000",
                    enabled: false,
                    config: {
                        offsetX: 0,
                        blur: 10,
                        offsetY: 0,
                    },
                },
                width: 252.15136872647528,
                fontFamily: "Montserrat",
                fontSize: "20",
                left: 1.6118156367623442,
                italic: false,
                duration: 56716,
                effect_type: "none",
                noEffect: true,
                maxHeight: 39.82780036968577,
                textBackgroundColor: null,
                effect_color: null,
                top: 35.1984476534296,
                textAlign: "center",
                start: 1501364,
                isFullVideoLength: false,
            },
        ],
        updatedAt: "2023-05-04T13:03:02.041Z",
        name: "template2",
        id: "UK0P6n_3IGcJXnN1--UIm",
        bRolls: [],
        width: 255.375,
        createdAt: "2023-05-04T13:03:02.041Z",
        subtitle: {
            effect_color: null,
            left: 32.13720138284691,
            blockBackground: {
                color: "#ab0000",
                enabled: false,
            },
            textBackgroundColor: null,
            top: 373.2546530165174,
            fill: "#ffffff",
            textAlign: "center",
            maxHeight: 19.209999999999997,
            text: "here's what I've done.",
            textBgColor: {
                color: "#000000",
                enabled: false,
            },
            shadow: {
                enabled: false,
                config: {
                    blur: 10,
                    offsetY: 0,
                    offsetX: 0,
                },
                color: "#000000",
            },
            italic: false,
            stroke: {
                enabled: false,
                color: "#000000",
                config: {
                    width: 3,
                },
            },
            fontColor: "#ffffff",
            style: {
                type: "default",
                color: "#ffe600",
            },
            effect_type: "none",
            bold: true,
            noEffect: true,
            underline: false,
            width: 191.10059723430618,
            fontSize: "17",
            fontFamily: "Poppins",
            lineHeight: 1,
        },
        height: 454,
    },
    {
        width: 255.375,
        updatedAt: "2023-06-24T16:52:59.920Z",
        createdAt: "2023-06-24T16:52:59.920Z",
        height: 454,
        texts: [],
        subtitle: {
            textBgColor: {
                enabled: false,
                color: "#000000",
            },
            left: 24.41108749820654,
            fill: "rgba(255,85,5,1)",
            maxHeight: 20.339999999999996,
            shadow: {
                config: {
                    offsetY: 0,
                    blur: 10,
                    offsetX: 0,
                },
                color: "#000000",
                enabled: false,
            },
            text: "got time to be listening",
            noEffect: true,
            stroke: {
                config: {
                    width: 3,
                },
                enabled: false,
                color: "#000000",
            },
            fontSize: "18",
            underline: false,
            textTransform: null,
            textBackgroundColor: null,
            textAlign: "center",
            effect_type: "none",
            lineHeight: 1,
            italic: false,
            style: {
                color: "#0000ff",
                type: "default",
            },
            blockBackground: {
                enabled: false,
                color: "#ab0000",
            },
            fontFamily: "HelveticaNowDisplay-Bold",
            bold: false,
            effect_color: null,
            fontColor: "rgba(255,85,5,1)",
            top: 361.21332611469154,
            width: 200.55282500358692,
        },
        id: "ZvmE3bMs8qmW5n7CFT4Yk",
        bRolls: [],

        name: "template-7 reel",
    },
    {
        createdAt: "2023-07-26T13:53:30.790Z",
        updatedAt: "2023-07-26T14:24:26.555Z",
        bRolls: [],
        name: "template-mU7gEacE",
        backgroundColor: "#ffffff",
        width: 255.375,
        height: 454,
        texts: [
            {
                type: 'clip_title',
                textAlign: "center",
                blockBackground: {
                    padding: 0,
                    color: "#ab0000",
                    radius: 4,
                    enabled: false,
                },
                italic: false,
                stroke: {
                    config: {
                        width: 3,
                    },
                    color: "#000000",
                    enabled: false,
                },
                textTransform: "LOWERCASE",
                duration: 26072,
                underline: false,
                textBackgroundColor: null,
                noEffect: true,
                lineHeight: 1,
                padding: {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                },
                isFullVideoLength: true,
                id: "title-text",
                effect_color: null,
                text: "Interviewing tech creators on youtube",
                maxHeight: 38.4,
                width: 221.8993183918669,
                fontSize: 16,
                shadow: {
                    enabled: false,
                    color: "#000000",
                    config: {
                        blur: 10,
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                bold: true,
                effect_type: "none",
                end: 28402,
                top: 9.593086876155258,
                start: 2330,
                fill: "#000000",
                textBgColor: {
                    color: "#000000",
                    enabled: false,
                    radius: 0,
                },
                fontFamily: "Montserrat",
                fontColor: "#000000",
                margin: {
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: 0,
                },
                left: 15.2781399195228,
            },
        ],
        id: "RCDnVroChHd5SxcYRSZuw",
        subtitle: {
            maxHeight: null,
            margin: {
                top: 0,
                bottom: 3,
                left: 0,
                right: 0,
            },
            fontFamily: "Montserrat",
            bold: true,
            top: 322.5523161408774,
            text: "You've been also andrew,",
            noEffect: false,
            underline: false,
            blockBackground: {
                padding: 0,
                enabled: false,
                color: "#ab0000",
                radius: 4,
            },
            fontSize: 16,
            fontColor: "#ffffff",
            padding: {
                bottom: 5,
                right: 5,
                left: 5,
                top: 5,
            },
            italic: false,
            fill: "#ffffff",
            lineHeight: 1,
            shadow: {
                enabled: true,
                color: "#000000",
                config: {
                    blur: 10,
                    offsetY: 0,
                    offsetX: 0,
                },
            },
            textBgColor: {
                color: "#000000",
                radius: 4,
                enabled: true,
            },
            textAlign: "center",
            stroke: {
                color: "#000000",
                config: {
                    width: 3,
                },
                enabled: false,
            },
            width: 232,
            textTransform: null,
            textBackgroundColor: null,
            effect_type: "none",
            effect_color: null,
            style: {
                color: "#ffe600",
                type: "word_color_change",
            },
            left: 10.542712243388507,
        },
    },
    {
        id: "kXPsn-7n_-73v5MyvbDIx",
        width: 251.4375,
        createdAt: "2023-08-31T16:49:18.081Z",
        subtitle: {
            shadow: {
                enabled: true,
                color: "rgba(0,0,0,1)",
                config: {
                    blur: "6",
                    offsetX: "2",
                    offsetY: "2",
                },
            },
            padding: {
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
            },
            text: "I'M SICK OF THIS,",
            effect_type: "shadow",
            margin: {
                right: 0,
                bottom: 0,
                left: 0,
                top: 0,
            },
            width: 212.07141607920337,
            noEffect: false,
            bold: true,
            blockBackground: {
                enabled: false,
                padding: 0,
                color: "rgba(255,255,255,1)",
                radius: 0,
            },
            italic: false,
            fontSize: 20,
            stroke: {
                config: {
                    width: "2",
                },
                color: "rgba(0,0,0,1)",
                enabled: true,
            },
            fontFamily: "Montserrat",
            textBackgroundColor: null,
            fill: "rgba(51,255,3,1)",
            underline: false,
            effect_color: "rgba(0,0,0,1)",
            style: {
                color: "rgba(0,105,199,1)",
                type: "default",
            },
            top: 61.042256161951855,
            maxHeight: null,
            left: 19.683041960398327,
            textTransform: "UPPERCASE",
            fontColor: "rgba(51,255,3,1)",
            textBgColor: {
                color: "rgba(15,225,208,0.83)",
                enabled: false,
                radius: 4,
            },
            lineHeight: 1,
            textAlign: "center",
        },
        updatedAt: "2023-08-31T16:49:18.081Z",
        texts: [],
        bRolls: [],
        height: 447,
        name: "InstagramTemplate_A5",
    },
    {
        createdAt: "2023-06-08T08:09:22.183Z",
        subtitle: {
            fontSize: "36",
            fill: "rgba(255,255,255,1)",
            fontFamily: "THEBOLDFONT",
            stroke: {
                color: "#000000",
                config: {
                    width: "7",
                },
                enabled: true,
            },
            top: 397.9443597624009,
            shadow: {
                config: {
                    offsetY: 0,
                    offsetX: 0,
                    blur: 10,
                },
                enabled: false,
                color: "#000000",
            },
            italic: false,
            blockBackground: {
                enabled: false,
                color: "#808080",
            },
            effect_type: "outline",
            noEffect: false,
            underline: false,
            text: "I'll be breaking down",
            fontColor: "rgba(255,255,255,1)",
            textBgColor: {
                enabled: false,
                color: "#000000",
            },
            left: 51.23297684575164,
            textBackgroundColor: null,
            effect_color: "#000000",
            style: {
                type: "random_word_color_change",
                color: "rgba(255,255,0,1)",
            },
            textTransform: "LOWERCASE",
            bold: false,
            width: 275.5965463084967,
            textAlign: "center",
            lineHeight: 1,
            maxHeight: 81.35999999999999,
        },
        texts: [],
        height: 665,
        name: "template-dabzbzW9",
        updatedAt: "2023-06-25T07:50:25.538Z",
        id: "mKGkhaU4Cv4LlrHaKaXzE",
        width: 374.0625,
        bRolls: [],
    },
    {
        width: 289.125,
        id: "JN3_hPO_yQ50-Xdv3SAjn",
        bRolls: [
            {
                url: "https://storage.googleapis.com/vidyo-bucket/image/fc77f861-a9c5-4f57-b9cd-ee01c0ca3ca1.png",
                width: 288.907647971347,
                left: -0.4761113756997588,
                duration: null,
                id: "wR-SGEg4VoB5ADb8N46cQ",
                assetType: "image",
                assetTag: "b_roll",
                metaData: {
                    isTemplate: true,
                    end: 1214900,
                    duration: 47.004,
                    isFullVideoLength: true,
                    isDraft: false,
                    start: 1164392,
                },
                top: 298.6359045899968,
                height: 43.3361471957021,
            },
        ],
        subtitle: {
            effect_color: "rgba(245,241,243,1)",
            style: {
                color: "rgba(255,77,2,1)",
                type: "word_background_change",
            },
            fontFamily: "HelveticaNowDisplay-Bold",
            padding: {
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
            },
            textTransform: "UPPERCASE",
            blockBackground: {
                padding: 5,
                color: "rgba(0,0,0,0.47)",
                enabled: false,
                radius: 0,
            },
            textAlign: "center",
            stroke: {
                enabled: false,
                color: "rgba(0,0,0,1)",
                config: {
                    width: "1",
                },
            },
            fontColor: "rgba(255,255,255,1)",
            margin: {
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
            },
            underline: false,
            noEffect: true,
            effect_type: "none",
            maxHeight: null,
            fill: "rgba(255,255,255,1)",
            textBackgroundColor: null,
            lineHeight: 1,
            width: 236.3266710917155,
            text: "PAIN IS A PREREQUISITE",
            italic: false,
            textBgColor: {
                enabled: false,
                color: "rgba(245,241,243,1)",
                radius: 4,
            },
            shadow: {
                config: {
                    offsetY: "1",
                    blur: "0",
                    offsetX: "1",
                },
                enabled: false,
                color: "rgba(0,0,0,1)",
            },
            top: 308.20397818784784,
            bold: false,
            left: 23.314377064116,
            fontSize: "16",
        },
        updatedAt: "2023-09-01T04:29:07.027Z",
        height: 514,
        name: "InstagramTemplate_A14",
        createdAt: "2023-09-01T04:29:07.027Z",
        texts: [],
    },
    {
        bRolls: [],
        id: "0eGqp-fx7EkDpGvJHuDRK",
        name: "template-M7TBRD7A",
        createdAt: "2023-08-31T17:44:59.646Z",
        subtitle: {
            fontFamily: "Poppins",
            fontColor: "rgba(253,193,21,1)",
            textAlign: "center",
            effect_type: "shadow",
            textBackgroundColor: null,
            style: {
                color: "rgba(0,105,199,1)",
                type: "default",
            },
            noEffect: false,
            effect_color: "rgba(0,0,0,1)",
            stroke: {
                enabled: true,
                config: {
                    width: "2",
                },
                color: "rgba(184,1,11,1)",
            },
            maxHeight: null,
            text: "TIMES OVER AND OVER.",
            blockBackground: {
                enabled: false,
                radius: 0,
                padding: 0,
                color: "rgba(255,255,255,1)",
            },
            margin: {
                bottom: 0,
                top: 0,
                right: 0,
                left: 0,
            },
            lineHeight: 1,
            textBgColor: {
                enabled: false,
                radius: 4,
                color: "rgba(15,225,208,0.83)",
            },
            bold: true,
            shadow: {
                enabled: true,
                color: "rgba(0,0,0,1)",
                config: {
                    offsetX: "2",
                    offsetY: "2",
                    blur: "6",
                },
            },
            fill: "rgba(253,193,21,1)",
            left: 16.64733392079671,
            textTransform: "UPPERCASE",
            top: 257.8261684105808,
            width: 218.1428321584066,
            italic: false,
            padding: {
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
            },
            fontSize: 20,
            underline: false,
        },
        height: 447,
        width: 251.4375,
        texts: [],
        updatedAt: "2023-09-19T15:03:18.155Z",
    },
    {
        updatedAt: "2023-07-26T13:56:28.037Z",
        subtitle: {
            left: 14.466943738641248,
            blockBackground: {
                padding: 0,
                enabled: false,
                color: "#ab0000",
                radius: 4,
            },
            style: {
                color: "#ffff00",
                type: "default",
            },
            textTransform: null,
            underline: false,
            padding: {
                bottom: 4,
                top: 4,
                left: 4,
                right: 4,
            },
            maxHeight: null,
            margin: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 3,
            },
            text: "because as somebody who",
            lineHeight: 1,
            fontFamily: "HelveticaNowDisplay-Bold",
            fill: "rgba(255,255,255,1)",
            stroke: {
                config: {
                    width: "2",
                },
                enabled: false,
                color: "rgba(255,255,255,1)",
            },
            shadow: {
                enabled: false,
                config: {
                    blur: 10,
                    offsetX: 0,
                    offsetY: 0,
                },
                color: "#000000",
            },
            effect_color: null,
            fontColor: "rgba(255,255,255,1)",
            italic: false,
            textBgColor: {
                color: "rgba(24,24,25,1)",
                enabled: true,
                radius: 4,
            },
            fontSize: "17",
            noEffect: false,
            textBackgroundColor: null,
            width: 221.72055626135875,
            top: 306.97827930450165,
            textAlign: "center",
            bold: false,
            effect_type: "none",
        },
        bRolls: [],
        name: "template-11 reel",
        width: 255.375,
        texts: [],
        height: 454,
        id: "Fdf1JwYmisYjYAnb7z6gj",
        createdAt: "2023-06-24T17:25:05.015Z",
    },
    {
        height: 541,
        subtitle: {
            textAlign: "center",
            fontSize: "20",
            fill: "#000000",
            left: 76.90624351783447,
            textBackgroundColor: null,
            effect_type: "shadow",
            text: "prompt. We put it again",
            italic: false,
            top: 398.7752935081319,
            bold: true,
            lineHeight: 1,
            effect_color: "#000000",
            noEffect: false,
            underline: false,
            width: 136.05423742000835,
            stroke: {
                enabled: false,
                config: {
                    width: 3,
                },
                color: "#000000",
            },
            fontFamily: "League Spartan",
            maxHeight: 45.199999999999996,
            fontColor: "#000000",
            shadow: {
                enabled: true,
                config: {
                    offsetX: 0,
                    blur: 10,
                    offsetY: 0,
                },
                color: "#000000",
            },
            style: {
                type: "word_color_change",
                color: "#0000ff",
            },
            textBgColor: {
                color: "#000000",
                enabled: false,
            },
            blockBackground: {
                color: "#ab0000",
                enabled: false,
            },
        },
        name: "template-g4JMiU6Q",
        id: "q99QxrErKciMXyFtnHJnk",
        images: [],
        backgroundColor: "#FFFFFF",
        width: 304.3125,
        texts: [
            {
                type: 'clip_title',
                left: 54.89166236958454,
                noEffect: true,
                fontFamily: "Oswald",
                fontColor: "#000000",
                textAlign: "center",
                fill: "#000000",
                fontSize: "22",
                underline: false,
                effect_color: null,
                shadow: {
                    color: "#000000",
                    config: {
                        offsetY: 0,
                        offsetX: 0,
                        blur: 10,
                    },
                    enabled: false,
                },
                lineHeight: 1,
                italic: false,
                stroke: {
                    enabled: false,
                    config: {
                        width: 3,
                    },
                    color: "#000000",
                },
                duration: 53030,
                bold: true,
                width: 180.0833997165082,
                blockBackground: {
                    color: "#ab0000",
                    enabled: false,
                },
                textBgColor: {
                    color: "#000000",
                    enabled: false,
                },
                effect_type: "none",
                top: 92.08098947168878,
                textBackgroundColor: null,
                text: "The 'Nexus Of Magic And Machines'",
                maxHeight: 49.72,
            },
        ],
    },
    {
        updatedAt: "2023-05-04T12:21:59.257Z",
        createdAt: "2023-05-04T12:21:59.257Z",
        subtitle: {
            left: 19.169981568352682,
            shadow: {
                enabled: true,
                config: {
                    offsetX: 0,
                    blur: 10,
                    offsetY: 0,
                },
                color: "#000000",
            },
            bold: false,
            lineHeight: 1,
            textBackgroundColor: null,
            text: "here is what I can do,",
            textBgColor: {
                color: "#000000",
                enabled: false,
            },
            stroke: {
                color: "#000000",
                enabled: false,
                config: {
                    width: 3,
                },
            },
            fontFamily: "Poppins",
            fill: "#ffffff",
            fontColor: "#ffffff",
            width: 217.03503686329464,
            maxHeight: 22.599999999999998,
            blockBackground: {
                color: "#ab0000",
                enabled: false,
            },
            top: 365.26042918980255,
            noEffect: false,
            italic: false,
            style: {
                color: "#ffe600",
                type: "default",
            },
            underline: false,
            fontSize: "20",
            effect_type: "shadow",
            textAlign: "center",
            effect_color: "#000000",
        },
        name: "template1",
        width: 255.375,
        bRolls: [],
        id: "kNcg09uK448Iz4tNxgvTO",
        height: 454,
        texts: [
            {
                type: 'clip_title',
                textAlign: "center",
                blockBackground: {
                    color: "rgba(0,0,0,0.48)",
                    enabled: false,
                },
                underline: false,
                textBackgroundColor: null,
                fill: "rgba(247,255,41,1)",
                effect_color: null,
                width: 252.15136872647528,
                start: 1501364,
                end: 1558080,
                fontColor: "rgba(247,255,41,1)",
                left: 1.6118156367623442,
                fontFamily: "Poppins",
                text: "Investor-Founder Relationship",
                lineHeight: 1,
                shadow: {
                    color: "#000000",
                    enabled: false,
                    config: {
                        offsetY: 0,
                        blur: 10,
                        offsetX: 0,
                    },
                },
                duration: 56716,
                isFullVideoLength: false,
                top: 31.201335740072196,
                textBgColor: {
                    color: "#000000",
                    enabled: false,
                },
                stroke: {
                    color: "#000000",
                    enabled: false,
                    config: {
                        width: 3,
                    },
                },
                noEffect: true,
                italic: false,
                bold: true,
                id: "title-text",
                maxHeight: 39.82780036968577,
                effect_type: "none",
                fontSize: "17",
            },
        ],
    },
    {
        name: "template-cRnqQV79",
        width: 251.4375,
        createdAt: "2023-07-26T14:01:41.847Z",
        id: "unPT9EQY1MToLovEnSUMk",
        subtitle: {
            text: "I don't qualify his",
            left: 13.122523393966787,
            fontColor: "#ffffff",
            fontSize: 19.691629955947135,
            bold: true,
            top: 316.6590608147436,
            textTransform: null,
            stroke: {
                color: "#000000",
                enabled: false,
                config: {
                    width: 3,
                },
            },
            effect_color: "#000000",
            fill: "#ffffff",
            padding: {
                bottom: 4,
                left: 4,
                top: 4,
                right: 4,
            },
            maxHeight: null,
            lineHeight: 1,
            shadow: {
                config: {
                    offsetY: 0,
                    blur: 10,
                    offsetX: 0,
                },
                color: "#000000",
                enabled: false,
            },
            margin: {
                top: 0,
                right: 0,
                left: 0,
                bottom: 3,
            },
            noEffect: false,
            textBgColor: {
                radius: 4,
                color: "#000000",
                enabled: true,
            },
            effect_type: "background",
            width: 216.46957462365435,
            textAlign: "center",
            textBackgroundColor: null,
            blockBackground: {
                enabled: false,
                color: "#ab0000",
                padding: 0,
                radius: 4,
            },
            italic: false,
            fontFamily: "League Spartan",
            style: {
                color: "rgba(255,222,89,1)",
                type: "word_color_change",
            },
            underline: false,
        },
        texts: [
            {
                type: 'clip_title',
                left: 21.970716059602637,
                effect_type: "none",
                stroke: {
                    color: "#000000",
                    config: {
                        width: 3,
                    },
                    enabled: false,
                },
                width: 204.25827814569539,
                start: 25628,
                end: 46086,
                fontFamily: "Montserrat",
                isFullVideoLength: true,
                maxHeight: 18.15066079295154,
                fontColor: "#000000",
                bold: true,
                duration: 20458,
                id: "title-text",
                textBackgroundColor: null,
                fontSize: 15.753303964757709,
                padding: {
                    bottom: 0,
                    right: 0,
                    left: 0,
                    top: 0,
                },
                text: "Qualifying masculinity",
                italic: false,
                underline: false,
                textBgColor: {
                    radius: 0,
                    enabled: false,
                    color: "#000000",
                },
                textAlign: "center",
                fill: "#000000",
                blockBackground: {
                    enabled: false,
                    radius: 4,
                    padding: 0,
                    color: "#ab0000",
                },
                lineHeight: 1,
                effect_color: null,
                textTransform: "LOWERCASE",
                shadow: {
                    color: "#000000",
                    enabled: false,
                    config: {
                        offsetY: 0,
                        blur: 10,
                        offsetX: 0,
                    },
                },
                noEffect: true,
                margin: {
                    bottom: 0,
                    left: 0,
                    top: 0,
                    right: 0,
                },
                top: 36.73125554090139,
            },
        ],
        updatedAt: "2023-08-22T15:53:06.861Z",
        backgroundColor: "#FFFFFF",
        bRolls: [],
        height: 447,
    },
    {
        bRolls: [],
        createdAt: "2023-08-24T12:25:40.804Z",
        updatedAt: "2023-08-24T12:25:40.804Z",
        texts: [],
        subtitle: {
            fontColor: "rgba(255,255,255,1)",
            top: 260.8228777341641,
            fontFamily: "THEBOLDFONT",
            textBgColor: {
                color: "#000000",
                enabled: false,
                radius: 0,
            },
            bold: false,
            width: 197.4133291371663,
            margin: {
                bottom: 0,
                right: 0,
                left: 0,
                top: 0,
            },
            italic: false,
            noEffect: false,
            textTransform: "UPPERCASE",
            padding: {
                bottom: 0,
                right: 0,
                left: 0,
                top: 0,
            },
            text: "HOW RICH HE IS OR HOW",
            effect_color: "#000000",
            textBackgroundColor: null,
            shadow: {
                config: {
                    blur: "5",
                    offsetY: "1",
                    offsetX: "0",
                },
                enabled: true,
                color: "#000000",
            },
            fill: "rgba(255,255,255,1)",
            underline: false,
            blockBackground: {
                color: "rgba(255,90,1,1)",
                padding: 0,
                enabled: false,
                radius: 0,
            },
            left: 27.866622862273175,
            textAlign: "center",
            effect_type: "shadow",
            maxHeight: null,
            style: {
                color: "rgba(111,17,237,1)",
                type: "word_background_change",
            },
            lineHeight: 1,
            fontSize: "22",
            stroke: {
                enabled: true,
                config: {
                    width: "1",
                },
                color: "rgba(0,0,0,1)",
            },
        },
        height: 447,
        id: "sE8v7nRyHt6qY11CMG2L4",
        width: 251.4375,
        name: "template-pkAM6RYq",
    },
    {
        id: "XGHPwk--B3Q57i_07yJeF",
        name: "YoutubeTemplate_A16",
        width: 289.125,
        createdAt: "2023-09-01T04:36:57.332Z",
        updatedAt: "2023-09-01T04:36:57.332Z",
        bRolls: [],
        subtitle: {
            style: {
                type: "two_word",
                color: "rgba(0,105,199,1)",
            },
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            left: 65.20019219551398,
            text: "YEAH.",
            lineHeight: 1,
            maxHeight: null,
            shadow: {
                enabled: true,
                color: "rgba(0,0,0,1)",
                config: {
                    offsetX: "2",
                    offsetY: "2",
                    blur: "6",
                },
            },
            textAlign: "center",
            fontColor: "rgba(255,77,2,1)",
            top: 264,
            stroke: {
                config: {
                    width: "8",
                },
                color: "rgba(255,255,255,1)",
                enabled: true,
            },
            textBgColor: {
                radius: 4,
                color: "rgba(15,225,208,0.83)",
                enabled: false,
            },
            width: 144.72461560897204,
            underline: false,
            effect_type: "shadow",
            fontFamily: "KOMIKAX_",
            blockBackground: {
                enabled: false,
                color: "rgba(255,255,255,1)",
                radius: 0,
                padding: 0,
            },
            italic: false,
            textBackgroundColor: null,
            fill: "rgba(255,77,2,1)",
            textTransform: "UPPERCASE",
            effect_color: "rgba(0,0,0,1)",
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
            fontSize: 22.997762863534675,
            noEffect: false,
            bold: false,
        },
        height: 514,
        texts: [],
    },
    {
        bRolls: [],
        texts: [],
        updatedAt: "2023-08-22T15:56:25.200Z",
        width: 251.4375,
        subtitle: {
            fontColor: "rgba(0,0,0,0.99)",
            fontSize: "20",
            fontFamily: "THEBOLDFONT",
            effect_color: "#000000",
            noEffect: false,
            fill: "rgba(0,0,0,0.99)",
            textBackgroundColor: null,
            textBgColor: {
                enabled: false,
                color: "#000000",
                radius: 0,
            },
            top: 262.82068394988625,
            width: 201.8387113352756,
            textTransform: "UPPERCASE",
            stroke: {
                config: {
                    width: 3,
                },
                enabled: false,
                color: "rgba(166,166,166,0.11)",
            },
            blockBackground: {
                color: "rgba(255,188,2,1)",
                radius: 4,
                padding: 5,
                enabled: true,
            },
            maxHeight: null,
            italic: false,
            lineHeight: 1,
            underline: false,
            margin: {
                left: 0,
                bottom: 0,
                right: 0,
                top: 0,
            },
            textAlign: "center",
            bold: false,
            padding: {
                right: 0,
                top: 0,
                left: 0,
                bottom: 0,
            },
            style: {
                color: "#ffe600",
                type: "default",
            },
            left: 23.948508037507054,
            effect_type: "none",
            shadow: {
                config: {
                    blur: "10",
                    offsetX: "0",
                    offsetY: "1",
                },
                color: "#000000",
                enabled: false,
            },
            text: "I DON'T QUALIFY HIS",
        },
        id: "EfvzuOQuSOuqQlKMUtP3L",
        name: "template-pkAM6RYq",
        height: 447,
        createdAt: "2023-08-18T05:01:53.170Z",
    },
    {
        texts: [],
        name: "template-p9fETU3E",
        createdAt: "2023-08-22T15:57:56.024Z",
        height: 447,
        updatedAt: "2023-08-22T15:57:56.024Z",
        bRolls: [],
        id: "O_lNXPyfPCJt4WdAmoeFR",
        subtitle: {
            effect_color: "#000000",
            shadow: {
                color: "#000000",
                config: {
                    blur: 10,
                    offsetY: 0,
                    offsetX: 0,
                },
                enabled: false,
            },
            textTransform: null,
            top: 279.84329837351765,
            noEffect: true,
            text: "though,",
            italic: false,
            blockBackground: {
                padding: 0,
                enabled: false,
                color: "#ab0000",
                radius: 4,
            },
            textBackgroundColor: null,
            stroke: {
                color: "#000000",
                enabled: false,
                config: {
                    width: 3,
                },
            },
            underline: false,
            width: 201.8387113352756,
            fontSize: "22",
            fill: "#ffffff",
            effect_type: "none",
            fontColor: "#ffffff",
            margin: {
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            },
            textAlign: "center",
            lineHeight: 1,
            maxHeight: null,
            style: {
                color: "#ffffff",
                type: "word_appended",
            },
            left: 15.613279956235601,
            bold: true,
            fontFamily: "Roboto Slab",
            padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            },
            textBgColor: {
                color: "#000000",
                enabled: false,
                radius: 0,
            },
        },
        width: 251.4375,
    },
    {
        createdAt: "2023-08-31T17:40:53.666Z",
        name: "template-GGtdkNUw",
        height: 447,
        bRolls: [],
        updatedAt: "2023-08-31T17:40:53.666Z",
        id: "5783hGn8af4aSdXHVbrcf",
        subtitle: {
            italic: false,
            noEffect: false,
            bold: true,
            maxHeight: null,
            stroke: {
                color: "rgba(0,0,0,1)",
                config: {
                    width: "4",
                },
                enabled: false,
            },
            width: 181.06668856886338,
            textBgColor: {
                color: "rgba(0,0,0,0.51)",
                radius: 5,
                enabled: false,
            },
            textBackgroundColor: null,
            shadow: {
                color: "#000000",
                enabled: false,
                config: {
                    offsetX: "1",
                    blur: "0",
                    offsetY: "1",
                },
            },
            textAlign: "center",
            fontColor: "rgba(255,255,255,1)",
            top: 263.8195870577473,
            effect_type: "none",
            text: "For creators like you",
            underline: false,
            textTransform: "LOWERCASE",
            blockBackground: {
                enabled: true,
                color: "rgba(39,38,39,1)",
                padding: 6,
                radius: 2,
            },
            effect_color: "rgba(0,0,0,0.51)",
            fontSize: 20,
            fill: "rgba(255,255,255,1)",
            style: {
                type: "default",
                color: "rgba(255,239,14,1)",
            },
            left: 32.18540571556832,
            margin: {
                bottom: 0,
                right: 0,
                left: 0,
                top: 0,
            },
            lineHeight: 1,
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
            fontFamily: "League Spartan",
        },
        texts: [
            {
                type: 'text',
                end: 560570,
                blockBackground: {
                    padding: 6,
                    color: "rgba(255,255,255,1)",
                    enabled: true,
                    radius: 5,
                },
                fontSize: "20",
                fontFamily: "Montserrat",
                width: 150,
                top: 44.64808043875688,
                italic: false,
                lineHeight: 1,
                bold: true,
                textBgColor: {
                    radius: 0,
                    enabled: false,
                    color: "rgba(255,255,255,1)",
                },
                textAlign: "center",
                start: 502976,
                underline: false,
                textBackgroundColor: "#000000",
                padding: {
                    left: 0,
                    bottom: 0,
                    top: 0,
                    right: 0,
                },
                duration: 57594,
                margin: {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                },
                left: 43.17495406398615,
                isFullVideoLength: true,
                shadow: {
                    config: {
                        offsetX: "0",
                        offsetY: "1",
                        blur: "10",
                    },
                    enabled: false,
                    color: "#000000",
                },
                id: "7SFUt7Zjwm_6TYw-bJFKD",
                textTransform: "LOWERCASE",
                fontColor: "rgba(0,0,0,1)",
                effect_type: "none",
                noEffect: false,
                stroke: {
                    config: {
                        width: 3,
                    },
                    color: "#000000",
                    enabled: false,
                },
                fill: "rgba(0,0,0,1)",
                text: "Add ⬇️ Title",
                effect_color: "#000000",
                maxHeight: 30,
            },
            {
                type: 'text',
                end: 560570,
                fontFamily: "Montserrat",
                textBgColor: {
                    color: "#000000",
                    radius: 0,
                    enabled: false,
                },
                noEffect: false,
                textAlign: "center",
                left: 81.87026656398615,
                effect_color: "#000000",
                textTransform: "LOWERCASE",
                id: "u7ENibcwYdu4llcI5GIUi",
                shadow: {
                    color: "#000000",
                    config: {
                        offsetX: "0",
                        blur: "10",
                        offsetY: "1",
                    },
                    enabled: false,
                },
                fontSize: "18",
                italic: false,
                top: 75.0480804387569,
                padding: {
                    bottom: 0,
                    right: 0,
                    left: 0,
                    top: 0,
                },
                bold: true,
                fontColor: "rgba(255,255,255,1)",
                start: 502976,
                textBackgroundColor: "#000000",
                margin: {
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 0,
                },
                isFullVideoLength: true,
                effect_type: "none",
                maxHeight: 27.6,
                fill: "rgba(255,255,255,1)",
                duration: 57594,
                text: "Here",
                lineHeight: 1,
                width: 72.609375,
                underline: false,
                blockBackground: {
                    enabled: true,
                    color: "rgba(26,86,219,1)",
                    padding: 6,
                    radius: 4,
                },
                stroke: {
                    config: {
                        width: 3,
                    },
                    enabled: false,
                    color: "#000000",
                },
            },
        ],
        width: 251.4375,
    },
    {
        texts: [],
        height: 447,
        subtitle: {
            bold: false,
            textBackgroundColor: null,
            width: 234.73743016759775,
            stroke: {
                color: "#000000",
                config: {
                    width: 3,
                },
                enabled: true,
            },
            fontSize: 19.691629955947135,
            text: "I've got no top on.",
            padding: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            effect_type: "none",
            style: {
                type: "word_color_change",
                color: "#ffe600",
            },
            shadow: {
                color: "#000000",
                enabled: false,
                config: {
                    blur: 10,
                    offsetY: 0,
                    offsetX: 0,
                },
            },
            effect_color: null,
            left: 7.745242667597779,
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            lineHeight: 1.1,
            fill: "#ffffff",
            italic: false,
            textTransform: null,
            fontColor: "#ffffff",
            textBgColor: {
                enabled: false,
                radius: 0,
                color: "#000000",
            },
            noEffect: false,
            top: 274.9957812261508,
            fontFamily: "Montserrat-Black",
            underline: false,
            blockBackground: {
                enabled: false,
                padding: 0,
                radius: 4,
                color: "#ab0000",
            },
            maxHeight: null,
            textAlign: "center",
        },
        id: "nG4c2l1hitq7IZNSc2dv2",
        updatedAt: "2023-08-09T16:39:50.297Z",
        name: "template-Pg8ANAqp",
        createdAt: "2023-06-09T07:42:35.534Z",
        bRolls: [],
        width: 251.4375,
    },
    {
        width: 251.4375,
        createdAt: "2023-08-18T04:13:46.588Z",
        bRolls: [],
        updatedAt: "2023-08-22T14:23:23.061Z",
        name: "template-zLgQgihV",
        subtitle: {
            lineHeight: 1,
            fontColor: "rgba(0,0,0,1)",
            fill: "rgba(0,0,0,1)",
            bold: true,
            textBackgroundColor: null,
            effect_type: "background",
            top: 290.33611646501333,
            maxHeight: null,
            width: 177.26171123894073,
            stroke: {
                enabled: false,
                color: "rgba(255,255,255,1)",
                config: {
                    width: "1",
                },
            },
            margin: {
                bottom: 4,
                top: 0,
                right: 0,
                left: 0,
            },
            style: {
                type: "word_appended",
                color: "rgba(0,74,173,1)",
            },
            effect_color: "rgba(207,207,207,1)",
            underline: false,
            textAlign: "center",
            fontSize: "16",
            italic: false,
            padding: {
                top: 4,
                right: 4,
                bottom: 4,
                left: 4,
            },
            shadow: {
                config: {
                    offsetY: 0,
                    offsetX: 0,
                    blur: 10,
                },
                enabled: false,
                color: "#000000",
            },
            textBgColor: {
                color: "rgba(207,207,207,1)",
                enabled: true,
                radius: 4,
            },
            fontFamily: "Lora",
            textTransform: "LOWERCASE",
            noEffect: false,
            blockBackground: {
                radius: 4,
                color: "rgba(207,207,207,0.63)",
                padding: 4,
                enabled: false,
            },
            left: 24.785019455252893,
            text: "I would rather you",
        },
        texts: [],
        id: "zqsIEzi8unJQB0PSKCT1r",
        height: 447,
    },
    {
        backgroundColor: "#6096b4",
        id: "6uthsFY3CCbbpRCOZVxaZ",
        width: 255.375,
        texts: [
            {
                type: 'clip_title',
                start: 112210,
                fontFamily: "Poppins",
                textAlign: "center",
                fill: "#ffffff",
                margin: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
                padding: {
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                },
                textBgColor: {
                    enabled: false,
                    radius: 4,
                    color: "#000000",
                },
                left: 6.328021523178791,
                effect_type: "none",
                bold: true,
                fontColor: "#ffffff",
                lineHeight: 1,
                text: "Unveiling the truth about scientology",
                textTransform: "LOWERCASE",
                fontSize: 16,
                isFullVideoLength: true,
                italic: false,
                duration: 52024,
                effect_color: null,
                shadow: {
                    enabled: false,
                    config: {
                        offsetY: 0,
                        offsetX: 0,
                        blur: 10,
                    },
                    color: "#000000",
                },
                id: "title-text",
                maxHeight: 38.4,
                textBackgroundColor: null,
                blockBackground: {
                    color: "#ab0000",
                    enabled: false,
                    padding: 0,
                    radius: 4,
                },
                width: 240.8594784768212,
                underline: false,
                end: 164234,
                top: 13.380187756985482,
                noEffect: true,
                stroke: {
                    color: "#000000",
                    enabled: false,
                    config: {
                        width: 3,
                    },
                },
            },
        ],
        height: 454,
        subtitle: {
            margin: {
                bottom: 0,
                right: 0,
                left: 0,
                top: 0,
            },
            blockBackground: {
                color: "#ab0000",
                enabled: false,
                padding: 0,
                radius: 4,
            },
            shadow: {
                enabled: false,
                config: {
                    offsetX: 0,
                    offsetY: 0,
                    blur: 10,
                },
                color: "#000000",
            },
            maxHeight: null,
            left: 14.2456884657837,
            padding: {
                right: 4,
                left: 4,
                top: 4,
                bottom: 4,
            },
            textBackgroundColor: null,
            noEffect: false,
            fontColor: "#ffffff",
            fill: "#ffffff",
            textTransform: null,
            text: "that the",
            width: 221.72055626135875,
            style: {
                color: "#ffe600",
                type: "two_word",
            },
            effect_type: "none",
            textAlign: "center",
            italic: false,
            underline: false,
            effect_color: null,
            fontFamily: "Lato",
            lineHeight: 1,
            fontSize: "18",
            textBgColor: {
                color: "rgba(96,150,180,1)",
                enabled: true,
                radius: 4,
            },
            bold: true,
            stroke: {
                enabled: false,
                config: {
                    width: 3,
                },
                color: "#000000",
            },
            top: 335.36738143402073,
        },
        updatedAt: "2023-07-26T13:59:36.292Z",
        bRolls: [],
        name: "template-jxbWApJA",
        createdAt: "2023-07-26T13:59:00.143Z",
    },
    {
        bRolls: [],
        texts: [
            {
                type: 'clip_title',
                fontSize: 15.944547134935304,
                fontColor: "#fff",
                textAlign: "center",
                textBgColor: {
                    enabled: false,
                    color: "#000000",
                },
                fontFamily: "Montserrat",
                start: 157210,
                effect_color: null,
                textBackgroundColor: null,
                lineHeight: 1,
                isFullVideoLength: true,
                end: 205150,
                id: "title-text",
                blockBackground: {
                    color: "#ab0000",
                    enabled: false,
                },
                maxHeight: 36.03467652495379,
                text: "Fear of Death and Afterlife",
                fill: "#fff",
                bold: true,
                underline: false,
                noEffect: true,
                italic: false,
                width: 196.4326710816777,
                stroke: {
                    config: {
                        width: 3,
                    },
                    enabled: false,
                    color: "#000000",
                },
                effect_type: "none",
                shadow: {
                    enabled: false,
                    config: {
                        blur: 10,
                        offsetX: 0,
                        offsetY: 0,
                    },
                    color: "#000000",
                },
                duration: 47940,
                left: 28.856901191004795,
                top: 18.695028371704836,
            },
        ],
        name: "template-square-black-bg",
        subtitle: {
            text: "Yes,",
            effect_color: "#000000",
            bold: true,
            textBackgroundColor: null,
            fontSize: 19.30129390018484,
            stroke: {
                color: "#000000",
                enabled: true,
                config: {
                    width: 3,
                },
            },
            lineHeight: 1,
            width: 146.40386441661988,
            fontColor: "#ffffff",
            textBgColor: {
                color: "#000000",
                enabled: false,
            },
            shadow: {
                enabled: false,
                color: "#000000",
                config: {
                    offsetX: 0,
                    blur: 10,
                    offsetY: 0,
                },
            },
            maxHeight: 21.81046210720887,
            left: 53.871304523533695,
            textTransform: null,
            noEffect: false,
            blockBackground: {
                enabled: false,
                color: "#ab0000",
            },
            effect_type: "outline",
            top: 287.269559310274,
            style: {
                color: "#ffe600",
                type: "two_word",
            },
            underline: false,
            italic: false,
            textAlign: "center",
            fill: "#ffffff",
            fontFamily: "Averia Sans Libre",
        },
        height: 454,
        id: "qx2JE0Q5txqqglVOqQUj3",
        createdAt: "2023-06-20T13:15:50.976Z",
        updatedAt: "2023-06-20T13:15:50.976Z",
        width: 255.375,
    },
    {
        id: "0olMcbZcpK5xans6tQ1nq",
        name: "InstagramTemplate_A10",
        bRolls: [],
        updatedAt: "2023-08-31T17:11:51.662Z",
        createdAt: "2023-08-31T17:11:51.662Z",
        subtitle: {
            fontColor: "rgba(255,255,255,1)",
            fill: "rgba(255,255,255,1)",
            blockBackground: {
                color: "rgba(255,255,255,1)",
                enabled: false,
                padding: 0,
                radius: 0,
            },
            padding: {
                bottom: 0,
                right: 0,
                top: 0,
                left: 0,
            },
            textBackgroundColor: null,
            fontSize: 20,
            textBgColor: {
                color: "rgba(245,241,243,1)",
                enabled: false,
                radius: 4,
            },
            italic: false,
            top: 235.8503000376375,
            noEffect: false,
            width: 188.64285401980078,
            shadow: {
                enabled: true,
                color: "rgba(0,0,0,1)",
                config: {
                    offsetY: "1",
                    offsetX: "1",
                    blur: "2",
                },
            },
            maxHeight: null,
            effect_type: "shadow",
            margin: {
                right: 0,
                left: 0,
                bottom: 0,
                top: 0,
            },
            text: "JUST ENCOURAGE EVERYONE",
            lineHeight: 1,
            effect_color: "rgba(0,0,0,1)",
            textTransform: "UPPERCASE",
            textAlign: "center",
            underline: false,
            left: 31.39732299009961,
            bold: true,
            stroke: {
                enabled: true,
                color: "rgba(0,0,0,1)",
                config: {
                    width: "2",
                },
            },
            style: {
                type: "word_appended",
                color: "rgba(63,255,27,1)",
            },
            fontFamily: "League Spartan",
        },
        width: 251.4375,
        height: 447,
        texts: [
            {
                type: 'text',
                text: "Add title here 🚀",
                fontSize: "20",
                textBgColor: {
                    color: "#000000",
                    enabled: false,
                    radius: 0,
                },
                effect_color: "#000000",
                maxHeight: 34,
                fontFamily: "HelveticaNowDisplay-Bold",
                id: "title-text",
                stroke: {
                    enabled: false,
                    config: {
                        width: 3,
                    },
                    color: "#000000",
                },
                shadow: {
                    config: {
                        offsetY: "1",
                        offsetX: "0",
                        blur: "10",
                    },
                    color: "#000000",
                    enabled: false,
                },
                width: 192.38348595200466,
                blockBackground: {
                    radius: 4,
                    color: "rgba(255,255,255,1)",
                    padding: 10,
                    enabled: true,
                },
                top: 295.80642435207983,
                textBackgroundColor: "#000000",
                isFullVideoLength: true,
                lineHeight: 1,
                textAlign: "center",
                noEffect: false,
                start: 1517728,
                italic: false,
                duration: 53822,
                padding: {
                    bottom: 0,
                    right: 0,
                    left: 0,
                    top: 0,
                },
                margin: {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                },
                fontColor: "rgba(0,0,0,1)",
                textTransform: "LOWERCASE",
                fill: "rgba(0,0,0,1)",
                left: 25.52700702399767,
                effect_type: "none",
                end: 1571550,
                bold: false,
                underline: false,
            },
        ],
    },
].slice().sort((a, b) => order.indexOf(b.id) - order.indexOf(a.id))


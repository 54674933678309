import { IMediaOverlay, MediaOverlayTypeEnum } from "../../../types/models";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, List, Row, Typography, notification } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { Uploader } from "../../../helpers/Uploader";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  createMediaOverlay,
  getMediaOverlays,
} from "../../../redux/Slices/videoDetailsSlice";
import { IEditorMediaOverlay } from "../../../types/interfaces";
import { setMediaOverlays } from "../../../redux/Slices/clipEditingSlice";
import MediaOverlayCard from "./MediaOverlayCard";
import AudioUploadedOverlay from "./AudioUploadedOverlay";

interface IProps {
  acceptedTypes: MediaOverlayTypeEnum[];
  accepts: string;
}

const MediaOverlays = ({ acceptedTypes, accepts }: IProps) => {
  const uploadRef = useRef<HTMLInputElement>(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const { mediaOverlays, selectedClip } = useAppSelector(
    (state) => state.videoDetails
  );
  const { mediaOverlays: overlays, currentTime } = useAppSelector(
    (state) => state.clipEditing
  );
  const dispatch = useAppDispatch();

  const clipDuration = useMemo(() => {
    if (!selectedClip) return 0;
    return selectedClip.end - selectedClip.start;
  }, []);

  const onChange = (overlays: IEditorMediaOverlay[]) => {
    dispatch(setMediaOverlays(overlays));
  };

  const onCreateOverlay = async ({
    url,
    type,
    title,
  }: {
    url: string;
    type: MediaOverlayTypeEnum;
    title: string;
  }) => {
    try {
      const response = await dispatch(
        createMediaOverlay({
          url,
          type,
          title,
        })
      );
      const newOverlay = response.payload as IMediaOverlay;
      onAddOverlay(newOverlay);
    } catch (err) {}

    setUploadLoading(false);
  };

  const onAddOverlay = (mediaOverlay: IMediaOverlay) => {
    const newOverlays = overlays.slice();
    const width = 100;
    newOverlays.push({
      transform: ``,
      ...mediaOverlay,
      width,
      height: (width / mediaOverlay.width) * mediaOverlay.height,
      sourceId: mediaOverlay.id,
      id: `media_${Date.now().toString()}`,
      start: selectedClip!.start,
      end:
        [MediaOverlayTypeEnum.video, MediaOverlayTypeEnum.audio].includes(
          mediaOverlay.type
        ) &&
        selectedClip!.end - selectedClip!.start > mediaOverlay.durationSeconds
          ? mediaOverlay.durationSeconds
          : selectedClip!.end,
      fullClip: true,
      volume: mediaOverlay.type === MediaOverlayTypeEnum.audio ? 50 : 0,
    });

    onChange(newOverlays);
  };

  const onDeleteOverlay = (index: number) => {
    const newOverlays = overlays.slice();
    newOverlays.splice(index, 1);
    onChange(newOverlays);
  };

  const onUpload = (files: FileList | null) => {
    const file = files?.[0];
    if (file) {
      const uploader = new Uploader({
        contentType: file.type,
        fileName: file.name,
        file: file,
      });
      uploader
        .onComplete(async (data: any) => {
          await onCreateOverlay({
            url: data.url,
            type: file.type.split("/")[0] as MediaOverlayTypeEnum,
            title: file.name,
          });
        })
        .onError((err: any) => {
          console.log(err);
          setUploadLoading(false);
          notification.error({
            message: err.message || "Something went wrong",
          });
        });
      setUploadLoading(true);
      uploader.start();
    }
  };

  const onApplyToFullClip = (index: number) => {
    const newOverlays = overlays.slice();
    newOverlays[index] = {
      ...newOverlays[index],
      start: selectedClip!.start,
      end: clipDuration,
      fullClip: true,
    };
    onChange(newOverlays);
  };

  const onVolumeChange = (index: number, volume: number) => {
    const newOverlays = overlays.slice();
    newOverlays[index] = {
      ...newOverlays[index],
      volume,
    };
    onChange(newOverlays);
  };

  const onDisApplyToFullClip = (index: number) => {
    const newOverlays = overlays.slice();
    newOverlays[index] = {
      ...newOverlays[index],
      fullClip: false,
    };
    onChange(newOverlays);
  };

  const onMarkNow = (index: number, field: "start" | "end") => {
    const newOverlays = overlays.slice();
    console.log("Now is", currentTime);
    if (
      newOverlays[index].type === MediaOverlayTypeEnum.video &&
      field === "end" &&
      newOverlays[index].start - newOverlays[index].end < 1
    ) {
      return notification.info({
        message: "Cannot set an end of less than 1 second",
      });
    }
    newOverlays[index] = {
      ...newOverlays[index],
      [field]: currentTime,
    };
    onChange(newOverlays);
  };

  const sortedOverlays = useMemo(() => {
    return overlays
      .slice()
      .filter((overlay) => acceptedTypes.includes(overlay.type))
      .sort(
        (a, b) => acceptedTypes.indexOf(a.type) - acceptedTypes.indexOf(b.type)
      );
  }, [overlays]);

  const sortedMediaOverlays = useMemo(() => {
    return mediaOverlays
      .slice()
      .filter((overlay) => acceptedTypes.includes(overlay.type));
    // .sort(
    //   (a, b) =>
    //     acceptedTypes.indexOf(a.type) -
    //     acceptedTypes.indexOf(b.type)
    // );
  }, [mediaOverlays]);

  useEffect(() => {
    dispatch(getMediaOverlays());
  }, []);

  return (
    <div
      style={{
        width: "100%",
        padding: 10,
        overflowY: "auto",
        maxHeight: "92vh",
        overflowX: "hidden",
      }}
    >
      <input
        type="file"
        style={{ display: "none" }}
        ref={uploadRef}
        accept={accepts}
        onChange={(e) => onUpload(e.target.files)}
      />
      <List>
        {!sortedOverlays.length ? (
          <Typography.Paragraph style={{ textAlign: "center" }}>
            Looks empty here...
          </Typography.Paragraph>
        ) : null}
        {sortedOverlays.map((overlay, index) => (
          <List.Item key={`media-overlay-${overlay.id}`}>
            <MediaOverlayCard
              index={index}
              overlay={overlay}
              onApplyToFullClip={onApplyToFullClip}
              onDisApplyToFullClip={onDisApplyToFullClip}
              onDeleteOverlay={onDeleteOverlay}
              onMarkNow={onMarkNow}
              onVolumeChange={onVolumeChange}
            />
          </List.Item>
        ))}
      </List>
      {sortedMediaOverlays.length ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography.Title level={4}>Uploaded Overlays</Typography.Title>
            <Button
              type="primary"
              icon={<UploadOutlined />}
              onClick={() => uploadRef.current?.click()}
              loading={uploadLoading}
            >
              Upload
            </Button>
          </div>
          {!sortedMediaOverlays.length ? (
            <Typography.Paragraph style={{ textAlign: "center" }}>
              Looks empty here...
            </Typography.Paragraph>
          ) : null}
          <Row gutter={24}>
            {sortedMediaOverlays.map((overlay) =>
              overlay.type === MediaOverlayTypeEnum.audio ? (
                <Col
                  span={8}
                  key={`media-overlay-${overlay.id}`}
                  style={{ marginBottom: 10 }}
                >
                  <AudioUploadedOverlay
                    overlay={overlay}
                    onAddOverlay={() => onAddOverlay(overlay)}
                  />
                </Col>
              ) : (
                <Col
                  span={6}
                  key={`media-overlay-${overlay.id}`}
                  style={{ marginBottom: 10 }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => onAddOverlay(overlay)}
                    src={overlay.thumbnailUrl || overlay.url}
                    width={"100%"}
                    height={"100%"}
                  />
                </Col>
              )
            )}
          </Row>
        </div>
      ) : null}
    </div>
  );
};

export default MediaOverlays;

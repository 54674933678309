import { Button, Spin, Typography } from "antd";
import ClipEditorTabContent from "../../ClipEditorTabContent/ClipEditorTabContent";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  generateClipScenes,
  setEditorClipSceneScreens,
  updateClipSceneStatus,
} from "../../../redux/Slices/clipEditingSlice";
import { useEffect, useState } from "react";
import { ClipSceneStatusEnum } from "../../../types/models";
import SceneCard from "../../SceneCard/SceneCard";
import { IEditorClipScene, IEditorClipScreen } from "../../../types/interfaces";
import { getPersonsDimensions } from "../../../remotion/subtitles/utils";

const SceneManager = () => {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { video, selectedClip } = useAppSelector((state) => state.videoDetails);
  const { clipSceneStatus, editorClipScenes } = useAppSelector(
    (state) => state.clipEditing
  );
  const dispatch = useAppDispatch();

  const onGenerateScene = async () => {
    if (!video) return;
    setSubmitLoading(true);
    try {
      await dispatch(
        generateClipScenes({ videoId: video!.id, id: selectedClip!.id })
      );
      await dispatch(
        updateClipSceneStatus({
          videoId: video!.id,
          id: selectedClip!.id,
          videoWidth: video?.videoWidth,
          videoHeight: video?.videoHeight,
        })
      );
    } catch (err) {
      console.log(err);
    }
    setSubmitLoading(false);
  };

  const onSplitScreenChange = (sceneIndex: number, splitScreen: boolean) => {
    if (!selectedClip || !video) return;
    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;

    const scene = editorClipScenes[sceneIndex];

    let screens: IEditorClipScreen[] = [];
    if (!selectedClip?.scenes) {
      const faces = selectedClip?.facesInfo
        .slice(0, splitScreen ? 2 : 1)
        .concat(
          splitScreen && selectedClip.facesInfo.length < 2
            ? [selectedClip.facesInfo[0]]
            : []
        );

      screens = faces.map((face, index) => {
        const objectTop = selectedClip?.facesInfo && face.BoundingBox.Top;
        const objectLeft = selectedClip?.facesInfo && face.BoundingBox.Left;

        const objectWidth = selectedClip?.facesInfo && face.BoundingBox.Width;
        const objectHeight = selectedClip?.facesInfo && face.BoundingBox.Height;

        let position: "center" | "top" | "bottom" =
          faces.length === 1 ? "center" : index === 0 ? "top" : "bottom";
        // : selectedClip.facesInfo.length === 1 ? 'center' : index === 0 ? 'top' : 'bottom'
        const { translateX, translateY, scale } = getPersonsDimensions({
          videoOriginDimesnsions: {
            videoHeight,
            videoWidth,
          },
          objectTop,
          objectLeft,
          objectWidth,
          objectHeight,
          position,
        });

        const transform = `scale(${scale}, ${scale}) translate(${Math.ceil(
          translateX
        )}px, ${Math.ceil(translateY)}px) rotate(0deg)`;
        return { speakerId: index + 1, transform };
      });
    } else {
      const speakersIds = scene.speakersIds
        ?.split("_")
        .map((item) => parseInt(item));
      if (!speakersIds?.length) {
        return;
      }

      type speakerPositionType = "center" | "top" | "bottom";

      const slicedSpeakerIds = speakersIds.slice(0, splitScreen ? 2 : 1);
      if (splitScreen && slicedSpeakerIds.length === 1) {
        slicedSpeakerIds.push(slicedSpeakerIds[0]);
      }

      const speakersPositions: speakerPositionType[] = [];
      if (slicedSpeakerIds.length === 1) {
        speakersPositions.push("center");
      } else if (slicedSpeakerIds.length === 2) {
        const firstSpeaker = scene.speakers.find(
          (item) => item.id === slicedSpeakerIds[0]
        )!;
        const secondSpeaker = scene.speakers.find(
          (item) => item.id === slicedSpeakerIds[1]
        )!;
        // place the nearest to the bottom at top
        // place nearest to top at bottom
        if (firstSpeaker.y < secondSpeaker.y) {
          speakersPositions.push("top");
          speakersPositions.push("bottom");
        } else {
          speakersPositions.push("bottom");
          speakersPositions.push("top");
        }
        // check the bottom space for the top element, the top space of the bottom element
      }

      slicedSpeakerIds.forEach((speakerId, speakerIndex) => {
        const speaker = scene.speakers.find((item) => item.id === speakerId);
        if (!speaker) {
          return;
        }

        if (!scene.screens?.length) {
          scene.screens = [];
        }

        let position: speakerPositionType = speakersPositions[speakerIndex];
        // speakersIds.length === 1 ? 'center' : speakerIndex === 0 ? 'top' : 'bottom'

        const { translateX, translateY, scale } = getPersonsDimensions({
          videoOriginDimesnsions: {
            videoHeight,
            videoWidth,
          },
          objectTop: speaker.y / videoHeight,
          objectLeft: speaker.x / videoWidth,
          objectWidth: speaker.width / videoWidth,
          objectHeight: speaker.height / videoHeight,
          position,
        });
        const transform = `scale(${scale}, ${scale}) translate(${Math.ceil(
          translateX
        )}px, ${Math.ceil(translateY)}px) rotate(0deg)`;

        screens.push({
          speakerId: speaker.id,
          transform,
        });
      });
    }

    dispatch(setEditorClipSceneScreens({ sceneIndex, screens }));
  };

  useEffect(() => {
    if (
      video &&
      selectedClip &&
      [ClipSceneStatusEnum.QUEUED, ClipSceneStatusEnum.PROCESSING].includes(
        clipSceneStatus
      )
    ) {
      setLoading(true);
      const interval = setInterval(() => {
        dispatch(
          updateClipSceneStatus({
            videoId: video!.id,
            id: selectedClip!.id,
            videoWidth: video.videoWidth,
            videoHeight: video.videoHeight,
          })
        );
      }, 10000);
      return () => clearInterval(interval);
    } else {
      setLoading(false);
    }
    return () => {};
  }, [clipSceneStatus, video, selectedClip]);

  return (
    <ClipEditorTabContent title="Scene Manager">
      <div
        style={{
          width: "100%",
          padding: 10,
          overflowY: "auto",
          maxHeight: "80vh",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <>
              <Typography.Paragraph>
                <Spin size="large" />
              </Typography.Paragraph>
              <Typography.Paragraph>
                Automatically generating your scenes...
              </Typography.Paragraph>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              {editorClipScenes.map((scene, index) => (
                <div key={`${scene.speakersIds}-${index}`}>
                  <SceneCard
                    scene={scene}
                    onSplitScreenChange={(splitScreen) =>
                      onSplitScreenChange(index, splitScreen)
                    }
                  />
                </div>
              ))}
              <Button
                size="large"
                type="primary"
                onClick={onGenerateScene}
                loading={submitLoading}
              >
                Generate Scenes
              </Button>
            </div>
          )}
        </div>
      </div>
    </ClipEditorTabContent>
  );
};

export default SceneManager;

import React from "react";
import RemotionVideos from "../../../remotion/Composition";
// declare module "../../../remotion/Composition" {
//   const Composition: React.FC<any>;
//   export default Composition;
// }
// export const Composition: React.FC<any>;

const Pipelines = () => {
  return (
    <div>
      {/* <RemotionVideos /> */}
    </div>
  );
};

export default Pipelines;

import React from "react";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";

const StylesTempelate: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      className="mx-auto"
    >
      <g fill="currentColor" clipPath="url(#a)">
        <rect width="18" height="4.5" x="3" y="3" rx="1"></rect>
        <rect width="10.8" height="10.8" x="3" y="10.2" rx="1"></rect>
        <rect width="4.5" height="10.8" x="16.5" y="10.2" rx="1"></rect>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export const TempIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={StylesTempelate} {...props} />
);

import { IEditorSubtitles, ISubtitlesConfig } from "../../types/interfaces";
import { ITranscriptionWord } from "../../types/models";
import { useEffect, useMemo, useRef, useState } from "react";
import { configToBlockStyles, configToInlineStyles } from "./utils";
import MoveableElement, {
  ITransformable,
} from "../MoveableElement/MoveableElement";

interface IProps extends ITransformable {
  words: ITranscriptionWord[];
  currentTime: number;
  config: ISubtitlesConfig;
  parentRef: React.RefObject<HTMLDivElement>;
}

const StorySubtitle = ({
  words,
  currentTime,
  config,
  isSelected,
  onSelectedChange,
  heightScale,
  widthScale,
}: IEditorSubtitles) => {
  const styles = configToBlockStyles(config, { widthScale, heightScale });
  const textStyle = configToInlineStyles(config, { widthScale, heightScale });

  const subtitlesSlices = useMemo(() => {
    let nextCount = 5;
    const slices: {
      start: number;
      end: number;
      words: ITranscriptionWord[];
    }[] = [];
    let accumilator: ITranscriptionWord[] = [];
    words.forEach((word) => {
      accumilator.push(word);
      if (
        accumilator.length === nextCount ||
        (accumilator.length &&
          (word.word.includes(".") || word.word.includes(",")))
      ) {
        slices.push({
          start: accumilator[0].start,
          end: accumilator[accumilator.length - 1].end,
          words: accumilator,
        });
        if (accumilator.length <= 3) {
          nextCount = 5;
        } else if (nextCount === 5) {
          nextCount = 4;
        } else {
          nextCount = 5;
        }
        accumilator = [];
      }
    });

    if (accumilator.length) {
      slices.push({
        start: accumilator[0].start,
        end: accumilator[accumilator.length - 1].end,
        words: accumilator,
      });
    }

    return slices;
  }, [words]);

  const currentSlice = useMemo(() => {
    return subtitlesSlices.find(
      (slice) => currentTime >= slice.start && currentTime <= slice.end
    );
  }, [subtitlesSlices, currentTime]);

  const prevSlice = useMemo(() => {
    return subtitlesSlices
      .reverse()
      .find((slice) => slice.start <= currentTime);
  }, [subtitlesSlices, currentTime]);

  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    // Update the current word index based on the video's current time
    if (currentSlice) {
      const wordsInCurrentSlice = currentSlice.words;
      const currentWord = wordsInCurrentSlice.find(
        (word) => currentTime >= word.start && currentTime <= word.end
      );
      if (currentWord) {
        setCurrentWordIndex(wordsInCurrentSlice.indexOf(currentWord));
      }
    }
  }, [currentSlice, currentTime]);
  return (
    <>
      <div
        id="subtitles"
        style={{
          ...styles,
          cursor: "move",
          position: "absolute",
          left: "10%",
          right: "10%",
          top: "50%",
        }}
        onClick={(e) => {
          onSelectedChange();
        }}
      >
        <span style={{ ...textStyle }}>
          {currentSlice?.words
            .slice(0, currentWordIndex + 1)
            .map((word) => word.word)
            .join(" ")}
        </span>
      </div>
    </>
  );
};

export default StorySubtitle;

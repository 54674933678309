export const faq = [
    {
        key: 1,
        label: "How does InstaClipify work? ",
        children:
            "InstaClipify uses advanced AI algorithms to automatically analyze and extract the most engaging moments from your long-form videos. These highlights are then transformed into short-form videos suitable for platforms like Instagram Stories, YouTube Shorts, and TikTok. It's as easy as uploading your video and letting our AI do the heavy lifting.",
    },
    {
        key: 2,
        label: " Is any technical expertise required to use InstaClipify?",
        children:
            "Absolutely not! InstaClipify is designed with user-friendliness in mind. You don't need any technical skills to navigate the platform. It's intuitive and straightforward, allowing content creators of all backgrounds to make the most of their videos.",
    },
    {
        key: 3,
        label: "Can I customize the short-form videos created by InstaClipify? ",
        children:
            "Certainly! InstaClipify offers customization options, allowing you to add your personal touch to the short-form videos. You can choose templates, add captions, and tailor the content to suit your brand and style.",
    },
    {
        key: 4,
        label: "Which platforms are compatible with InstaClipify? ",
        children:
            "InstaClipify is compatible with popular platforms such as Instagram, YouTube, and TikTok. You can create content optimized for these platforms with ease.",
    },
    {
        key: 5,
        label: " Is there a free trial available?",
        children:
            "Yes, there is a free trial available. You can explore InstaClipify's features and see the transformation it can bring to your content creation process before committing to a subscription.",
    },
    {
        key: 6,
        label: "How do I get started with InstaClipify?",
        children:
            "Getting started is simple. Just click the 'Get Started' button on our landing page, and you'll be guided through the sign-up process. Once you're in, you can begin transforming your videos into viral short-form content right away.",
    },
];

export const prices = [
    {
        id: 1,
        title: "Basic",
        price: 20,
    },
    {
        id: 2,
        title: "Pro",
        price: 20,
    },
    {
        id: 3,
        title: "Premium",
        price: 20,
    },
    {
        id: 4,
        title: "Enterprise",
        price: 20,
    },
];
import { Space, Typography } from "antd";
import "./CustomLandingSection.scss";
import VideoPlaceholder from "../../assets/Icons/VideoPlaceholder";
import LandingPlayButton from "../../assets/Icons/LandingPlayButton";
import LineArrow from "../../assets/Icons/LineArrow";
import Logo from "../Header/Logo";
import InstagramIcon from "../../assets/Icons/InstagramIcon";
import FacebookIcon from "../../assets/Icons/FacebookIcon";
import TiktokIcon from "../../assets/Icons/TiktokIcon";
import YoutubeIcon from "../../assets/Icons/YoutubeIcon";
import { useCurrentWidth } from "react-socks";

const CustomLandingSection = () => {
  const width = useCurrentWidth();

  return (
    <div
      style={{
        width: "100%",
        paddingLeft: width >= 768 ? 75 : 20,
        paddingRight: width >= 768 ? 75 : 20,

        // padding: width <= 768 ? "0 75px" : "0 40px",
        marginTop: "35px",
      }}
    >
      <Space className="custom-section">
        <div className="title-wrapper">
          <Typography.Text className="strong-text">
            Create once,
          </Typography.Text>
          <Typography.Text className="section-title">
            &nbsp;publish shorts everywhere.
          </Typography.Text>
        </div>

        <Typography.Paragraph className="section-paragraph">
          Automatically create shorts & reels from your long-form videos and
          publish them to{" "}
          <Typography.Text className="youtube-highlighted">
            {" "}
            YouTube Shorts,
          </Typography.Text>{" "}
          <Typography.Text className="tiktok-highlighted">
            TikTok,
          </Typography.Text>
          <br />
          <Typography.Text className="instagram-highlighted">
            Instagram
          </Typography.Text>{" "}
          ,
          <Typography.Text className="facebook-highlighted">
            Facebook{" "}
          </Typography.Text>
          with unlimited pieces of content optimized for each platform.
        </Typography.Paragraph>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: width < 700 ? "column" : "row",
            height: width < 700 ? "440.62px" : "auto",

            justifyContent: width<700? "space-between" : "center",
            alignItems: "center",
            // paddingTop: "80px",
          }}
          className="icons-containers"
        >
          <div
            style={{
              position: "relative",
              width: "fit-content",
              height: "fit-content",
              marginRight: width <= 700 ? "0" : "35px",
            }}
          >
            <VideoPlaceholder width={width <= 880 ? "200" : "100%"} />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)", // Center the button
              }}
            >
              <LandingPlayButton />
            </div>
          </div>
          <div
            style={{
              // transform:''
              transform: width < 700 ? "rotate(90deg)" : "",
            }}
          >
            <LineArrow />
          </div>
          <div
            className="logo-container"
            style={{
              display: "flex",
              alignItems: "center",
              margin: width < 800 ? "0 0" : "0 15px",
            }}
          >
            <Logo width={width <= 880 ? 50 : 80} />
          </div>
          <div
            style={{
              // transform:''
              transform: width < 700 ? "rotate(90deg)" : "",
            }}
          >
            <LineArrow />
          </div>
          <div
            style={{
              marginLeft: width <= 700 ? "0" : "35px",
              display: "grid",
              gridTemplateColumns: "auto auto",
              gap: "15px",
            }}
          >
            <div id="instagram-gradient-container">
              <InstagramIcon />
            </div>
            <div id="facebook-container">
              <FacebookIcon width={width <= 880 ? "40" : "100%"} />
            </div>
            <div id="tiktok-container">
              <TiktokIcon width={width <= 880 ? "40" : "100%"} />
            </div>
            <div id="youtube-container">
              <YoutubeIcon width={width <= 880 ? "40" : "100%"} />
            </div>
          </div>
        </div>
      </Space>
    </div>
  );
};

export default CustomLandingSection;

import { ITranscriptionWord } from "../../types/models";
import { useMemo, useRef } from "react";
import { configToBlockStyles, configToInlineStyles } from "./utils";
import Moveable, { OnDrag, OnDragEnd, OnRotate, OnScale } from "react-moveable";
import { IEditorSubtitles, ISubtitlesConfig } from "../../types/interfaces";
import MoveableElement from "../MoveableElement/MoveableElement";
import { Sequence, useVideoConfig } from "remotion";

interface IProps {
  words: ITranscriptionWord[];
  currentTime: number;
  config: ISubtitlesConfig;
}

const SingleWordSubtitles = ({
  words,
  currentTime,
  config,
  onSelectedChange,
  isSelected,
  heightScale,
  widthScale,
}: IEditorSubtitles) => {
  const styles = configToBlockStyles(config, { widthScale, heightScale });
  const textStyle = configToInlineStyles(config, { widthScale, heightScale });

  const { fps } = useVideoConfig();

  const sortedWords = useMemo(() => {
    return words.slice().sort((a, b) => a.start - b.start);
  }, [words]);

  const currentWord = words.find((word) => {
    return word.start >= currentTime && word.end >= currentTime;
  });
  const prevWord = words
    .slice()
    .reverse()
    .find((word) => word.start <= currentTime);

  return (
    <>
      <div
        id="subtitles"
        style={{
          ...styles,
          cursor: "move",
          position: "absolute",
          left: "10%",
          right: "10%",
          top: "50%",
        }}
        onClick={(e) => {
          onSelectedChange();
        }}
      >
        {words.map((word, index) => (
          <Sequence
            layout="none"
            key={`${word.start}-${word.end}`}
            from={word.start * fps}
            durationInFrames={
              (index === words.length - 1
                ? word.end - word.start
                : words[index + 1].start - word.start) * fps
            }
          >
            <span style={{ ...textStyle }}>{word.word}</span>
          </Sequence>
        ))}
      </div>
    </>
  );
};

export default SingleWordSubtitles;

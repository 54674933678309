import React, { useEffect, useMemo, useState } from "react";
import styles from "./landing.module.scss";
import { Col, Collapse, Layout, Row, Switch, Typography } from "antd";
import AppHeader from "../../Components/Header/Header";
import LandingSection from "../../Components/LandingSection/LandingSection";
import { faq } from "../../constants/landingConstants";
import { Link } from "react-router-dom";
import { ROUTES_MAP } from "../../routes/routesMap";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchPlans } from "../../redux/Slices/BillingSlice";
import PlanCard from "../../Components/PlanCard/PlanCard";
import CustomLandingSection from "../../Components/CustomLandingSection/CustomLandingSection";
import { PLANS } from "../../constants/Plans";
import { useCurrentWidth } from "react-socks";

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;

const Landing: React.FC = () => {
  const width = useCurrentWidth();
  const [isYearlyChecked, setIsYearlyChecked] = useState(false);

  const dispatch = useAppDispatch();
  const { plans } = useAppSelector((state) => state.billing);

  const toggleYearlyChecked = () => setIsYearlyChecked(!isYearlyChecked);

  const filteredPlans = useMemo(() => {
    if (!plans) return [];
    if (isYearlyChecked) {
      return plans.filter((plan) => plan.id.includes("yearly"));
    }
    return plans.filter(
      (plan) => plan.id === "free_trial" || plan.id.includes("monthly")
    );
  }, [isYearlyChecked, plans]);

  useEffect(() => {
    dispatch(fetchPlans());
  }, []);

  return (
    <Layout className={styles.layout}>
      <AppHeader landing />
      <Content className={styles.pageContent}>
        <LandingSection
          image={"/images/sectionOneNew.png"}
          description={
            <div className={styles.section}>
              <Paragraph className={styles.seconedParagraph}>
                10X Your Audience & Reach with Shorts & Reels
              </Paragraph>
              <Typography.Title level={1} className={styles.firstTitle}>
                Convert your long videos into{" "}
                <Text className={styles.highlighted}>Viral Shorts </Text>
                With <Text className={styles.highlighted}> AI</Text>
              </Typography.Title>
              <Paragraph className={styles.thirdParagraph}>
                * Our AI will automatically extract the most exciting parts of
                your videos and turn them into shorts & reels.
                <br />
                <br />* Focus on creating great content, and with
                InstaClipify.AI, Automatically Create viral shorts & reels from
                your long-form videos.
              </Paragraph>
              <Link to={ROUTES_MAP.signUp} className={styles.startTrial}>
                Start Free Trial
              </Link>
            </div>
          }
        />
        <CustomLandingSection />
        <LandingSection
          image={"/images/creatil.png"}
          order={1}
          description={
            <div className={styles.creatilSection}>
              <div className={styles.titleWrapper}>
                <Typography.Text className={styles.strongText}>
                  Be certail,
                </Typography.Text>
                <Typography.Text className={styles.sectionTitle}>
                  &nbsp;no guess work.
                </Typography.Text>
              </div>

              <p
                style={{
                  fontSize: 16,
                }}
              >
                * Skip the guess work of deciding which part of your
                videos/podcasts can be made into shorts & reels.
              </p>
              <p
                style={{
                  fontSize: 16,
                }}
              >
                * Let the AI automatically extract the most exiciting parts of
                your content and turn them ino shorts & reels.
              </p>
            </div>
          }
        />
        <LandingSection
          image={"/images/workless.png"}
          order={1}
          description={
            <div className={styles.creatilSection}>
              <div className={styles.titleWrapper}>
                <Typography.Text className={styles.strongText}>
                  Work less,
                </Typography.Text>
                <Typography.Text className={styles.sectionTitle}>
                  &nbsp;grow more.
                </Typography.Text>
              </div>

              <p
                style={{
                  fontSize: 16,
                }}
              >
                * Eliminate time-wasting tasks of manually going through your
                videos, extracting & cutting subtitle and use our pre-built
                shorts templates
              </p>
              <p
                style={{
                  fontSize: 16,
                }}
              >
                * No complicated video editing software
              </p>

              <p
                style={{
                  fontSize: 16,
                }}
              >
                * No manual downloading of videos or live streams No manual
                uploading to multiple platforms
              </p>
            </div>
          }
        />
        <div
          style={{
            width: "100%",
            height: "275px",
            padding: "30px 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#6165DB",
          }}
        >
          <Typography.Text
            style={{
              color: "white",
              fontFamily: "Montserrat",
              fontSize: width < 700 ? "24px" : "35px",
              fontWeight: "700",
            }}
          >
            Ready to grow your audience?
          </Typography.Text>
          <Typography.Text
            style={{
              color: "white",
              fontFamily: "Montserrat",
              fontSize: width < 700 ? "16px" : "18px",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            Take InstaClipify for a test drive and create your first shorts
            collection for FREE when you start your free trial.
            <br /> No obligation. No reason not to.
          </Typography.Text>
          <Link
            to={ROUTES_MAP.signUp}
            style={{
              backgroundColor: "white",
              color: "#6165DB",
              borderRadius: "45px",
              fontWeight: "800",
              textDecoration: "none",
              width: "175px",
              height: "41px",
              padding: "10px 20px",
              textAlign: "center",
            }}
          >
            Start Free Trial
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
          className={styles.plansSectionWrapper}
        >
          <Typography.Text
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Montserrat",
            }}
          >
            Simple Pricing
          </Typography.Text>
          <Typography.Text
            style={{
              fontSize: 18,
            }}
          >
            Create your first shorts collection for{" "}
            <strong
              style={{
                fontWeight: "bold",
              }}
            >
              FREE{" "}
            </strong>
            - No credit card required
          </Typography.Text>
        </div>
        <div className={styles.plansSection}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: 10 }}>Monthly</span>{" "}
            <Switch checked={isYearlyChecked} onChange={toggleYearlyChecked} />{" "}
            <span style={{ marginLeft: 10 }}>Yearly (2 months free)</span>
          </div>
          <Row gutter={24} style={{ justifyContent: "center" }}>
            {filteredPlans.map((plan) => (
              <Col
                md={6}
                xs={24}
                key={plan.id}
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
              >
                <PlanCard plan={plan} landing />
              </Col>
            ))}
          </Row>
        </div>
        <Title className={styles.title}> FAQ </Title>
        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Collapse
            items={faq}
            // bordered={false}
            accordion
            ghost
            size="large"
            className={styles.collapse}
          />
        </div>
      </Content>
      <Layout.Footer
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        All rights reserved 2023
      </Layout.Footer>
    </Layout>
  );
};

export default Landing;

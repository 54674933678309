import Tracker from "@openreplay/tracker";

const tracker = new Tracker({
    projectKey: "xD3EE3XmpRFE5rkj7EWm",
});

let userEmail = '';
export function initSessionTracker() {
    if (process.env.NODE_ENV === "production") {
        userEmail = '';
        tracker.start();
    }
}

export function identifySessionUser(email: string) {
    if (process.env.NODE_ENV === "production" && email !== userEmail) {
        tracker.setUserID(email)
    }
}
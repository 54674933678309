import { AuthSlice } from './Slices/AuthSlice';
import { VideosSlice } from './Slices/VideosSlice';
import { BillingSlice } from './Slices/BillingSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers, configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { videoDetailsSlice } from './Slices/videoDetailsSlice';
import { ClipEditingSlice } from './Slices/clipEditingSlice';


const rootReducer = combineReducers({
    auth: AuthSlice.reducer,
    videos: VideosSlice.reducer,
    billing: BillingSlice.reducer,
    videoDetails: videoDetailsSlice.reducer,
    clipEditing: ClipEditingSlice.reducer,
});


export const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export const { forceLogout, setIsLoggedIn, clearError } = AuthSlice.actions;

export const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector


import { colors } from '../../Themes/colors';
import { CustomStyles } from '../../types/stylesTypes';


export const stylesComponent: CustomStyles = {
    contentStyle: {
        textAlign: 'center',
        minHeight: 120,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: colors.white,
        justifyContent: 'center',
        width: "100%",
        marginTop: "50px",
    },
    titleStyle: {
        marginTop: '50px',
        textAlign: 'center',
        color: colors.black,
        backgroundColor: 'white',
        fontSize: 'calc(16px + 1vw)',
    },
    formContentStyle: {
        textAlign: 'center',
        width: "100%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    submitStyle: {
        fontSize: '20px',
        fontWeight: '500',
        backgroundColor: colors.white,
        height: '40px',
        width: '30%',
        marginTop: '20px',
    }
};


export const dividerStyle = {
    margin: '10px auto',
    backgroundColor: colors.black,
    height: '1px',
    width: '30%',
    opacity: 0.3
}
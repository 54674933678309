import { getFileDuration } from './helpers';
import { axiosInstance as api } from './request'
import { retryOperation } from './retryOperation'

interface IOptions {
    chunkSize?: number
    threadsQuantity?: number;
    file: any;
    fileName: string;
    contentType: string;
}

// original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
export class Uploader {
    chunkSize: number;
    threadsQuantity: number;
    file: any;
    fileName: string;
    contentType: string;
    aborted: boolean;
    uploadedSize: number;
    progressCache: any;
    activeConnections: any;
    parts: any[];
    uploadedParts: any[];
    fileId: string | null;
    fileKey: string | null;
    onProgressFn: (data: any) => any;
    onErrorFn: (data: any) => any;
    onCompleteFn: (data: any) => any;

    constructor(options: IOptions) {
        // this must be bigger than or equal to 5MB,
        // otherwise AWS will respond with:
        // "Your proposed upload is smaller than the minimum allowed size"
        this.chunkSize = options.chunkSize || 1024 * 1024 * 10
        // number of parallel uploads
        this.threadsQuantity = Math.min(options.threadsQuantity || 3, 15)
        this.file = options.file
        this.fileName = options.fileName
        this.contentType = options.contentType
        this.aborted = false
        this.uploadedSize = 0
        this.progressCache = {}
        this.activeConnections = {}
        this.parts = []
        this.uploadedParts = []
        this.fileId = null
        this.fileKey = null
        this.onProgressFn = () => { }
        this.onErrorFn = () => { }
        this.onCompleteFn = () => { };
    }

    // starting the multipart upload request
    start() {
        this.initialize()
    }

    async initialize() {
        try {
            // initializing the multipart request
            const videoInitializationUploadInput: any = {
                fileName: this.fileName,
                contentType: this.contentType,
                durationSeconds: 0
            }

            if (this.contentType.includes('video')) {
                console.log("Getting duration", this.file);
                const durationSeconds = await getFileDuration(this.file)
                console.log("Got Duration");
                videoInitializationUploadInput.durationSeconds = durationSeconds
            }

            const initializeReponse = await api.request({
                url: "/storage/multipart/init",
                method: "POST",
                data: videoInitializationUploadInput,
            })

            const AWSFileDataOutput = initializeReponse.data

            this.fileId = AWSFileDataOutput.fileId
            this.fileKey = AWSFileDataOutput.fileKey

            // retrieving the pre-signed URLs
            const numberOfparts = Math.ceil(this.file.size / this.chunkSize)

            const AWSMultipartFileDataInput = {
                fileId: this.fileId,
                fileKey: this.fileKey,
                parts: numberOfparts,
            }

            const urlsResponse = await api.request({
                url: "/storage/multipart/generate-urls",
                method: "POST",
                data: AWSMultipartFileDataInput,
            })

            const newParts = urlsResponse.data.parts
            this.parts.push(...newParts)

            // wait for 3 seconds before starting
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(true)
                }, 3 * 1000);
            })
            this.sendNext()
        } catch (error) {
            await this.complete(error)
        }
    }

    sendNext() {
        const activeConnections = Object.keys(this.activeConnections).length

        if (activeConnections >= this.threadsQuantity) {
            return
        }

        if (!this.parts.length) {
            if (!activeConnections) {
                this.complete(null)
            }

            return
        }

        const part = this.parts.pop()
        if (this.file && part) {
            const sentSize = (part.PartNumber - 1) * this.chunkSize
            const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)

            const sendChunkStarted = () => {
                this.sendNext()
            }

            this.sendChunk(chunk, part, sendChunkStarted)
                .then(() => {
                    this.sendNext()
                })
                .catch((error) => {
                    this.parts.push(part)

                    this.complete(error)
                })
        }
    }

    // terminating the multipart upload request on success or failure
    async complete(error: any) {
        if (error && !this.aborted) {
            this.onErrorFn(error)
            return
        }

        if (error) {
            this.onErrorFn(error)
            return
        }

        try {
            await this.sendCompleteRequest()
        } catch (error) {
            this.onErrorFn(error)
        }
    }

    // finalizing the multipart upload request on success by calling
    // the finalization API
    async sendCompleteRequest() {
        if (this.fileId && this.fileKey) {
            const videoFinalizationMultiPartInput = {
                fileId: this.fileId,
                fileKey: this.fileKey,
                parts: this.uploadedParts,
            }

            const response = await api.request({
                url: "/storage/multipart/finalize",
                method: "POST",
                data: videoFinalizationMultiPartInput,
            })

            this.onCompleteFn(response.data)
        }
    }

    sendChunk(chunk: any, part: number, sendChunkStarted: any) {
        return new Promise((resolve, reject) => {

            retryOperation(async () => {
                try {
                    const status = await this.upload(chunk, part, sendChunkStarted);
                    if (status !== 200) {
                        throw new Error("Failed chunk upload");
                    }
                } catch (err) {
                    throw err;
                }
            }, 1000, 10)
                .then(() => resolve(true))
                .catch((error: any) => reject(error || new Error("Something went wrong")));
        })
    }

    // calculating the current progress of the multipart upload request
    handleProgress(part: any, event: any) {
        if (this.file) {
            if (event.type === "progress" || event.type === "error" || event.type === "abort") {
                this.progressCache[part] = event.loaded
            }

            if (event.type === "uploaded") {
                this.uploadedSize += this.progressCache[part] || 0
                delete this.progressCache[part]
            }

            const inProgress = Object.keys(this.progressCache)
                .map(Number)
                .reduce((memo, id) => (memo += this.progressCache[id]), 0)

            const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

            const total = this.file.size

            const percentage = Math.round((sent / total) * 100)

            this.onProgressFn({
                sent: sent,
                total: total,
                percentage: percentage,
            })
        }
    }

    // uploading a part through its pre-signed URL
    upload(file: File, part: any, sendChunkStarted: any) {
        // uploading each part with its pre-signed URL
        return new Promise((resolve, reject) => {
            if (this.fileId && this.fileKey) {
                // - 1 because PartNumber is an index starting from 1 and not 0
                const xhr = (this.activeConnections[part.PartNumber - 1] = new XMLHttpRequest())

                sendChunkStarted()

                const progressListener = this.handleProgress.bind(this, part.PartNumber - 1)

                xhr.upload.addEventListener("progress", progressListener)

                xhr.addEventListener("error", progressListener)
                xhr.addEventListener("abort", progressListener)
                xhr.addEventListener("loadend", progressListener)

                xhr.open("PUT", part.signedUrl)

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        // retrieving the ETag parameter from the HTTP headers
                        const ETag = xhr.getResponseHeader("ETag")

                        if (ETag) {
                            const uploadedPart = {
                                PartNumber: part.PartNumber,
                                // removing the " enclosing carachters from
                                // the raw ETag
                                ETag: ETag.replace(/\"/g, ""),
                            }

                            this.uploadedParts.push(uploadedPart)

                            delete this.activeConnections[part.PartNumber - 1]
                            resolve(xhr.status)
                        }
                    } else if (xhr.status >= 400) {
                        console.log("========= Bad request ==========");
                        delete this.activeConnections[part.PartNumber - 1]
                        reject(new Error('Bad status code'))
                    }
                }

                xhr.onerror = (error) => {
                    console.log("ON ERROR")
                    delete this.activeConnections[part.PartNumber - 1]
                    reject(error)
                }

                xhr.onabort = () => {
                    delete this.activeConnections[part.PartNumber - 1]
                    reject(new Error("Upload canceled by user"))
                }

                xhr.send(file)
            }
        })
    }

    onProgress(onProgress: any) {
        this.onProgressFn = onProgress
        return this
    }

    onError(onError: any) {
        this.onErrorFn = onError
        return this
    }

    onComplete(onComplete: any) {
        this.onCompleteFn = onComplete
        return this;
    }

    abort() {
        Object.keys(this.activeConnections)
            .map(Number)
            .forEach((id) => {
                this.activeConnections[id].abort()
            })

        this.aborted = true
    }
}
import { useEffect, useMemo, useState } from "react";
import { Card, Col, Row, Switch, Typography } from "antd";
import { fetchPlans } from "../../../redux/Slices/BillingSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import PlanCard from "../../../Components/PlanCard/PlanCard";
import { IPlan } from "../../../types/models";
import "./billing.scss";

const Billing = () => {
  const { plans } = useAppSelector((state) => state.billing);
  const dispatch = useAppDispatch();
  const [isYearlyChecked, setIsYearlyChecked] = useState(false);

  const toggleYearlyChecked = () => setIsYearlyChecked(!isYearlyChecked);

  const filteredPlans = useMemo(() => {
    if (!plans) return [];
    if (isYearlyChecked) {
      return plans.filter((plan) => plan.id.includes("yearly"));
    }
    return plans.filter(
      (plan) => plan.id === "free_trial" || plan.id.includes("monthly")
    );
  }, [isYearlyChecked, plans]);

  useEffect(() => {
    dispatch(fetchPlans());
  }, []);

  return (
    <div className="billing">
      <Typography.Title level={2} style={{ marginTop: 0 }}>
        Billing
      </Typography.Title>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span style={{ marginRight: 10 }}>Monthly</span>{" "}
        <Switch checked={isYearlyChecked} onChange={toggleYearlyChecked} />{" "}
        <span style={{ marginLeft: 10 }}>Yearly (2 months free)</span>
      </div>
      <Row gutter={24} className="plans-row">
        {filteredPlans.map((plan) => (
          <Col xs={24} md={6} key={plan.id} className="plan-column">
            <PlanCard plan={plan} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Billing;

import { Button, Card, Col, Grid, Modal, Row, Space, Typography } from "antd";
import "./videos.scss";
import React, { useEffect, useState } from "react";
import UplaodVideoModal from "../../../Components/UploadVideoModal/UploadVideoModal";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import VideoCard from "../../../Components/VideoCard/VideoCard";
import {
  fetchVideos,
  onFetchVideosSuccess,
} from "../../../redux/Slices/VideosSlice";
import OtpInput from "react-otp-input";
import { forceLogout, resendVerify, verifyUser } from "../../../redux/Slices/AuthSlice";
import VideoCardSkeleton from "../../../Components/VideoCard/VideoCardSkeleton";
import { PlusOutlined } from "@ant-design/icons";

const Videos = () => {
  const [otp, setOtp] = useState("");
  const [isNewVideoOpen, setIsNewVideoOpen] = useState(false);
  const [resendSubmitted, setResendSubmitted] = useState(false);

  const { videos, nextPage, loading } = useAppSelector((state) => state.videos);
  const { user, isLoading: authLoading } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();

  const onResend = () => {
    dispatch(resendVerify());
    setResendSubmitted(true);
  };

  const onLogout = () => {
    dispatch(forceLogout())
  }

  useEffect(() => {
    dispatch(onFetchVideosSuccess({ videos: [] }));
    dispatch(fetchVideos());
  }, [dispatch]);

  return (
    <div className="videos">
      <Typography.Title level={2} style={{ marginTop: 0 }}>
        Hello, {user?.name} 👋
      </Typography.Title>
      <div className="header-wrapper">
        <Typography.Title level={2}>Your Videos</Typography.Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="large"
          onClick={() => setIsNewVideoOpen(true)}
        >
          New Video
        </Button>
      </div>
      <div className="new-video-container">
        <UplaodVideoModal
          isOpen={isNewVideoOpen}
          onClose={() => setIsNewVideoOpen(false)}
        />
      </div>

      <Modal
        title="User verification"
        centered
        open={!user?.verified}
        width={1000}
        okButtonProps={{ disabled: otp.length === 0, loading: authLoading }}
        onOk={() => dispatch(verifyUser({ verificationCode: otp }))}
        cancelButtonProps={{ disabled: otp.length === 0 }}
        onCancel={() => setOtp("")}
      >
        <Space
          style={{
            height: "200px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography.Text
            style={{
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            We Send a confirmation email for your email{" "}
            <strong>{user?.email}</strong> <br />
            Please check your inbox
            <br />
            <small>
              If you can't find the email in your inbox, please check your spam
              folder
            </small>
          </Typography.Text>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              width: "50px",
              height: "50px",
            }}
          />
          {resendSubmitted ? (
            <Typography.Paragraph style={{ color: "green", marginTop: 10 }}>
              Please check your email
            </Typography.Paragraph>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Button type="primary" onClick={onResend}>
                Resend Code
              </Button>
            </div>
          )}
              <Button type="link" onClick={onLogout}>
              Logout
              </Button>
        </Space>
      </Modal>

      <Card>
        {loading ? (
          <Row gutter={24}>
            {Array.from(new Array(8)).map((_, i) => (
              <Col
                xs={24}
                sm={8}
                md={6}
                key={`video-skeleton-${i}`}
                className="video-column"
              >
                <VideoCardSkeleton />
              </Col>
            ))}
          </Row>
        ) : videos.length ? (
          <Row gutter={24}>
            {videos.map((video) => (
              <Col
                xs={24}
                sm={8}
                md={6}
                key={video.id}
                className="video-column"
              >
                <VideoCard video={video} />
              </Col>
            ))}
          </Row>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              minHeight: "60vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography.Title level={4}>Looks Empty here</Typography.Title>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              size="large"
              onClick={() => setIsNewVideoOpen(true)}
            >
              Upload your first Video
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};

export default Videos;

import { Button, Input, Popconfirm, Popover, Typography } from "antd";
import { ITranscriptionWord } from "../../../types/models";
import { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const WordEditor = ({
  word,
  isActive,
  onChange,
}: {
  word: ITranscriptionWord;
  isActive: boolean;
  onChange: (text: string) => void;
}) => {
  const [text, setText] = useState(word.word);
  const onSave = () => onChange(text);

  const content = (
    <div>
      <Input
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        style={{ marginBottom: 5 }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button type="default" size="small" style={{ marginRight: 10 }}>
          Cancel
        </Button>
        <Button
          type="primary"
          size="small"
          disabled={word.word === text}
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
  return (
    <Popover content={content} title="Edit Word" trigger="hover">
      <span
        key={`word-${word.start}-${word.word}`}
        style={{
          display: "inline-block",
          marginRight: 5,
          color: isActive ? "#6165DB" : "black",
        }}
      >
        {word.word}
      </span>
    </Popover>
  );
};

interface IProps {
  words: ITranscriptionWord[];
  currentTime: number;
  onChange: ({ text, index }: { text: string; index: number }) => void;
  onRestoreDefaults: () => void;
}

const SubtitleEditingEditor = ({
  words,
  currentTime,
  onChange,
  onRestoreDefaults,
}: IProps) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Typography.Text>
          <ExclamationCircleOutlined /> hover a word to edit
        </Typography.Text>
        <div>
          <Popconfirm
            title="Resort Defaults"
            description="Are you sure to restore the default subtitles?"
            cancelText="No"
            okText="Yes"
            placement="left"
            onConfirm={() => onRestoreDefaults()}
          >
            <Button type="link">Restore Defaults</Button>
          </Popconfirm>
        </div>
      </div>
      <div>
        {words.map((word, index) => (
          <WordEditor
            key={`word-${word.start}-${word.word}`}
            word={word}
            isActive={currentTime >= word.start && currentTime <= word.end}
            onChange={(text) => onChange({ text, index })}
          />
        ))}
      </div>
    </div>
  );
};

export default SubtitleEditingEditor;

import { userLogin, userRegister, userVerification } from '../types/authTypes';
import ApiUrlConstans from './ApiUrlConstants';
import { axiosInstance as api } from '../helpers/request'
import { ClipProps, ITranscriptionWord, VideoDetailsTypes } from '../types/models';


class VideoDetailsApi {
    getVideoDetails(id: string) {
        return api.request<VideoDetailsTypes>({
            url: `${ApiUrlConstans.videoDetails}/${id}`,
            method: 'GET',
        })
    }

    async createClip({ videoId, start, end, title }: { videoId: string, start: number, end: number, title: string }) {
        const data = await api.post<ClipProps>(`/videos/${videoId}/clips`, { start, end, title });
        return data.data;
    }

    async deleteClip({ videoId, id }: { videoId: string, id: string }) {
        const data = await api.delete(`/videos/${videoId}/clips/${id}`);
        return data.data;
    }

    async generateClipScenes({ videoId, id }: { videoId: string, id: string }) {
        const data = await api.post(`/videos/${videoId}/clips/${id}/scenes/generate`);
        return data.data;
    }

    async getClipById({ videoId, id }: { videoId: string, id: string }) {
        const data = await api.get<ClipProps>(`/videos/${videoId}/clips/${id}`);
        return data.data;
    }

    async getSectionWords({ videoId, start, end }: { start: number, end: number, videoId: string }) {
        const data = await api.get<ITranscriptionWord[]>(`/videos/${videoId}/words?start=${start}&end=${end}`);
        return data.data;
    }
}

const videoDetailsApi = new VideoDetailsApi();

export default videoDetailsApi;
import { useRef } from "react";
import { Sequence, Video, useCurrentFrame, useVideoConfig } from "remotion";
import { ClipScene, ClipScreen } from "../../types/models";
import { applyTransformScaling } from "../subtitles/utils";
import { IEditorClipScene, IEditorClipScreen } from "../../types/interfaces";

interface IProps {
  scene: IEditorClipScene;
  onLoadedData: () => void;
  videoWidth: number;
  videoHeight: number;
  widthScale: number;
  heightScale: number;
  source: string;
  startFrom: number;
  onSelectedChange: (id: string) => void;
  sceneIndex: number;
  selectedId: string;
}

interface IScreenProps {
  screen: IEditorClipScreen;
  onLoadedData: () => void;
  videoWidth: number;
  videoHeight: number;
  widthScale: number;
  heightScale: number;
  source: string;
  startFrom: number;
  onSelectedChange: () => void;
  isSelected: boolean;
  isMuted: boolean;
  id: string;
}

const Screen = ({
  heightScale,
  widthScale,
  videoWidth,
  videoHeight,
  onLoadedData,
  onSelectedChange,
  screen,
  source,
  startFrom,
  isSelected,
  isMuted,
  id,
}: IScreenProps) => {
  const { fps } = useVideoConfig();

  return (
    <>
      <Video
        id={id}
        onLoadedData={() => onLoadedData()}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: videoWidth * widthScale!,
          height: videoHeight * heightScale!,
          cursor: "move",
          transform: applyTransformScaling(screen.transform, {
            widthScale,
            heightScale,
          }),
          transformOrigin: "left top",
        }}
        muted={isMuted}
        startFrom={startFrom * fps}
        src={source}
        onClick={() => onSelectedChange()}
      />
    </>
  );
};

const SceneOverlay = ({
  scene,
  onLoadedData,
  videoWidth,
  videoHeight,
  widthScale,
  heightScale,
  source,
  startFrom,
  onSelectedChange,
  sceneIndex,
  selectedId,
}: IProps) => {
  const { fps } = useVideoConfig();
  const currentFrame = useCurrentFrame();

  return (
    <>
      {scene.screens.map((screen, index) => {
        const skipDuration = 2;
        const canSkipSecond = scene.start - skipDuration > 0;

        return (
          <Sequence
            key={`original_video_${sceneIndex}_${index}`}
            from={
              !canSkipSecond
                ? scene.start * fps
                : (scene.start - skipDuration) * fps
            }
            durationInFrames={
              !canSkipSecond
                ? (scene.end - scene.start) * fps
                : (scene.end - scene.start + skipDuration) * fps
            }
            layout="none"
          >
            <Video
              id={`original_video_${sceneIndex}_${index}`}
              onLoadedData={() => onLoadedData()}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: videoWidth * widthScale!,
                height: videoHeight * heightScale!,
                cursor: "move",
                transform: applyTransformScaling(screen.transform, {
                  widthScale,
                  heightScale,
                }),
                transformOrigin: "left top",
                ...(canSkipSecond && currentFrame < scene.start * fps
                  ? { zIndex: -100, visibility: "hidden" }
                  : {}),
              }}
              muted={
                index !== 0 ||
                (canSkipSecond && currentFrame < scene.start * fps)
              }
              startFrom={
                !canSkipSecond
                  ? (startFrom + scene.start) * fps
                  : (startFrom + scene.start - skipDuration) * fps
              }
              src={source}
              onClick={() =>
                onSelectedChange(`original_video_${sceneIndex}_${index}`)
              }
            />
          </Sequence>
        );
      })}
    </>
  );
};

export default SceneOverlay;

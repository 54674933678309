import { IPlan } from "../types/models";
import { axiosInstance } from "../helpers/request";

class BillingApi {
  async getPlans() {
    const data = await axiosInstance.get<{ plans: IPlan[] }>("/billing/plans");
    return data.data;
  }

  async createCheckoutSession({ planId }: { planId: string }) {
    const data = await axiosInstance.post<{ url: string }>(
      "/billing/create-checkout-session",
      { planId }
    );

    return data.data;
  }

  async getBillingPortalSession() {
    const data = await axiosInstance.get<{ url: string }>(
      "/billing/billing-portal"
    );

    return data.data;
  }
}

const billingApi = new BillingApi();

export default billingApi;

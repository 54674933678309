import { IEditorMediaOverlay } from "../../types/interfaces";
import { useEffect, useRef, useState } from "react";
import {
  applyTransformScaling,
  configToBlockStyles,
  configToInlineStyles,
} from "../subtitles/utils";
import Moveable, { OnDrag, OnDragEnd, OnRotate, OnScale } from "react-moveable";
import { Audio } from "remotion";
import { ITransformable } from "../MoveableElement/MoveableElement";

interface IProps {
  overlay: IEditorMediaOverlay;
  currentTime: number;
}
const AudioOverlay = ({ overlay, currentTime }: IProps) => {
  const audioRef = useRef<HTMLVideoElement>(null);
  const startFrom = currentTime % overlay.durationSeconds;

  return (
    <>
      <div
        style={{
          visibility: "hidden",
          position: "absolute",
          left: -200,
          top: -200,
        }}
      >
        <Audio
          muted={overlay.volume === 0}
          volume={overlay.volume / 100}
          startFrom={startFrom}
          src={overlay.url}
          ref={audioRef}
          loop={overlay.end - overlay.start > overlay.durationSeconds}
        />
      </div>
    </>
  );
};

export default AudioOverlay;

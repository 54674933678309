export const ROUTES_MAP = {
  landing: "/",
  login: "/login",
  resetPassword: '/reset-password',
  signUp: "/signUp",
  dashboard: {
    index: "/dashboard",

    videos: `/dashboard/videos`,
    videoDetails: (id: string) => `/dashboard/videos/${id}`,
    videoEdit: (id: string, clipId: string) =>
      `/dashboard/videos/${id}/edit/${clipId}`,

    pipelines: `/dashboard/pipelines`,
    connections: `/dashboard/connections`,
    templates: `/dashboard/templates`,
    settings: {
      profile: `/dashboard/settings/profile`,
      billing: `/dashboard/settings/billing`,
    },
  },
};

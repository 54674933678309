import React from "react";
import { secondsToHHMMSS } from "../../helpers/helpers";
import { DeleteFilled } from "@ant-design/icons";
import CustomColorPicker from "../CustomColorPicker/CustomColorPicker";
import { Button } from "antd";
import "./SceneCard.scss";
import { IEditorClipScene } from "../../types/interfaces";

const SceneCard = ({
  scene,
  onSplitScreenChange,
}: {
  scene: IEditorClipScene;
  onSplitScreenChange: (splitScreen: boolean) => void;
}) => {
  const handleInnerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
  return (
    <div className="card">
      <div className="row">
        <div className="labeled-input">
          <span>Start Time</span>
          <input
            defaultValue={secondsToHHMMSS(scene.start)}
            style={{
              marginLeft: 5,
            }}
          />
        </div>
        <DeleteFilled
          style={{
            color: "red",
          }}
        />
      </div>
      <div className="row">
        <div className="labeled-Check">
          <input
            type="checkbox"
            checked={scene.splitScreen}
            onChange={() => onSplitScreenChange(!scene.splitScreen)}
            style={{
              marginRight: 5,
            }}
          />
          <span>Split Screen</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              marginRight: 5,
            }}
          >
            Scene Color
          </span>
          <CustomColorPicker
            colorValue="#FF0000"
            onChange={(value: string) => {}}
            trigger={
              <Button
                // disabled={!active}
                onClick={handleInnerClick}
                style={{
                  border: "1px solid gray",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#FF0000",
                  }}
                />
              </Button>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SceneCard;

export const pickersColors = [
    {
        id: 1, color: 'rgba(0, 0, 0, 1)'
    },
    {
        id: 2, color: 'rgba(128, 128, 128, 1)'
    },
    {
        id: 3, color: 'rgba(169, 169, 169, 1)'
    },
    {
        id: 4, color: 'rgba(211, 211, 211, 1)'
    },
    {
        id: 5, color: 'rgba(229, 226, 228, 1)'
    },
    {
        id: 6, color: 'rgba(255, 255, 255, 1)'
    },
    {
        id: 7, color: 'rgba(255, 0, 0, 1)'
    },
    {
        id: 8, color: 'rgba(255, 87, 87, 1)'
    },
    {
        id: 9, color: 'rgba(255, 102, 196, 1)'
    },
    {
        id: 10, color: 'rgba(203, 108, 230, 1)'
    },
    {
        id: 11, color: 'rgba(140, 82, 255, 1)'
    },
    {
        id: 12, color: 'rgba(94, 23, 235, 1)'
    },
    {
        id: 13, color: 'rgba(3, 152, 158, 1)'
    },
    {
        id: 14, color: 'rgba(0, 194, 203, 1)'
    },
    {
        id: 15, color: 'rgba(92, 225, 230, 1)'
    },
    {
        id: 16, color: 'rgba(55, 145, 77, 1)'
    },
    {
        id: 17, color: 'rgba(82, 113, 255, 1)'
    },
    {
        id: 18, color: 'rgba(0, 74, 173, 1)'
    },
    {
        id: 19, color: 'rgba(0, 128, 55, 1)'
    },
    {
        id: 20, color: 'rgba(126, 217, 87, 1)'
    },
    {
        id: 21, color: 'rgba(201, 226, 101, 1)'
    },
    {
        id: 22, color: 'rgba(255, 222, 89, 1)'
    },
    {
        id: 23, color: 'rgba(255, 189, 89, 1)'
    },
    {
        id: 24, color: 'rgba(255, 145, 77, 1)'
    }
]

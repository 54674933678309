export enum StatusEnum {
    QUEUED = 'QUEUED',
    PROCESSING = 'PROCESSING',
    FAILED = 'FAILED',
    COMPLETED = 'COMPLETED'
}


export interface IVideo {
    id: string;
    userId: string;
    durationSeconds: number;
    youtubeId: string;
    videoUrl: string;
    thumbnailUrl?: string;
    transcriptUrl: string;
    type: 'url';
    title: string;
    status: StatusEnum,
    createdAt: number;
    videoWidth: number;
    videoHeight: number;
}

export interface IClipsExport {
    id: string;
    userId: string;
    videoId: string;
    url: string;
    status: StatusEnum;
    progress: number;
    createdAt: number;
}

export interface ITranscriptionWord {
    start: number;
    end: number;
    word: string;
}

export interface IBoundingBox {
    BoundingBox: {
        Height: number;
        Width: number;
        Left: number;
        Top: number;
    }
}
export interface ClipProps {
    id: string;
    videoId: string;
    start: number;
    end: number;
    topic: string;
    sentence: string;
    createdAt: number;
    thumbnailUrl: string;
    words: ITranscriptionWord[];
    facesInfo: IBoundingBox[];
    scenesStatus: ClipSceneStatusEnum;
    scenes: ClipScene[]
}

export enum ClipSceneStatusEnum {
    NONE = "NONE",
    QUEUED = "QUEUED",
    PROCESSING = "PROCESSING",
    FAILED = "FAILED",
    COMPLETED = "COMPLETED"
}
export interface ClipScene {
    start: number;
    end: number;
    noOfSpeakers: number;
    speakersIds: string;
    speakers: ClipSceneSpeaker[]
}

export interface ClipScreen {
    transform: string;
    speakerId: number;
}

export interface ClipSceneSpeaker {
    id: number;
    x: number;
    y: number;
    width: number;
    height: number;
    frame: number;
    time: number
}

export interface VideoDetailsTypes extends IVideo {
    clips: ClipProps[]
}

export interface Subscription {
    planId: string;
    status: string;
    startDate: string;
    nextBillingDate: string;
    stripeSubscriptionId: string;
    stripePriceId: string;
    stripeStatus: string;
    tokens: number;
    cancelAt: number | null;
}

export interface User {
    id: string;
    name: string;
    email: string;
    stripeCustomerId: string;
    subscription: Subscription;
    verified: boolean;
    createdAt: number;
}
export interface RegisterResponse {
    user: User;
    authToken: string;
    verified: boolean;
}

export interface LoginResponse {
    user?: User;
    authToken: string;
}
export interface userVerificationResponse {
    verified: boolean;
}

export interface IPlan {
    id: string;
    title: string;
    description: string;
    tokens: number;
    price: number;
    connections: number;
    highlighted?: boolean;
    // features: string[]
}

export enum MediaOverlayTypeEnum {
    image = 'image',
    video = 'video',
    audio = 'audio'
}

export interface IMediaOverlay {
    id: string;
    title: string;
    type: MediaOverlayTypeEnum;
    userId: string;
    durationSeconds: number;
    url: string;
    width: number;
    height: number;
    thumbnailUrl: string;
    createdAt: number;
}
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./TextEditing.module.scss";
import "./TextEditingStyles.scss";
import {
  Breadcrumb,
  Button,
  Layout,
  Modal,
  Progress,
  Radio,
  Tabs,
  TabsProps,
  Tooltip,
  Typography,
} from "antd";
import RemotionVideos from "../../remotion/Composition";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Link, useParams } from "react-router-dom";
import {
  getMediaOverlays,
  getVideoById,
  setSelectedClip,
  setSelectedClipStartEnd,
} from "../../redux/Slices/videoDetailsSlice";
import { TempIcon } from "../../assets/Icons/stylesTempelate";
import { SubtitleIcon } from "../../assets/Icons/subtitleIcon";
import {
  DownloadOutlined,
  StarFilled,
  ScissorOutlined,
  RadarChartOutlined,
} from "@ant-design/icons";
import SubtitleLayout from "../../Components/SubtitleEditing";
import {
  addTransformationData,
  applyTemplate,
  downloadClip,
  getDownloadingClipExport,
  resetState,
  setClipScenes,
  setCurrentTime,
  setDownloadingClipExport,
  setEditorClipScenes,
  setMediaOverlays,
  setSelectedFaceIndex,
  setSelectedId,
  setSelectedQuality,
  setTextOverlays,
  setTransform,
  setWords,
} from "../../redux/Slices/clipEditingSlice";
import { PlayerRef } from "@remotion/player";
import { formatClipScenes } from "../../remotion/subtitles/utils";

import { ROUTES_MAP } from "../../routes/routesMap";
import {
  IClipsExport,
  ITranscriptionWord,
  StatusEnum,
} from "../../types/models";
import ClipEditorTimeline from "../../Components/ClipEditorTimeline/ClipEditorTimeline";
import SubtitleTempelates from "../../Components/SubtitleEditing/SubtitleTempelates/SubtitleTempelates";
import { CompositionProps } from "../../remotion/constants";
import { z } from "zod";
import {
  PLAYER_HEIGHT,
  PLAYER_HEIGHT_SCALE,
  PLAYER_HEIGHT_SCALE_DOM,
  PLAYER_WIDTH,
  PLAYER_WIDTH_SCALE,
  PLAYER_WIDTH_SCALE_DOM,
} from "../../constants/constants";
import { formatedSubtitleConfig } from "../../helpers/formatTempelates";
import SceneManager from "../../Components/SubtitleEditing/SceneManager/SceneManager";
import ClipEditorOverlaysTabs from "../../Components/ClipEditing/ClipEditorOverlaysTabs/ClipEditorOverlaysTabs";
import MoveableElement from "../../remotion/MoveableElement/MoveableElement";
import videoDetailsApi from "../../api/videoDetailsApi";

const { Content } = Layout;

const TextEditing: React.FC = () => {
  const playerRef = useRef<PlayerRef | null>(null);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("2");
  const [moveableEl, setMoveableEl] = useState<any>();

  const { user } = useAppSelector((state) => state.auth);
  const { video, selectedClip } = useAppSelector((state) => state.videoDetails);
  const {
    mediaOverlays,
    textOverlays,
    subtitlesConfig,
    words,
    selectedId,
    selectedFaceIndex,
    selectedQuality,
    downloadingClipExport,
    subtitleEffectBGColor,
    subtitleEffectColor,
    subtitleStyle,
    transform,
    currentTime,
    editorClipScenes,
  } = useAppSelector((state) => state.clipEditing);

  const params = useParams();
  const dispatch = useAppDispatch();

  const temps = useMemo(
    () => formatedSubtitleConfig({ selectedClip }),
    [selectedClip]
  );

  const hasWatermark = useMemo(
    () => user?.subscription.planId === "free_trial",
    [user]
  );

  const toggleIsDownloadOpen = () => {
    setIsDownloadOpen(!isDownloadOpen);
    dispatch(setDownloadingClipExport(null));
  };

  const onDownload = async () => {
    if (!video || !selectedClip) return;

    const remotionProps: z.infer<typeof CompositionProps> = {
      selectedFaceIndex,
      source: video!.videoUrl!,
      startFrom: selectedClip!.start,
      endTime: selectedClip!.end,
      framesPerSecond: 30,
      clipTargetsPersonsDimensions: selectedClip!.facesInfo,
      videoOriginDimesnsions: {
        videoHeight: video!.videoHeight,
        videoWidth: video!.videoWidth,
      },
      subtitlesConfig: subtitlesConfig,
      widthScale: selectedQuality / PLAYER_WIDTH_SCALE_DOM,
      heightScale: (selectedQuality * (16 / 9)) / PLAYER_HEIGHT_SCALE_DOM,
      selectedId: "",
      width: selectedQuality,
      height: selectedQuality * (16 / 9),
      subtitleStyle,
      subtitleEffectBGColor,
      subtitleEffectColor,
      onSceneScreenTransformChange: () => {},
      transform,
      onMediaOverlayTransformChange: () => {},
      onSelectedChange: () => {},
      onSubtitlesTransformChange: () => {},
      onTextOverlayTransformChange: () => {},
      hasWatermark,

      // Reset timing
      words: words.map((word) => ({
        ...word,
        start: word.start - selectedClip.start,
        end: word.end - selectedClip.start,
      })),
      textOverlays: textOverlays.map((overlay) => ({
        ...overlay,
        start: overlay.start - selectedClip.start,
        end: overlay.end - selectedClip.start,
      })),
      mediaOverlays: mediaOverlays.map((overlay) => ({
        ...overlay,
        start: overlay.start - selectedClip.start,
        end: overlay.end - selectedClip.start,
      })),
      scenes: editorClipScenes.map((scene) => ({
        ...scene,
        start: scene.start - selectedClip.start,
        end: scene.end - selectedClip.start,
      })),
    };

    try {
      setDownloadLoading(true);
      const clipExport = await dispatch(
        downloadClip({
          videoId: video?.id,
          clipId: selectedClip?.id,
          quality: selectedQuality,
          props: remotionProps,
        })
      );

      const interval = setInterval(async () => {
        const updatedClipExport = await dispatch(
          getDownloadingClipExport((clipExport.payload as IClipsExport).id)
        );

        if (
          (updatedClipExport.payload as IClipsExport).status ===
            StatusEnum.COMPLETED ||
          (updatedClipExport.payload as IClipsExport).status ===
            StatusEnum.FAILED
        ) {
          clearInterval(interval);
          setDownloadLoading(false);
        }
      }, 5 * 1000);
    } catch (err) {
      setDownloadLoading(false);
    }
  };

  const tabsItems: TabsProps["items"] = [
    {
      label: (
        <Tooltip title="Templates" placement="left">
          <TempIcon
            style={{
              width: "1.2rem",
            }}
          />
        </Tooltip>
      ),
      key: "1",
      children: <SubtitleTempelates />,
    },
    {
      label: (
        <Tooltip title="Scene Manager" placement="left">
          <ScissorOutlined
            style={{
              width: "1.2rem",
            }}
          />
        </Tooltip>
      ),
      key: "2",
      children: <SceneManager />,
    },
    {
      label: (
        <Tooltip title="Subtitles" placement="left">
          <SubtitleIcon
            style={{
              width: "1.2rem",
            }}
          />
        </Tooltip>
      ),
      key: "3",
      children: selectedClip ? (
        <SubtitleLayout currentTime={currentTime} />
      ) : null,
    },
    {
      label: (
        <Tooltip title="Overlays" placement="left">
          <RadarChartOutlined
            style={{
              fontSize: "1.2rem",
            }}
          />
        </Tooltip>
      ),
      key: "4",
      children: selectedClip ? <ClipEditorOverlaysTabs /> : null,
    },
  ];

  const onSelectedChange = (id: string) => {
    dispatch(setSelectedId(id));
  };

  const onCursorTimeChange = (time: number) => {
    playerRef.current?.seekTo(time * 30);
  };

  const onClipStartEndChange = async (start: number, end: number) => {
    dispatch(setSelectedClipStartEnd({ start, end }));
    let newWords = words.slice();
    const existingWordsTimings = newWords.map((word) => word.start);

    let allWords: ITranscriptionWord[] = await videoDetailsApi.getSectionWords({
      videoId: video!.id,
      start,
      end,
    });

    console.log({ allWords });

    newWords = newWords
      .concat(
        allWords.filter((word) => !existingWordsTimings.includes(word.start))
      )
      .sort((a, b) => a.start - b.start);

    dispatch(setWords(newWords));
  };

  const onTextOverlayTransformChange = (index: number, transform: string) => {
    const newTextOverlays = textOverlays.slice();
    newTextOverlays[index] = {
      ...newTextOverlays[index],
      config: {
        ...newTextOverlays[index].config,
        transform,
      },
    };
    dispatch(setTextOverlays(newTextOverlays));
  };

  const onMediaOverlayTransformChange = (index: number, transform: string) => {
    const newMediaOverlays = mediaOverlays.slice();
    newMediaOverlays[index] = {
      ...newMediaOverlays[index],
      transform,
    };
    dispatch(setMediaOverlays(newMediaOverlays));
  };

  const onSubtitlesTransformChange = (transform: string) => {
    dispatch(setTransform(transform));
  };

  const onTransformChange = (transform: string) => {
    if (selectedId === "subtitles") {
      return onSubtitlesTransformChange(transform);
    }
    if (selectedId.startsWith("original_video_")) {
      const [sceneIndex, screenIndex] = selectedId
        .split("original_video_")[1]
        .split("_");

      return dispatch(
        addTransformationData({
          sceneIndex: parseInt(sceneIndex),
          screenIndex: parseInt(screenIndex),
          transform,
        })
      );
    }
    if (selectedId.startsWith("text")) {
      const index = textOverlays.findIndex(
        (overlay) => overlay.id === selectedId
      );
      return onTextOverlayTransformChange(index, transform);
    }
    if (selectedId.startsWith("media")) {
      const index = mediaOverlays.findIndex(
        (overlay) => overlay.id === selectedId
      );
      return onMediaOverlayTransformChange(index, transform);
    }
  };

  useEffect(() => {
    if (params.clipId && video) {
      const targetedClip = video?.clips.find(
        (item: any) => item.id === params.clipId
      );
      if (targetedClip) {
        dispatch(setSelectedClip(targetedClip));
        dispatch(setWords(targetedClip.words));
        const videoHeight = video && video?.videoHeight;
        const videoWidth = video && video?.videoWidth;

        const scenes = formatClipScenes({
          selectedClip: targetedClip,
          videoWidth,
          videoHeight,
        });

        dispatch(setEditorClipScenes(scenes));
        dispatch(
          setClipScenes({
            clipScenes: targetedClip.scenes,
            clipSceneStatus: targetedClip.scenesStatus,
          })
        );
      }
    }
  }, [params.clipId, video]);

  useEffect(() => {
    if (
      selectedClip &&
      currentTime >= selectedClip.end &&
      playerRef.current &&
      playerRef.current.isPlaying()
    ) {
      playerRef.current.seekTo(30 * selectedClip.start);
      playerRef.current.pause();
      dispatch(setCurrentTime(selectedClip.start));
    }
  }, [currentTime, playerRef, selectedClip]);

  useEffect(() => {
    if (playerRef.current) {
      const onTimeUpdate = (data: { detail: { frame: number } }) => {
        dispatch(setCurrentTime(data.detail.frame / 30));
      };
      playerRef.current.addEventListener("timeupdate", onTimeUpdate);
      return () => {
        playerRef.current?.removeEventListener("timeupdate", onTimeUpdate);
      };
    }
    return () => {};
  }, [playerRef.current]);

  // This checks if the playing has ended
  // and resets from the start
  useEffect(() => {
    if (selectedClip && playerRef.current) {
      dispatch(setCurrentTime(selectedClip.start));
      playerRef.current.seekTo(30 * selectedClip.start);
    }
  }, [playerRef, selectedClip]);

  useEffect(() => {
    if (params.id) {
      dispatch(getVideoById(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (temps.length) {
      dispatch(applyTemplate({ id: temps[0].id, temps }));
    }
  }, [temps]);

  useEffect(() => {
    if (selectedId) {
      setMoveableEl(document.getElementById(selectedId)!);
    } else {
      setMoveableEl(null);
    }
  }, [selectedId]);

  useEffect(() => {
    dispatch(getMediaOverlays());
    dispatch(resetState());
  }, [params.clipId]);

  return (
    <Content className={styles.pageContent}>
      <div className={styles.videoDetailsWrapper}>
        <div>
          <div className={styles.videoDetailsHeader}>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link
                      to={ROUTES_MAP.dashboard.videoDetails(video?.id || "")}
                    >
                      {video?.title}
                    </Link>
                  ),
                  path: ROUTES_MAP.dashboard.videoDetails(video?.id || ""),
                },
                {
                  title: selectedClip?.topic,
                },
              ]}
            />
            <Button
              type="primary"
              onClick={toggleIsDownloadOpen}
              icon={<DownloadOutlined />}
            >
              Download
            </Button>
          </div>
          <div
            id="video-player-wrapper"
            className={styles.videoPlayerWrapper}
            onClick={() => {
              onSelectedChange("");
            }}
          >
            <div
              style={{
                overflow: "hidden",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MoveableElement
                elRef={moveableEl}
                onTransformChange={onTransformChange}
              />
              {video?.videoUrl && selectedClip && (
                <RemotionVideos
                  selectedFaceIndex={selectedFaceIndex}
                  onSelectedFaceIndexChange={(index) =>
                    dispatch(setSelectedFaceIndex(index))
                  }
                  source={video?.videoUrl && video?.videoUrl}
                  startFrom={0}
                  endTime={video.durationSeconds}
                  words={words}
                  framesPerSecond={30}
                  clipTargetsPersonsDimensions={selectedClip?.facesInfo}
                  videoOriginDimesnsions={{
                    videoHeight: video.videoHeight,
                    videoWidth: video.videoWidth,
                  }}
                  textOverlays={textOverlays}
                  mediaOverlays={mediaOverlays}
                  playerRef={playerRef}
                  selectedId={selectedId}
                  onSelectedChange={onSelectedChange}
                  onTextOverlayTransformChange={onTextOverlayTransformChange}
                  onMediaOverlayTransformChange={onMediaOverlayTransformChange}
                  onSubtitlesTransformChange={onSubtitlesTransformChange}
                  subtitlesConfig={subtitlesConfig}
                  subtitleStyle={subtitleStyle}
                  subtitleEffectBGColor={subtitleEffectBGColor}
                  subtitleEffectColor={subtitleEffectColor}
                  transform={transform}
                  width={PLAYER_WIDTH}
                  height={PLAYER_HEIGHT}
                  widthScale={PLAYER_WIDTH_SCALE}
                  heightScale={PLAYER_HEIGHT_SCALE}
                  onSceneScreenTransformChange={(
                    sceneIndex,
                    screenIndex,
                    transform
                  ) =>
                    dispatch(
                      addTransformationData({
                        sceneIndex,
                        screenIndex,
                        transform,
                      })
                    )
                  }
                  hasWatermark={false}
                  scenes={editorClipScenes}
                />
              )}
            </div>
            <div style={{ width: "100%", marginTop: 20 }}>
              <ClipEditorTimeline
                onCursorTimeChange={onCursorTimeChange}
                onClipStartEndChange={onClipStartEndChange}
              />
            </div>
          </div>
        </div>
        <div className={styles.seconedColumn}>
          <Tabs
            activeKey={activeTab}
            tabPosition={"right"}
            className={styles.tabs}
            items={tabsItems}
            onChange={(key) => setActiveTab(key)}
          />
        </div>
      </div>
      <Modal
        open={isDownloadOpen}
        onCancel={toggleIsDownloadOpen}
        okText="Export"
        title="Download Clip"
        onOk={onDownload}
        okButtonProps={{
          loading: downloadLoading,
          disabled: !!downloadingClipExport,
        }}
      >
        <div>
          <Typography.Title level={3}>Quality</Typography.Title>
          <Radio.Group
            name="quality"
            disabled={downloadLoading}
            value={selectedQuality}
            onChange={(e) => dispatch(setSelectedQuality(e.target.value))}
            style={{ fontSize: 20, marginLeft: 30 }}
          >
            <Radio value={720} style={{ fontSize: 20 }}>
              720p
            </Radio>
            <br />
            <Radio
              value={1080}
              style={{ fontSize: 20 }}
              disabled={hasWatermark || downloadLoading}
            >
              1080p{" "}
              <span
                style={{
                  display: "inline-block",
                  padding: "5px 10px",
                  backgroundColor: "#6165DB",
                  color: "white",
                  borderRadius: 10,
                  fontSize: 15,
                }}
              >
                <StarFilled style={{ fontSize: 10, marginRight: 5 }} />
                Pro
              </span>
            </Radio>
          </Radio.Group>
          {downloadingClipExport && downloadingClipExport.progress > 0 ? (
            <Progress
              percent={downloadingClipExport.progress}
              status={downloadingClipExport.url ? "success" : "active"}
            />
          ) : null}
          {downloadingClipExport &&
          downloadingClipExport.url &&
          !downloadLoading ? (
            <Typography.Paragraph style={{ textAlign: "right" }}>
              <a href={downloadingClipExport.url} target="_blank">
                Click Here to Download
              </a>
            </Typography.Paragraph>
          ) : null}
        </div>
      </Modal>
    </Content>
  );
};

export default TextEditing;

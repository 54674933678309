import { IEditorSubtitles, ISubtitlesConfig } from "../../types/interfaces";
import { ITranscriptionWord } from "../../types/models";
import { useMemo, useRef } from "react";
import { configToBlockStyles, configToInlineStyles } from "./utils";
import { Sequence, useVideoConfig } from "remotion";

const RegulerSubtitles = ({
  words,
  currentTime,
  config,
  isSelected,
  onSelectedChange,
  heightScale,
  widthScale,
}: IEditorSubtitles) => {
  const { fps } = useVideoConfig();

  const styles = configToBlockStyles(config, { widthScale, heightScale });
  const textStyle = configToInlineStyles(config, { widthScale, heightScale });

  const subtitlesSlices = useMemo(() => {
    let nextCount = 5;
    const slices: {
      start: number;
      end: number;
      words: ITranscriptionWord[];
    }[] = [];
    let accumilator: ITranscriptionWord[] = [];
    words.forEach((word) => {
      accumilator.push(word);
      if (
        accumilator.length === nextCount ||
        (accumilator.length &&
          (word.word.includes(".") || word.word.includes(",")))
      ) {
        slices.push({
          start: accumilator[0].start,
          end: accumilator[accumilator.length - 1].end,
          words: accumilator,
        });
        if (accumilator.length <= 3) {
          nextCount = 5;
        } else if (nextCount === 5) {
          nextCount = 4;
        } else {
          nextCount = 5;
        }
        accumilator = [];
      }
    });

    if (accumilator.length) {
      slices.push({
        start: accumilator[0].start,
        end: accumilator[accumilator.length - 1].end,
        words: accumilator,
      });
    }

    return slices.sort((a, b) => a.start - b.start);
  }, [words]);

  return (
    <>
      <div
        id="subtitles"
        style={{
          ...styles,
          cursor: "move",
          position: "absolute",
          left: "10%",
          right: "10%",
          top: "50%",
        }}
        onClick={(e) => {
          onSelectedChange();
        }}
      >
        {subtitlesSlices.map((slice, index) => (
          <Sequence
            layout="none"
            key={`${slice.start}-${slice.end}`}
            from={slice.start * fps}
            durationInFrames={
              (index === subtitlesSlices.length - 1
                ? slice.end - slice.start
                : subtitlesSlices[index + 1].start - slice.start) * fps
            }
          >
            <span style={{ ...textStyle }}>
              {slice.words?.map((word) => word.word).join(" ")}
            </span>
          </Sequence>
        ))}
      </div>
    </>
  );
};

export default RegulerSubtitles;

import { IEditorMediaOverlay } from "../../types/interfaces";
import { useEffect, useRef, useState } from "react";
import {
  applyTransformScaling,
  configToBlockStyles,
  configToInlineStyles,
} from "../subtitles/utils";
import Moveable, { OnDrag, OnDragEnd, OnRotate, OnScale } from "react-moveable";
import { AbsoluteFill, Video, useVideoConfig } from "remotion";
import MoveableElement, {
  ITransformable,
} from "../MoveableElement/MoveableElement";
import { Spin, Typography } from "antd";

interface IProps {
  overlay: IEditorMediaOverlay;
  currentTime: number;
  isSelected: boolean;
  onSelectedChange: (id: string) => void;
  widthScale: number;
  heightScale: number;
}
const VideoOverlay = ({
  overlay,
  currentTime,
  isSelected,
  widthScale,
  heightScale,
  onSelectedChange,
}: IProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);

  const startFrom = currentTime % overlay.durationSeconds;

  return (
    <>
      <div
        id={overlay.id}
        ref={ref}
        style={{
          transform: applyTransformScaling(overlay.transform, {
            widthScale,
            heightScale,
          }),
          cursor: "move",
          position: "absolute",
          left: "10%",
          right: "10%",
          top: "20%",
          width: overlay.width * widthScale,
          height: overlay.height * heightScale,
        }}
        onClick={(e) => {
          onSelectedChange(overlay.id);
        }}
      >
        <Video
          muted={overlay.volume === 0}
          volume={overlay.volume / 100}
          startFrom={startFrom}
          src={overlay.url}
          width="100%"
          height="100%"
          onLoadedData={() => setLoaded(true)}
          loop={overlay.end - overlay.start > overlay.durationSeconds}
        />
        {!loaded && (
          <AbsoluteFill
            style={{
              zIndex: 10,
              width: overlay.width * widthScale,
              height: overlay.height * heightScale,
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="small" />
          </AbsoluteFill>
        )}
      </div>
    </>
  );
};

export default VideoOverlay;

import React, { useEffect } from "react";
import AppHeader from "../../Components/Header/Header";
import { Layout, Typography } from "antd";
import { stylesComponent } from "./styles";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { checkLocalStorage } from "../../redux/Slices/AuthSlice";
import { useNavigate } from "react-router-dom";
import ResetPasswordForm from "../../Components/ResetPasswordForm/ResetPasswordForm";

const { Content } = Layout;
const { Title } = Typography;

const ResetPassword: React.FC = () => {
  return (
    <Layout
      style={{
        backgroundColor: "#fff",
      }}
    >
      <AppHeader />
      <Content style={stylesComponent.contentStyle}>
        <Title style={stylesComponent.titleStyle}>Forgot your password? 🤔</Title>
        <Typography.Title level={3}>Enter your email</Typography.Title>
        <Content style={stylesComponent.formContentStyle}>
          <ResetPasswordForm />
        </Content>
      </Content>
    </Layout>
  );
};

export default ResetPassword;

import React from "react";
import { AbsoluteFill, Sequence } from "remotion";

const words = [
  "Show Two",
  "words at",
  "a time",
  "so that",
  "your video",
  "feels pacey",
  "and yet",
  "doesn't feel",
  "too fast",
  "Try it",
  "out, maybe? 🤟",
];

export const SubtitleStylesCardTwoWords: React.FC = () => {
  return (
    <AbsoluteFill
      style={{
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {words.map((word, index) => {
        return (
          <Sequence key={index} from={index * 30} durationInFrames={30}>
            <div
              style={{
                fontSize: 30,
                color: "white",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {word}
            </div>
          </Sequence>
        );
      })}
    </AbsoluteFill>
  );
};

import { Typography } from "antd";
import React from "react";

const Connections = () => {
  return (
    <div style={{ padding: "1.5rem" }}>
      <Typography.Title level={2} style={{ marginTop: 0 }}>
        Coming Soon!
      </Typography.Title>
    </div>
  );
};

export default Connections;

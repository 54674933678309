import { IVideo } from "../types/models";
import { axiosInstance } from "../helpers/request";

class VideosApi {
  async createVideo({ url, title }: { url: string; title: string }) {
    const data = await axiosInstance.post<IVideo>("/videos", { url, title });
    return data.data;
  }

  async getVideos() {
    const data = await axiosInstance.get<{
      videos: IVideo[];
      nextPage?: string;
    }>("/videos");
    return data.data;
  }

  async deleteVideo({ id }: { id: string }) {
    const data = await axiosInstance.delete<IVideo>(`/videos/${id}`);
    return data.data;
  }
}

const videosApi = new VideosApi();

export default videosApi;

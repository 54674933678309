import React from "react";

const LineArrow = () => {
  return (
    <svg
      width="66"
      height="6"
      viewBox="0 0 66 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66 3.00001L61 0.113254L61 5.88676L66 3.00001ZM-4.37114e-08 3.5L61.5 3.50001L61.5 2.50001L4.37114e-08 2.5L-4.37114e-08 3.5Z"
        fill="#B3B3B3"
      />
    </svg>
  );
};

export default LineArrow;

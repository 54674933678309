import React from "react";
import { Slider, Typography } from "antd";
import EffectCard from "../EffectCard/EffectCard";
import "./SubtitleEditingBackground.scss";
import { Color } from "antd/es/color-picker";
import { ISubtitlesConfig } from "../../../types/interfaces";

const { Title } = Typography;

const RangeComponent: React.FC<{
  title: string;
  defaultValue?: number;
  maxValue: number;
  minValue?: number;
  onChange?: (value: number) => void;
}> = ({ title, defaultValue = 0, maxValue, minValue = 0, onChange }) => {
  const handleSliderChange = (value: number) => {
    if (onChange) {
      onChange(value);
    }
  };
  return (
    <div className="range-component" onClick={(e: any) => e.stopPropagation()}>
      <span>{title}</span>
      <Slider
        onChange={handleSliderChange}
        defaultValue={defaultValue}
        min={minValue}
        max={maxValue}
      />
    </div>
  );
};

const backgroundItems = [
  { id: 1, title: "Margin", max: 40, default: 0 },
  { id: 2, title: "Padding", max: 40, default: 0 },
  { id: 3, title: "Radius", max: 50, default: 0 },
];

const BackgroundColorContent = ({
  changeTextPadding,
  changeTextMargin,
  changeTextRadius,
  configMargin,
  configPadding,
  configRadius,
}: {
  changeTextPadding: (value: number) => void;
  changeTextMargin: (value: number) => void;
  changeTextRadius: (value: number) => void;
  configMargin: number;
  configPadding: number;
  configRadius: number;
}) => {
  return (
    <div
      className="background-color-controller"
      onClick={(e: any) => e.stopPropagation()}
    >
      {backgroundItems.map((item) => (
        <RangeComponent
          key={item.id}
          title={item.title}
          defaultValue={
            item.id === 1
              ? configMargin
              : item.id === 2
              ? configPadding
              : item.id === 3
              ? configRadius
              : 0
          }
          maxValue={item.max}
          onChange={(value: number) => {
            if (item.id === 1 && item.title === "Margin") {
              changeTextMargin(value);
            } else if (item.id === 2 && item.title === "Padding") {
              changeTextPadding(value);
            } else if (item.id === 3 && item.title === "Radius") {
              changeTextRadius(value);
            }
          }}
        />
      ))}
    </div>
  );
};

const blockEffectItems = [
  { id: 1, title: "Padding", max: 40, default: 0 },
  { id: 2, title: "Radius", max: 50, default: 0 },
];

const BlockEffectContent = ({
  onChangeBlockPadding,
  onChangeBlockRadius,
  configPadding,
  configRadius,
}: {
  onChangeBlockPadding: (value: number) => void;
  onChangeBlockRadius: (value: number) => void;
  configPadding: number;
  configRadius: number;
}) => {
  return (
    <div
      className="block-effect-controller"
      onClick={(e: any) => e.stopPropagation()}
    >
      {blockEffectItems.map((item) => (
        <RangeComponent
          key={item.id}
          title={item.title}
          defaultValue={
            item.id === 1 ? configPadding : item.id === 2 ? configRadius : 0
          }
          maxValue={item.max}
          onChange={(value: number) => {
            if (item.id === 1 && item.title === "Padding") {
              onChangeBlockPadding(value);
            } else if (item.id === 2 && item.title === "Radius") {
              onChangeBlockRadius(value);
            }
          }}
        />
      ))}
    </div>
  );
};

const OutlineEffectContent = ({
  changeOutlineWidth,
  configThickness,
}: {
  changeOutlineWidth: (value: number) => void;
  configThickness: number;
}) => {
  return (
    <div
      className="outline-effect-controller"
      onClick={(e: any) => e.stopPropagation()}
    >
      <span>Thickness</span>
      <Slider
        onChange={(value: number) => changeOutlineWidth(value)}
        defaultValue={configThickness}
        min={0}
        max={10}
      />
    </div>
  );
};

const shadowEffectItems = [
  { id: 1, title: "OffsetX", max: 50, default: 0, min: -50 },
  { id: 2, title: "OffsetY", max: 50, default: 0, min: -50 },
  { id: 3, title: "Blur", max: 30, default: 0 },
];

const ShadowEffecContent = ({
  changeShadowOffsetX,
  changeShadowOffsetY,
  changeShadowBlur,
  configOffsetX,
  configOffsetY,
  configBlur,
}: {
  changeShadowOffsetX: (value: number) => void;
  changeShadowOffsetY: (value: number) => void;
  changeShadowBlur: (value: number) => void;
  configOffsetX: number;
  configOffsetY: number;
  configBlur: number;
}) => {
  return (
    <div
      className="block-effect-controller"
      onClick={(e: any) => e.stopPropagation()}
    >
      {shadowEffectItems.map((item) => (
        <RangeComponent
          key={item.id}
          title={item.title}
          defaultValue={
            item.id === 1
              ? configOffsetX
              : item.id == 2
              ? configOffsetY
              : item.id == 3
              ? configBlur
              : 0
          }
          maxValue={item.max}
          minValue={item.min}
          onChange={(value: number) => {
            if (item.id === 1 && item.title === "OffsetX") {
              changeShadowOffsetX(value);
            } else if (item.id === 2 && item.title === "OffsetY") {
              changeShadowOffsetY(value);
            } else if (item.id === 3 && item.title === "Blur") {
              changeShadowBlur(value);
            }
          }}
        />
      ))}
    </div>
  );
};

interface ISubtitleEditingBackgroundProps {
  config: ISubtitlesConfig;
  clearAllEffect: () => void;
  toggleBackgroundEffect: () => void;
  toggleBlockingEffect: () => void;
  toggleOutlineEffect: () => void;
  toggleShadowEffect: () => void;
  changeTextPadding: (value: number) => void;
  changeTextMargin: (value: number) => void;
  changeTextRadius: (value: number) => void;
  onChangeTextBgColor: (value: string) => void;
  onChangeBlockPadding: (value: number) => void;
  onChangeBlockRadius: (value: number) => void;
  onChangeBlockBG: (value: string) => void;
  changeOutlineWidth: (value: number) => void;
  changeOutlineColor: (value: string) => void;
  changeShadowOffsetX: (value: number) => void;
  changeShadowOffsetY: (value: number) => void;
  changeShadowBlur: (value: number) => void;
  changeShadowColor: (value: string) => void;
}

const SubtitleEditingBackground: React.FC<ISubtitleEditingBackgroundProps> = ({
  config,
  changeTextPadding,
  changeTextMargin,
  changeTextRadius,
  onChangeTextBgColor,
  clearAllEffect,
  toggleBackgroundEffect,
  toggleBlockingEffect,
  onChangeBlockPadding,
  onChangeBlockRadius,
  onChangeBlockBG,
  changeOutlineWidth,
  changeOutlineColor,
  changeShadowOffsetX,
  changeShadowOffsetY,
  changeShadowBlur,
  toggleOutlineEffect,
  toggleShadowEffect,
  changeShadowColor,
}) => {
  return (
    <div className="subtitle-editing-background">
      <Title className="effect-customization-title">Effect Customization</Title>
      <EffectCard
        colorValue=""
        cardTitle="No Effect"
        onClick={clearAllEffect}
        active={
          !config.textBG.enabled &&
          !config.blockBG.enabled &&
          !config.textOutline.enabled &&
          !config.textShadow.enabled
        }
      />
      <EffectCard
        cardTitle="Background Effect"
        onClick={toggleBackgroundEffect}
        popOverContent={
          <BackgroundColorContent
            changeTextPadding={changeTextPadding}
            changeTextMargin={changeTextMargin}
            changeTextRadius={changeTextRadius}
            configMargin={config.textBG.margin}
            configPadding={config.textBG.padding}
            configRadius={config.textBG.borderRadius}
          />
        }
        popOverTitle="Background Controls"
        withIcons
        textStyle={{
          backgroundColor: config.textBG.color,
          color: config.color,
        }}
        colorValue={config.textBG.color}
        onChangeColor={onChangeTextBgColor}
        active={config.textBG.enabled}
      />
      <EffectCard
        cardTitle="Block Effect"
        onClick={toggleBlockingEffect}
        withIcons
        popOverContent={
          <BlockEffectContent
            onChangeBlockPadding={onChangeBlockPadding}
            onChangeBlockRadius={onChangeBlockRadius}
            configPadding={config.textBG.padding}
            configRadius={config.textBG.borderRadius}
          />
        }
        textStyle={{
          backgroundColor: config.blockBG.color,
          color: config.color,
          padding: "10px 15px",
        }}
        popOverTitle="Block Controls"
        colorValue={config.blockBG.color}
        onChangeColor={onChangeBlockBG}
        active={config.blockBG.enabled}
      />
      <EffectCard
        cardTitle="Outline Effect"
        onClick={toggleOutlineEffect}
        withIcons
        popOverContent={
          <OutlineEffectContent
            changeOutlineWidth={changeOutlineWidth}
            configThickness={config.textOutline.width}
          />
        }
        popOverTitle="Outline Controls"
        onChangeColor={changeOutlineColor}
        colorValue={config.textOutline.color}
        active={config.textOutline.enabled}
        textStyle={{
          color: config.color,
          "-webkit-text-stroke-width": "2px",
          "-webkit-text-stroke-color": config.textOutline.color,
        }}
      />
      <EffectCard
        cardTitle="Shadow Effect"
        onClick={toggleShadowEffect}
        withIcons
        popOverContent={
          <ShadowEffecContent
            changeShadowOffsetX={changeShadowOffsetX}
            changeShadowOffsetY={changeShadowOffsetY}
            changeShadowBlur={changeShadowBlur}
            configOffsetX={config.textShadow.offsetX}
            configOffsetY={config.textShadow.offsetY}
            configBlur={config.textShadow.blur}
          />
        }
        popOverTitle="Shadow Controls"
        onChangeColor={changeShadowColor}
        colorValue={config.textShadow.color}
        active={config.textShadow.enabled}
        textStyle={{
          color: config.color,
          textShadow: `1px 1px 1px ${config.textShadow.color} `,
        }}
      />
    </div>
  );
};

export default SubtitleEditingBackground;

import React from "react";
import './videoCardSkeleton.scss'
import { Card, Skeleton } from "antd";
import Meta from "antd/es/card/Meta";

const VideoCardSkeleton = () => {
  return (
    <Card className={'videoSkeletonCard'} hoverable cover={<Skeleton.Image active style={{ width: "100%", height: 150 }} />}>
      <Meta
        title={
          <Skeleton.Input size="small" style={{height: 20}} active />
        }
        description={<Skeleton.Input size="small" style={{height: 20, width: '50%', display: 'inline-block'}} block active />}
      />
    </Card>
  );
};

export default VideoCardSkeleton;

import React, { CSSProperties } from "react";
import styles from "./EffectCard.module.scss";
import { Button, Popover, Typography } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { colors } from "../../../Themes/colors";
import CustomColorPicker from "../../CustomColorPicker/CustomColorPicker";

const textOutline = `0px 0px 2px black,
0px 0px 2px black,0px 0px 2px black
,0px 0px 2px black,0px 0px 2px black
,0px 0px 2px black,0px 0px 2px black
,0px 0px 2px black,0px 0px 2px black
,0px 0px 2px black,0px 0px 2px black
,0px 0px 2px black,0px 0px 2px black
,0px 0px 2px black,0px 0px 2px black
,0px 0px 2px black,0px 0px 2px black
,0px 0px 2px black,0px 0px 2px black`;

const textShadow = "5px 5px 5px black";

interface EffectCardProps {
  cardTitle: string;
  onClick?: () => void;
  withIcons?: boolean;
  textStyle?: CSSProperties & {
    "-webkit-text-stroke-width"?: string;
    "-webkit-text-stroke-color"?: string;
  };
  popOverContent?: React.ReactNode;
  popOverTitle?: string;
  onChangeColor?: (value: string) => void;
  colorValue: string;
  active?: boolean;
  noSettings?: boolean;
}

const EffectCard: React.FC<EffectCardProps> = ({
  cardTitle,
  onClick,
  withIcons,
  textStyle,
  popOverContent,
  popOverTitle,
  onChangeColor,
  colorValue,
  active,
  noSettings,
}) => {
  const handleInnerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
  return (
    <div
      className={styles.cardContainer}
      style={{
        justifyContent: withIcons ? "space-between" : "center",
        cursor: "pointer",
        border: `2px solid ${active ? colors.sideBg : "white"}`,
      }}
      onClick={onClick}
    >
      <Typography.Text
        style={{
          color:
            cardTitle === "Shadow Effect"
              ? "red"
              : cardTitle === "No Effect" || cardTitle === "Effect Color"
              ? "black"
              : "white",
          backgroundColor:
            cardTitle === "Background Effect" || cardTitle === "Block Effect"
              ? "red"
              : "white",
          padding: cardTitle === "Block Effect" ? "0 15px" : "0",
          textShadow:
            cardTitle === "Outline Effect"
              ? textOutline
              : cardTitle === "Shadow Effect"
              ? textShadow
              : "",
        }}
        className={styles.cardTitle}
      >
        {cardTitle}
      </Typography.Text>
      {withIcons && (
        <div className={styles.actionIcons} onClick={handleInnerClick}>
          {!noSettings && (
            <Popover
              title={popOverTitle}
              placement="leftBottom"
              content={popOverContent}
              trigger="click"
            >
              <Button
                disabled={!active}
                className={styles.iconButton}
                onClick={handleInnerClick}
              >
                <SettingOutlined />
              </Button>
            </Popover>
          )}
          <CustomColorPicker
            colorValue={colorValue && colorValue}
            onChange={(value: string) => {
              console.log(value);
              if (onChangeColor) {
                onChangeColor(value);
              }
            }}
            trigger={
              <Button
                disabled={!active}
                onClick={handleInnerClick}
                style={{
                  border: "1px solid gray",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: colorValue,
                  }}
                />
              </Button>
            }
          />
        </div>
      )}
    </div>
  );
};

export default EffectCard;

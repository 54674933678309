import React, { useEffect, useState } from "react";
import SubtitleEditingHeader from "./SubtitleEditingHeader/SubtitleEditingHeader";
import { Tabs } from "antd";
import SubtitleEditingTextFontSettings from "./SubtitleEditingTextFontSettings/SubtitleEditingTextFontSettings";
import { colors } from "../../Themes/colors";
import SubtitleEditingBackground from "./SubtitleEditingBackground/SubtitleEditingBackground";
import SubtitleEditingEditor from "./SubtitleEditingEditor/SubtitleEditingEditor";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  alignTextCenter,
  alignTextLeft,
  alignTextRight,
  capitelizeText,
  changeBlockBGColor,
  changeBlockPadding,
  changeBlockRadius,
  changeFontWeight,
  changeItalic,
  changeLineHeight,
  changeSubtitleStylesBGColor,
  changeSubtitleStylesTextColor,
  changeTextBgColor,
  changeTextBgMargin,
  changeTextBgPadding,
  changeTextBgRadius,
  changeTextOutlineColor,
  changeTextOutlineWidth,
  changeTextShadowBlur,
  changeTextShadowColor,
  changeTextShadowX,
  changeTextShadowY,
  changeUnderline,
  clearAllEffect,
  onWordChange,
  setFontColor,
  setFontFamily,
  setFontSize,
  setWords,
  toggleBackgroundEffect,
  toggleBlockingEffect,
  toggleOutlineEffect,
  toggleShadowEffect,
  uncapitalizeText,
} from "../../redux/Slices/clipEditingSlice";
import { Color } from "antd/es/color-picker";
import SubtitleStyles from "./SubtitleStyles/SubtitleStyles";
import { FontFamilyEnum, fontsArray } from "../../remotion/subtitles/fonts";
import EffectCard from "./EffectCard/EffectCard";
import { ISubtitleStyleEnum } from "../../types/interfaces";

interface IProps {
  currentTime: number;
}
const SubtitleLayout = ({ currentTime }: IProps) => {
  const [activeTab, setActiveTab] = useState<string>("1");
  const { selectedClip } = useAppSelector((state) => state.videoDetails);
  const {
    words,
    subtitlesConfig,
    subtitleStyle,
    subtitleEffectBGColor,
    subtitleEffectColor,
  } = useAppSelector((state) => state.clipEditing);

  const dispatch = useAppDispatch();

  const onRestoreDefaults = () => {
    dispatch(setWords(selectedClip!.words));
  };

  const handleWordChange = ({
    text,
    index,
  }: {
    text: string;
    index: number;
  }) => {
    dispatch(onWordChange({ text, index }));
  };

  const onChangeSize = (value: number | null) => {
    if (value !== null) {
      dispatch(setFontSize(value));
    }
  };

  const onChangeLineHeight = (value: number | null) => {
    if (value !== null) {
      dispatch(changeLineHeight(value));
    }
  };

  const handleEditMainOptions = (index: number) => {
    console.log(index);
    switch (index) {
      case 0:
        dispatch(changeFontWeight());
        break;
      case 1:
        dispatch(changeUnderline());
        break;
      case 2:
        dispatch(changeItalic());
        break;
      default:
        break;
    }
  };

  const handleAlignment = (index: number) => {
    switch (index) {
      case 0:
        dispatch(alignTextLeft());
        break;
      case 1:
        dispatch(alignTextCenter());
        break;
      case 2:
        dispatch(alignTextRight());
        break;
      default:
        break;
    }
  };

  const handleFontChange = (value: FontFamilyEnum) => {
    dispatch(setFontFamily(value));
  };

  const changeFontColor = (value: string) => {
    dispatch(setFontColor(value));
  };

  const capitalize = () => {
    dispatch(capitelizeText());
  };

  const unCapitalize = () => {
    dispatch(uncapitalizeText());
  };

  return (
    <div
      style={{
        width: "100%",
        height: "92vh",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <SubtitleEditingHeader />
      <div
        style={{
          width: "98%",
          borderRadius: "10px",
          padding: "10px",
          backgroundColor: colors.white,
          marginTop: "10px",
        }}
      >
        <Tabs
          defaultActiveKey="1"
          // className={styles.tabs}
          onChange={(key: string) => setActiveTab(key)}
          items={[
            {
              key: "1",
              label: "Font Settings",
              children: (
                <>
                  <SubtitleEditingTextFontSettings
                    config={subtitlesConfig}
                    onChangeFontSize={onChangeSize}
                    onChangeFontLineHeight={onChangeLineHeight}
                    handleEditMainOptions={handleEditMainOptions}
                    handleAlignment={handleAlignment}
                    fontsArray={fontsArray}
                    handleFontChange={handleFontChange}
                    changeFontColor={changeFontColor}
                    capitalizeText={capitalize}
                    unCapitalizeText={unCapitalize}
                  />
                </>
              ),
              // children: "Content",
            },
            {
              key: "2",
              label: "Editor",
              children: (
                <SubtitleEditingEditor
                  words={words}
                  currentTime={currentTime}
                  onRestoreDefaults={onRestoreDefaults}
                  onChange={handleWordChange}
                />
              ),
            },
            {
              key: "3",
              label: "Styles",
              children: (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <SubtitleStyles />
                  <div
                    style={{
                      width: "100%",
                      marginTop: "30px",
                    }}
                  >
                    {(subtitleStyle ===
                      ISubtitleStyleEnum.HIGHLIGHT_CURRENT_WORD ||
                      subtitleStyle ===
                        ISubtitleStyleEnum.HIGHLIGHT_CURRENT_BG) && (
                      <EffectCard
                        cardTitle="Effect Color"
                        withIcons
                        active
                        noSettings
                        colorValue={
                          subtitleStyle ===
                          ISubtitleStyleEnum.HIGHLIGHT_CURRENT_BG
                            ? subtitleEffectBGColor
                            : subtitleEffectColor
                        }
                        onChangeColor={(value) => {
                          if (
                            subtitleStyle ===
                            ISubtitleStyleEnum.HIGHLIGHT_CURRENT_BG
                          ) {
                            dispatch(changeSubtitleStylesBGColor(value));
                          } else if (
                            subtitleStyle ===
                            ISubtitleStyleEnum.HIGHLIGHT_CURRENT_WORD
                          ) {
                            dispatch(changeSubtitleStylesTextColor(value));
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
      {activeTab === "1" && (
        <div
          style={{
            width: "98%",
            borderRadius: "10px",
            padding: "10px",
            backgroundColor: colors.white,
            marginTop: "10px",
          }}
        >
          <SubtitleEditingBackground
            config={subtitlesConfig}
            clearAllEffect={() => {
              dispatch(clearAllEffect());
            }}
            toggleBackgroundEffect={() => {
              dispatch(toggleBackgroundEffect());
            }}
            toggleBlockingEffect={() => {
              dispatch(toggleBlockingEffect());
            }}
            toggleOutlineEffect={() => {
              dispatch(toggleOutlineEffect());
            }}
            toggleShadowEffect={() => {
              dispatch(toggleShadowEffect());
            }}
            changeTextPadding={(value: number) => {
              dispatch(changeTextBgPadding(value));
            }}
            changeTextMargin={(value: number) => {
              dispatch(changeTextBgMargin(value));
            }}
            changeTextRadius={(value: number) => {
              dispatch(changeTextBgRadius(value));
            }}
            onChangeTextBgColor={(value: string) => {
              dispatch(changeTextBgColor(value));
            }}
            onChangeBlockPadding={(value: number) => {
              dispatch(changeBlockPadding(value));
            }}
            onChangeBlockRadius={(value: number) => {
              dispatch(changeBlockRadius(value));
            }}
            onChangeBlockBG={(value: string) => {
              dispatch(changeBlockBGColor(value));
            }}
            changeOutlineWidth={(value: number) => {
              dispatch(changeTextOutlineWidth(value));
            }}
            changeOutlineColor={(value: string) => {
              dispatch(changeTextOutlineColor(value));
            }}
            changeShadowOffsetX={(value: number) => {
              dispatch(changeTextShadowX(value));
            }}
            changeShadowOffsetY={(value: number) => {
              dispatch(changeTextShadowY(value));
            }}
            changeShadowBlur={(value: number) => {
              dispatch(changeTextShadowBlur(value));
            }}
            changeShadowColor={(value: string) => {
              dispatch(changeTextShadowColor(value));
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SubtitleLayout;

import { ISubtitleStyleEnum, ISubtitlesConfig } from "../../types/interfaces";
import StorySubtitle from "./StorySubtitle";
import HighlightCurrentWordSubtitles from "./HighlightCurrentWordSubtitles";
import TwoWordsSubtitles from "./TwoWordsSubtitles";
import SingleWordSubtitles from "./SingleWordSubtitles";
import RegulerSubtitles from "./RegulerSubtitles";
import { ITranscriptionWord } from "../../types/models";

interface IProps {
  subtitleStyle: ISubtitleStyleEnum;
  words: ITranscriptionWord[];
  subtitlesConfig: ISubtitlesConfig;
  selectedId: string;
  onSelectedChange: (id: string) => void;
  widthScale: number;
  heightScale: number;
  onSubtitlesTransformChange: (transform: string) => void;
  currentTime: number;
  subtitleEffectColor: string;
  subtitleEffectBGColor: string;
}

const Subtitles = ({
  words,
  subtitleStyle,
  currentTime,
  subtitlesConfig,
  widthScale,
  heightScale,
  onSelectedChange,
  onSubtitlesTransformChange,
  selectedId,
  subtitleEffectBGColor,
  subtitleEffectColor,
}: IProps) => {
  return subtitleStyle === "SubtitleStylesBasic" ? (
    <RegulerSubtitles
      words={words}
      currentTime={currentTime}
      config={subtitlesConfig}
      isSelected={selectedId === "subtitles"}
      onSelectedChange={() => onSelectedChange("subtitles")}
      widthScale={widthScale!}
      heightScale={heightScale!}
    />
  ) : subtitleStyle === "SubtitleStylesCardOneWord" ? (
    <SingleWordSubtitles
      words={words}
      currentTime={currentTime}
      config={subtitlesConfig}
      isSelected={selectedId === "subtitles"}
      onSelectedChange={() => onSelectedChange("subtitles")}
      widthScale={widthScale!}
      heightScale={heightScale!}
    />
  ) : subtitleStyle === "SubtitleStylesCardTwoWords" ? (
    <TwoWordsSubtitles
      words={words}
      currentTime={currentTime}
      config={subtitlesConfig}
      isSelected={selectedId === "subtitles"}
      onSelectedChange={() => onSelectedChange("subtitles")}
      widthScale={widthScale!}
      heightScale={heightScale!}
    />
  ) : subtitleStyle === "SubtitleStylesCardHighlightCurrentBG" ? (
    <HighlightCurrentWordSubtitles
      bg
      words={words}
      currentTime={currentTime}
      config={subtitlesConfig}
      isSelected={selectedId === "subtitles"}
      onSelectedChange={() => onSelectedChange("subtitles")}
      widthScale={widthScale!}
      heightScale={heightScale!}
      subtitleEffectBGColor={subtitleEffectBGColor}
    />
  ) : subtitleStyle === "SubtitleStylesCardHighlightCurrentColor" ? (
    <HighlightCurrentWordSubtitles
      words={words}
      currentTime={currentTime}
      config={subtitlesConfig}
      isSelected={selectedId === "subtitles"}
      onSelectedChange={() => onSelectedChange("subtitles")}
      widthScale={widthScale!}
      heightScale={heightScale!}
      subtitleEffectColor={subtitleEffectColor}
    />
  ) : subtitleStyle === "SubtitleStylesCardStory" ? (
    <StorySubtitle
      words={words}
      currentTime={currentTime}
      config={subtitlesConfig}
      isSelected={selectedId === "subtitles"}
      onSelectedChange={() => onSelectedChange("subtitles")}
      widthScale={widthScale!}
      heightScale={heightScale!}
    />
  ) : null;
};

export default Subtitles;

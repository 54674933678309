import { Col, Grid, Image, Row } from "antd";
import React from "react";
import "./landingSection.scss";

interface FormattedTextProps {
  description: React.ReactNode;
  order?: number;
  image?: string;
}

const { useBreakpoint } = Grid;

const LandingSection: React.FC<FormattedTextProps> = ({
  description,
  order,
  image,
}) => {
  const screens = useBreakpoint();
  return (
    <Row className="sectionRow">
      <Col
        span={12}
        className="rowColDesc"
        // order={
        //   screens.md === false && (screens.sm === true || screens.xs === true)
        //     ? 1
        //     : order
        // }
      >
        {description}
      </Col>
      <Col span={12} className="rowCol">
        <div className="imageContainer">
          <Image
            src={image}
            alt="section-image"
            className="sectionImage"
            preview={false}
          />
        </div>
      </Col>
    </Row>
  );
};

export default LandingSection;

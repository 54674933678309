import {
  Button,
  Card,
  Checkbox,
  Input,
  List,
  Popconfirm,
  Tooltip,
  Typography,
} from "antd";
import { secondsToHHMMSS } from "../../../helpers/helpers";
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  SettingFilled,
} from "@ant-design/icons";
import { IEditorTextOverlay } from "../../../types/interfaces";
import ClipEditorTabContent from "../../ClipEditorTabContent/ClipEditorTabContent";
import { CustomFontSettings } from "../../../assets/Icons/customSettingsIcon";
import SubtitleEditingTextFontSettings from "../../SubtitleEditing/SubtitleEditingTextFontSettings/SubtitleEditingTextFontSettings";
import { useMemo, useState } from "react";
import { getAvailableFonts } from "@remotion/google-fonts";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import "./TextOverlays.scss";
import SubtitleEditingBackground from "../../SubtitleEditing/SubtitleEditingBackground/SubtitleEditingBackground";
import { colors } from "../../../Themes/colors";
import { FontFamilyEnum, fontsArray } from "../../../remotion/subtitles/fonts";
import { setTextOverlays } from "../../../redux/Slices/clipEditingSlice";
import { getDefaultTextConfig } from "../../../remotion/subtitles/utils";

const TextOverlays = () => {
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [targetOverlayIndex, setTargetOverlayIndex] = useState<number>(-1);

  const { selectedClip } = useAppSelector((state) => state.videoDetails);
  const { textOverlays: overlays, currentTime } = useAppSelector(
    (state) => state.clipEditing
  );

  const dispatch = useAppDispatch();

  const onChange = (overlays: IEditorTextOverlay[]) => {
    dispatch(setTextOverlays(overlays));
  };

  const clipDuration = useMemo(() => {
    if (!selectedClip) return 0;
    return selectedClip.end - selectedClip.start;
  }, []);

  const addNewOverlay = () => {
    const newOverlays = overlays.slice();

    newOverlays.push({
      id: `text_${Date.now().toString()}`,
      text: "Edit Me",
      start: selectedClip!.start,
      end: selectedClip!.end,
      fullClip: true,
      config: { ...getDefaultTextConfig() },
    });

    dispatch(setTextOverlays(newOverlays));
  };

  const onDeleteOverlay = (index: number) => {
    const newOverlays = overlays.slice();
    newOverlays.splice(index, 1);
    onChange(newOverlays);
  };

  const onTextChange = (index: number, text: string) => {
    const newOverlays = overlays.slice();
    newOverlays[index] = {
      ...newOverlays[index],
      text,
    };
    onChange(newOverlays);
  };

  const onApplyToFullClip = (index: number) => {
    const newOverlays = overlays.slice();
    newOverlays[index] = {
      ...newOverlays[index],
      fullClip: true,
      start: selectedClip!.start,
      end: clipDuration,
    };
    onChange(newOverlays);
  };

  const onDisApplyToFullClip = (index: number) => {
    const newOverlays = overlays.slice();
    newOverlays[index] = {
      ...newOverlays[index],
      fullClip: false,
    };
    onChange(newOverlays);
  };

  const onMarkNow = (index: number, field: "start" | "end") => {
    const newOverlays = overlays.slice();
    newOverlays[index] = {
      ...newOverlays[index],
      [field]: currentTime,
    };
    onChange(newOverlays);
  };

  const [loadedFonts, setLoadedFonts] = useState<Set<string>>(new Set());
  const newFonts = getAvailableFonts();

  const onChangeSize = (value: number | null) => {
    const newConfig = { ...overlays[targetOverlayIndex]?.config };
    const newOverlays = overlays.slice();
    if (value !== null) {
      newConfig.fontSize = value;
      newOverlays[targetOverlayIndex] = {
        ...newOverlays[targetOverlayIndex],
        config: newConfig,
      };
      onChange(newOverlays);
    }
  };

  const onChangeLineHeight = (value: number | null) => {
    const newConfig = { ...overlays[targetOverlayIndex]?.config };
    const newOverlays = overlays.slice();
    if (value !== null) {
      newConfig.lineHeight = value;
      newOverlays[targetOverlayIndex] = {
        ...newOverlays[targetOverlayIndex],
        config: newConfig,
      };
      onChange(newOverlays);
    }
  };

  const handleEditMainOptions = (index: number) => {
    const newConfig = { ...overlays[targetOverlayIndex]?.config };
    const newOverlays = overlays.slice();
    switch (index) {
      case 0:
        newOverlays[targetOverlayIndex] = {
          ...newOverlays[targetOverlayIndex],
          config: {
            ...newConfig,
            bold: !newConfig.bold,
          },
        };
        onChange(newOverlays);
        break;
      case 1:
        newConfig.underline = !newConfig.underline;
        newOverlays[targetOverlayIndex] = {
          ...newOverlays[targetOverlayIndex],
          config: newConfig,
        };
        onChange(newOverlays);
        break;
      case 2:
        newConfig.italic = !newConfig.italic;
        newOverlays[targetOverlayIndex] = {
          ...newOverlays[targetOverlayIndex],
          config: newConfig,
        };
        console.log(newOverlays);
        onChange(newOverlays);

        break;
      default:
        break;
    }
  };

  const handleAlignment = (index: number) => {
    const newConfig = { ...overlays[targetOverlayIndex]?.config };
    const newOverlays = overlays.slice();
    switch (index) {
      case 0:
        newConfig.textAlign = "left";
        newOverlays[targetOverlayIndex] = {
          ...newOverlays[targetOverlayIndex],
          config: newConfig,
        };
        onChange(newOverlays);
        break;
      case 1:
        newConfig.textAlign = "center";
        newOverlays[targetOverlayIndex] = {
          ...newOverlays[targetOverlayIndex],
          config: newConfig,
        };
        onChange(newOverlays);
        break;
      case 2:
        newConfig.textAlign = "right";
        newOverlays[targetOverlayIndex] = {
          ...newOverlays[targetOverlayIndex],
          config: newConfig,
        };
        onChange(newOverlays);

        break;
      default:
        break;
    }
  };

  const handleFontChange = (value: FontFamilyEnum) => {
    const newConfig = { ...overlays[targetOverlayIndex]?.config };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        fontFamily: value,
      },
    };
    console.log(newOverlays[targetOverlayIndex]);
    onChange(newOverlays);
  };

  const changeFontColor = (value: string) => {
    const newConfig = { ...overlays[targetOverlayIndex]?.config };
    const newOverlays = overlays.slice();
    newConfig.color = value;
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: newConfig,
    };
    onChange(newOverlays);
  };

  const capitalize = () => {
    const newConfig = { ...overlays[targetOverlayIndex]?.config };
    const newOverlays = overlays.slice();
    newConfig.textTransform = "uppercase";
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: newConfig,
    };
    onChange(newOverlays);
  };

  const unCapitalize = () => {
    const newConfig = { ...overlays[targetOverlayIndex]?.config };
    const newOverlays = overlays.slice();
    newConfig.textTransform = "none";
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: newConfig,
    };
    onChange(newOverlays);
  };

  const clearAllEffect = () => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textBG: { ...newConfig.textBG, enabled: false },
        blockBG: {
          ...newConfig.blockBG,
          enabled: false,
        },
        textOutline: { ...newConfig.textOutline, enabled: false },
        textShadow: { ...newConfig.textShadow, enabled: false },
      },
    };
    onChange(newOverlays);
  };

  const toggleBackgroundEffect = () => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textBG: { ...newConfig.textBG, enabled: !newConfig.textBG.enabled },
      },
    };
    onChange(newOverlays);
  };

  const toggleBlockingEffect = () => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        blockBG: {
          ...newConfig.blockBG,
          enabled: !newConfig.blockBG.enabled,
        },
      },
    };
    onChange(newOverlays);
  };

  const toggleOutlineEffect = () => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textOutline: {
          ...newConfig.textOutline,
          enabled: !newConfig.textOutline.enabled,
        },
      },
    };
    onChange(newOverlays);
  };

  const toggleShadowEffect = () => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textShadow: {
          ...newConfig.textShadow,
          enabled: !newConfig.textShadow.enabled,
        },
      },
    };
    onChange(newOverlays);
  };

  const changeShadowOffsetX = (value: number) => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textShadow: {
          ...newConfig.textShadow,
          offsetX: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const changeShadowOffsetY = (value: number) => {
    console.log(value);
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textShadow: {
          ...newConfig.textShadow,
          offsetY: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const changeShadowBlur = (value: number) => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textShadow: {
          ...newConfig.textShadow,
          blur: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const changeShadowColor = (value: string) => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textShadow: {
          ...newConfig.textShadow,
          color: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const changeOutlineWidth = (value: number) => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textOutline: {
          ...newConfig.textOutline,
          width: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const changeOutlineColor = (value: string) => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textOutline: {
          ...newConfig.textOutline,
          color: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const onChangeBlockPadding = (value: number) => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        blockBG: {
          ...newConfig.blockBG,
          padding: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const onChangeBlockRadius = (value: number) => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        blockBG: {
          ...newConfig.blockBG,
          borderRadius: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const onChangeBlockBG = (value: string) => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        blockBG: {
          ...newConfig.blockBG,
          color: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const changeTextPadding = (value: number) => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textBG: {
          ...newConfig.textBG,
          padding: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const changeTextMargin = (value: number) => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textBG: {
          ...newConfig.textBG,
          margin: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const changeTextRadius = (value: number) => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textBG: {
          ...newConfig.textBG,
          borderRadius: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const onChangeTextBgColor = (value: string) => {
    const newConfig = {
      ...overlays[targetOverlayIndex]?.config,
    };
    const newOverlays = overlays.slice();
    newOverlays[targetOverlayIndex] = {
      ...newOverlays[targetOverlayIndex],
      config: {
        ...newConfig,
        textBG: {
          ...newConfig.textBG,
          color: value,
        },
      },
    };
    onChange(newOverlays);
  };

  const toggleSettings = (index: number) => {
    if (targetOverlayIndex === index) {
      setTargetOverlayIndex(-1); // Close settings for the currently selected overlay
    } else {
      setTargetOverlayIndex(index); // Open settings for the clicked overlay
    }
  };
  return (
    <div
      style={{
        width: "100%",
        padding: 10,
        overflowY: "auto",
        maxHeight: "80vh",
      }}
    >
      {!overlays.length ? (
        <Typography.Text>Looks empty here...</Typography.Text>
      ) : null}
      <List>
        {overlays.map((overlay, index) => (
          <div
            style={{
              margin: "10px 0px",
              // backgroundColor: "wheat",
            }}
          >
            <List.Item
              key={`txt-overlay-${overlay.id}`}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Card style={{ width: "100%", borderWidth: 3 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        value={secondsToHHMMSS(overlay.start)}
                        readOnly
                        style={{ width: 100 }}
                      />
                      {!overlay.fullClip && (
                        <div>
                          <Button
                            type="link"
                            onClick={() => onMarkNow(index, "start")}
                          >
                            Mark now
                          </Button>
                          {index === 0 && (
                            <Tooltip title="Mark the current time in the video as the text start time">
                              <ExclamationCircleOutlined />
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </div>
                    <span style={{ margin: 5 }}>-</span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        value={secondsToHHMMSS(overlay.end)}
                        readOnly
                        style={{ width: 100 }}
                      />
                      {!overlay.fullClip && (
                        <div>
                          <Button
                            type="link"
                            onClick={() => onMarkNow(index, "end")}
                          >
                            Mark now
                          </Button>
                          {index === 0 && (
                            <Tooltip title="Mark the current time in the video as the text end time">
                              <ExclamationCircleOutlined />
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip
                      title="settings"
                      placement="left"
                      className="tooltip"
                    >
                      <SettingFilled
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginRight: 10,
                        }}
                        onClick={() => {
                          toggleSettings(index);
                        }}
                      />
                    </Tooltip>
                    <Popconfirm
                      title="Delete Overlay"
                      description="Are you sure to delete this overlay?"
                      cancelText="No"
                      okText="Yes"
                      placement="left"
                      onConfirm={() => onDeleteOverlay(index)}
                    >
                      <DeleteFilled style={{ color: "red", fontSize: 16 }} />
                    </Popconfirm>
                  </div>
                </div>
                <div style={{ marginBottom: 10 }}>
                  <Checkbox
                    checked={overlay.fullClip}
                    onChange={(e) => {
                      if (overlay.fullClip) {
                        onDisApplyToFullClip(index);
                      } else {
                        onApplyToFullClip(index);
                      }
                    }}
                  >
                    Apply to full clip
                  </Checkbox>
                </div>
                <Input
                  value={overlay.text}
                  size="large"
                  onChange={(e) => onTextChange(index, e.target.value)}
                  autoFocus={index === overlays.length - 1}
                />
                <div
                  style={{
                    backgroundColor: "white",
                    // padding: 24,
                    marginTop: 10,
                    width: "100%",
                  }}
                  className={
                    targetOverlayIndex === index ? "expand" : "collapse"
                  }
                >
                  {targetOverlayIndex > -1 && (
                    <>
                      <SubtitleEditingTextFontSettings
                        config={overlays[targetOverlayIndex]?.config}
                        onChangeFontSize={onChangeSize}
                        onChangeFontLineHeight={onChangeLineHeight}
                        handleEditMainOptions={handleEditMainOptions}
                        handleAlignment={handleAlignment}
                        fontsArray={fontsArray}
                        handleFontChange={handleFontChange}
                        changeFontColor={changeFontColor}
                        capitalizeText={capitalize}
                        unCapitalizeText={unCapitalize}
                      />
                      <div
                        style={{
                          width: "98%",
                          borderRadius: "10px",
                          padding: "10px",
                          backgroundColor: colors.white,
                          marginTop: "10px",
                        }}
                      >
                        <SubtitleEditingBackground
                          config={overlays[targetOverlayIndex]?.config}
                          clearAllEffect={clearAllEffect}
                          toggleBackgroundEffect={toggleBackgroundEffect}
                          toggleBlockingEffect={toggleBlockingEffect}
                          toggleOutlineEffect={toggleOutlineEffect}
                          toggleShadowEffect={toggleShadowEffect}
                          changeTextPadding={(value: number) => {
                            changeTextPadding(value);
                          }}
                          changeTextMargin={(value: number) => {
                            changeTextMargin(value);
                          }}
                          changeTextRadius={(value: number) => {
                            changeTextRadius(value);
                          }}
                          onChangeTextBgColor={(value: string) => {
                            onChangeTextBgColor(value);
                          }}
                          onChangeBlockPadding={(value: number) => {
                            onChangeBlockPadding(value);
                          }}
                          onChangeBlockRadius={(value: number) => {
                            onChangeBlockRadius(value);
                          }}
                          onChangeBlockBG={(value: string) => {
                            onChangeBlockBG(value);
                          }}
                          changeOutlineWidth={(value: number) => {
                            changeOutlineWidth(value);
                          }}
                          changeOutlineColor={(value: string) => {
                            changeOutlineColor(value);
                          }}
                          changeShadowOffsetX={(value: number) => {
                            changeShadowOffsetX(value);
                          }}
                          changeShadowOffsetY={(value: number) => {
                            changeShadowOffsetY(value);
                          }}
                          changeShadowBlur={(value: number) => {
                            changeShadowBlur(value);
                          }}
                          changeShadowColor={(value: string) => {
                            changeShadowColor(value);
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>
            </List.Item>
          </div>
        ))}
      </List>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <Button
          size="large"
          type="primary"
          icon={<PlusCircleOutlined />}
          block
          onClick={addNewOverlay}
          style={{
            width: "95%",
          }}
        >
          Add New Text
        </Button>
      </div>
    </div>
  );
};

export default TextOverlays;

import React from "react";
import { AbsoluteFill, Sequence } from "remotion";

const words = [
  "Basic Subtitles",
  "Where Sentence will",
  "appear at the same time",
  "it's old school",
  "but it's works ✌",
];

export const SubtitleStylesBasic: React.FC = () => {
  return (
    <AbsoluteFill
      style={{
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {words.map((word, index) => {

        return (
          <Sequence key={index} from={index * 30} durationInFrames={30}>
            <div
              style={{
                fontSize: 20,
                color: "white",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {word}
            </div>
          </Sequence>
        );
      })}
    </AbsoluteFill>
  );
};

import { userLogin, userRegister, userVerification } from '../types/authTypes';
import ApiUrlConstans from './ApiUrlConstants';
import { axiosInstance as api } from '../helpers/request'
import { LoginResponse, RegisterResponse, userVerificationResponse } from '../types/models';


class AuthService {
    register(userData: userRegister) {
        return api.request<RegisterResponse>({
            url: ApiUrlConstans.register,
            method: 'POST',
            data: userData
        })
    }

    login(userData: userLogin) {
        return api.request<LoginResponse>({
            url: ApiUrlConstans.login,
            method: 'POST',
            data: userData
        })
    }

    verifyUser(verificationCode: userVerification) {
        return api.request<userVerificationResponse>({
            url: ApiUrlConstans.verifyUser,
            method: 'POST',
            data: verificationCode
        })
    }

    resendVerify() {
        return api.request({
            url: ApiUrlConstans.resendVerifyUser,
            method: 'POST',
        })
    }


    getMe() {
        return api.request<LoginResponse>({
            url: ApiUrlConstans.me,
            method: 'GET',
        })
    }

    requestResetPassword(email: string) {
        return api.request<userVerificationResponse>({
            url: ApiUrlConstans.requestResetPassword,
            method: 'POST',
            data: { email }
        })
    }

    submitResetPassword(data: { email: string, code: string , password: string}) {
        return api.request<userVerificationResponse>({
            url: ApiUrlConstans.submitResetPassword,
            method: 'POST',
            data
        })
    }


}

const authServiceInstance = new AuthService();

export default authServiceInstance;
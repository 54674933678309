import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClipProps, ClipScene, ClipSceneStatusEnum, IClipsExport, ITranscriptionWord, StatusEnum } from "../../types/models";
import { IEditorClipScene, IEditorClipScreen, IEditorMediaOverlay, IEditorTextOverlay, ISubtitlesConfig, ISubtitleStyleEnum } from "../../types/interfaces";
import { formatClipScenes, getDefaultTextConfig } from "../../remotion/subtitles/utils";
import clipsExportApi from "../../api/clipsExportApi";
import { FontFamilyEnum } from "../../remotion/subtitles/fonts";
import videoDetailsApi from "../../api/videoDetailsApi";

interface IInitialState {
    textOverlays: IEditorTextOverlay[];
    mediaOverlays: IEditorMediaOverlay[];
    subtitlesConfig: ISubtitlesConfig
    words: ITranscriptionWord[];
    selectedId: string;
    selectedFaceIndex: number;
    selectedQuality: number;
    downloadingClipExport: IClipsExport | null;
    subtitleStyle: ISubtitleStyleEnum;
    subtitleEffectBGColor: string;
    subtitleEffectColor: string;
    selectedTemplate?: string;
    transform: string;
    editorClipScenes: IEditorClipScene[],
    clipScenes: ClipScene[],
    clipSceneStatus: ClipSceneStatusEnum
    currentTime: number;
}

const initialState: IInitialState = {
    textOverlays: [],
    mediaOverlays: [],
    subtitlesConfig: getDefaultTextConfig(),
    words: [],
    selectedId: '',
    selectedFaceIndex: 0,
    selectedQuality: 720,
    downloadingClipExport: null,
    subtitleStyle: ISubtitleStyleEnum.BASIC,
    subtitleEffectColor: 'blue',
    subtitleEffectBGColor: 'blue',
    selectedTemplate: '',
    transform: '',
    editorClipScenes: [],
    clipScenes: [],
    clipSceneStatus: ClipSceneStatusEnum.NONE,
    currentTime: 0,
}

export const ClipEditingSlice = createSlice({
    name: "clipEditing",
    initialState,
    reducers: {
        setSelectedFaceIndex(state, action: PayloadAction<number>) {
            state.selectedFaceIndex = action.payload;
        },
        setSelectedQuality(state, action: PayloadAction<number>) {
            state.selectedQuality = action.payload;
        },
        setDownloadingClipExport(state, action: PayloadAction<IClipsExport | null>) {
            state.downloadingClipExport = action.payload
        },
        setTextOverlays(state, action: PayloadAction<IEditorTextOverlay[]>) {
            state.textOverlays = action.payload;
        },
        setMediaOverlays(state, action: PayloadAction<IEditorMediaOverlay[]>) {
            state.mediaOverlays = action.payload;
        },
        setSubtitlesConfig(state, action: PayloadAction<ISubtitlesConfig>) {
            state.subtitlesConfig = action.payload;
        },
        setWords(state, action: PayloadAction<ITranscriptionWord[]>) {
            state.words = action.payload;
        },
        onWordChange(state, { payload: { text, index } }: PayloadAction<{ text: string, index: number }>) {
            const newWords = state.words.slice();
            newWords[index].word = text;
            state.words = newWords;
        },
        chooseStyles(state, action: PayloadAction<ISubtitleStyleEnum>) {
            state.subtitleStyle = action.payload;
        },
        resetState(state) {
            state = { ...initialState }
        },
        updateConfigSubtitleFromTemplate: (state, action: PayloadAction<ISubtitlesConfig>) => {
            state.subtitlesConfig = action.payload
        },
        addTransformationData: (state, action: PayloadAction<{ sceneIndex: number; screenIndex: number; transform: string }>) => {
            const { sceneIndex, screenIndex, transform } = action.payload
            state.editorClipScenes[sceneIndex].screens[screenIndex].transform = transform
        },
        updateConfigTextOverlaysFromTemplate: (state, action: PayloadAction<IEditorTextOverlay[]>) => {
            state.textOverlays = action.payload
        },
        updateConfigMediaOberlaysFromTemplate: (state, action: PayloadAction<IEditorMediaOverlay[]>) => {
            state.mediaOverlays = action.payload
        },
        updateSubtitleStylesEffectsAndColors: (state, action: PayloadAction<{
            subtitleStyle: ISubtitleStyleEnum;
            subtitleEffectColor: string;
            subtitleEffectBGColor: string;
        }>) => {
            state.subtitleStyle = action.payload.subtitleStyle
            state.subtitleEffectColor = action.payload.subtitleEffectColor
            state.subtitleEffectBGColor = action.payload.subtitleEffectBGColor
        },
        selectTempelate: (state, action: PayloadAction<string>) => {
            state.selectedTemplate = action.payload
        },
        changeSubtitleStylesBGColor(state, action: PayloadAction<string>) {
            state.subtitleEffectBGColor = action.payload;
        },
        changeSubtitleStylesTextColor(state, action: PayloadAction<string>) {
            state.subtitleEffectColor = action.payload;
        },
        setSelectedId(state, action: PayloadAction<string>) {
            state.selectedId = action.payload;
        },
        setFontFamily: (state, action: PayloadAction<FontFamilyEnum>) => {
            state.subtitlesConfig.fontFamily = action.payload
        },
        setFontColor: (state, action: PayloadAction<string>) => {
            state.subtitlesConfig.color = action.payload
        },
        setFontSize: (state, action: PayloadAction<number>) => {
            state.subtitlesConfig.fontSize = action.payload
        },
        changeFontWeight: (state) => {
            state.subtitlesConfig.bold = !state.subtitlesConfig.bold
        },
        changeUnderline: (state) => {
            state.subtitlesConfig.underline = !state.subtitlesConfig.underline
        },
        changeItalic: (state) => {
            state.subtitlesConfig.italic = !state.subtitlesConfig.italic
        },
        capitelizeText: (state) => {
            state.subtitlesConfig.textTransform = "uppercase"
        },
        uncapitalizeText: (state) => {
            state.subtitlesConfig.textTransform = "none"
        },
        alignTextLeft: (state) => {
            state.subtitlesConfig.textAlign = "left"
        },
        alignTextRight: (state) => {
            state.subtitlesConfig.textAlign = "right"
        },
        alignTextCenter: (state) => {
            state.subtitlesConfig.textAlign = "center"
        },
        changeLineHeight: (state, action: PayloadAction<number>) => {
            state.subtitlesConfig.lineHeight = action.payload
        },
        changeBlockPadding: (state, action: PayloadAction<number>) => {
            state.subtitlesConfig.blockBG.padding = action.payload
        },
        changeBlockRadius: (state, action: PayloadAction<number>) => {
            state.subtitlesConfig.blockBG.borderRadius = action.payload
        },
        changeBlockBGColor: (state, action: PayloadAction<string>) => {
            state.subtitlesConfig.blockBG.color = action.payload
        },
        changeTextBgColor: (state, action: PayloadAction<string>) => {
            state.subtitlesConfig.textBG.color = action.payload
        },
        changeTextBgPadding: (state, action: PayloadAction<number>) => {
            state.subtitlesConfig.textBG.padding = action.payload
        },
        changeTextBgMargin: (state, action: PayloadAction<number>) => {
            state.subtitlesConfig.textBG.margin = action.payload
        },
        changeTextBgRadius: (state, action: PayloadAction<number>) => {
            state.subtitlesConfig.textBG.borderRadius = action.payload
        },
        changeTextOutlineColor: (state, action: PayloadAction<string>) => {
            state.subtitlesConfig.textOutline.color = action.payload
        },
        changeTextOutlineWidth: (state, action: PayloadAction<number>) => {
            state.subtitlesConfig.textOutline.width = action.payload
        },
        changeTextShadowColor: (state, action: PayloadAction<string>) => {
            state.subtitlesConfig.textShadow.color = action.payload;
        },
        changeTextShadowX: (state, action: PayloadAction<number>) => {
            state.subtitlesConfig.textShadow.offsetX = action.payload
        },
        changeTextShadowY: (state, action: PayloadAction<number>) => {
            state.subtitlesConfig.textShadow.offsetY = action.payload
        },
        changeTextShadowBlur: (state, action: PayloadAction<number>) => {
            state.subtitlesConfig.textShadow.blur = action.payload
        },
        toggleBackgroundEffect: (state) => {
            state.subtitlesConfig.textBG.enabled = !state.subtitlesConfig.textBG.enabled
        },
        toggleBlockingEffect: (state) => {
            state.subtitlesConfig.blockBG.enabled = !state.subtitlesConfig.blockBG.enabled
        },
        toggleOutlineEffect: (state) => {
            state.subtitlesConfig.textOutline.enabled = !state.subtitlesConfig.textOutline.enabled
        },
        toggleShadowEffect: (state) => {
            state.subtitlesConfig.textShadow.enabled = !state.subtitlesConfig.textShadow.enabled
        },
        setTransform: (state, action: PayloadAction<string>) => {
            state.subtitlesConfig.transform = action.payload;
        },
        clearAllEffect: (state) => {
            state.subtitlesConfig.textBG.enabled = false;
            state.subtitlesConfig.blockBG.enabled = false;
            state.subtitlesConfig.textOutline.enabled = false;
            state.subtitlesConfig.textShadow.enabled = false;
        },
        setClipScenes(state, action: PayloadAction<{ clipScenes: ClipScene[], clipSceneStatus: ClipSceneStatusEnum }>) {
            state.clipScenes = action.payload.clipScenes
            state.clipSceneStatus = action.payload.clipSceneStatus
        },
        setEditorClipScenes(state, action: PayloadAction<IEditorClipScene[]>) {
            state.editorClipScenes = action.payload
        },
        setEditorClipSceneScreens(state, action: PayloadAction<{ sceneIndex: number, screens: IEditorClipScreen[] }>) {
            const { sceneIndex, screens } = action.payload
            state.editorClipScenes[sceneIndex].screens = screens;
            state.editorClipScenes[sceneIndex].splitScreen = screens.length > 1;
        },
        setCurrentTime(state, action: PayloadAction<number>) {
            state.currentTime = action.payload
        },
    },
});

export const {
    updateConfigSubtitleFromTemplate,
    updateConfigTextOverlaysFromTemplate,
    updateConfigMediaOberlaysFromTemplate,
    updateSubtitleStylesEffectsAndColors,
    addTransformationData,
    selectTempelate,
    chooseStyles,
    setSelectedFaceIndex,
    setSelectedQuality,
    setTextOverlays,
    setMediaOverlays,
    setSubtitlesConfig,
    onWordChange,
    setWords,
    resetState,
    setSelectedId,
    clearAllEffect,
    toggleBackgroundEffect,
    toggleBlockingEffect,
    toggleOutlineEffect,
    toggleShadowEffect,
    changeTextShadowColor,
    changeTextShadowX,
    changeTextShadowY,
    changeTextShadowBlur,
    changeTextOutlineColor,
    changeTextOutlineWidth,
    alignTextLeft,
    alignTextRight,
    alignTextCenter,
    setFontFamily,
    setFontColor,
    setFontSize,
    setTransform,
    changeFontWeight,
    changeUnderline,
    changeItalic,
    capitelizeText,
    uncapitalizeText,
    changeLineHeight,
    changeBlockBGColor,
    changeBlockRadius,
    changeBlockPadding,
    changeTextBgColor,
    changeTextBgPadding,
    changeTextBgMargin,
    changeTextBgRadius,
    setDownloadingClipExport,
    changeSubtitleStylesBGColor,
    changeSubtitleStylesTextColor,
    setClipScenes,
    setCurrentTime,
    setEditorClipScenes,
    setEditorClipSceneScreens,
} = ClipEditingSlice.actions;

export const downloadClip = createAsyncThunk(
    'clipEditing/downloadClip',
    async ({ videoId, clipId, quality, props }: any, { dispatch }) => {
        const response = await clipsExportApi.createClipExport({ videoId, clipId, quality, props })
        dispatch(setDownloadingClipExport(response));
        return response;
    }
)

export const getDownloadingClipExport = createAsyncThunk(
    'clipEditing/getDownloadingClipExport',
    async (id: string, { dispatch }) => {
        const response = await clipsExportApi.getClipExport(id)
        dispatch(setDownloadingClipExport(response))
        return response;
    }
)

export const applyTemplate = createAsyncThunk(
    'clipEditing/applyTemplate',
    ({ id, temps }: { id: string, temps: any[] }, { getState, dispatch }) => {

        const {
            subtitlesConfig,
            textOverlays,
            mediaOverlays,
            subtitleStyle,
            subtitleEffectBGColor,
            subtitleEffectColor
        } = ((getState() as any).clipEditing) as IInitialState;

        dispatch(selectTempelate(id));

        const selectedTemplateData = temps.find((temp) => temp.id === id);

        let newConfig = { ...subtitlesConfig };
        if (selectedTemplateData && selectedTemplateData.subTitleConfig) {
            newConfig = selectedTemplateData.subTitleConfig;
            dispatch(updateConfigSubtitleFromTemplate(newConfig));
        }
        let newTextOverlays = textOverlays.slice();
        if (selectedTemplateData && selectedTemplateData.textOverlays) {
            newTextOverlays = selectedTemplateData.textOverlays;
            dispatch(updateConfigTextOverlaysFromTemplate(newTextOverlays));
        }
        let newMediaOverlays = mediaOverlays.slice();
        if (selectedTemplateData && selectedTemplateData.mediaOverlays) {
            newMediaOverlays = selectedTemplateData.mediaOverlays;
            dispatch(updateConfigMediaOberlaysFromTemplate(newMediaOverlays));
        }
        let newSubtitleStyles = subtitleStyle;
        let newSubtitleStylesBg = subtitleEffectBGColor;
        let newSubtitleStylesColor = subtitleEffectColor;
        if (selectedTemplateData && selectedTemplateData.subtitleStyles) {
            newSubtitleStyles = selectedTemplateData.subtitleStyles
                .subtitleStyle as ISubtitleStyleEnum;
            newSubtitleStylesBg =
                selectedTemplateData.subtitleStyles.subtitleEffectBGColor ||
                "rgba(255,0,0,1)";
            newSubtitleStylesColor =
                selectedTemplateData.subtitleStyles.subtitleEffectColor ||
                "rgba(255,0,0,1)";

            dispatch(
                updateSubtitleStylesEffectsAndColors({
                    subtitleStyle: newSubtitleStyles,
                    subtitleEffectBGColor: newSubtitleStylesBg,
                    subtitleEffectColor: newSubtitleStylesColor,
                })
            );
        }

    }
)

export const generateClipScenes = createAsyncThunk(
    'video/generateClipScene',
    async ({ videoId, id }: { videoId: string, id: string }) => {
        try {
            await videoDetailsApi.generateClipScenes({ videoId, id })
        } catch (err) {
            console.log(err)
        }
    }
)

export const updateClipSceneStatus = createAsyncThunk(
    'video/updateClipSceneStatus',
    async ({ videoId, id, videoWidth, videoHeight }: { videoId: string, id: string, videoWidth: number, videoHeight: number }, { dispatch, getState }) => {
        try {
            const clip = await videoDetailsApi.getClipById({ videoId, id })
            const formattedClipScenes = formatClipScenes({ selectedClip: clip, videoWidth, videoHeight })
            dispatch(setClipScenes({ clipScenes: formattedClipScenes, clipSceneStatus: clip.scenesStatus }))
            dispatch(setEditorClipScenes(formattedClipScenes))
        } catch (err) {
            console.log(err)
        }
    }
)